import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function ConsumerDefense() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Defensa del Consumidor</TermsTitle>
          <TermsText>
            Que en cumplimiento con la normativa vigente, n1u cumple en informar
            que, los consumidores se encuentran amparados por la Ley N°2.244 que
            establece que las personas físicas o jurídicas que comercialicen, o
            presten servicios a consumidores y/o usuarios en el ámbito de la
            C.A.B.A. y posean página de internet, deberán agregar un enlace con
            la Dirección General de Defensa y Protección al Consumidor.
            ‍Subsecretaría de Defensa del Consumidor. Para consultas y/o
            denuncias<a href="https://www.argentina.gob.ar/economia/comercio/defensadelconsumidor"> <span>ingrese aquí.</span></a> ‍Dirección General de Defensa y Protección
            al Consumidor. Para consultas y/o denuncias <a href="https://autogestion.produccion.gob.ar/consumidores"><span>ingrese aquí.</span></a>
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default ConsumerDefense;
