import React from "react";
import styled from "styled-components";
import ios from "../assets/home/homecover/IOS buttom (1).png";
import googleplay from "../assets/home/homecover/Google play buttom (1).png";
import phone from "../assets/home/homedownload/phone-download.png";
import { Link } from "react-router-dom";
const Download = () => {
  return (
    <>
      <DownloadSection>
        <DownloadContainer>
          <Content>
            <h2>
              <b>Descargá </b>ahora
              <br /> y <b> creá tu cuenta</b>
            </h2>

            <div>
              <Link
                to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                target="_blank"
              >
                <img src={ios} alt="ios" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" />
              </Link>
            </div>
          </Content>
          <ImageContainer>
            <img src={phone} alt="phone" style={{ marginBottom: "0px" }} />
          </ImageContainer>
        </DownloadContainer>
      </DownloadSection>
    </>
  );
};

const DownloadSection = styled.div`
  height: 100vh;
  width: 100%;
  margin: auto;
  background-color: black;
  background-image: url("/backgrounds/download-light-min.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;


  @media only screen and (min-width: 2000px) {
    height: auto;
  }

  @media only screen and (max-width: 800px) {
    /* height: 110vh; */
  }
`;

const DownloadContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-around;
  max-width: 1600px;


  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 502px;
  align-items: center;
  align-self: center;
  height: 50%;
  margin-top: 180px;

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 70px;
    color: #ffffff;
    font-weight: 300;
    margin-top: 5px;
    width: 100%;

    b {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
  }

  img {
    height: 70px;
    width: 210px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  @media only screen and (max-width: 1145px) {
    width: 430px;
    h2 {
      font-size: 60px;
    }
    img {
      height: 60px;
      width: 178px;
    }
  }

  @media only screen and (max-width: 1060px) {
    width: 394px;
    margin-top: 150px;

    h2 {
      font-size: 55px;
    }
    img {
      height: 50px;
      width: 155px;
    }
  }

  @media only screen and (max-width: 1015px) {
    margin-top: 180px;
  }

  @media only screen and (max-width: 960px) {
    width: 330px;
    margin-top: 150px;
    h2 {
      font-size: 45px;
    }
    img {
      height: 45px;
      width: 130px;
    }
  }

  @media only screen and (max-width: 900px) {
    margin-top: 0px;
    width: auto;
    height: 270px;
    justify-content: center;

    h2 {
      font-size: 40px;
    }
    img {
      height: 42px;
      margin-top: -20px;
    }
  }

  @media only screen and (max-width: 360px) {
    h2 {
      font-size: 37px;
    }
    img {
      height: 40px;
      margin-top: -20px;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;



  img {
    height: 600px;
    width: 500px;
  }

  @media only screen and (max-width: 1015px) {
    img {
      height: 550px;
      width: 458px;
    }
  }

  @media only screen and (max-width: 900px) {
    img {
      height: 400px;
      width: 334px;
    }
  }

  @media only screen and (max-width: 360px) {
    img {
      height: 360px;
      width: 300px;
    }
  }
`;

export default Download;
