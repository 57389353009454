import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function N1uRegister() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            Términos y Condiciones de la Promoción de registro n1u
          </TermsTitle>

          <TermsSubText>1. <b>Elegibilidad y vigencia</b></TermsSubText>

          <TermsText>
            Esta promoción está abierta a todos los usuarios de n1u que
            completen el proceso de registro entre el 08 de septiembre y el 29
            de septiembre de 2023 en Argentina.
            <br />
            <br />
            No será válido para todas aquellas personas que eliminen sus cuentas
            y quieran realizar un nuevo registro.
          </TermsText>

          <TermsSubText>2. <b>Premio</b></TermsSubText>
          <TermsText>
            Los usuarios elegibles que cumplan con los requisitos recibirán un
            pago único de $1500 (ARS) en pesos argentinos.
          </TermsText>

          <TermsSubText>3. <b>Acreditación del Premio</b></TermsSubText>

          <TermsText>
            La acreditación del premio se realizará dentro de los 30 días
            siguientes a la finalización del período de elegibilidad, en pesos
            argentinos, exclusivamente en Argentina.
            <br />
            <br />
            Se aclara, que la acreditación del premio se realizará, en la CVU
            que haya abierto el usuario ganador en el momento del proceso de
            registro.
          </TermsText>

          <TermsSubText>4. <b>Requisitos</b></TermsSubText>

          <TermsText>
            Los usuarios deben completar el proceso de onboarding según las
            pautas proporcionadas mediante la app.
          </TermsText>

          <TermsSubText>5. <b>Exclusiones</b></TermsSubText>

          <TermsText>
            Los empleados y familiares directos de n1u no son elegibles para
            participar en esta promoción en Argentina.
          </TermsText>

          <TermsSubText>6. <b>Cambios y Terminación</b></TermsSubText>

          <TermsText>
            n1u se reserva el derecho de realizar cambios en estos términos y
            condiciones o de dar por terminada la promoción en Argentina en
            cualquier momento y sin previo aviso.
          </TermsText>

          <TermsSubText>7. <b>Cumplimiento Legal</b></TermsSubText>

          <TermsText>
            Esta promoción está sujeta a todas las leyes y regulaciones
            aplicables en Argentina.
            <br />
            <br />
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>8. <b>Aceptación de Términos</b></TermsSubText>
          <TermsText>
            La participación en esta promoción implica la aceptación de todos
            los términos y condiciones establecidos aquí.
          </TermsText>

          <TermsSubText>9. <b>Cumplimiento legal</b></TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default N1uRegister;
