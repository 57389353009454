import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function FreeFire() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Términos y Condiciones Torneos Free Fire</TermsTitle>
          <TermsSubText>1. DATOS IDENTIFICATIVOS</TermsSubText>
          <TermsText>
            Los presentes términos y condiciones rigen para la participación del
            acceso al torneo “n1u- Naguará” organizado por “Naguará” (en
            adelante “el organizador”) con domicilio en Moldes 2329 CABA
            contacto: <a  href="mailto:martin@trihaus.es"><span>martin@trihaus.es</span></a> y el sponsor oficial (en
            adelante “n1u”) con domicilio a estos efectos en Ciudad de la Paz
            3252 piso 3 departamento “C”, Ciudad Autónoma de Buenos
            Aires. Correo electrónico de contacto: legales@n1u.app
          </TermsText>

          <TermsSubText>
            2. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES
          </TermsSubText>
          <TermsText>
            Quienes participan del sorteo, por su sola participación, implica el
            conocimiento y aceptación de todas sus bases y condiciones, como así
            también, de las decisiones que adopte el organizador sobre cualquier
            cuestión no prevista en las mismas, las cuales tendrán carácter de
            definitivas e inapelables.
          </TermsText>

          <TermsSubText>3. VIGENCIA y ALCANCE</TermsSubText>
          <TermsText>
            El presente campeonato tendrá vigencia en todo el territorio de la
            República Argentina (en adelante, el “territorio”). Su vigencia
            inicia el 30 de octubre de 2023 y culmina el 30 de noviembre de
            2023.
          </TermsText>

          <TermsSubText>4. PARTICIPACIÓN</TermsSubText>
          <TermsText>
            Para poder participar del campeonato, cada equipo deberá inscribirse
            en el siguiente formulario <a href="https://docs.google.com/forms/d/e/1FAIpQLSfjwLl1qdLTKy7-v6jmkxBAN5Fztu-EUvfvbwXeVqbd11btug/viewform"><span>Torneo n1u- Naguará</span></a> teniendo
            en cuenta que los equipos solo podrán ser integrados por dos
            personas.
            <br />
            <br />
            Una vez que el equipo realice la acción antes descripta, ya se
            encuentra inscripto para participar.
          </TermsText>

          <TermsSubText>
            5. MEDIOS DE INSCRIPCIÓN Y CANAL OFICIAL DE COMUNICACIÓN
          </TermsSubText>
          <TermsText>
            El único medio de inscripción oficial es el promocionado por n1u en
            sus medios de comunicación oficiales, las únicas inscripciones
            válidas son las que se realicen mediante el formulario del punto 4
            de estos términos y condiciones, cualquier otro tipo de inscripción
            no será válido, n1u NO toma cargo, ni se responsabiliza por ningún
            tipo de indemnización reclamo, costo, daño y/o perjuicio reclamado,
            causados a el/los participantes y/o sus derecho habientes, con
            motivo y en ocasión de la inscripción y/o competencia.
            <br />
            <br />
            También se aclara que el canal oficial como medio de comunicación
            del sorteo es el de Discord siendo <a href="https://discord.com/invite/stD4asQaJT"><span>
              Torneo n1u- Naguará
            </span></a>{" "}
            , no existiendo otro canal oficial, n1u NO toma cargo, ni se
            responsabiliza por ningún tipo de indemnización reclamo, costo, daño
            y/o perjuicio reclamado, causados a el/los participantes y/o sus
            derecho habientes, con motivo y en ocasión de la inscripción y/o
            competencia.
          </TermsText>

          <TermsSubText>6. PREMIO</TermsSubText>
          <TermsText>
            El programa de premios consistirá en lo siguiente:
          </TermsText>
          <TermsSubText>
            • Pareja ganadora 200.000 ARS.
            <br />• 2do Puesto 100.000 ARS.
          </TermsSubText>
          <TermsText>
            Los premios serán entregados con el siguiente mecanismo, el
            organizador se pondrá en contacto con los ganadores, por el cual le
            van a llegar las instrucciones que debe seguir para obtener el
            premio.
            <br />
            <br />
            El premio será otorgado a los ganadores en partes iguales a los
            n1uID que informaron al momento de realizar la inscripción, para la
            pareja ganadora, se entregará 100.000 ARS a cada uno y al 2do puesto
            50.000 ARS a cada uno.
            <br />
            <br />
            Sin perjuicio de los antes dicho, el organizador no responde por las
            demoras que puedan existir en la entrega del premio por causas no
            imputables al mismo.
          </TermsText>

          <TermsSubText>7. ACREDITACIÓN DEL PREMIO</TermsSubText>
          <TermsText>
            La acreditación del premio se realizará dentro de los 30 días
            siguientes a la finalización del campeonato y la elección de los
            ganadores.
          </TermsText>

          <TermsSubText>8. SPONSOR- FACULTADES:</TermsSubText>
          <TermsText>
            n1u como sponsor oficial del campeonato“n1u- Nagurá”, sin que ello
            le genere carga, obligación y/o responsabilidad alguna podrá: a)
            Modificar las presentes BASES bastando la publicación del nuevo
            instrumento en la página web <a href="/"><span>https://n1u.app/</span></a> para
            considerar notificados a los potenciales participantes, los
            participantes, los potenciales ganadores y los ganadores; b) dejar
            sin efecto o suspender esta promoción antes de la notificación a los
            ganadores; c) prorrogar cada uno de los plazos de vigencia y fechas
            fijadas antes de la finalización del periodo de inscripción previsto
            en estas bases; modificar la fecha de entrega de los premios o
            ejecución de los mismos; d) solicitar a los participantes y/o
            potenciales ganadores, en cualquier momento antes de la finalización
            de la promoción, que verifique sus datos y/o los corroboren y/o
            rectifiquen los mismos.
          </TermsText>
          <TermsSubText>9. LEYES APLICABLES Y JURISDICCIÓN</TermsSubText>
          <TermsText>
            Esta promoción está sujeta a todas las leyes y regulaciones
            aplicables en Argentina.
            <br />
            <br />
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>10. CUMPLIMIENTO LEGAL</TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
            <br />
            <br />
            <b>
              Las siguientes cláusulas corresponden al reglamento de Naguará
            </b>
            <br />
            <br />
            Reglamento Torneo N1U Naguará
          </TermsText>
          <TermsSubText>1. Equipamiento y software</TermsSubText>
          <TermsText>
            1.1. Equipamiento en partidas en línea. Se espera que los jugadores
            proporcionen la totalidad de su propio equipamiento, siendo ellos
            los exclusivos responsables de: la protección y funcionamiento de su
            celular, estabilidad de la conexión a Internet, el porcentaje y
            estado de la batería, actualizar el juego y que su dispositivo lo
            pueda ejecutar, el rendimiento del juego en sus dispositivos y
            periféricos. El torneo no será responsable por aquellas afecciones
            que perjudiquen la funcionalidad del equipamiento.
            <br />
            <br />
            1.2. Emuladores. No se permite jugar en emuladores, los Árbitros se
            reservan el derecho de sancionar a un jugador o equipo si se detecta
            que juega con emulador de cualquier tipo y en cualquier dispositivo
            diferente a los permitidos .
            <br />
            <br />
            1.3. Versión de juego. Cada partida se jugará con la versión
            disponible actual del juego.
            <br />
            <br />
            1.4. Sistema de comunicación (Discord). El sistema de comunicación
            que se utilizará será Discord. El uso de este sistema de
            comunicación será opcional para los jugadores durante las partidas
            del torneo, pero será obligatorio estar presentes en los canales de
            chat para recibir información. Los enlaces a servidor de discord así
            como reglas de su uso serán enviados por los Árbitros del torneo
            previo al inicio del torneo. Descarga iOS & Descarga Android.
          </TermsText>
          <TermsSubText>2. Inscripción</TermsSubText>
          <TermsText>
            La inscripción al torneo se realizará a través de un formulario que
            dará acceso al Discord oficial del torneo. Dicho formulario tendrá
            ciertos requisitos a definir entre n1u y Naguará y serán comunicados
            al momento del lanzamiento del torneo.
          </TermsText>

          <TermsSubText>3. Formato de competición</TermsSubText>
          <TermsText>
            3.1. Detalles de cada fase.
            <br />
            <br />
            3.1.1. Fase Regular. Fase en línea que consta de 320 equipos
            divididos en 32 grupos, donde clasifica el mejor de cada grupo a la
            siguiente ronda.
            <br />
            <br />
            3.2.1.1 Semi finales. los 32 mejores equipos juegan 1 partida en
            mapa abierto donde van a pasar los 10 mejores a la final.
            <br />
            <br />
            3.2.1.2. Final. Se jugarán 5 partidas donde por sumatoria de puntos
            se obtendrán los posicionamientos.
            <br />
            <br />
            3.2.1.3. Formato de partida. La partida se creará en el formato
            “Liga”, modo “Dúo”, lista de suministros “Clásico”, Ráfaga.
            <br />
            <br />
            3.2.1.4. Desempate en la tabla de clasificación. En caso de empate
            de 2 o más equipos en el puntaje global, se tomará en cuenta la suma
            de asesinatos, ordenando a los equipos empatados de mayor a menor
            número de asesinatos. De seguir empatados se definirá por la
            posición obtenida en la última partida.
            <br />
            <br />
            3.3. Remake. La organización tiene la potestad de decidir, según su
            mejor y exclusivo criterio, determinar el remake de una partida con
            el fin de velar por la correcta integridad deportiva o ante fallos
            en el servidor de juego.
          </TermsText>

          <TermsSubText>4. Proceso de enfrentamientos</TermsSubText>
          <TermsText>
            4.1. Papel de los Árbitros. Son los miembros del torneo que tienen
            la responsabilidad de juzgar cada asunto, pregunta y situación que
            ocurra antes, durante e inmediatamente después de cada juego. Las
            decisiones de los Árbitros son definitivas y no podrán ser
            discutidas ni cambiadas una vez tomadas.
            <br />
            <br />
            4.2. Cambios en el calendario. El torneo puede, a su consideración,
            reorganizar el horario de las series de un día dado y/o cambiar la
            fecha de un combate del torneo a una fecha u hora distinta. En caso
            de modificaciones los Árbitros notificarán a todos los equipos a la
            mayor brevedad posible.
            <br />
            <br />
            4.3. Asistencia y tolerancia. Para la primera partida del día los
            equipos deberán presentarse al Discord 1 hora antes de la hora
            indicada para el inicio de la partida y en la sala de juego 30
            minutos antes el cual se enviará el ID y Password de SALA por
            discord . La tolerancia será de 15 minutos, es decir que deberán
            presentarse a más tardar 10 minutos antes de la hora indicada en el
            calendario o serán sancionados. Aquellos equipos que no cumplan con
            los plazos establecidos recibirán una penalización. Esta
            penalización será acumulativa y perdurará durante toda la
            competencia por lo que la reincidencia generará que se aplique la
            siguiente sanción en el listado de sanciones Listado de sanciones 1°
            – Warning 2° – Quita de 5 puntos de la tabla general de posiciones
            3° – Quita de 10 puntos de la tabla general de posiciones 4° – Quita
            de 20 puntos de la tabla general de posiciones 5° – Descalificación
            de la ronda (el equipo no podrá participar en esa ronda)
            Descalificación de la Jornada (el equipo no podrá participar en esa
            jornada y se considera como derrota por inasistencia) 7° –
            Descalificación de la competencia En caso de no participar en 2 o
            más partidas de una serie en una jornada se le otorgará una derrota
            por inasistencia al equipo y no recibirá puntos en esa jornada.
            <br />
            <br />
            4.4. Equipos completos. Se le permitirá a los equipos que no reúnan
            a 2 jugadores competir con no menos de 2. Para tales efectos, la
            sala será creada en el formato “Liga” con idénticas configuraciones
            de sala y partida.
            <br />
            <br />
            4.5. Creación del lobby de juego. Los Árbitros serán los
            responsables de crear la sala de juego oficial y compartir la
            información en Discord para que los equipos puedan unirse. Los
            equipos son responsables de estar atentos a la información de la
            sala y unirse por su propia cuenta.
            <br />
            <br />
            4.6. Restricciones de elementos de juego. Se podrán agregar
            restricciones en cualquier momento antes o durante una serie, si hay
            errores conocidos de algún elemento del juego. El hecho de ignorar
            estas restricciones sea de forma deliberada o intencionada podrá ser
            motivo de derrota, pérdida de puntos y/o expulsión del equipo de la
            competición.
            <br />
            <br />
            4.7. Resultados. Al final de cada partida los Árbitros son los
            responsables de hacer el reporte de resultados y guardar las pruebas
            de la partida.
            <br />
            <br />
            4.8. Sin transmisiones. En caso de haber transmisión oficial del
            torneo no se permitirá que los jugadores transmitan sus partidas
            públicamente. Los jugadores solo pueden transmitir sus grabaciones
            una vez terminada la transmisión oficial.
          </TermsText>

          <TermsSubText>5. Desconexiones y pausas</TermsSubText>
          <TermsText>
            5.1. Desconexiones. Si un participante es desconectado debido a
            problemas de red, se les permite volver a conectarse al juego
            mientras el partido sigue en curso. Los participantes no pueden
            solicitar una pausa o un remake debido a esta razón.
            <br />
            <br />
            5.2. Desconexiones intencionales. Si un participante se desconecta
            intencionalmente de la partida el equipo será sancionado con la
            quita de 10 puntos del puntaje global. Queda a la exclusiva
            discreción de la Liga determinar cuál situación constituye o no una
            desconexión intencional.
            <br />
            <br />
            5.3. Ping alto. El participante es el único responsable por la
            calidad de su conexión a internet. Los participantes no pueden
            solicitar una pausa o un remake debido a ping alto o malas
            condiciones de red.
            <br />
            <br />
            5.4. Pausas. Solo los Árbitros pueden considerar una pausa, si se
            detectan razones que no permitan que el juego transcurra con
            normalidad. Algunos ejemplos de razones que ameritan una pausa son:
            fallo en el servidor de juegos y que todos los jugadores no pueden
            conectarse, o si 10 jugadores o más se desconectan al mismo tiempo.
            <br />
            <br />
            5.5. Reanudación de la partida. Después de una pausa y que se hayan
            resuelto los problemas, los Árbitros serán los responsables de
            reanudar la partida dando aviso a los equipos por medio del chat
            general de Discord 60 segundos antes de ejecutar la acción de
            reanudación. Los equipos son responsables de estar alertas a los
            mensajes de parte de los Árbitros.
          </TermsText>

          <TermsSubText>
            6. Premios.
            <br />
            <br />• Pareja ganadora 200.000 ARS.
            <br />• 2do Puesto 100.000 ARS.
          </TermsSubText>
          <TermsSubText>7. Código de conducta</TermsSubText>
          <TermsText>
            7.1. Conductas o acciones no permitidas. Las siguientes acciones
            están totalmente prohibidas y serán sujetas a sanciones a discreción
            de los Árbitros.
            <br />
            <br />
            7.1.1. Confabulación. Cualquier acuerdo o acción entre 2 o más
            jugadores o equipos para dejar en desventaja a los contrincantes o
            beneficiarse de un resultado.
            <br />
            <br />
            7.1.2. Hackeo. Cualquier modificación al cliente de juego.
            <br />
            <br />
            7.1.3. Aprovechamiento. Usar de manera intencionada cualquier error
            dentro del juego para tratar de sacar ventaja.
            <br />
            <br />
            7.1.4. Obscenidad. Usar lenguaje obsceno, vil, vulgar, insultante,
            amenazador, abusivo, injurioso, calumnioso o difamatorio.
            <br />
            <br />
            7.1.5. Acoso. Actos sistemáticos, hostiles y repetidos que se
            realizan para aislar o excluir a una persona o afectar su dignidad.
            <br />
            <br />
            7.1.6. Discriminación y denigración. Ofender la dignidad o
            integridad de un país, persona privada o grupo de personas por medio
            de palabras o acciones despectivas, discriminatorias o denigrantes a
            causa de la raza, color de piel, etnia, origen nacional o social,
            género, idioma, religión, orientación sexual, opinión política o de
            otro tipo, estado financiero, nacimiento u otro estado.
            <br />
            <br />
            7.1.7. Declaraciones negativas. Hacer, publicar o apoyar cualquier
            declaración o acción que tenga o esté designada a tener un efecto
            perjudicial o dañino..
            <br />
            <br />
            7.1.8. Actividad criminal. Estar involucrado en actividades que
            estén prohibidas por una ley, estatuto o tratado común.
            <br />
            <br />
            7.1.9. Soborno. Ofrecer regalos a un jugador, entrenador, director,
            árbitro, empleado del torneo o a otra persona relacionada a la
            Competición por servicios que beneficien en los resultados de la
            competencia.
            <br />
            <br />
            7.1.10. Engaño. Presentar evidencias falsas o mentir a los árbitros.
            <br />
            <br />
            7.1.11. Confidencialidad. Ningún miembro de los equipos puede
            revelar ninguna información confidencial proporcionada por el
            torneo.
            <br />
            <br />
            7.2. Derecho a publicar. El torneo tendrá el derecho de emitir una
            declaración en la que exponga la sanción a un equipo.
            <br />
            <br />
            7.3. Sanciones. Luego de descubrir que cualquier miembro de equipo
            ha cometido una violación a las reglas, el torneo puede, sin
            limitación de su autoridad, aplicar una o cuantas desee de las
            siguientes sanciones al jugador y/o equipo: ● Warning ● Quita de
            puntos ● Pérdida de una partida o serie, tanto disputada como por
            disputarse. ● Pérdida de los premios ● Suspensión de partidos ●
            Suspensión de jornadas ● Descalificación de la competencia Las
            infracciones repetidas serán sujeto de sanciones escaladas, hasta, e
            incluyendo, descalificación de futuras participaciones en el torneo.
            Debe notarse que las sanciones puede que no siempre sean impuestas
            de una manera sucesiva. El torneo, a su criterio, por ejemplo, puede
            descalificar a un jugador por una primera ofensa si la acción de
            dicho jugador se considera suficientemente ofensiva como para
            merecer la descalificación del torneo.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default FreeFire;
