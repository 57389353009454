import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function YoungMiko() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            Términos y Condiciones Sorteo entradas show Young Miko
          </TermsTitle>
          <TermsSubText>1. ORGANIZADOR:</TermsSubText>
          <TermsText>
            Los presentes términos y condiciones rigen el sorteo “Sorteo
            entradas para el show de Young Miko” y es organizado por n1u (en
            adelante “el organizador”), con domicilio legal en Cdad. de la Paz
            3252, CABA.
          </TermsText>

          <TermsSubText>2. ACEPTACIÓN DE LAS BASES:</TermsSubText>
          <TermsText>
            Quienes participan del sorteo, por su sola participación, implica el
            conocimiento y aceptación de todas sus bases y condiciones, como así
            también, de las decisiones que adopte el organizador sobre cualquier
            cuestión no prevista en las mismas, las cuales tendrán carácter de
            definitivas e inapelables.
          </TermsText>

          <TermsSubText>3. ALCANCE Y VIGENCIA:</TermsSubText>
          <TermsText>
            La presente promoción tendrá vigencia en todo el territorio de la
            República Argentina (en adelante, el “territorio”). Su vigencia
            inicia el 17 de octubre de 2023 y culmina el 24 de octubre de 2023 a
            las 17:00 pm.
          </TermsText>

          <TermsSubText>4. PARTICIPACIÓN:</TermsSubText>
          <TermsText>
            Podrán participar del sorteo todas las personas humanas mayores de
            CATORCE (14) años que residan en el territorio.
            <br />
            <br />
            Los menores de 17 años deberán ser acompañados por un mayor a los
            fines de que sea permitido el ingreso al show.
            <br />
            <br />
            Quedan excluidos expresamente de la participación del presente
            sorteo los directores de la empresa organizadora, el personal
            dependiente de ella y sus familiares directos, los directores de sus
            agencias de publicidad y el personal dependiente de ellas, y los
            directores de sus proveedores y personal dependiente de ellos.
          </TermsText>
          <TermsSubText>5. MECÁNICA DE PARTICIPACIÓN:</TermsSubText>
          <TermsText>
            Para participar del presente sorteo deberán seguir en la red social
            INSTAGRAM a @n1u.app y además realizar un comentario etiquetando a
            dos amigos, en la publicación referida al sorteo de las entradas
            para el show de Young Miko. Todos los requisitos exigidos en la
            publicación deberán estar completos para que la participación tenga
            validez. El organizador se reserva el derecho de anular aquellas
            participaciones que no se encuentren correctamente realizadas.
            <br />
            <br />
            Cualquier interesado, a través de los canales de comunicación, podrá
            solicitar información acerca de los alcances y modalidad del sorteo.
          </TermsText>

          <TermsSubText>6. SELECCIÓN DE LOS GANADORES:</TermsSubText>
          <TermsText>
            Entre todas las personas que hayan cumplimentado los requisitos para
            participar conforme se establece en el punto 5 de estos términos y
            condiciones, mediante un sistema informático de selección aleatoria,
            se seleccionarán DOS (2) ganadores.
            <br />
            <br />
            El ganador del sorteo será notificado mediante INSTAGRAM STORIES. En
            caso de que el ganador no respondiera al mecanismo de contacto
            anteriormente mencionado, perderá la posibilidad de recibir el
            premio y nada podrá reclamar al organizador. Ante dicha
            circunstancia, el organizador se contactará con el suplente
            correspondiente, de acuerdo a lo estipulado en la presente cláusula.
            En caso de que el potencial ganador suplente tampoco diera
            cumplimiento al requerimiento, el premio quedará automáticamente y
            sin más en poder del organizador en concepto de premio vacante.
            <br />
            <br />
            La adjudicación del premio identificado en el punto 8 quedará
            condicionada a que el ganador haya completado los requisitos del
            punto 5. Cumplidas las condiciones mencionadas, el ganador será
            considerado ganador del premio.
            <br />
            <br />
            Todo premio no adjudicado quedará en poder del organizador.
          </TermsText>

          <TermsSubText>7. DEL LUGAR Y FECHA DEL SORTEO:</TermsSubText>

          <TermsText>
            El sorteo correspondiente se realizarán mediante un sistema
            informático de selección aleatoria, siendo{" "}
          <a href="https://app-sorteos.com/es/apps/sorteo-instagram-multicuenta">  <span>
              https://app-sorteos.com/es/apps/sorteo-instagram-multicuenta
            </span></a>
            , el día 24/10/2023 a las 18hs, anunciandose el ganador en la cuenta
            de INSTAGRAM de @n1u.app
          </TermsText>
          <TermsSubText>8. PREMIO:</TermsSubText>
          <TermsText>
            El programa de premios consistirá en la entrega de 2 (DOS) entradas
            al ganador, para concurrir al show de Young Miko a realizarse en el
            Complejo Art Media, Av. Corrientes 6271, CABA, Argentina, el ganador
            podrá elegir una de las fechas en que se desarrollará el show siendo
            28/10/2023 o 29/10/2023, no siendo posible una vez seleccionada
            realizar cambio alguno.
            <br />
            <br />
            El premio será entregado al ganador de acuerdo a los pasos que el
            organizador le indique, los cuales deberán ser cumplidos sin
            excepción a fin de obtener el premio.
            <br />
            <br />
            El organizador se pondrá en contacto con el ganador, a través de la
            misma plataforma por la cual éste participó y por el cual le van a
            llegar las instrucciones que debe seguir para obtener el premio.
            <br />
            <br />
            Sin perjuicio de los antes dicho, el organizador no responde por las
            demoras que puedan existir en la entrega del premio por causas no
            imputables al mismo.
            <br />
            <br />
            El organizador, no se hará responsable de los gastos que pudiera
            ocasionarle al ganador, el traslado hacía el “Show de Young Miko” a
            realizarse en Av. Corrientes 6271, CABA, Argentina, (Complejo Art
            Media) ni tampoco se hará responsable en el caso de suspensión del
            mismo.
          </TermsText>

          <TermsSubText>9. AUTORIZACIÓN:</TermsSubText>
          <TermsText>
            El ganador de la presente promoción, por su sola participación,
            autoriza expresamente al organizador a difundir sus datos
            personales, localidad donde viven, imágenes, fotografías, a los
            fines publicitarios, en los medios de comunicación y formas que el
            organizador disponga durante toda la vigencia de la presente
            promoción y hasta transcurrido CIENTO OCHENTA (180) días de su
            finalización., sin tener por ello derecho a recibir compensación
            alguna.
            <br />
            <br />
            Asimismo, los participantes del presente sorteo, por el simple hecho
            de participar, autorizan al organizador a utilizar sus datos para
            incorporarlos en una base de datos, garantizando fielmente el
            organizador confidencialidad en la recopilación de dichos datos,
            junto a su exclusivo uso y acceso. Al aceptar las bases y
            condiciones, el participante confiere el consentimiento previsto en
            los artículos 5 y 11 de la Ley de Protección de Datos Personales N°
            25.326
          </TermsText>

          <TermsSubText>10. ORGANIZADOR- FACULTADES:</TermsSubText>
          <TermsText>
            El organizador, sin que ello le genere carga, obligación y/o
            responsabilidad alguna podrá: a) Modificar las presentes BASES
            bastando la publicación del nuevo instrumento en la página web{" "}
            <a href="/"><span>https://n1u.app/</span></a> para considerar notificados a los
            potenciales participantes, los participantes, los potenciales
            ganadores y los ganadores; b) dejar sin efecto o suspender esta
            promoción antes de la notificación a los ganadores; c) prorrogar
            cada uno de los plazos de vigencia y fechas fijadas antes de la
            finalización del periodo de inscripción previsto en estas bases;
            modificar la fecha de entrega de los premios o ejecución de los
            mismos; d) solicitar a los participantes y/o potenciales ganadores,
            en cualquier momento antes de la finalización de la promoción, que
            verifique sus datos y/o los corroboren y/o rectifiquen los mismos.
          </TermsText>
          <TermsSubText>11. JURISDICCIÓN:</TermsSubText>
          <TermsText>
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>12. CUMPLIMIENTO LEGAL</TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default YoungMiko;
