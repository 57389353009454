import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsQuestions,
} from "../styles/texts";

function General() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Términos y condiciones Generales del Servicio</TermsTitle>
          <TermsText>
            Estos términos y condiciones de uso y sus anexos (denominados como
            “Términos y Condiciones de Uso”, constituyen un acuerdo entre una
            persona (en adelante, “Usuario” o, en plural, “Usuarios”) que desee
            acceder al servicio de aplicación n1ulevel de Blockchain Payments
            Rails S.A. (“n1u”) cuit 30-71750667-3 con domicilio legal en Ciudad
            de la Paz 3252 Piso 3 Departamento C, Ciudad Autónoma de Buenos
            Aires, Argentina – código postal C1429AOD. El Usuario debe leer,
            entender y aceptar las condiciones de estos Términos y Condiciones
            de Uso y sus políticas/principios incorporados a los mismos, previo
            a su registro como Usuario de n1u o la utilización de los Servicios.
            La utilización de los Servicios implica la aceptación por parte del
            Usuario de estos Términos y Condiciones de Uso, donde se describen
            los derechos, responsabilidades y obligaciones del Usuario y de n1u
            al utilizar la aplicación, el servicio y cualquier producto,
            funcionalidad o servicio relacionado (cualquiera de ellos,
            indistintamente, el “Servicio” y conjuntamente, los “Servicios”,
            según corresponda).
          </TermsText>

          <TermsQuestions>
            1- Registro para el uso de los Servicios
          </TermsQuestions>

          <TermsText>
            1. <b>Registro.</b> Es requisito completar el formulario de registro
            con información personal exacta, precisa, verdadera, íntegra y
            auténtica (“Datos Personales”), en carácter de declaración jurada.
            Completado el registro y aceptado por n1u, quien se registre será
            considerado como el “Usuario”. El Usuario asume el compromiso de
            actualizar los Datos Personales periódicamente, conforme resulte
            necesario. n1u no se responsabiliza ante el Usuario o frente a
            terceros por la veracidad de los Datos Personales provistos
            provistos por sus Usuarios.
            <br />
            <br />
            n1u se reserva el derecho de solicitar comprobantes o información
            adicional a efectos de corroborar la información entregada por un
            Usuario en materia de Datos Personales y suspender temporal o
            definitivamente a aquellos Usuarios cuyos datos no hayan podido ser
            confirmados.
            <br />
            <br />
            n1u es Sujeto Obligado ante la Unidad de Información Financiera
            (“UIF”), de acuerdo a la Resolución 76/2019. En función a ello, y en
            cumplimiento de las regulaciones vigentes en materia de Prevención
            del Lavado de Dinero y Financiamiento del Terrorismo (“PLDyFT”), se
            encuentra obligado a recabar cierta información y/o documentación de
            los Usuarios, la cual deberá mantenerse actualizada, para dar
            cumplimiento a procesos de identificación, verificación,
            conocimiento y monitoreo de los mismos, los que podrán variar, de
            acuerdo con la actividad del Usuario. n1u se reserva el derecho de
            rechazar una solicitud de registro o de cancelar/suspender, temporal
            o definitivamente una Cuenta de n1u, en los siguientes supuestos:i)
            en caso de detectar incongruencia/inconsistencia en la información
            provista por un Usuario o actividad inusual o sospechosa del Usuario
            en materia de PLDyFT o de fraude; ii)en caso que el Usuario no
            proporcione o no actualice en tiempo y forma la información o
            documentación requerida, sin que tal decisión genere para el Usuario
            derechos de indemnización o resarcimiento; iii) en caso de que el
            Usuario se encuentre sujeto a Sanciones, o
            situado-constituido-residente en un país o territorio sujeto a
            Sanciones,; iv) en caso de que el Usuario estuviese o pudiese estar
            relacionado con actividades de naturaleza delictiva.
            <br />
            <br />
            De conformidad con lo dispuesto en el Artículo 21 de la Resolución
            de UIF 76/2019, complementarias y modificatorias, el Usuario acepta
            expresamente que n1u podrá compartir los legajos de
            información-documentación del Usuario a otras sociedades del mismo
            grupo económico, a fines de dar cumplimiento a obligaciones
            regulatorias.
            <br />
            <br />
            Cuenta en n1u. Al registrarse en n1u el Usuario abrirá una cuenta en
            n1u (la “Cuenta en n1u “). La Cuenta en n1u permitirá al Usuario
            utilizar los Servicios junto con cualquier otra funcionalidad que en
            un futuro se ofrezca al Usuario, sujeto a los términos y condiciones
            que se establezcan para cada funcionalidad. Para la utilización de
            los Servicios, n1u podrá generar automáticamente una Clave Virtual
            Uniforme (CVU) vinculada a la Cuenta de n1u del Usuario.
            <br />
            <br />
            A los fines de la registración, n1u podrá requerir al Usuario el
            ingreso de sus datos biométricos para ser comparados con registros
            de terceros (RENAPER u otro que lo reemplace), incluyendo la
            posibilidad de n1u de resguardar dichos datos biométricos para uso o
            validación futura.
            <br />
            <br />
            La Cuenta es personal, única e intransferible, asignada al Usuario y
            prohibida su explotación por cuenta de terceros, su venta, cesión o
            transferencia (incluyendo la reputación) excepto la autorización a
            operar respecto de algún servicio al adolescente a su cargo. El
            Usuario podrá asociar a su Cuenta de n1u solo medios de pago de su
            misma titularidad, tales como tarjetas de crédito, débito, cuentas
            bancarias (CBU o ALIAS) u otras cuentas virtuales (CVU o ALIAS). El
            Usuario no podrá permitir ni autorizar el uso de su Cuenta por
            terceras personas, excepto la autorización a operar respecto de
            algún servicio al adolescente a su cargo. Cualquier referencia a
            dinero en n1u se entenderá hecha a la moneda de curso legal en la
            República Argentina, salvo en los casos que se indique expresamente
            otra moneda.
            <br />
            <br />
            El Usuario brinda su consentimiento para enrolar vincular la/s
            cuenta/s a la vista/de pago que usará en la aplicación de n1u. El
            cliente reconoce que la aplicación es provista por n1u.
            <br />
            <br />
            Asimismo, El Usuario declara y reconoce que todas las cuentas a la
            vista y/o cuentas de pago enroladas son de su titularidad.
            <br />
            <br />
            Toda instrucción de pago o de débito ordenada por el Usuario
            mediante la aplicación de n1u se cursará mientras esté vigente el
            consentimiento o la autorización otorgada por el Usuario.
            <br />
            <br />
            Oportunamente, El Usuario tendrá la posibilidad de establecer y
            modificar parámetros de uso de su cuenta/s a la vista/de pago en la
            aplicación.
            <br />
            <br />
            Por último, podrá desvincular la cuenta a la vista/de pago enrolada
            a través del Centro de Ayuda: ayuda@n1u.app o al t.e. indicado en la
            plataforma de acuerdo a los procedimientos detallados en los
            Términos y Condiciones presentes y los que se establezcan a futuro.
            <br />
            <br />
            El Usuario accederá a su Cuenta de n1u mediante el ingreso de la
            dirección de e-mail registrada y clave de seguridad personal elegida
            (“Clave de Ingreso”). n1u podrá requerir confirmaciones adicionales
            aleatorias para validación del Usuario. El Usuario se obliga a
            mantener la confidencialidad de su Clave de Ingreso y Código de
            Seguridad (“Credenciales”). En virtud de ello, el Usuario será el
            único y exclusivo responsable por todas las operaciones efectuadas
            en su Cuenta de n1u mediante la utilización de las Credenciales. El
            Usuario se compromete a notificar a n1u en forma inmediata y por
            medio idóneo y fehaciente, de cualquier uso no autorizado de su
            Cuenta, así como del ingreso o de intentos de ingreso por terceros
            no autorizados a la misma.
            <br />
            <br />
            En el sitio institucional web se encuentran los términos y
            condiciones aquí mencionados y el botón de baja mediante el cual el
            Usuario podrá solicitar la rescisión del servicio. Asimismo el
            Usuario podrá revocar la aceptación del producto o servicio dentro
            del plazo de diez días hábiles.
          </TermsText>

          <TermsQuestions>
            2- Procesamiento de pagos. Mandato Contrato de Procesamiento de
            Pagos.
          </TermsQuestions>

          <TermsText>
            El Usuario y n1u (conjuntamente, las “Partes”), podrán celebrar de
            tiempo en tiempo contratos de procesamiento de pagos (cada uno, una
            “Solicitud de Procesamiento de Pago”) mediante los cuales brinde
            servicios de procesamiento de pagos, transferencias, recargas, etc.
            de acuerdo a las instrucciones del Usuario (los “Servicios de
            Procesamiento de Pagos”), a fin de pagar, efectuar recargas o
            transferir saldos o percibir por cuenta y orden del Usuario, a
            través de la aplicación brindada (en adelante la “aplicación”)
            <br />
            <br />
            <b>Mandato Irrevocable.</b> Al completar una Solicitud de
            Procesamiento de Pago, el Usuario otorgará un mandato irrevocable
            para i) pagar por su cuenta y orden una determinada suma de dinero
            en relación a la cual se complete la Solicitud de Procesamiento de
            Pago (los “Fondos”). El mandato irrevocable otorgado por el Usuario
            implica una autorización a favor de n1u para disponer en su nombre
            de ciertos Fondos de su Cuenta y transferirlos a la Cuenta del
            destinatario designado de acuerdo con sus instrucciones. Asimismo,
            el mandato irrevocable implica una autorización del Usuario para
            recibir, cobrar y acreditar en su Cuenta o en la cuenta que el
            Usuario designe los Fondos de acuerdo con sus instrucciones. n1u
            acreditará y mantendrá los Fondos del Usuario en su Cuenta en las
            condiciones y plazos establecidos en estos Términos y Condiciones de
            Uso y/u otras condiciones especiales o particulares que se
            establezcan para otros productos o particulares que se establezcan
            para otros productos o servicios.
            <br />
            <br />
            <b>Celebración de la Solicitud de Procesamiento de Pagos.</b> La
            Solicitud de Procesamiento de Pagos se efectuará por el Usuario en
            forma on-line y mediante la utilización de la aplicación. n1u se
            reserva el derecho de no procesar aquellas Solicitudes de
            Procesamiento de Pago que estén incompletas o en las cuales haya
            discrepancias entre los datos provistos por los Usuarios y los datos
            ingresados efectivamente a n1u o porque n1u lo considere necesario,
            sin necesidad de justificar su decisión. El Usuario es el exclusivo
            responsable por las instrucciones de pago y sus consecuencias. n1u
            no verificará la causa u obligación que originó la instrucción de
            pago, ni las demás circunstancias relativas a la instrucción de
            pago. Las instrucciones de pago introducidas en una Solicitud de
            Procesamiento de Pagos sólo podrán efectuarse a través de la
            aplicación según lo previsto en estos Términos y Condiciones.
            <br />
            <br />
            <b>Perfeccionamiento de la Solicitud.</b> La Solicitud de
            Procesamiento de Pago no se considerará perfeccionada y n1u no
            asumirá responsabilidad u obligación alguna bajo la Solicitud de
            Procesamiento de Pago hasta tanto i) n1u no haya aceptado la
            Solicitud de Procesamiento de Pago del Usuario (se aclara que n1u
            podrá negarse a cumplir con la Solicitud de Procesamiento de Pago
            sin dar motivo o razón alguna y por ello no podrá imputársele
            responsabilidad alguna), ii) se encuentren disponibles en la Cuenta
            la totalidad de los Fondos.
            <br />
            <br />
            <b>Responsabilidad por la Solicitud.</b> n1u no será responsable por
            órdenes, instrucciones, Solicitudes de Procesamiento de Pago y/o
            pagos equivocados o incompletos causados por la introducción errónea
            de los datos identificatorios de la Cuenta, información relevante
            del destinatario o de la operación de pago, efectuados por el
            Usuario.
            <br />
            <br />
            El Usuario asume, reconoce y acepta su total responsabilidad por las
            pérdidas que se originen, en especial si a través de su nombre de
            Usuario se impartieran instrucciones fraudulentas, duplicadas, no
            autorizadas o meramente erróneas, así como por los eventuales
            perjuicios que pueda causar a n1u.
            <br />
            <br />
            El Usuario declara aceptar los riesgos de que terceros no
            autorizados accedan a la información correspondiente a su Cuenta,
            por lo cual exonera de todo tipo de responsabilidad a n1u,
            conociendo que n1u ha realizado sus mejores esfuerzos técnicos a los
            efectos de que la información incluida en el sistema mantenga su más
            alto grado de confidencialidad posible.
            <br />
            <br />
            <b>Responsabilidad por las instrucciones de pago.</b> n1u no será
            responsable ni garantizará el cumplimiento de las obligaciones que
            hubiesen asumido los Usuarios con terceros en relación a los pagos a
            efectuar o a recibir a través de la aplicación. El Usuario reconoce
            y acepta que al realizar transacciones con otro Usuario o tercero lo
            hace por su propia voluntad, prestando su consentimiento libremente
            y bajo su propio riesgo y responsabilidad. En ningún caso n1u será
            responsable por cualquier daño o perjuicio que sufra el Usuario,
            debido a transacciones realizadas o no a través de la aplicación.
            n1u es ajeno a la obligación que originó a la Solicitud de
            Procesamiento de Pago, n1u no será responsable ni verificará las
            causas, importe o cualquier otra circunstancia relativa a dicha
            Solicitud de Procesamiento de Pago, así como respecto de la
            existencia-calidad-cantidad-funcionamiento-estado-integridad-legitimidad
            de los bienes/servicios adquiridos por los Usuarios y pagados
            utilizando el servicio, así como de la capacidad para contratar de
            los Usuarios y la veracidad de los datos personales ingresados. En
            caso que uno o más Usuarios o algún tercero inicien reclamo o acción
            legal contra otro Usuario, todos los Usuarios involucrados en dichos
            reclamos/acciones eximen de toda responsabilidad a n1u y a sus
            directores-gerentes-empleados-agentes-operarios-representantes y Los
            Usuarios tendrán idéntica asunción de responsabilidad en relación al
            medio de pago utilizado para sus transacciones, n1u no garantiza el
            cumplimiento de las obligaciones de los Usuarios con terceros en
            relación a los pagos a efectuar o a recibir a través de la
            aplicación o en forma presencial en tanto estuviere habilitado.
            <br />
            <br />
            <b>Utilización de los Servicios.</b> El Usuario que utilice los
            Servicios como alternativa para el pago de los bienes/servicios
            declara conocer y aceptar que no podrá: i)realizar transacciones por
            nada adicional a la concreción de operación genuina propia de su
            giro comercial, o envío o recepción de dinero o pago de servicio
            ofrecido por n1u; ii)imponer un mínimo o un máximo de valor para la
            realización de transacciones; discriminar y/o inducir a los Usuarios
            a utilizar un medio de pago con preferencia a otro; iv) permitir
            desdoblar una misma transacción en dos o más operaciones; v) aceptar
            transacciones de operaciones que no sean efectuadas en virtud de
            operaciones de bienes o servicios que comercializa en forma directa
            con el Usuario Comprador; vi) realizar transacción ilegal; vii)
            solicitar el procesamiento de reversiones o contra cargos para
            compras realizadas con tarjeta de crédito en una tarjeta diferente
            de la tarjeta que haya sido utilizada para concretar la compra, ni
            aceptar dinero por parte del Usuario que hubiera efectuado la compra
            del bien o servicio (el “Usuario Comprador”) en relación a una
            reversión o contra cargo.
          </TermsText>

          <TermsQuestions>
            3- Ingreso, aplicación y retiro de los fondos
          </TermsQuestions>

          <TermsQuestions>Condiciones Generales</TermsQuestions>

          <TermsText>
            Los ingresos de fondos a la Cuenta se efectuarán por alguno de los
            siguientes medios: a) mediante transferencias de fondos efectuada
            por el propio Usuario o terceros a la CVU asignada; b)mediante la
            acreditación de los pagos recibidos por el Usuario con motivo de
            alguno de los Procesamientos de Pago efectuado a favor del Usuario;
            c)mediante cualquier otro medio físico o presencial que n1u aceptare
            en el futuro.
            <br />
            <br />
            Una vez aceptada y autorizada por n1u una Solicitud de Procesamiento
            de Pago del Usuario, el Usuario deberá tener disponible la cantidad
            de dinero necesaria para poder cumplir con la instrucción de pago
            con más los impuestos asociados a la transacción, de corresponder.
            Los fondos pendientes de liquidación o retenidos no forman parte del
            dinero acreditado y disponible del Usuario.
            <br />
            <br />
            Una vez autorizada una Solicitud de Procesamiento de Pago, los
            Fondos quedarán en estado pendiente de liquidación, y se acreditarán
            en la Cuenta del Usuario en los plazos y términos previstos en el
            inciso b).
            <br />
            <br />
            n1u dejará pendiente de liquidación en la Cuenta del Usuario el
            importe que efectivamente haya sido autorizado por el medio de pago
            utilizado, ello independientemente del monto declarado por el
            Usuario en aquellos supuestos en que el medio de pago elegido
            permita realizar una declaración por un monto de dinero distinto al
            realmente autorizado.
            <br />
            <br />
            En su Cuenta el Usuario podrá recibir transferencias o pagos a
            través de las herramientas habilitadas en la aplicación o en forma
            presencial, en tanto estuviere habilitado. Si n1u considera que
            posiblemente un Usuario podría estar utilizando de manera irregular
            los mecanismos para agregar fondos, n1u podrá suspender temporal o
            definitivamente la Cuenta del Usuario.
            <br />
            <br />
            El Usuario se compromete a no aplicar ningún cargo adicional por
            aceptar pagos o recibir fondos a través de la aplicación o en forma
            presencial en tanto estuviere habilitado.
          </TermsText>

          <TermsQuestions>
            b) Liquidación y acreditación de los Fondos.
          </TermsQuestions>
          <TermsText>
            Los Fondos se acreditarán en la Cuenta que el Usuario hubiere
            designado conforme a las tarifas y plazos de liquidación, y a los
            mecanismos y reglas determinados y aceptados por el Usuario a través
            de estos Términos y Condiciones de Uso. El Usuario podrá disponer de
            los Fondos acreditados para realizar las operaciones descriptas de
            acuerdo con lo establecido en estos Términos y Condiciones de Uso.
            <br />
            <br />
            Por razones de seguridad, los Fondos que se acrediten en la Cuenta
            del Usuario y/o que estén pendientes de liquidación (sin importar el
            medio de pago por el que se hubiera efectuado la transacción) podrán
            permanecer indeterminadamente retenidos cuando, a exclusivo criterio
            de n1u, existan sospechas de ilegalidad, fraude, excesiva cantidad
            de operaciones-transacciones que puedan ser fraudulentas o excesivos
            pedidos de devoluciones o contra cargos o cualquier otro acto
            contrario a estos Términos y Condiciones de Uso.
            <br />
            <br />
            El Usuario será responsable por el pago de cualquier
            multa-tasa-penalidad-daño-costo-reclamo, que sea aplicado a n1u por
            parte de empresas adquirentes o marcas de tarjeta, como resultado de
            contra cargos relacionados con las transacciones hechas por el
            Usuario.
            <br />
            <br />
            Toda información sobre los fondos disponibles o retenidos,
            pendientes de liquidación en la Cuenta debe ser siempre verificada
            el Usuario a través de la aplicación, accediendo con su Clave de
            Ingreso o datos biométricos. n1u y sus sociedades relacionadas no
            serán responsables por las conductas del Usuario que se originen o
            vinculen con información contenida en correos electrónicos falsos o
            que aparenten provenir de n1u que sean enviados por terceros sin
            relación con n1u ni sus sociedades relacionadas; como así tampoco
            responderán por cualquier consecuencia dañosa derivada de tales
            conductas ni por la circulación de los mencionados correos
            electrónicos falsos.
          </TermsText>

          <TermsQuestions>
            c) Instrucciones respecto a los Fondos.
          </TermsQuestions>

          <TermsText>
            n1u seguirá las instrucciones ingresadas por el Usuario, para i)
            realizar un pago o transferencia de los fondos en su Cuenta; ii
            )recibir los fondos en su Cuenta, ya sea que éstos se encuentren
            disponibles o pendientes de liquidación. Se aclara que el Usuario al
            registrarse y mantener una Cuenta activa acuerda/ acepta/autoriza
            recibir fondos que oportunamente envíe otro Usuario a su Cuenta y
            que se le debite cualquier cargo que resulte aplicable.
          </TermsText>

          <TermsQuestions>
            d) Ingreso y retiro de fondos. Transferencias.
          </TermsQuestions>

          <TermsText>
            Los Usuarios pueden ingresar dinero en su Cuenta como resultado de
            i) operaciones de cobro realizadas, ii) operaciones de
            transferencias de CBU y/o CVU a la CVU de la Cuenta, y iii) a través
            de redes extra bancarias habilitadas a futuro.
            <br />
            <br />
            El Usuario reconoce/acepta que la transferencia o retiro de los
            fondos por los canales habilitados quedará supeditado a la
            disponibilidad de fondos en la Cuenta del Usuario y al previo pago
            de cualquier deuda que el Usuario mantuviere con n1u, cualquiera
            fuera su causa.
            <br />
            <br />
            Por razones de seguridad, n1u podrá bloquear las
            transferencias-retiros-extracciones de fondos solicitados por
            aquellos Usuarios que en el último tiempo hayan recibido un
            porcentaje elevado de reclamos y disputas o se haya detectado o haya
            fuertes indicios-sospechas de que los mismos han utilizado los
            Servicios para realizar actividades fraudulentas-ilícitas o
            contrarias a los Términos y Condiciones de Uso.
            <br />
            <br />
            n1u no garantiza el acceso ininterrumpido a los cajeros automáticos
            previstos en esta cláusula, los cuales pueden no estar disponibles
            debido a inconvenientes técnicos-fallas de
            internet-indisponibilidad-falta de recursos o cualquier otro motivo
            ajeno a n1u. En este sentido, n1u no será responsable por cualquier
            daño o perjuicio que haya podido sufrir el Usuario con relación a
            ingresos-retiros-extracciones de fondos rechazados o restringidos,
            realizados a través de redes extra bancarias habilitadas a futuro,
            comercio habilitado o cajeros automáticos. n1u no será responsable
            ante operaciones fraudulentas que se realicen a través de
            operaciones de ingresos-retiros de fondos de la Cuenta de los
            Usuarios.
            <br />
            <br />
            El detalle de todos los ingresos-transferencias-retiros de fondos
            realizados por los Usuarios se reflejarán en los movimientos de la
            Cuenta de cada uno de éstos.
          </TermsText>

          <TermsQuestions>e) Límites.</TermsQuestions>
          <TermsText>
            n1u determinará un importe máximo de extracción y para las
            Solicitudes de Procesamiento de Pagos, monto que podrá variar de
            acuerdo al método de pago elegido, tipo de Solicitud o a criterio de
            n1u, pudiendo ser modificado en cualquier momento mediante su
            publicación en la aplicación.
          </TermsText>
          <TermsQuestions>f) Prevención del Fraude</TermsQuestions>
          <TermsText>
            El Usuario que reciba fondos en su Cuenta en pago (el “Usuario
            Destinatario”), por parte de otro Usuario que haya pagado, (el
            “Usuario Remitente”) verificará en su Cuenta, a través de la
            aplicación, que la transacción esté autorizada, pudiendo encontrarse
            disponible o pendiente de liquidación.
            <br />
            <br />
            Tanto el Usuario Remitente como el Usuario Destinatario deberán
            ingresar a su Cuenta a fin de verificar la efectiva transferencia de
            fondos o los saldos pendientes de liquidación o disponibles en sus
            respectivas Cuentas, no siendo suficiente la información recibida a
            través de correos electrónicos para tenerlos por verificados.
          </TermsText>

          <TermsQuestions>
            g) Reversiones, contra cargos, desconocimiento de cargos efectuados
            por cualquier medio de pago.
          </TermsQuestions>
          <TermsText>
            Sin perjuicio de lo detallado en esta sección, se informa que en el
            caso que un Usuario haya recibido un pago y quien realizó el pago o
            el titular del medio de pago utilizado realizara una
            cancelación-anulación-contra cargo-desconocimiento-reversión sobre
            los importes con posterioridad al pago, dicho importe será
            descontado y debitado de la Cuenta del Usuario Destinatario de los
            fondos.
            <br />
            <br />
            En virtud de ello, el Usuario autoriza expresamente a n1u a debitar
            fondos de su Cuenta necesarios para cubrir la anulación-contra
            cargo-desconocimiento-reversión y en caso de no disponer de fondos
            suficientes, a debitarlos de cualquier otro ingreso futuro de fondos
            a su Cuenta o el Usuario se compromete a pagarlos dentro de los diez
            (10) días siguientes al descuento de los fondos. Se establece que a
            efectos de impugnar la devolución de los fondos, será importante
            cualquier prueba que el Usuario Destinatario de los mismos pudiera
            aportar a n1u en su defensa, pero ello no implicará que los fondos
            sean devueltos nuevamente ni que n1u se comprometa a impugnar la
            devolución del cargo incluso si el Usuario proporciona pruebas.
            <br />
            <br />
            En caso de una anulación-contra cargo-desconocimiento-reversión, el
            Usuario receptor de los Fondos podrá responder ante n1u por el
            importe involucrado más cualquier otro costo/gasto aplicable. n1u
            podrá iniciar las medidas judiciales o extrajudiciales que estime
            pertinentes a los efectos de obtener el pago de dichos fondos. El
            Usuario receptor de Fondos se compromete a conservar la
            documentación respaldatoria de la operación efectuada, por el plazo
            mínimo legal a partir de la recepción de los Fondos.
            <br />
            <br />
            El Usuario declara conocer y aceptar que el medio de pago utilizado
            podrá retener un pago o liquidación ante incumplimiento del Usuario
            a estos Términos y Condiciones de Uso.
          </TermsText>

          <TermsQuestions>
            4- Condiciones generales de contratación
          </TermsQuestions>

          <TermsText>
            <b>Registración.</b>
            <br />
            No podrán registrarse ni utilizar los Servicios menores de edad sin
            autorización, personas que no tengan capacidad para contratar o
            aquellos Usuarios que hayan sido suspendidos o inhabilitados de la
            aplicación, temporal o definitivamente.
          </TermsText>

          <TermsText>
            <b>Declaraciones del Usuario. Actividades Prohibidas.</b> El Usuario
            manifiesta que el objeto por el cual se celebra no infringe ninguna
            ley aplicable, ni es por un servicio, venta o transmisión que está
            prohibida por la ley vigente, los Términos y Condiciones de Uso, las
            Actividades Prohibidas y demás políticas de n1u. El Usuario no
            utilizará los Servicios de n1u para transmitir material que
            constituya un delito o bien que pueda dar lugar, directa o
            indirectamente a responsabilidades civiles o que infrinjan los
            Términos y Condiciones de Uso.
          </TermsText>

          <TermsText>
            Asimismo, queda absolutamente prohibida la utilización del servicio
            para efectuar cualquier operación realizada por un Usuario en su
            propio beneficio y/o entre dos o más Usuarios que pueda considerarse
            o ser entendida como adelanto de efectivo o préstamo. n1u
            considerará, sin limitación, también se incluye dentro de esta
            actividad cuando un Usuario ingresa una Solicitud de Procesamiento
            de Pago a favor de otro Usuario quien posteriormente realiza a su
            favor una nueva Solicitud de Procesamiento de Pago, quien finalmente
            solicita el retiro de tales fondos.
          </TermsText>

          <TermsText>
            Si n1u considera que hay una sospecha o indicio de la utilización
            para alguna actividad prohibida por la ley o los Términos y
            Condiciones de Uso, podrá rechazar-cancelar-suspender una Solicitud
            de Procesamiento de Pago o bloquear temporalmente el acceso-uso de
            una Cuenta o la utilización-disponibilidad de funcionalidades o
            cancelar definitivamente una Cuenta. En tal caso, el Usuario
            responderá por los daños y perjuicios que pueda haber ocasionado a
            la n1u, sus controlantes/ controladas/ filiales/ subsidiarias/
            funcionarios/ empleados/ directores/ agentes/ empleados, a cuyo
            efecto n1u se reserva el derecho de iniciar acciones judiciales/
            extrajudiciales que estime pertinente.
          </TermsText>

          <TermsText>
            El Usuario será exclusiva e ilimitadamente responsable por los
            perjuicios que su conducta pueda causar a n1u o a restantes Usuarios
            de los servicios o terceros.
          </TermsText>

          <TermsText>
            <b>Utilización del servicio por cuenta propia.</b> Se permite
            utilizar los Servicios en nombre propio y no en representación o por
            cuenta de terceros no El Usuario no revenderá los Servicios a
            terceros.
          </TermsText>

          <TermsText>
            <b>n1u como proveedor de servicios de pago.</b> Ofrece servicios de
            pago y no se encuentra autorizado a operar como entidad financiera
            por el Banco Central de la República Argentina. No presta al Usuario
            ningún servicio bancario o cambiario. Entre otros Servicios, brinda
            un servicio por cuenta y orden de los Usuarios según las condiciones
            establecidas en los Términos y Condiciones de Uso y en la normativa
            aplicable.
          </TermsText>

          <TermsText>
            Los fondos depositados en la cuenta de pago no constituyen depósitos
            en una entidad financiera, ni cuentan con ninguna de las garantías
            que tales depósitos puedan gozar de acuerdo con la legislación y
            reglamentación aplicables en materia de depósitos de entidades
            financieras.
          </TermsText>

          <TermsText>
            <b>Intereses.</b> Los fondos disponibles o pendientes de liquidación
            en la Cuenta no generan intereses a favor de los Usuarios. El
            Usuario podrá disponer en todo momento de los fondos disponibles
            para realizar las operaciones descritas en los Términos y
            Condiciones de Uso una vez que resulten acreditados en su Cuenta
            conforme los plazos-mecanismos-reglas determinados por n1u.
          </TermsText>

          <TermsText>
            <b>Tarifas del Servicio:</b> El Usuario autoriza a n1u a descontar y
            retener las tarifas o cualquier otro importe debido de los fondos
            disponibles en su Cuenta . Asimismo, el Usuario autoriza a descontar
            y retener de los fondos disponibles en su Cuenta cualquier importe
            adeudado a empresas relacionadas con quien n1u tuviera algún acuerdo
            comercial en tal sentido.
          </TermsText>

          <TermsText>
            <b>Cobros en cuentas Relacionadas.</b> El Usuario autoriza a n1u a
            descontar-retener-restringir en su uso, de los fondos disponibles en
            su Cuenta, un monto equivalente a las Tarifas o cualquier otro
            importe debido (en adelante el “Monto Adeudado”), sea que el Monto
            Adeudado se hubiese generado en la Cuenta del Usuario o en cualquier
            otra Cuenta que, a criterio razonable, sea considerada una cuenta
            relacionada a la Cuenta del Usuario (en adelante la “Cuenta
            Relacionada”). n1u considerará que una Cuenta de un tercero es una
            Cuenta Relacionada a la del Usuario cuando ambas cuentas compartan
            un atributo o más, que razonablemente hagan suponer que existe un
            uso operacional conjunto de las cuentas o que denote una, el
            conocimiento operacional de la otra o que el uso de una o ambas
            cuentas tenga como fin evadir obligaciones que pudieran recaer sobre
            la otra. En aquellos casos en los que el Monto Adeudado se haya
            generado en una Cuenta Relacionada, el Usuario deberá justificar, en
            su caso, que no se trata de Cuentas Relacionadas. Sin perjuicio de
            esto último, la determinación de la existencia de Cuentas
            Relacionadas queda sujeta al criterio único-exclusivo-razonable de
            n1u.
          </TermsText>

          <TermsText>
            <b>Seguridad y Confidencialidad de datos personales.</b> n1u no
            venderá-alquilará-negociará con otra empresa la información personal
            del Usuario salvo en la forma y casos establecidos en las políticas
            de Estos datos se utilizarán para prestar el Servicio. Sin embargo,
            podrá compartir dicha información con proveedores de servicios de
            valor agregado que se integren dentro de la aplicación o mediante
            links a otros sitios de Internet, para atender necesidades de los
            Usuarios relacionadas con los servicios que suministra. Para mayor
            información, consulte las cláusulas de Políticas de Privacidad de
            datos que rigen para los Servicios. Toda la información personal
            transmitida se hace a través de una página de Internet segura que
            protege y encripta la información. La información personal se
            almacena en servidores o medios magnéticos que mantienen altos
            estándares de seguridad. n1u hará sus mejores esfuerzos para
            mantener la confidencialidad y seguridad de que trata esta sección,
            pero no responderá por perjuicios que puedan derivar de la violación
            de dichas medidas por parte de terceros que utilicen las redes
            públicas o Internet para acceder a dicha información o en los casos
            en que haya habido culpa o negligencia del Usuario.
            <br />
          </TermsText>

          <TermsText>
            <b>
              Limitación de Responsabilidad por el Servicio y/o la Plataforma.
            </b>{" "}
            n1u no garantiza el acceso-uso continuado-ininterrumpido de la
            aplicación. El sistema puede eventualmente no estar disponible
            debido a dificultades técnicas o fallas de Internet o herramientas o
            por otra circunstancia ajena a Los Usuarios no podrán imputarle
            responsabilidad a n1u ni exigir resarcimiento, en virtud de
            perjuicios resultantes de las mencionadas dificultades, así como por
            cualquier otra clase de daños, incluyendo daños directos,
            indirectos, especiales o consecuentes que surjan o experimenten los
            Usuarios, incluso en el caso que dichas fallas afecten los montos
            que deban ser pagados/acreditados.
          </TermsText>

          <TermsText>
            <b>Licencia Limitada.</b> Todos los derechos intelectuales e
            industriales, sobre la aplicación, herramientas, códigos,
            desarrollo, software, hardware, dominio, logos, emblemas, logotipos,
            diseños, estructura, contenidos, información, etc. son de propiedad
            de n1u. En ningún caso se entenderá que el Usuario tendrá algún tipo
            de derecho sobre los mismos, independientemente de los derechos
            conferidos sobre la aplicación que n1u otorga a disposición del
            usuario durante la vigencia de este acuerdo.
          </TermsText>

          <TermsText>
            Los Usuarios deben abstenerse de realizar cualquier acto que pudiera
            causar un daño-pérdida de reputación-disminución del valor de dichas
            marcas o utilizarlas en forma contraria a la moral y buenas
            costumbres.
          </TermsText>

          <TermsText>
            <b>Embargos e inhabilitación de cuentas.</b> n1u se encuentra
            facultada para trabar embargos-dar cumplimiento a cualquier medida
            ordenada por autoridad competente incluyendo sin limitación, la
            inhabilitación de la Cuenta en caso de quiebra.
          </TermsText>

          <TermsText>
            <b>Modificaciones de los Términos y Condiciones del Servicio.</b>{" "}
            n1u podrá modificar en cualquier momento los Términos y Condiciones
            de Uso. n1u notificará los cambios al Usuario publicando una versión
            actualizada en la aplicación indicando fecha de la última
            modificación. Los términos modificados entrarán en vigor a los 60
            días de su publicación. Dentro de los 10 días siguientes a la
            publicación de las modificaciones introducidas, el Usuario deberá
            comunicar por e-mail si no acepta las mismas y en ese caso quedará
            disuelto el vínculo Vencido este plazo, se considerará que el
            Usuario acepta los nuevos términos.
          </TermsText>

          <TermsText>
            <b>ll) Cesión.</b> El Usuario no podrá
            ceder-transferir-delegar-disponer de los derechos/obligaciones
            derivados del presente, total o parcialmente. n1u podrá
            ceder-transferir-delegar-disponer de los derechos/ obligaciones
            derivados del presente o de su posición contractual, total o
            parcialmente, para lo cual el Usuario otorga su consentimiento de
            manera previa para la realización de dichas acciones.
          </TermsText>

          <TermsText>
            <b>Terminación del servicio.</b> n1u y el Usuario podrán en
            cualquier oportunidad de vigencia del presente terminarlo sin
            expresión de causa alguna, lo que implicará el cierre de la Cuenta
            del Usuario, pero no suspenderá el cumplimiento de las operaciones
            ya autorizadas por el Usuario al momento de notificación. A los
            efectos de ejercer esta facultad, es necesario que la parte que
            pretende la terminación no adeude a la otra ni a terceros
            involucrados en las operaciones el cumplimiento de alguna
            obligación.
          </TermsText>

          <TermsText>
            Asimismo, n1u podrá, en caso de incumplimiento del Usuario a las
            condiciones del presente o a la legislación aplicable en la materia,
            dar por terminada la prestación de los Servicios sin ningún tipo de
            aviso previo, reservándose el derecho de reclamar los daños y
            perjuicios que tal incumplimiento le cause.
          </TermsText>

          <TermsText>
            <b>Documentación. </b>El Usuario opta por la documentación e
            información relacionada con el servicio sea provista en soporte
            electrónico, salvo disposición expresa en contrario. En todo caso,
            el Usuario podrá hacer copias incluso en soporte físico, de la
            documentación-información relativa al presente. El Usuario autoriza
            en forma irrevocable a n1u o a quien ella designe, a grabar las
            operaciones del Usuario relativas a los servicios prestados y a
            utilizar dichas grabaciones como medio probatorio ante autoridades
            administrativas o Las constancias emitidas serán consideradas prueba
            suficiente del cumplimiento de las instrucciones, sustituyen la
            necesidad de cualquier recibo y son plenamente oponibles al Usuario.
            A tales efectos, el Usuario y n1u acuerdan otorgarle valor
            probatorio a las constancias que se emitan como consecuencia de las
            aprobaciones-instrucciones dadas por el Usuario, con el alcance
            establecido en la legislación vigente.
          </TermsText>

          <TermsText>
            <b>Notificaciones.</b> Serán válidas y el Usuario acepta
            expresamente que todas las notificaciones realizadas a los Usuarios,
            incluyendo sin limitación a los avisos-recordatorios-comunicaciones
            relacionadas con los Servicios brindados, sean dirigidas i) a la
            dirección de correo electrónico principal registrada por éstos
            (domicilio especial electrónico), ii) mediante la aplicación, iii) a
            la Cuenta del Usuario.
          </TermsText>

          <TermsText>
            <b>Domicilios.</b> Se fija como domicilio de n1u Ciudad de la Paz
            3252 Piso 3 Departamento C, Ciudad Autónoma de Buenos Aires,
            Argentina – código postal C1429AOD.
          </TermsText>

          <TermsText>
            . El domicilio constituido por los Usuarios es el domicilio
            registrado por los Usuarios en la registración o el modificado
            posteriormente por los medios aceptados en la plataforma.
          </TermsText>

          <TermsText>
            <b>Anexos.</b> Forman parte integral de los Términos y Condiciones
            de Uso incorporados en este acuerdo, los siguientes documentos, los
            cuales se podrán consultar dentro de la aplicación mediante el
            enlace abajo provisto o accediendo directamente a las páginas
            correspondientes:
          </TermsText>

          <TermsText>
            • TyC Generales del Servicio (
            <a href="/terminos-y-condiciones">
              <span>https://n1u.app/terminos-y-condiciones/</span>
            </a>
            )
            <br />• Tarjeta Prepaga (
            <a href="/tarjetaprepaga">
              <span>https://www//n1u.app/tarjetaprepaga/</span>
            </a>
            )
            <br />• Actividades Prohibidas (
            <a href="/actividadesprohibidas">
              <span>https://www.n1u.app/actividadesprohibidas/</span>
            </a>
            )
            <br />• Política de Privacidad de datos
            <br />
            <a href="https://n1uapp.notion.site/Politicas-de-Privacidad-3672df0e8d1b4d8c94a6bfe3299afd22">
              <span>
                {" "}
                https://n1uapp.notion.site/Politicas-de-Privacidad-3672df0e8d1b4d8c94a6bfe3299afd22
              </span>
            </a>
          </TermsText>

          <TermsText>
            <b>Tarifas</b> <span>https://www.n1u.app/tarifas</span> – Usted
            puede consultar el “Régimen de Transparencia” elaborado por el Banco
            Central sobre la base de la información proporcionada por los
            sujetos obligados a fin de comparar los costos y características de
            los productos y servicios financieros ingresando a
            <a href="https://www.bcra.gob.ar/BCRAyVos/Regimen_de_transparencia.asp">
              <span>
                {" "}
                http://www.bcra.gob.ar/BCRAyVos/
                <br />
                Regimen_de_transparencia.asp”.{" "}
              </span>
            </a>
          </TermsText>

          <TermsText>
            <b>Resolución de Reclamos y Disputas.</b> Los Usuarios aceptan
            someter sus controversias a la decisión de n1u. En aquellos casos en
            los que no resulten aplicables las reglas de n1u, aceptan el
            mecanismo de Resolución de Reclamos y Disputas establecido por n1u.
            Los Usuarios aceptan acatar la decisión que se determine cuando las
            Partes de una operación sujeta a dicho mecanismo no lleguen a un
            acuerdo y renuncian a realizar reclamo alguno con motivo de dicha
            decisión. n1u solamente decidirá, en la solución de controversias
            sujetas a la devolución de fondos a un Usuario cuando cuente con
            elementos que puedan justificar en forma suficiente y razonable
            dicha decisión, efectuándose dicha devolución se efectuará
            únicamente a la cuenta de origen de la que provinieron.
          </TermsText>

          <TermsText>
            <b>Información a Terceros.</b> Sin perjuicio de los deberes de
            confidencialidad asumidos, el Usuario toma pleno conocimiento que
            n1u. tiene la obligación legal de responder todos los requerimientos
            que legalmente se le cursen y que tenga obligación de responder,
            tales como el BCRA, AFIP, Organismos de recaudación o fiscalización
            provinciales y municipales, n1u descontará de los fondos a acreditar
            o debitar en la Cuenta del usuario, los gastos, descuentos,
            impuestos, retenciones y percepciones que correspondan, conforme lo
            estipulado por las leyes, decretos o cualquier normativa aplicable a
            la operatoria.
          </TermsText>

          <TermsText>
            Todos los impuestos, retenciones o percepciones impositivas (de
            carácter nacional, provincial o municipal), se aplicarán en función
            de la situación fiscal manifestada por el Usuario y estarán a su
            exclusivo cargo.
          </TermsText>

          <TermsText>
            En el caso de usuarios ocasionales, se entienden que están actuando
            con la situación impositiva con la que figuran en los registros de
            AFIP para la aplicación de los Impuestos Nacionales, y en carácter
            de “Responsable No Inscripto” para los Impuestos Provinciales. De no
            ser así, tendrán la opción de exteriorizar su situación fiscal
            (nacional y provincial) dentro de las 48 horas, bajo su
            responsabilidad. El monto de los impuestos, retenciones y
            percepciones, será consignado en la cuenta de Usuario, como una
            detracción sobre el monto de cada transacción. Consecuentemente,
            cuando se realice el efectivo pago, puesta a disposición o se
            acrediten los fondos en la cuenta informada por el Usuario , los
            mismos se acreditarán netos del precio del Servicio y de los
            mencionados impuestos, retenciones y/o percepciones impositivas.
          </TermsText>

          <TermsText>
            Los ciclos de facturación serán cada 15 (quince) días. En forma
            mensual, se pondrá a disposición de los usuarios, los certificados
            de las retenciones /percepciones practicadas durante cada mes
            calendario.
          </TermsText>

          <TermsText>
            Si hubiera diferencias entre el impuesto o retención o percepción
            consignado en la Cuenta y el monto resultante por dicho concepto en
            el momento del cálculo final, se depositará el saldo a favor del
            Usuario en su Cuenta.
          </TermsText>

          <TermsText>
            Al utilizar el Servicio, el Usuario acepta y autoriza que se debiten
            de su Cuenta los importes por los conceptos aquí mencionados.
          </TermsText>

          <TermsText>
            <b>Jurisdicción y ley aplicable.</b> Este acuerdo estará regido en
            todos sus puntos por las leyes vigentes en la Argentina. Cualquier
            controversia derivada del presente acuerdo, su existencia, validez,
            interpretación, alcance o cumplimiento, será sometida a los
            tribunales nacionales de la Ciudad de Buenos Aires, salvo
            disposición específica de orden público, como por ejemplo la Ley de
            Defensa del Consumidor o normativa vinculada, en los casos en los
            que ésta sea aplicable.
          </TermsText>

          <TermsQuestions>
            5- Términos y Condiciones de las Herramientas de la aplicación y
            autorización del usuario titular a otro usuario.
          </TermsQuestions>

          <TermsText>
            Los presentes Términos y Condiciones aplicables al uso de la
            aplicación (“Condiciones de Uso”) establecen las reglas para el uso
            por parte de los Usuarios de los servicios de n1u consistente en
            permitir en las distintas alternativas que n1u pone a disposición de
            los Usuarios. Las presentes condiciones de Uso deben considerarse
            como parte integrante de los Términos y Condiciones Generales del
            Servicio.
            <br />
            <br />
            A- KOIBANX EL SALVADOR S.A. de C.V. (en adelante, “Koibanx”)
            ofrecerá acceso a los usuarios de n1u al servicio de compra y venta
            de activos de moneda digital a través de la Plataforma. Dicho
            servicio será administrado por Koibanx. Para ello, el Usuario deberá
            aceptar tanto los Términos y Condiciones como las Políticas de
            Privacidad correspondientes.
            <br />
            <br />
            Se hace saber que, para la prestación de este servicio, n1u
            compartirá datos personales recolectados de Usuarios a Koibanx. Esto
            será realizado en virtud de la relación comercial existente entre
            ambas empresas, mediante la cual n1u permite a Koibanx ofrecer el
            Servicio en su Plataforma. Asimismo, se hace saber al Usuario que
            ambas empresas han suscrito un acuerdo de transferencia
            internacional de datos que concierne la mencionada operatoria.
            <br />
            <br />
            A LOS FINES DE HACER USO DEL SERVICIO PROVISTO POR KOIBANX EN LA
            PLATAFORMA n1u, EL USUARIO DEBERÁ DAR SU CONSENTIMIENTO LIBRE,
            EXPRESO E INFORMADO A TRAVÉS DEL CORRESPONDIENTE CLICK EN EL
            CHECKBOX QUE INDICA “ACEPTO COMPARTIR MIS DATOS”.
            <br />
            <br />
            <b>B- Cuenta para Adolescentes Autorizados.</b> A partir de los 13
            años, la persona menor de edad podrá registrarse como usuario en
            n1u, siempre que cuente con autorización de su Representante Legal.
            La Cuenta será utilizada por el adolescente autorizado (el
            “adolescente o menor” o “adolescente o Menor Autorizado”). n1u se
            reserva la facultad de rechazar cualquier solicitud de registro de
            adolescentes o suspender o cerrar la Cuenta a su sola discreción o
            colocar restricciones a ciertas operatorias según la edad del
            adolescente.
            <br />
            <br />
            A los efectos del registro, así como todas las operaciones
            realizadas en el marco de los servicios y cualquier otro asunto
            relacionado, los adolescentes actuarán bajo la representación legal
            de su padre, madre o tutor con la capacidad de desempeñarse en tal
            carácter en términos de la legislación vigente (el “Representante
            Legal”). El Representante Legal declara que: (i) actúa en
            representación del adolescente para todos los efectos legales que se
            deriven de la apertura y operación de la Cuenta del adolescente;
            (ii) será responsable de todas las acciones y obligaciones que se
            deriven de la utilización de la Cuenta del adolescente y/o los
            Servicios, incluyendo, sin limitación, reclamos por incumplimientos,
            fraudes, contra cargos, reversiones, montos adeudados y la
            participación en programas y promociones; (iii) no existe oposición
            de otro representante legal para la apertura de la Cuenta del
            adolescente y que puede acreditarlo en cualquier momento que sea
            requerido.
            <br />
            <br />
            La Cuenta para adolescentes tendrá las siguientes limitaciones: (i)
            el adolescente podrá utilizar los servicios permitidos a
            adolescentes a través de la aplicación únicamente con la supervisión
            y autorización de su Representante Legal; pero autorizando a n1u y
            al adolescente a vincularse de forma remota, a abrir cuenta,
            efectuar recargas, extraer dinero, asociar la tarjeta a otras
            billeteras, efectuar compras y recibir pagos y transferencias hasta
            el monto permitido en la aplicación y obtener una tarjeta y otorgar
            tarjetas de regalo, participar en programas de fidelización, operar
            con servicios autorizados para ellos en la plataforma con las
            facultades o limitaciones que su Representante indique en la
            plataforma para el caso de adolescentes (ii) el Representante Legal
            deberá velar por el uso responsable y adecuado de la Cuenta del
            adolescente en atención a su madurez.
            <br />
            <br />
            Cuando el adolescente alcance la mayoría de edad, n1u podrá
            convertir la Cuenta del adolescente en una Cuenta conforme a
            Términos y Condiciones, de titularidad exclusiva del hasta entonces
            adolescente, quien deberá aceptar los Términos y Condiciones de Uso
            para asumir el carácter de titular.
            <br />
            <br />
            n1u podrá requerir cualquier documentación o información adicional
            que considere pertinente con el fin de validar la identidad del
            adolescente y/o del Representante Legal en cualquier momento. En
            caso de detectar actividades sospechosas o inusuales, n1u podrá
            adoptar las medidas que estime convenientes para evitar conductas de
            mala fe o fraudulentas y evaluar el origen de fondos de la cuenta,
            entre otras, incluyendo el cierre de la cuenta del adolescente y/o
            del Representante Legal.
          </TermsText>
          <TermsQuestions>Conformidad representante legal</TermsQuestions>

          <TermsText>
            Por el presente presto conformidad a la participación en los
            servicios de la aplicación y a los nuevos servicios que ella
            incorpore mediante la actualización de los presentes términos y
            condiciones como el tratamiento de los datos personales del
            adolescente a mi cargo y sin encontrar oposición de otra persona que
            ejerza dicho cargo, a quien informé en forma completa del contenido
            y las posibilidades de participación en la aplicación. La
            participación la encontramos adaptada a su edad, madurez y promueve
            su desarrollo, bienestar e integración a la vida diaria y familiar,
            con la finalidad de facilitar las transacciones cotidianas,
            estimulando su habilidad financiera y preparándolo para el futuro,
            comprometiéndome a acompañar y controlar la operatoria de la forma y
            con los parámetros de uso que les indico en los términos y
            condiciones de la aplicación y aceptando el tratamiento de datos de
            información de identidad, de información de contacto, de información
            de uso e información de marketing y compartir información para
            cumplir con leyes o responder solicitudes legales, proteger derechos
            o por emergencias o para nuevos propósitos.
            <br />
            <br />
            Conozco que PUEDO SOLICITAR A n1u EN CUALQUIER MOMENTO SUPRESIÓN DEL
            DATO PERSONAL, MÍO O DEL ADOLESCENTE AUTORIZADO, ACCESO A ELLOS Y
            RECTIFICACIÓN DE DATO ERRÓNEO u otros derechos sobre datos según
            normativa vigente al momento de la solicitud y me podrán informar el
            responsable o usuario del banco de datos que proveyó la información.
            <br />
            <br />
            Conozco que TENGO FACULTAD PARA EJERCER EL DERECHO DE ACCESO A LOS
            DATOS EN FORMA GRATUITA a intervalos no inferiores a seis meses,
            salvo que acredite un interés legítimo al efecto y que LA AGENCIA DE
            ACCESO A LA INFORMACIÓN PÚBLICA, ES ÓRGANO DE CONTROL DE LA LEY
            25326, CON ATRIBUCIÓN PARA ATENDER DENUNCIA Y RECLAMO QUE SE
            INTERPONGA POR INCUMPLIMIENTO SOBRE DATOS PERSONALES;
            <br />
            <br />
            PRESTO MI CONSENTIMIENTO LIBRE, EXPRESO E INFORMADO para que: n1u
            TRATE LOS DATOS PERSONALES, INCLUSO LOS BIOMÉTRICOS, las operaciones
            e información económica para prestar el servicio, calificar
            crediticiamente, para oferta comercial/publicitaria de
            producto/servicio, para control o fin estadístico, incorporando
            ellos a los sistemas y a las bases de datos de n1u registradas ante
            la Agencia de Acceso a la Información Pública o la entidad que la
            reemplace a futuro;
            <br />
            <br />
            n1u TRATE, CEDA O TRANSFIERA LOS DATOS, A AFILIADA, SUBSIDIARIA,
            CONTROLANTE, CONTROLADA O CUALQUIER TERCERO, aún en forma gratuita,
            manteniendo en el tratamiento la confidencialidad y la seguridad de
            ellos, con el FIN de: (a)Analizar el riesgo para nuevo producto o
            segmentar perfil comercial(b)Enviar n1u o tercero por cualquier
            medio, oferta comercial/publicidad personalizada o no(c)Confrontar
            los datos con la Base del Registro Nacional de las Personas(Renaper)
            validando identidad(d)Cualquier otro similar o que reemplace o que
            complemente a los puntos anteriormente mencionados.
            <br />
            <br />
            n1u UTILICE TRATAMIENTO CON MEDIO TÉCNICO COMO POR EJEMPLO
            DISPOSITIVO PARA CAPTURA, LECTURA O PROCESAMIENTO DE DATOS, INCLUSO
            DATOS BIOMÉTRICOS (COMO HUELLA DACTILAR, RECONOCIMIENTO FACIAL U
            OTRO) y n1u los comparta con terceros para resguardo de ellos o para
            verificación de identidad u otro fin similar;
            <br />
            <br />
            n1u ALOJE DATOS EN SERVIDOR PROPIO O DE TERCERO, EN ARGENTINA U OTRO
            PAÍS que podría no tener legislación con nivel de protección
            adecuado. n1u realizará esfuerzos para lograr acuerdos con nivel de
            protección adecuado; n1u evalúe la situación crediticia,
            autorizándolo a solicitar y otorgar información a agencias de
            información crediticia, a informar la situación de
            cumplimiento/incumplimiento respecto de deuda, incluso respecto del
            cumplimiento o cuando nos encontremos en mora o refinanciación o no
            cancele la obligación.
          </TermsText>

          <TermsQuestions>
            6- Disposiciones Comunes a todos los medios
          </TermsQuestions>
          <TermsQuestions>
            a) Cargos, Contra cargos y Reversiones
          </TermsQuestions>
          <TermsText>
            Los cobros realizados por el Usuario no se encuentran protegidos por
            n1u por concepto de cargos, contra cargos y reversiones. El Usuario
            reconoce y acepta que usa el servicio de gestión de pagos asumiendo
            el riesgo, sin ninguna cobertura o cualquier otro mecanismo de
            protección contra contra cargos.
            <br />
            <br />
            En caso de que un Usuario realice una anulación-contra
            cargo-desconocimiento-reversión de una operación de cobro, n1u
            trasladará al otro Usuario el contra cargo. En el caso que al
            momento de realizar el contra cargo, n1u hubiese ingresado las
            retenciones impositivas, las mismas no serán reintegradas ni
            neteadas de futuros pagos.
            <br />
            <br />
            A tal fin, n1u podrá debitar los fondos contra cargados del saldo de
            la Cuenta del Usuario, y/o de saldos que pudieran ingresar en la
            Cuenta del Usuario en el futuro. Para tales efectos, el Usuario
            autoriza expresamente a la n1u a debitar de su Cuenta el importe
            necesario para cubrir la anulación-contra cargo,
            desconocimiento-reversión renunciando a ejercer cualquier reclamo
            contra n1u y de cualquier otra cuenta, que n1u a su solo criterio,
            establezca que tiene relación con la operatoria del Usuario.
            <br />
            <br />
            El Usuario se compromete a mantener indemne a n1u de todo reclamo o
            daño que pueda sufrir como consecuencia directa o indirecta del
            incumplimiento del Usuario de las obligaciones previstas en la
            presente cláusula.
          </TermsText>

          <TermsQuestions>b) Responsabilidad del Usuario</TermsQuestions>
          <TermsText>
            Los Usuarios son los únicos y exclusivos responsables por el uso del
            Servicio, incluyendo cualquier daño derivado del mismo.
          </TermsText>

          <TermsQuestions>c) Declaraciones de n1u.</TermsQuestions>

          <TermsText>
            En ningún caso n1u será responsable por lucro cesante o por otro
            daño o perjuicio que hayan podido sufrir los Usuarios, debido a las
            transacciones realizadas o no realizadas mediante el Servicio. n1u
            no asume la obligación de mantener todos los servicios descriptos en
            condiciones de usabilidad para todos los entornos operativos,
            incluyendo, la interoperabilidad con todas las actuales y/o futuras
            versiones de software o hardware.
          </TermsText>
          <br />
          <br />
          <TermsText>
            n1u no se responsabiliza por los daños que el Usuario o cualquier
            tercero sufra como consecuencia del uso indebido o contrario a estas
            Condiciones de Uso. En caso que cualquier tercero inicie cualquier
            tipo de reclamo o acción legal, administrativa o extrajudicial
            contra n1u, sus accionistas/ controlantes/ controladas/filiales o
            subsidiarias/ funcionarios/ empleados/ directores/ agentes/
            representantes/ apoderados, el Usuario se obliga a mantener indemne
            a n1u y a los mencionados anteriormente. El Usuario acepta
            expresamente que n1u podrá debitar los fondos en su Cuenta
            (existentes o que ingresen en el futuro) cualquier importe debido
            por el Usuario en concepto de reparación de daños y perjuicios,
            renunciando a ejercer cualquier reclamo contra n1u
          </TermsText>

          <TermsQuestions>
            d) Modificación de las Condiciones de los servicios.
          </TermsQuestions>
          <TermsText>
            n1u podrá modificar en cualquier momento las Condiciones de Uso,
            notificando los cambios al Usuario publicando una versión
            actualizada de dichos términos y condiciones en la aplicación
            indicando la fecha de la última modificación. Todos los términos
            modificados entran en vigor a los 60 días de su publicación.
          </TermsText>

          <TermsQuestions>
            7- Términos y condiciones de la Tarjeta Prepaga Visa y de la tarjeta
            de regalo.
          </TermsQuestions>

          <TermsText>
            Estos términos y condiciones aplican al Usuario respecto del uso de
            las tarjetas prepagas ofrecidas (la “Tarjeta Prepaga”). Excepto en
            los casos expresamente establecidos en el presente, estos términos y
            condiciones son parte integrante de los Términos y Condiciones del
            Servicio que resultan plenamente aplicables al uso de las Tarjetas
            Prepagas. Cualquier persona que no acepte estos términos y
            condiciones, que tienen un carácter obligatorio y vinculante, deberá
            abstenerse de solicitar y usar la Tarjeta Prepaga o sus servicios
            conexos.
            <br />
            <br />
            Las palabras en mayúscula no definidas en estos términos y
            condiciones tendrán el significado provisto en los Términos y
            Condiciones de Uso.
            <br />
            <br />
            Se deja expresa constancia que no resultará de aplicación la Ley
            25.065.
            <br />
            <br />
            <b>7.1 Tarjeta Prepaga</b>
            <br />
            <br />
            • Podrá ser titular de una Tarjeta Prepaga (el “Titular”) cualquier
            Usuario que sea una persona humana.
            <br />
            <br />
            • La Tarjeta Prepaga es un plástico nominado y recargable que se
            puede utilizar para adquirir bienes, servicios o extraer fondos en
            la República Argentina en establecimiento asociado a la red
            Asimismo, podrá utilizarse para adquirir bienes y/o servicios en el
            exterior en establecimiento asociado a la red Visa. La Tarjeta
            Prepaga podrá ser utilizada para realizar pago presencial, por
            internet, teléfono o por cualquier otro canal habilitado.
            <br />
            <br />
            • La Tarjeta Prepaga no es una tarjeta de crédito, débito o de
            compra y no admite compras en Los pagos o extracciones de fondos
            realizados con la Tarjeta Prepaga se debitarán del saldo de fondos
            disponibles en la Cuenta del Titular.
            <br />
            <br />
            <b>7.2 Tarifa</b>
            <br />
            <br />
            • Las tarifas aplicables a la obtención y uso de la Tarjeta Prepaga
            se encuentran disponibles para consultar en todo momento las Tarifas
            del Servicio. Las extracciones de fondos por cajeros automáticos
            podrán estar sujetas a tarifas adicionales aplicadas directamente
            por las compañías responsables de los cajeros automáticos, sin la
            intervención de n1u.
            <br />
            <br />
            <b>7.3 Solicitud de la Tarjeta Prepaga</b>
            <br />
            <br />
            • Al solicitar la Tarjeta Prepaga, el Usuario deberá proveer en
            forma exacta-verdadera-completa los datos y documentos que le fueran
            requeridos (nombre completo/domicilio real/número de teléfono, ).
            Asimismo, el Usuario debe mantener actualizados dichos datos o
            documentación registrándolos en su Cuenta o suministrándolos a n1u
            por medio de los canales habilitados.
            <br />
            <br />
            • Toda la información-documentación provista a n1u por el Usuario o
            Titular tienen carácter de declaración No obstante, n1u podrá
            solicitar comprobante o información adicional para completar o
            corroborar la información entregada por un Usuario o Titular,
            incluso a partir de la superación de ciertos montos de transacciones
            mensuales realizadas por el Titular.
            <br />
            <br />
            • La solicitud de la Tarjeta Prepaga quedará perfeccionada con la
            recepción de la Tarjeta Prepaga por parte del Sin embargo, n1u podrá
            rechazar una solicitud de Tarjeta Prepaga, suspenderla o cancelarla
            en caso de detectar inconsistencias en los datos o documentación
            provista por un Usuario o Titular, o en caso de que el Usuario o
            Titular no proporcione los datos o documentación adicional
            solicitada. Asimismo, n1u podrá en cualquier caso rechazar una
            solicitud de Tarjeta Prepaga a su criterio exclusivo, sin necesidad
            de proveer una justificación al Usuario.
            <br />
            <br />
            <b> 7.4 Uso de la Tarjeta Prepaga</b>
            <br />
            <br />
            • Una vez recibida, la Tarjeta Prepaga si la correspondencia que
            contiene la Tarjeta Prepaga está dañada o violada, el Usuario o
            Titular deberá rechazar su recepción y comunicar el hecho de
            inmediato a n1u a través de los canales de atención Solamente
            deberán activarse la tarjetas físicas.
            <br />
            <br />
            • La Tarjeta Prepaga se podrá utilizar dentro del plazo de validez
            impreso en su frente, sujeto al saldo de los Fondos disponibles en
            la Cuenta y dentro de los límites de uso diarios para operaciones
            determinados por n1u o por las redes de servicios asociados.
            <br />
            <br />
            La Tarjeta Prepaga es personal e intransferible. Únicamente el
            usuario mayor de edad mayor podrá otorgar adicionales a ella y
            solamente respecto de tarjetas físicas contra el débito de su
            cuenta. El Usuario será el único responsable de la custodia y
            confidencialidad de la Tarjeta Prepaga y el PIN autogenerado para su
            utilización. Por lo tanto, el Titular es responsable por todas las
            operaciones realizadas con la Tarjeta Prepaga.
            <br />
            <br />
            • Por razones de seguridad, el ingreso incorrecto del PIN de la
            Tarjeta Prepaga en un cajero automático podrá derivar en la
            retención de la Tarjeta Prepaga por parte de la red del servicio del
            cajero automático. En ese caso, el Titular deberá solicitar una
            nueva Tarjeta Prepaga por los canales habilitados. En caso de haber
            olvidado el PIN de la Tarjeta Prepaga, el Titular podrá realizar la
            autogestión de blanqueo de PIN a través de la aplicación.
            <br />
            <br />
            • El uso de la Tarjeta Prepaga estará sujeto a la disponibilidad de
            la red Visa, circunstancia ajena a n1u. La aprobación de la
            transacción se realizará de acuerdo con los procedimientos
            específicos según el tipo de transacción o canal de pago utilizado.
            Por ejemplo, respecto de algunos canales de pago ofrecidos por
            ciertos establecimientos, el uso de la contraseña o de la firma se
            podrán reemplazar mediante la indicación del número de la Tarjeta
            Prepaga, su fecha de vencimiento y su código de seguridad. En
            cualquier caso, el cumplimiento de los requisitos necesarios para
            realizar la transacción implica la aceptación de la transacción y su
            consiguiente responsabilidad por parte del titular.
            <br />
            <br />
            • Los montos de las transacciones realizadas con la Tarjeta Prepaga
            más los impuestos correspondientes y las Tarifas se debitarán del
            saldo de los Fondos disponibles en la Cuenta del Titular en el
            momento, en forma total y en un solo débito. Si los Fondos
            disponibles en la Cuenta no fueran suficientes, la transacción no
            será autorizada. Las operaciones realizadas con la Tarjeta Prepaga
            se podrán consultar en los movimientos de la Cuenta del Titular.
            <br />
            <br />
            • Cualquier cancelación de una transacción de compra de corresponder
            deberá ser solicitada por el Titular al establecimiento. En el
            momento de la solicitud de cancelación, el Titular deberá obtener el
            comprobante de cancelación correspondiente directamente del
            establecimiento. Los saldos de la Tarjeta Prepaga serán reintegrados
            al Usuario a partir de la aprobación de la cancelación por parte de
            Visa.
            <br />
            <br />
            Tarjeta de regalo. Procede arrepentimiento del usuario que la emite
            dentro del término de 10 días. Si la tarjeta de regalo no se
            utilizó, se puede reintegrar el monto de la misma dentro de los 10
            días. Si ella fue utilizada no se puede revocar. Validez de la
            tarjeta de regalo 12 meses. Quien recibe la tarjeta de regalo debe
            ser usuario de la billetera.
            <br />
            <br />
            <b>7.5 Declaración Jurada PEP y de Origen de Fondos</b>
            <br />
            <br />
            • El Usuario o Titular declara bajo juramento que no se encuentra
            incluido o alcanzado dentro de la “Nómina de Personas Expuestas
            Políticamente” (PEP) aprobada por la Unidad de Información
            Financiera, que ha leído. En caso afirmativo, el Usuario o Titular
            se compromete a indicar detalladamente el motivo a n1u a través de
            los canales de atención.
            <br />
            <br />
            Además, el Titular asume el compromiso de informar cualquier
            modificación que se produzca a este respecto dentro de los 30 días
            de ocurrida, mediante la presentación de una nueva declaración
            jurada a través de los canales de atención habilitados.
            <br />
            <br />
            • El Usuario o Titular manifiesta con carácter de declaración jurada
            que los Fondos de las operaciones realizadas con la Tarjeta Prepaga
            fueron obtenidos mediante actividades lícitas y declaradas y tendrán
            un destino igualmente legítimo.
            <br />
            <br />
            <b>7.6 Plazo de Vigencia de la Tarjeta Prepaga</b>
            <br />
            <br />
            • La Tarjeta Prepaga será válida desde la fecha de su activación
            hasta la fecha de vencimiento informado en su dorso. Vencido el
            plazo de vigencia, la Tarjeta Prepaga se cancelará automáticamente.
            Sin embargo, n1u podrá cancelar la Tarjeta Prepaga en caso de que no
            fuera utilizada por su Titular durante un plazo de 6 meses o mayor
            sin notificación previa a su Titular.
            <br />
            <br />
            • En caso de vencimiento y/o cancelación de la Tarjeta Prepaga, el
            Usuario podrá solicitar una nueva Tarjeta Prepaga por los canales
            habilitados.
            <br />
            <br />
            <b>
              7.7 Denuncia de extravío, robo, hurto, cancelación y nueva emisión
              de Tarjeta Prepaga
            </b>
            <br />
            <br />
            • El Usuario deberá notificar inmediatamente a n1u mediante por el
            medio habilitado, el extravío, hurto, robo o uso indebido de la
            Tarjeta La Tarjeta Prepaga informada como extraviada, hurtada,
            robada o usada indebidamente será cancelada y no podrá ser
            reactivada.
            <br />
            <br />
            • El Usuario será el único responsable por el uso de su Tarjeta
            Prepaga hasta que comunique el evento por medio de un medio
            habilitado y obtenga la confirmación de la cancelación de la Tarjeta
            Prepaga por parte de n1u. n1u y Visa no serán responsables en ningún
            caso por el uso de la Tarjeta Prepaga antes de su cancelación
            <br />
            <br />
            • Una vez cancelada, la emisión de una nueva Tarjeta Prepaga estará
            sujeta a las Tarifas aplicables y se emitirá una nueva solo por
            decisión del usuario que emitió la original.
            <br />
            <br />
            <b>7.8 Cancelación o suspensión de la Tarjeta Prepaga</b>
            <br />
            <br />
            • El Usuario podrá solicitar la cancelación voluntaria de su Tarjeta
            Prepaga en cualquier momento a través de los canales habilitados por
            n1u.
            <br />
            <br />
            • Además, la Tarjeta Prepaga será cancelada o suspendida, sin
            limitación, en los siguientes casos: i) denuncia de daño, extravío,
            robo o hurto de la Tarjeta Prepaga por parte del Titular, ii)
            requerimiento judicial o de autoridad competente, iii) sospecha de
            fraude, uso ilegal o prohibido a criterio exclusivo de n1u o Visa,
            suspensión o cancelación de la Cuenta del Titular, v) falta de
            provisión a n1u de información o documentación solicitada, y vi)
            incumplimiento de los presentes términos y condiciones, incluyendo
            los Términos y Condiciones de Uso de n1u . En caso de
            desconocimiento de una transacción por parte del Titular, y sin que
            ello implique reconocimiento alguno de dicho desconocimiento, se
            emitirá al momento de la denuncia un bloqueo de la Tarjeta Prepaga y
            la emisión de un nuevo plástico en su reemplazo.

            <br />
            <br />
            <b>7.9 Canal de Atención al Titular</b>
            <br />
            <br />
            • n1u brinda al Titular distintos canales de atención para que éste
            pueda despejar sus dudas o realizar consultas relacionadas con la
            Tarjeta El Titular podrá obtener ayuda en el sitio web ayuda@n1u.app
            .
            <br />
            <br />
            <b>7.10 Operaciones en el exterior</b>
            <br />
            <br />
            El tipo de cambio que se tomará en consideración para la conversión
            de moneda extranjera a pesos para las operaciones realizadas con la
            Tarjeta Prepaga en el exterior, será el tipo de cambio vendedor
            minorista del Banco de la Nación Argentina publicado al cierre del
            día anterior a fecha de la operación (“Tipo de Cambio BNA”), más el
            6,5% sobre el Tipo de Cambio BNA más todos los impuestos y
            percepciones que correspondan conforme normativa.
            <br />
            <br />
            <b>7.11 Limitación de responsabilidad de n1u</b>
            <br />
            <br />
            • n1u no garantiza el acceso ininterrumpido de los canales de uso de
            la Tarjeta Prepaga, los cuales pueden no estar disponibles debido a
            inconvenientes técnicos, fallas de internet, indisponibilidad de la
            red Visa, indisponibilidad de extracción en cajeros automáticos
            adheridos a la red, o cualquier otro motivo, incluso ajeno a n1u.
            <br />
            <br />
            • Los Usuarios o Titulares exoneran de todo tipo de responsabilidad
            a n1u por daño directo, lucro cesante o cualquier otro daño o
            perjuicio que haya podido sufrir el Titular con relación a las
            transacciones con la Tarjeta Prepaga realizadas, rechazados o
            restringidos.
            <br />
            <br />
            <b>7.12 n1u como proveedor de servicios de pagos </b>
            <br />
            <br />
            n1u ofrece servicios de pago y no se encuentra autorizado a operar
            como entidad financiera por el Banco Central de la República
            Argentina. n1u no presta al usuario ningún servicio bancario o
            cambiario. Los fondos depositados en la cuenta de pago no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos de entidades financieras.
          </TermsText>

          <TermsQuestions>
            8.Términos y condiciones pago con activos digitales
          </TermsQuestions>

          <TermsText>
            <b>
              {" "}
              8.1 Uso de Activos Digitales para el pago con la Tarjeta Prepaga
            </b>
            <br />
            El Titular instruirá a n1u para que al momento de realizar una
            Transacción con su Tarjeta Prepaga, n1u realice una venta de la
            cantidad de activos digitales, disponibles en la cuenta del Titular
            en la Plataforma, necesaria para cubrir el monto en moneda fiat de
            la Transacción a realizar (cada una de ellas, una “Instrucción”). La
            venta de activos digitales se realizará conforme a lo elegido en
            “Configuración de Pagos” en la app n1u y a la cotización del momento
            de la Instrucción. Cada operación de pago que se realice implicará
            la conversión del saldo de activos digitales y el débito del saldo
            resultante. En ningún caso se podrán realizar operaciones por un
            importe superior al saldo disponible en la cuenta del Titular en la
            Plataforma al momento de la Transacción.
            <br />
            <br />
            <b>
              8.2 Elección del activo digital a debitar con el uso de la tarjeta
              prepaga.
            </b>{" "}
            <br />
            1.Para hacer uso de esta funcionalidad, el usuario deberá pre
            seleccionar una moneda diferente a ARS, desde la sección
            Configuración de Pagos, en su perfil o en la sección “Más” de la
            aplicación. Dentro de la sección, podrá elegir entre las monedas
            disponibles al momento. Teniendo en cuenta que la disponibilidad de
            monedas puede variar. La funcionalidad quedará activada una vez que
            el usuario confirme la moneda a debitar de su saldo, y visualice la
            confirmación en la app de n1u. Se debitará el valor total de la
            compra, con los impuestos correspondientes incluídos. Si no posee el
            saldo en la moneda elegida necesaria para cubrir el monto restante,
            se rechazará la compra.
            <br />
            <br />
            <b>8.3 Desconocimientos y/o cancelaciones de transacciones</b>
            <br />
            <br />
            <b>8.3.1.</b> Una vez solicitado el desconocimiento o cancelación
            mediante el proceso detallado en el punto 4.7 de los Términos y
            Condiciones generales del servicio el saldo de la transacción será
            reintegrado al Usuario en la cuenta del Titular en la app de n1u,
            por la cantidad de pesos argentinos, equivalente al monto de la
            Transacción.
            <br />
            <br />
            <b>8.3.2</b> El Titular podrá desconocer una Transacción y/o
            movimiento de su cuenta, dentro de los 30 (treinta) días de
            visualizado el mismo en la Plataforma. Para ello, deberá informar a
            n1u a través de los canales habilitados, la cual deberá dar trámite
            de dicho desconocimiento dentro de los 7 (siete) días de recibido el
            reclamo. En caso de corresponder, n1u podrá requerir al Titular la
            documentación respaldatoria que acredite dicho desconocimiento en
            forma previa a la devolución del monto en un plazo no mayor a los 15
            (quince) días de recepcionado el reclamo.
            <br />
            <br />
            <b>8.3.3</b> El uso de la Tarjeta Prepaga estará sujeto a la
            disponibilidad de la red Visa, lo cual constituye una circunstancia
            ajena y fuera del control de n1u. La aprobación de cada Transacción
            se realizará de acuerdo con los procedimientos específicos
            establecidos o acordados, según el tipo de Transacción o canal de
            pago utilizado. Por ejemplo, respecto de algunos canales de pago
            ofrecidos por ciertos establecimientos, el uso de la contraseña o la
            firma se podrán reemplazar mediante la indicación del número de la
            Tarjeta Prepaga, su fecha de vencimiento y su código de seguridad.
            En cualquier caso, el cumplimiento de los requisitos necesarios para
            realizar la Transacción implica la aceptación de la Transacción y su
            consiguiente responsabilidad por parte del Titular.
            <br />
            <br />
            <b>8.4 </b>Los montos de las Transacciones realizadas con la Tarjeta
            Prepaga, incluyendo cualquier Tarifa, se debitarán del saldo de la
            Tarjeta Prepaga en el momento, en forma total y en un solo débito.
            Si el saldo de la Tarjeta Prepaga no fuera suficiente, la
            Transacción no será autorizada. Las operaciones realizadas con la
            Tarjeta Prepaga se podrán consultar en la Plataforma de n1u pero
            podrán estar sujetas a limitaciones temporales, de forma tal que
            operaciones de cierta antigüedad pre-establecida, no estarán
            disponibles para consulta en la Plataforma a partir de cierta fecha,
            pero podrán igualmente ser solicitadas a n1u para consulta a través
            de los canales habilitados a los Usuarios.
            <br />
            <br />
            <b>8.5</b> Los fondos depositados por los Titulares en su cuenta no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos de entidades financieras.
          </TermsText>

          <TermsQuestions>9- Actividades Prohibidas</TermsQuestions>

          <TermsText>
            Los presentes Términos y Condiciones aplicables al uso de la Cuenta
            (“Condiciones de Uso”) establecen las reglas para las actividades
            consideradas prohibidas para los Usuarios de los servicios de n1u.
            Las presentes condiciones de Uso deben considerarse como parte
            integrante de los Términos y Condiciones Generales del Servicio.
            <br />
            <br />
            Las palabras en mayúscula no definidas en estos términos y
            condiciones tendrán el significado provisto en los Términos y
            Condiciones de Uso.
            <br />
            <br />
            Mediante estas Condiciones de Uso, además de las restantes
            obligaciones asumidas en Términos y Condiciones de Uso, el Usuario
            acepta y asume la obligación de NO realizar las actividades,
            prácticas o conductas que se detallan a continuación, en el marco
            del uso del Servicio:
            <br />
            <br />
            • Utilizar los contenidos de forma, con fines o efectos contrarios a
            la ley, a la moral y a las buenas costumbres generalmente aceptadas
            o al orden público.
            <br />
            <br />
            • Reproducir, copiar, representar, utilizar, distribuir, transformar
            o modificar los contenidos de la aplicación, por cualquier
            procedimiento o sobre cualquier soporte, total o parcial, o permitir
            el acceso del público a través de cualquier modalidad de
            comunicación pública.
            <br />
            <br />
            • Utilizar los contenidos de cualquier manera que importe un riesgo
            de daño o inutilización de la aplicación o de los contenidos o de
            terceros.
            <br />
            <br />
            • Suprimir, eludir o manipular el derecho de autor y demás datos
            identificativos de los derechos de autor incorporados a los
            contenidos, así como los dispositivos técnicos de protección, o
            cualesquiera mecanismos de información que pudieren tener los
            contenidos.
            <br />
            <br />
            • Emplear los contenidos y, en particular, la información de
            cualquier clase obtenida a través de la aplicación para distribuir,
            transmitir, remitir, modificar, re usar o reportar la publicidad o
            los contenidos de esta con fines de venta directa o con cualquier
            otra clase de finalidad comercial, mensajes no solicitados dirigidos
            a una pluralidad de personas con independencia de su finalidad, así
            como comercializar o divulgar de cualquier modo dicha información.
            <br />
            <br />
            • Permitir que terceros ajenos al Usuario usen la aplicación con su
            clave.
            <br />
            <br />
            • Utilizar la aplicación y los contenidos con fines ilícitos,
            contrarios a lo establecido en estos Términos y Condiciones, o al
            uso mismo de la aplicación, que sean lesivos de los derechos e
            intereses de terceros, o que de cualquier forma puedan dañar,
            inutilizar, sobrecargar o deteriorar la aplicación y los contenidos
            o impedir la normal utilización o disfrute de esta y de los
            contenidos por parte de los restantes usuarios.
            <br />
            <br />
            • Enviar spam, publicidad no solicitada o no autorizada, cartas
            encadenadas, o cualquier otro tipo de comunicaciones ilícitas.
            <br />
            <br />
            • Vulnerar derechos marcarios o cualquier otra propiedad intelectual
            de n1u o de terceros.
            <br />
            <br />
            • Cargar, transmitir, o facilitar material que contenga virus
            informáticos o cualquier otro código, archivo o programa informático
            diseñado para interrumpir, destruir o limitar la funcionalidad de
            cualquier software o hardware o equipo de telecomunicaciones de n1u.
            <br />
            <br />
            • Utilizar los Servicios de la aplicación para: i) reventa de
            entradas para espectáculos; ii) comercialización de sustancias
            medicinales y/o químicas no permitidas por la regulación vigente, o
            que exijan autorizaciones o disposiciones especiales para su
            comercialización; iii) comercialización de listas de correo o base
            de datos personales; iv) comercialización de contenidos relacionados
            con la pornografía; v) comercialización de productos de hacking y
            cracking (herramientas para introducir virus o hack); vi)
            comercialización de armas de fuego y artículos relacionados; vii)
            comercialización de servicios y/o productos para alteración de
            telefonía celular; viii) realización de préstamos de dinero, auto
            préstamos, y remesas; ix) casinos, venta de fichas y/o cualquier
            otro mecanismo; x) personas que operen desde apartados postales
            (P.O. box numbers, o similares); xi) prostitución; xii) negocios
            ilegales o que aparenten estar comprometidos con actividades
            ilegales; xiii) gambling
            <br />
            <br />
            • Utilizar el Servicio fuera del territorio autorizado y/o procesar
            transacciones en otra moneda que no sea de las autorizadas.
            <br />
            <br />
            • Discriminar entre las Tarjetas en cualquiera de sus formas y por
            cualquier motivo, sin perjuicio de los descuentos, cuotas u otras
            acciones promocionales que pudieran existir con respecto a la
            utilización de cualquiera de las Tarjetas a través de n1u .
            <br />
            <br />
            • Aceptar pagos por transacciones no originadas de una operación
            directa entre el Usuario y el comprador.
            <br />
            <br />
            • Aceptar pagos que el Usuario sepa o deba saber que son
            fraudulentos o no autorizados por el titular de la Tarjeta, de
            acuerdo con las normas legales y los presentes Términos y
            Condiciones del Servicio.
            <br />
            <br />
            • Usar el Servicio para procesar pagos para terceros o transferir
            fondos para terceros.
            <br />
            <br />• Usar el Servicio con la sola finalidad de desembolsar
            efectivo, ya sea en beneficio del comprador, del Usuario o de
            terceros. El Usuario no deberá efectuar operaciones que puedan
            considerarse o verse como un adelanto de efectivo o préstamo. Se
            considerará adelanto de efectivo a cualquier operación en la que un
            Usuario agregue fondos a su Cuenta de Usuario mediante operaciones
            de cobro con Tarjetas propias y/o de personas humanas o jurídicas
            relacionadas al Usuario para retirar dichos fondos, y en general a
            cualquier operación de cobro de Tarjetas en la medida de que no se
            correspondan con una operación auténtica de venta o prestación de
            servicios. n1u se reserva el derecho de suspender temporalmente o
            bloquear definitivamente al Usuario que incumpla con las
            obligaciones de uso y con los presentes Términos y Condiciones, sin
            previo aviso ni necesidad de fundar la decisión, con el único
            requisito de informar al Usuario la infracción cometida que ha
            motivado la baja o suspensión como Usuario.
          </TermsText>

          <TermsQuestions>10- Política de Privacidad de datos</TermsQuestions>

          <TermsText>
            Mediante la utilización de la aplicación, de la página web o del
            sitio especial que provee n1u para efectuar consultas de las
            operaciones efectuadas en el marco de las operaciones realizadas a
            través de la aplicación, usted (el “Usuario”) acepta nuestra
            política de privacidad de datos (la “Política de Privacidad”). En
            caso de no estar de acuerdo con la misma, por favor, no acceda a los
            Sitios.
            <br />
            <br />
            <b>1. Aplicación Política de Privacidad</b>
            <br />
            <br />
            La presente Política de Privacidad aplica a la billetera y al Sitio,
            pero no a los enlaces a los que el usuario pueda acceder a través de
            otros links, en cuyo caso le informaremos que está abandonando
            nuestro Sitio y que no tenemos responsabilidad sobre la página web a
            la cual estaría por ingresar. Recomendamos, se informe respecto de
            la política de privacidad y seguridad de cada uno de dichos enlaces.
            <br />
            <br />
            <b> 2. Información personal</b>
            <br />
            <br />
            Cumplimos con todos los requisitos que exige la Ley de Protección de
            Datos Personales (Ley N° 25.326) -y modificatorias y
            complementarias, la que tiene por objeto proteger la información
            personal de los Usuarios que se encuentran asentados en los archivos
            y bases de datos.
            <br />
            <br />
            El Titular de los datos, previa acreditación de su identidad, tiene
            derecho a solicitar y obtener información sobre sus datos
            personales.
            <br />
            <br />
            Nos es imprescindible recabar la información personal o financiera
            del Usuario a fin de cumplir con las obligaciones hacia nuestros
            Usuarios, por lo que aplicamos estrictas medidas y procesos de
            seguridad para prevenir el acceso no autorizado a la información en
            cuestión. Las políticas que poseemos son las siguientes:
            <br />
            <br />
            • Resguardamos la información que los Usuarios provean, conforme a
            niveles de protección adecuados.
            <br />
            <br />
            • No utilizamos la información provista a menos que el Usuario lo
            autorice, con el objeto de informarle acerca de los productos y
            servicios ofrecidos.
            <br />
            <br />
            • No revelamos la información provista a ninguna organización
            externa, salvo que la soliciten formalmente autoridades competentes,
            el Banco Central de la República Argentina (“BCRA)” o salvo que se
            brinde a compañías relacionadas–. Asimismo, el Usuario expresamente
            acepta que los datos de sus transacciones con tarjetas podrán ser
            compartidos con las administradoras de dichas tarjetas.
            <br />
            <br />
            • El usuario dispone del detalle de la información que entregó a n1u
            , para rectificarla y/o solicitar la baja del registro
            correspondiente.
            <br />
            <br />
            En cumplimiento de lo dispuesto legalmente en materia de protección
            de datos, n1u comunica que recolecta y trata los datos personales,
            con fines comerciales y con la finalidad de la creación de perfiles
            de cliente, a efectos de ofrecer productos o servicios acorde a las
            necesidades o características específicas, así como con fines de
            análisis de riesgos para evaluar nuevas operaciones, en ambos
            supuestos conservando dichas segmentaciones o perfiles en tanto y en
            cuanto el interviniente mantenga relación contractual. Asimismo
            puede transferir sus datos a terceros para su tratamiento.
            <br />
            <br />
            Asimismo, informamos que n1u tiene registradas todas sus Bases de
            Datos ante el organismo de control.
            <br />
            <br />
            n1u dispone de los siguientes canales de contacto, por medio de los
            que se ejercen los derechos previstos en la Ley (acceso,
            rectificación y supresión de los datos) en forma gratuita a
            intervalos no inferiores a seis meses, salvo que se acredite un
            interés legítimo al efecto conforme lo establecido en el artículo
            14, inciso 3 de la Ley Nº 25.326: ayuda@n1u.app.
            <br />
            <br />
            La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, órgano de control de
            la ley N° 25.326, tiene la atribución de atender las denuncias y
            reclamos que se interpongan quienes resulten afectados en sus
            derechos por incumplimiento de las normas vigentes en materia de
            protección de datos personales.
            <br />
            <br />
            <b> 3. MONITOREO DEL USO DE LA PÁGINA WEB</b>
            <br />
            <br />
            Nos reservamos la facultad de monitorear los mensajes o información
            registrada en los Sitios por los Usuarios, con la frecuencia que estimemos necesaria, así como a
            suprimir cualquier registro cuyo contenido sea o pueda ser
            considerado a criterio de n1u , ilegal, contrario a la moral o
            buenas costumbres, difamatorio, calumnioso, obsceno,
            discriminatorio, o cualquier otro material cuyo contenido incite
            algún comportamiento o conducta que pueda ser considerada ilegal o
            inmoral o contraria a las buenas costumbres. Por otro lado,
            almacenamos información en la computadora del Usuario en forma de
            cookie, con el objetivo de mejorar los Sitios, a modo de reflejar
            las preferencias del Usuario. Cabe destacar, que con las cookies no
            podemos recabar información personal del Usuario y tampoco de la
            actividad que realiza el mismo en internet, ya que solo se trata de
            un fragmento de información proporcionado por el explorador del
            Usuario, cuando visita nuestros Sitios. Si bien el Usuario puede
            desactivar el uso de cookies, le recomendamos no hacerlo para que
            nuestros Sitios funcionen correctamente.
            <br />
            <br />
            BAJA O DESUSCRIPCIÓN DE CORREO ELECTRÓNICO
            <br />
            <br />
            A fin de ejercer el derecho de retiro o bloqueo total o parcial de
            sus datos en nuestra base de datos o a fin de no recibir más
            comunicaciones por e-mail, deberá enviar un correo electrónico a la
            siguiente dirección de e-mail: de suscripción ayuda@n1u.app . Para
            una mejor comprensión, se transcriben: 1) Artículo 27, inc. 3ro. de
            la ley 25.326: (Archivos, registros o bancos de datos con fines de
            publicidad) “El titular podrá en cualquier momento solicitar el
            retiro o bloqueo de su nombre de los bancos de datos a los que se
            refiere el presente artículo”; y 2) Artículo 27 – Anexo I – Decreto
            1558/01: “En toda comunicación con fines de publicidad que se
            realice por correo, teléfono, correo electrónico, internet u otro
            medio a distancia a conocer, se deberá indicar, en forma expresa y
            destacada, la posibilidad del titular del dato de solicitar el
            retiro o bloqueo, total o parcial, de su nombre de la base de datos.
            A pedido del interesado, se deberá informar el nombre del
            responsable o usuario del banco de datos que proveyó la
            información.”
            <br />
            <br />
            RENAPER.CONSENTIMIENTO INFORMADO
            <br />
            <br />
            Por medio del presente, en mi carácter de TITULAR DE LOS DATOS
            presto mi CONSENTIMIENTO para que “BLOCKCHAIN PAYMENTS RAILS S.A.”en
            su carácter de CESIONARIO confronte mis datos personales que se
            indican en párrafo siguiente con la base de datos del RENAPER,
            conforme a las especificaciones que a continuación se detallan:
            <br />
            <br />
            DATOS AUTORIZADOS:El presente consentimiento para el tratamiento de
            mis datos personales alcanza a los incluidos en mi Documento
            Nacional de Identidad (INCLUYENDO DATOS BIOMÉTRICOS DE HUELLA
            DACTILAR Y DE RECONOCIMIENTO FACIAL) en confronte con lo que informa
            el web service del REGISTRO NACIONAL DE LAS PERSONAS.
            <br />
            <br />
            INFORMACIÓN SOBRE EL TRATAMIENTO:
            <br />
            <br />
            1. Los datos serán tratados con la exclusiva finalidad de validar mi
            identidad y verificar la vigencia de mi Documento Nacional de
            Identidad para n1u.
            <br />
            <br />
            2. Los datos confrontados serán destruidos una vez verificada la
            validez del Documento Nacional de Identidad y validada la misma, no
            pudiendo ser almacenados.
            <br />
            <br />
            3. Los datos son facilitados con carácter obligatorio, por cuanto es
            imprescindible identificar fehacientemente al titular, para asegurar
            el correcto proceso de identificación.
            <br />
            <br />
            4. El titular de los datos podrá ejercer los derechos de acceso,
            rectificación y supresión de sus datos en cualquier momento y a su
            sola solicitud ante el RENAPER.
            <br />
            <br />
            5. En cumplimiento de la Resolución AAIP Nº 14/2018, le hacemos
            saber que la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su
            carácter de Órgano de Control de la Ley N° 25.326, tiene la
            atribución de atender las denuncias y reclamos que interpongan
            quienes resulten afectados en sus derechos por incumplimiento de las
            normas vigentes en materia de protección de datos. Los datos
            confrontados serán destruidos una vez verificada la validez del
            Documento Nacional de Identidad y validada la misma, no pudiendo ser
            almacenados.
          </TermsText>

          <TermsQuestions>
            Términos y Condiciones del Programa de n1u store
          </TermsQuestions>
          <TermsText>
            El Programa de n1u store (en adelante, el “Programa”) es una
            propuesta de beneficios exclusiva para el usuario de Blockchain
            Payments Rails S.A. (en adelante, “n1u”) por el que podrá
            adquirir-canjear productos, servicios o beneficios (los
            “Beneficios”), mediante el canje de n1u coins, canje de n1u coins
            más pesos o bajo la modalidad solo Pesos, dentro del n1u store. Para
            acceder a n1u store deberá ingresar en la app n1u. Más en la app n1u
            ayuda@n1u.app .
            </TermsText>
      
            <TermsText>
            La participación en el Programa implica la aceptación de los
            presentes Términos y Condiciones (en adelante, “Términos y
            Condiciones”), los que establecen los derechos, obligaciones y
            responsabilidades de los usuarios. Al acceder y utilizar el
            Programa, se considera que ha leído, comprendido y aceptado estar
            sujeto a estos Términos y los términos de la Plataforma de n1u.El
            Programa se ofrece en la plataforma que es de propiedad y
            administrada por n1u disponible en la aplicación móvil (la
            “Plataforma”).
            </TermsText>
          
            <TermsText>
            El usuario ya ha aceptado los Términos y Condiciones Generales, la
            Política de Privacidad y los Términos y Condiciones de uso de las
            Tarjetas Prepagas de n1u – Visa y estos Términos y Condiciones no
            implican modificación alguna de los mismos y ellos se aplicarán en
            su totalidad al Programa, en forma complementaria a lo aquí
            dispuesto. El usuario debe estar en estado activo y en situación
            normal de pago, en caso de no registrar alguna de las condiciones
            mencionadas, se perderá el derecho de realizar canje o adquisición
            de Beneficios hasta tanto regularice tal situación. La baja de la
            totalidad de productos mediante los cuales el usuario acumule coins,
            significará la cancelación de la participación en el Programa,
            provocando la pérdida de todas las coins acumuladas.
            </TermsText>
         
            <TermsText>
            Se podrá cambiar el Programa o estos Términos en cualquier momento a
            discreción de n1u y entrará en vigencia a partir de los 60 días de
            su publicación. Usted acepta podemos agregar funciones o eliminar
            las existentes y cambiar el Programa o una parte de él.
            </TermsText>
         
            <TermsText>
            Podrán utilizar el Programa todos aquellos usuarios de n1u. Se
            considera como “usuario” a aquellas personas que tengan capacidad
            legal para contratar, sean mayores de edad y se encuentren
            registrados en la Plataforma y los menores autorizados desde 13 años
            de edad, que hayan completado el proceso de n1u. Es decir, podrán
            utilizar el Programa aquellos Usuarios que se hayan registrado en la
            Plataforma y hayan completado de manera correcta el proceso de
            verificación en la Plataforma, cumpliendo con la presentación de la
            documentación solicitada por n1u en el “Proceso de Verificación”.
            Los usuarios están obligados a presentar datos verdaderos y
            actualizados, como así también responder a los requerimientos
            exigidos por n1u para la utilización del Programa.
            </TermsText>
      
            <TermsText>
            Los presentes Términos y Condiciones permanecerán vigentes del
            13/04/23 al 31/12/25 o hasta sus renovaciones siguientes.
            <br />
            <br />
            Plan de Beneficios. Se denominan Beneficios a todos aquellos
            productos, servicios o beneficios que el usuario podrá adquirir bajo
            las modalidades disponibles en n1u store. El usuario podrá elegir
            entre los Beneficios que figuren en el catálogo del n1u store
            disponible en la app, donde se detallarán cada uno de los beneficios
            y su correspondiente puntaje/valor en n1u coins con vigencia dentro
            de Argentina. Las imágenes incluidas en el catálogo son
            ilustrativas, las características, especificaciones técnicas y
            garantías serán de exclusiva responsabilidad del fabricante,
            distribuidor o proveedor de los mismos. Asimismo, los Beneficios
            podrán ser comunicados a través de folleto o catálogo, afiche u otro
            material publicitario gráfico similar disponible o expuesto en la
            App de n1u. Los Beneficios están sujetos a la modalidad de canje o
            adquisición así informado como a su disponibilidad. Una vez agotado
            el stock de beneficios (de cualquier tipo), n1u no estará obligado a
            reposición de stock ni a cambio por otros beneficios, asimismo no
            asume ningún tipo de responsabilidad por la no disponibilidad de los
            beneficios, cualquiera fuera la causa. En los beneficios que
            consisten en entradas para recitales u otros eventos similares, n1u
            no será responsable por la suspensión del mismo, resultando ajeno a
            cualquier consecuencia que pudiera derivarse de tal evento.
            <br />
            <br />
            Los beneficios, conforme se detalle en n1u, podrán ser enviados al
            mail que el usuario previamente informe o ser retirados
            personalmente por el usuario o quien éste designe, en los puntos de
            entrega o enviados de manera gratuita en caso de así indicarlo.
            Aquellos con envío gratuito, serán entregados en un plazo no mayor a
            15 días hábiles posteriores al canje o adquisición del mismo; si por
            circunstancias ajenas a n1u se excede este plazo, se realizarán las
            gestiones tendientes a cumplimentar con la entrega sin que esto
            implique asunción de responsabilidad alguna por la demora ocurrida.
            Aceptar los Beneficios implica la autorización de los usuarios a n1u
            para difundir o publicar sus nombres/imagen/fotografía/voz con fines
            publicitarios, sin derecho a compensación alguna. Para efectuar
            reclamos, los Clientes se deberán contactarse a través de
            ayuda@n1u.app, indicado en la plataforma. Los beneficios
            consistentes en productos-servicios que sean prestados por terceros,
            serán de exclusiva responsabilidad de los prestadores, asimismo n1u
            no asume responsabilidad ni extiende garantía alguna por fallas,
            roturas, errores, desperfectos, daños o cualquier tipo de
            inconveniente relativo a los beneficios, no obstante si el reclamo
            corresponde a fallas, roturas, errores, desperfectos o daños y se
            efectúa dentro de los 10 días siguientes de haber recibido el
            beneficio, n1u gestionará con el proveedor la reposición. Superado
            el plazo precedentemente establecido, el usuario deberá hacer uso de
            la garantía del producto reclamando a la marca del mismo. Para ello
            debe contar con la factura tanto de compra como adquisición que será
            enviada por mail al momento de la gestión de la solicitud. El
            usuario podrá canjear un importe en DAI, BTC, ETH, ARS o USDT,
            equivalente al monto en coins determinado por n1u. n1u advierte que
            toda operación con activos digitales es riesgosa y puede no ser
            adecuada para todos los Usuarios. Asimismo, se hace saber que las
            activos digitales no son respaldadas por ninguna entidad
            gubernamental. El estado regulatorio de los activos de moneda
            digital no está resuelto actualmente, varía entre jurisdicciones y
            está sujeto a una incertidumbre significativa. Es posible que en el
            futuro se implementen nuevas leyes, reglamentos, políticas o reglas
            relacionadas con los activos escritos en clave y/o la tecnología
            blockchain, lo que podría afectar o restringir directa o
            indirectamente este producto.
            </TermsText>
           
            <TermsText>
            Operatoria. Las n1u coins se obtienen a partir de las transacciones
            que el usuario haga en la app y las misiones que cumpla.
            <br />
            <br />
            Misiones. Las misiones ofrecidas por n1u se anunciarán en la sección
            “misiones” dentro del perfil del usuario en la aplicación móvil n1u
            y en cada misión se detallarán las condiciones para su cumplimiento.
            Se le indicará las 3 misiones semanales. Por cada misión cumplida,
            se le adjudicarán n1u coins.
            <br />
            <br />
            n1u coins. El Usuario podrá ver la cantidad de n1u coins que posee
            en la Plataforma. En caso de cumplirse 6 meses de inactividad en
            n1u, todas las coins acumuladas por el usuario caducarán y serán
            eliminadas sin que ello otorgue derecho a indemnización o reclamo de
            ningún tipo. Se considera inactividad tanto a la no acumulación de
            n1u coins, como la no redención de canje de n1u coins en el n1u
            store.
            </TermsText>
         
            <TermsText>
            Los Usuarios acumularán n1u coins en n1u a través de las siguientes
            acciones:
            <br />
            <br />
            • Pagos con tarjeta
            <br />
            <br />
            • Cumplimiento de misiones
            <br />
            <br />
            Modificaciones de los Términos y Condiciones. Modificaciones del
            Programa. n1u podrá modificar estos términos y condiciones en
            cualquier momento y hacerlos públicos en la Plataforma de n1u. Los
            Términos y Condiciones modificados entrarán en vigor a los 60 días
            de su publicación. Asimismo, n1u se reserva el derecho a modificar,
            mejorar, corregir o eliminar la información, contenido, servicios,
            funcionalidades, requisitos, facultades, descripciones y
            configuraciones establecidas en el Programa y en la Plataforma de
            n1u, y sin que estas medidas estén sujetas a ningún requisito ni
            derecho a reclamar daños y perjuicios por parte del usuario.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default General;
