import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const CoverVideo = () => {
  // const playerRef = useRef();
  // const [play, setPlay] = useState(true);

  // useEffect(() => {
  //   // Configura la reproducción automática después de 1 segundo
  //   const timeoutId = setTimeout(() => {
  //     setPlay(true);
  //   }, 1000);

  //   // Limpia el temporizador al desmontar el componente
  //   return () => clearTimeout(timeoutId);
  // }, []);

  return (
    <>
      <CoverContainer>
        {/* <ResponsivePlayer
          ref={playerRef}
          url="https://www.youtube.com/watch?v=oWxXYoEx4Yw"
          playing={play}
          loop={true}
          controls={false}
          muted={true}
          width="100%"
          height="100%"
        /> */}
        <div>
          <ResponsivePlayer
            url="https://www.youtube.com/watch?v=6VrLb2DmUUc"
            playing
            width="100%"
            height="100%"
            loop
          />
        </div>
      </CoverContainer>
    </>
  );
};

const CoverContainer = styled.div`
  margin-top: 70px !important;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

`;

const ResponsivePlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`;

export default CoverVideo;
