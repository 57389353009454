import React from "react";
import styled from "styled-components";
import ray from "../assets/wiki/ray.svg";
import Tutorials from "./Tutorials";
import Notes from "./Notes";

function Learn() {
  return (
    <>
      <LearnSection>
        <LearnContainer>
          <h1>
            Aprendé <br />
            todo sobre <br />
            n1u
          </h1>

          <Top>
            <h2>tutoriales</h2>
            <Line />
            <img src={ray} alt="rayo" />
          </Top>
        </LearnContainer>
        <Tutorials />
        <Notes />
      </LearnSection>
    </>
  );
}

const LearnSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: black;
  background-image: url("/backgrounds/degrade-wiki.png");
  background-size: cover;
  background-position: center center;
`;

const LearnContainer = styled.div`
  width: 90%;
  height: 70vh;
  max-width: 1600px;
  position: relative;
  display: flex;
  position: relative;
  margin-top: 40px;
  @media only screen and (min-width: 2000px) {
    height: auto;
    padding: 300px 0px;
  }

  @media only screen and (max-width: 920px) {
    height: 50vh;
  }

  @media only screen and (max-width: 675px) {
    height: 40vh;
  }

  h1 {
    color: #fff;
    font-size: 130px;
    font-family: "LoRes";
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    z-index: 200;
    position: absolute;
    bottom: 0;
    left: 0;

    @media only screen and (max-width: 1200px) {
      font-size: 110px;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 100px;
    }

    @media only screen and (max-width: 920px) {
      font-size: 90px;
      margin-top: 50px;
    }

    @media only screen and (max-width: 675px) {
      font-size: 70px;
    }

    @media only screen and (max-width: 525px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 55px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 40px;
      margin-top: 100px;
    }
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  margin-left: 300px;

  h2 {
    color: #fff;
    font-family: "LoRes";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    margin-right: 30px;
  }

  img {
    height: 50px;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const Line = styled.div`
  width: 550px;
  background-color: white;
  height: 1px;
  margin-right: 30px;

  @media only screen and (max-width: 1650px) {
    width: 400px;
  }

  @media only screen and (max-width: 800px) {
    width: 100px;
  }
`;

export default Learn;
