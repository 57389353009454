import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../styles/texts";

function N1uPass() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            Términos y Condiciones del Programa de n1u pass
          </TermsTitle>

          <TermsSubText><b>1. Condiciones Generales</b></TermsSubText>
          <TermsText>
            El Programa de n1u pass (en adelante, el “Programa”) es una
            propuesta de beneficios exclusiva para el usuario de Blockchain
            Payments Rails S.A. (en adelante, “n1u”) por el que podrá adquirir
            productos, servicios y/o beneficios (en adelante, los “Beneficios”),
            mediante el canje de n1u coins, canje de n1u coins más pesos o bajo
            la modalidad solo Pesos, dentro del n1u store.
            <br />
            <br />
            La participación en el Programa implica la aceptación de los
            presentes Términos y Condiciones. Estos Términos y Condiciones
            establecen los derechos, obligaciones y responsabilidades de los
            usuarios (los “Usuarios”) en el marco del Programa y se aplicarán en
            forma complementaria a los Términos y Condiciones Generales, la
            Política de Privacidad y los Términos y Condiciones de uso de las
            Tarjetas Prepagas de N1U – Visa, que se aplican a la plataforma de
            N1U disponible en la aplicación móvil (la “Plataforma”).
            <br />
            <br />
            El usuario ya ha aceptado los Términos y Condiciones Generales, la
            Política de Privacidad y los Términos y Condiciones de uso de las
            Tarjetas Prepagas de n1u – Visa y estos Términos y Condiciones no
            implican modificación alguna de los mismos y ellos se aplicarán en
            su totalidad al Programa, en forma complementaria a lo aquí
            dispuesto. ‍
          </TermsText>

          <TermsSubText><b>2. Condiciones de participación</b></TermsSubText>
          <TermsText>
            Podrán utilizar el Programa todos aquellos usuarios de n1u. Se
            considera como “usuario” a aquellas personas que tengan capacidad
            legal para contratar, sean mayores de edad y se encuentren
            registrados en la Plataforma y los menores autorizados desde 13 años
            de edad, que hayan completado el proceso de n1u. Es decir, podrán
            utilizar el Programa aquellos Usuarios que se hayan registrado en la
            Plataforma y hayan completado de manera correcta el proceso de
            verificación en la Plataforma, cumpliendo con la presentación de la
            documentación solicitada por n1u en el “Proceso de Verificación”.
            Los usuarios están obligados a presentar datos verdaderos y
            actualizados, como así también responder a los requerimientos
            exigidos por n1u para la utilización del Programa. ‍
            <br />
            <br />
            El usuario deberá estar en estado activo y en situación normal de
            pago, en caso de no registrar alguna de las condiciones mencionadas,
            se perderá el derecho de realizar canje o adquisición de Beneficios
            hasta tanto regularice tal situación.
            <br />
            <br />
            La baja de la totalidad de productos mediante los cuales el usuario
            acumule coins, significará la cancelación de la participación en el
            Programa, provocando la pérdida de todas las coins acumuladas.
          </TermsText>

          <TermsSubText><b>3. Misiones</b></TermsSubText>
          <TermsText>
            Las misiones ofrecidas por n1u se anunciarán en la sección
            “misiones” dentro del perfil del usuario en la aplicación móvil n1u
            y en cada misión se detallarán las condiciones para su cumplimiento.
            Se le indicará las 3 misiones semanales. Por cada misión cumplida,
            se le adjudicarán n1u coins.
          </TermsText>

          <TermsSubText><b>4. n1u coins</b></TermsSubText>
          <TermsText>
           <b> 4.1 Formas de acumulación</b>
            <br />
            <br />
            Los usuarios acumularán coins por compras con su tarjeta recargable
            Visa y por el cumplimiento de las misiones. Las reglas de
            acumulación y acreditación de coins se reservan el derecho de ser
            modificadas en cualquier momento durante la vigencia del Programa.
            Las reglas de acumulación y acreditación de Coins por compras con la
            tarjeta recargable Visa es de $150 = 1 coin. No se computarán las
            fracciones de puntos, los mismos se redondean hacia abajo. n1u se
            reserva el derecho de modificar la mencionada relación entre pesos y
            coins en cualquier momento durante la vigencia del Programa. La
            acreditación de coins se realizará automáticamente después de cada
            transacción, en un plazo máximo de 2 (dos) horas. No suman coins los
            saldos financiados, cargos de resumen, cargos de renovación de
            tarjeta, gastos de mantenimiento, intereses, adelantos en efectivo,
            cargos negativos (reversión de transacciones u otros cargos) y todo
            otro concepto que no corresponda a un consumo. En el caso de
            reversos de consumos y/u operaciones, contracargos y devoluciones
            (salvo aquellas que resulten de una bonificación por beneficios y
            ahorros) las coins que pudieron haberse acumulado se descontarán por
            el monto equivalente a la operación de que se trate.
            <br />
            <br />
            n1u no se responsabiliza por los cupones que hayan sido presentados
            fuera de término por los establecimientos adheridos al sistema de
            Tarjetas Prepagas o por aquellos cupones rechazados por cualquier
            motivo.
            <br />
            <br />
            n1u se reserva el derecho de realizar promociones periódicas que
            impliquen la asignación adicional de coins de n1u pass a los
            Clientes.
            <br />
            <br />
          </TermsText>

          <TermsText>
           <b> 4.2 Transferencia de coins</b>
            <br />
            <br />
            Las coins del programa no podrán ser transferidos a ninguna otra
            persona o entidad ni bajo ningún título o causa (fallecimiento,
            incapacidad, disolución de la sociedad conyugal, etc.), y tampoco
            podrán ser transferidos a ningún otro usuario sin el consentimiento
            expreso de n1u.
            <br />
            <br />
          </TermsText>
          <TermsText>
            <b>4.3 Caducidad de coins</b>
            <br />
            <br />
            Las coins caducarán automáticamente y sin necesidad de notificación
            alguna en los siguientes casos: a) Al finalizar el Programa, b)
            Cuando los Usuarios dejen de acumular o canjear coins por 6 (seis)
            meses, c) En caso de fallecimiento del Usuario titular de la cuenta,
            d) Ante la baja y/o inhabilitación definitiva de la cuenta del
            Usuario adherida al Programa, por causas atribuibles a los Usuarios,
            e) Ante la solicitud de baja del Programa actual por parte del
            Usuario.
            <br />
            <br />{" "}
          </TermsText>
          <TermsSubText><b>5. Vigencia</b></TermsSubText>
          <TermsText>
            Los presentes Términos y Condiciones permanecerán vigentes del
            13/04/23 al 31/12/25 o hasta sus renovaciones siguientes.
          </TermsText>

          <TermsSubText><b>‍6. Plan de Beneficios</b></TermsSubText>
          <TermsText>
            Se denominan Beneficios a todos aquellos productos, servicios o
            beneficios que el usuario podrá adquirir bajo las modalidades
            disponibles en n1u store. El usuario podrá elegir entre los
            Beneficios que figuren en el catálogo del n1u store disponible en la
            app, donde se detallarán cada uno de los beneficios y su
            correspondiente puntaje/valor en n1u coins con vigencia dentro de
            Argentina. Las imágenes incluidas en el catálogo son ilustrativas,
            las características, especificaciones técnicas y garantías serán de
            exclusiva responsabilidad del fabricante, distribuidor o proveedor
            de los mismos. Asimismo, los Beneficios podrán ser comunicados a
            través de folleto o catálogo, afiche u otro material publicitario
            gráfico similar disponible o expuesto en la App de n1u. Los
            Beneficios están sujetos a la modalidad de canje o adquisición así
            informado como a su disponibilidad. Una vez agotado el stock de
            beneficios (de cualquier tipo), n1u no estará obligado a reposición
            de stock ni a cambio por otros beneficios, asimismo no asume ningún
            tipo de responsabilidad por la no disponibilidad de los beneficios,
            cualquiera fuera la causa. En los beneficios que consisten en
            entradas para recitales u otros eventos similares, n1u no será
            responsable por la suspensión del mismo, resultando ajeno a
            cualquier consecuencia que pudiera derivarse de tal evento.
            <br />
            <br />
            Los beneficios, conforme se detalle en n1u, podrán ser enviados al
            mail que el usuario previamente informe o ser retirados
            personalmente por el usuario o quien éste designe, en los puntos de
            entrega si correspondiera. Aceptar los Beneficios implica la
            autorización de los usuarios a n1u para difundir o publicar sus
            nombres/imagen/fotografía/voz con fines publicitarios, sin derecho a
            compensación alguna.
          </TermsText>
          <TermsSubText><b>7. Reclamos</b></TermsSubText>

          <TermsText>
            Para efectuar reclamos, los Clientes se deberán contactar vía mail a
            ayuda@n1u.app. Los beneficios consistentes en productos-servicios
            que sean prestados por terceros, serán de exclusiva responsabilidad
            de los prestadores, asimismo n1u no asume responsabilidad ni
            extiende garantía alguna por fallas, roturas, errores, desperfectos,
            daños o cualquier tipo de inconveniente relativo a los beneficios,
            no obstante si el reclamo corresponde a fallas, roturas, errores,
            desperfectos o daños y se efectúa dentro de los 10 días siguientes
            de haber recibido el beneficio, n1u gestionará con el proveedor la
            reposición. Superado el plazo precedentemente establecido, el
            usuario deberá hacer uso de la garantía del producto reclamando a la
            marca del mismo. Para ello debe contar con la factura tanto de
            compra como adquisición que será enviada por mail al momento de la
            gestión de la solicitud.
          </TermsText>

          <TermsSubText>
            <b>8. Modalidad de canje y/o adquisición de Beneficios</b>
          </TermsSubText>

          <TermsText>
            Los Beneficios podrán ser canjeados y/o adquiridos bajo las
            siguientes modalidades: CANJE DE COINS: Consiste en la adquisición
            de Beneficios mediante canje íntegro de coins. Será requisito tener
            acumulados suficientes Coins equivalentes al Beneficio elegido.
            COINS + PESOS: Consiste en el canje de Coins, más una suma en pesos
            a abonar determinada correspondiente al valor del Beneficio elegido.
            FULL PESOS: Consiste en la adquisición de Beneficios se realiza
            íntegramente mediante el abono de la suma determinada en pesos
            correspondiente al Beneficio elegido. Esta modalidad no requiere
            contar con coins acumulados. Precio publicado para consumidor final.
          </TermsText>

          <TermsSubText><b>9. Aspectos generales</b></TermsSubText>

          <TermsText>
           <b> 9.1 Defensa consumidor</b>
            <br />
            <br />
            n1u no otorga ninguna garantía distinta de la garantía legal
            establecida en la ley 24.240, de Defensa del Consumidor, con
            relación al Programa ni será responsable de daños, perjuicios o
            pérdidas ocasionados a los Participantes o a terceros, en sus
            personas o bienes, con motivo o en ocasión del uso del Programa ni
            por fallas en los el sitio web, la App y/o los servicios de pago, de
            suministro de energía, de líneas telefónicas, de la red Internet, ni
            por desperfectos técnicos, errores humanos o acciones deliberadas de
            terceros que pudieran interrumpir o alterar el normal desarrollo del
            Programa
            <br />
            <br />
            <br />
           <b> 9.2 Datos personales</b>
            <br />
            <br />
            ‍Al utilizar el Programa, los Usuarios otorgan a Blockchain Rails
            SA., su consentimiento previo, expreso e informado para recolectar y
            para almacenar en sus bases de datos los datos personales de los
            Usuarios, incluyendo, pero sin limitarse a nombre de Usuario dentro
            de la Plataforma, nombre y apellido, correo electrónico, DNI, entre
            otros (en adelante, los “Datos Personales”), para procesarlos y
            utilizarlos a los efectos de compartirlos con otros Usuarios de la
            Plataforma e identificarse con otros Usuarios al utilizar el
            Programa a través de la Plataforma, proporcionarle información y
            comunicaciones útiles, noticias y actualizaciones de productos;
            informarle sobre productos y servicios nuevos; y para procesar su
            identificación, autenticación, administración y gestión comercial al
            momento de la participación en el Programa. n1u se compromete a no
            vender y/o ceder la base de datos que se genere con motivo de la
            utilización del Programa sin autorización de los Usuarios. n1u sólo
            utilizará los datos de acuerdo a la finalidad para los que han sido
            recogidos y adoptará las medidas necesarias para evitar su
            alteración, pérdida, tratamiento o acceso no autorizado. Asimismo,
            n1u se compromete a no divulgar los Datos Personales a terceros,
            salvo en los casos aquí contemplados. n1u recolectará únicamente
            aquella información que sea proporcionada directamente por los
            Usuarios. Los Usuarios entienden que es necesaria la provisión de
            información para la utilización y correcto funcionamiento del
            Programa.
          </TermsText>
          <TermsSubText>
            <b>10. Modificaciones de los Términos y Condiciones</b>
          </TermsSubText>
          <TermsText>
            n1u podrá modificar estos términos y condiciones en cualquier
            momento y hacerlos públicos en la Plataforma de n1u. Los Términos y
            Condiciones modificados entrarán en vigor a los 60 días de su
            publicación. Asimismo, n1u se reserva el derecho a modificar,
            mejorar, corregir o eliminar la información, contenido, servicios,
            funcionalidades, requisitos, facultades, descripciones y
            configuraciones establecidas en el Programa y en la Plataforma de
            n1u, y sin que estas medidas estén sujetas a ningún requisito ni
            derecho a reclamar daños y perjuicios por parte del usuario.
          </TermsText>

          <TermsText>
            <br />
            <br />
            <br />
            <br />
            Versión 10/05/2023
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default N1uPass;
