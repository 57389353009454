import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
  TermsQuestions,
} from "../styles/texts";

function PrepaidCard() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Tarjeta Prepaga</TermsTitle>
          <TermsText>
            <b>
              {" "}
              7- Términos y condiciones de la Tarjeta Prepaga Visa y de la
              tarjeta de regalo.
            </b>
            <br />
            <br />
            Estos términos y condiciones aplican al Usuario respecto del uso de
            las tarjetas prepagas ofrecidas (la “Tarjeta Prepaga”). Excepto en
            los casos expresamente establecidos en el presente, estos términos y
            condiciones son parte integrante de los Términos y Condiciones del
            Servicio que resultan plenamente aplicables al uso de las Tarjetas
            Prepagas. Cualquier persona que no acepte estos términos y
            condiciones, que tienen un carácter obligatorio y vinculante, deberá
            abstenerse de solicitar y usar la Tarjeta Prepaga o sus servicios
            conexos.
            <br />
            <br />
            Las palabras en mayúscula no definidas en estos términos y
            condiciones tendrán el significado provisto en los Términos y
            Condiciones de Uso.
            <br />
            <br />
            Se deja expresa constancia que no resultará de aplicación la Ley
            25.065.
          </TermsText>
          <TermsSubText>1. Tarjeta Prepaga</TermsSubText>

          <TermsText>
            1.1. Podrá ser titular de una Tarjeta Prepaga (el “Titular”)
            cualquier Usuario que sea una persona humana.
            <br />
            <br />
            1.2. La Tarjeta Prepaga es un plástico nominado y recargable que se
            puede utilizar para adquirir bienes, servicios o extraer fondos en
            la República Argentina en establecimiento asociado a la red Visa.
            Asimismo, podrá utilizarse para adquirir bienes y/o servicios en el
            exterior en establecimiento asociado a la red Visa. La Tarjeta
            Prepaga podrá ser utilizada para realizar pago presencial, por
            internet, teléfono o por cualquier otro canal habilitado.
            <br />
            <br />
            1.3. La Tarjeta Prepaga no es una tarjeta de crédito, débito o de
            compra y no admite compras en cuotas. Los pagos o extracciones de
            fondos realizados con la Tarjeta Prepaga se debitarán del saldo de
            fondos disponibles en la Cuenta del Titular.
          </TermsText>

          <TermsSubText>2. Tarifa</TermsSubText>
          <TermsText>
            2.1. Las tarifas aplicables a la obtención y uso de la Tarjeta
            Prepaga se encuentran disponibles para consultar en todo momento las
            Tarifas del Servicio. Las extracciones de fondos por cajeros
            automáticos podrán estar sujetas a tarifas adicionales aplicadas
            directamente por las compañías responsables de los cajeros
            automáticos, sin la intervención de n1u.
          </TermsText>

          <TermsSubText>3. Solicitud de la Tarjeta Prepaga</TermsSubText>

          <TermsText>
            3.1. Al solicitar la Tarjeta Prepaga, el Usuario deberá proveer en
            forma exacta-verdadera-completa los datos y documentos que le fueran
            requeridos (nombre completo/domicilio real/número de teléfono,
            etc.). Asimismo, el Usuario debe mantener actualizados dichos datos
            o documentación registrándolos en su Cuenta o suministrándolos a n1u
            por medio de los canales habilitados.
            <br />
            <br />
            3.2. Toda la información-documentación provista a n1u por el Usuario
            o Titular tienen carácter de declaración jurada. No obstante, n1u
            podrá solicitar comprobante o información adicional para completar o
            corroborar la información entregada por un Usuario o Titular,
            incluso a partir de la superación de ciertos montos de transacciones
            mensuales realizadas por el Titular.
            <br />
            <br />
            3.3. La solicitud de la Tarjeta Prepaga quedará perfeccionada con la
            recepción de la Tarjeta Prepaga por parte del Titular. Sin embargo,
            n1u podrá rechazar una solicitud de Tarjeta Prepaga, suspenderla o
            cancelarla en caso de detectar inconsistencias en los datos o
            documentación provista por un Usuario o Titular, o en caso de que el
            Usuario o Titular no proporcione los datos o documentación adicional
            solicitada. Asimismo, n1u podrá en cualquier caso rechazar una
            solicitud de Tarjeta Prepaga a su criterio exclusivo, sin necesidad
            de proveer una justificación al Usuario.
          </TermsText>
          <TermsSubText>4. Uso de la Tarjeta Prepaga</TermsSubText>

          <TermsText>
            4.1. Una vez recibida, la Tarjeta Prepaga si la correspondencia que
            contiene la Tarjeta Prepaga está dañada o violada, el Usuario o
            Titular deberá rechazar su recepción y comunicar el hecho de
            inmediato a n1u a través de los canales de atención disponibles.
            Solamente deberán activarse la tarjetas físicas.
            <br />
            <br />
            4.2. La Tarjeta Prepaga se podrá utilizar dentro del plazo de
            validez impreso en su frente, sujeto al saldo de los Fondos
            disponibles en la Cuenta y dentro de los límites de uso diarios para
            operaciones determinados por n1u o por las redes de servicios
            asociados.
            <br />
            <br />
            4.3 La Tarjeta Prepaga es personal e intransferible. Únicamente el
            usuario mayor de edad mayor podrá otorgar adicionales a ella y
            solamente respecto de tarjetas físicas contra el débito de su
            cuenta. El Usuario será el único responsable de la custodia y
            confidencialidad de la Tarjeta Prepaga y el PIN autogenerado para su
            utilización. Por lo tanto, el Titular es responsable por todas las
            operaciones realizadas con la Tarjeta Prepaga.
            <br />
            <br />
            4.4. Por razones de seguridad, el ingreso incorrecto del PIN de la
            Tarjeta Prepaga en un cajero automático podrá derivar en la
            retención de la Tarjeta Prepaga por parte de la red del servicio del
            cajero automático. En ese caso, el Titular deberá solicitar una
            nueva Tarjeta Prepaga por los canales habilitados. En caso de haber
            olvidado el PIN de la Tarjeta Prepaga, el Titular podrá realizar la
            autogestión de blanqueo de PIN a través de la aplicación.
            <br />
            <br />
            4.5. El uso de la Tarjeta Prepaga estará sujeto a la disponibilidad
            de la red Visa, circunstancia ajena a n1u. La aprobación de la
            transacción se realizará de acuerdo con los procedimientos
            específicos según el tipo de transacción o canal de pago utilizado.
            Por ejemplo, respecto de algunos canales de pago ofrecidos por
            ciertos establecimientos, el uso de la contraseña o de la firma se
            podrán reemplazar mediante la indicación del número de la Tarjeta
            Prepaga, su fecha de vencimiento y su código de seguridad. En
            cualquier caso, el cumplimiento de los requisitos necesarios para
            realizar la transacción implica la aceptación de la transacción y su
            consiguiente responsabilidad por parte del Titular.
            <br />
            <br />
            4.6. Los montos de las transacciones realizadas con la Tarjeta
            Prepaga más los impuestos de corresponder y las Tarifas se debitarán
            del saldo de los Fondos disponibles en la Cuenta del Titular en el
            momento, en forma total y en un solo débito. Si los Fondos
            disponibles en la Cuenta no fueran suficientes, la transacción no
            será autorizada. Las operaciones realizadas con la Tarjeta Prepaga
            se podrán consultar en los movimientos de la Cuenta del Titular.
            <br />
            <br />
            4.7. Cualquier cancelación de una transacción de compra de
            corresponder deberá ser solicitada por el Titular al
            establecimiento. En el momento de la solicitud de cancelación, el
            Titular deberá obtener el comprobante de cancelación correspondiente
            directamente del establecimiento. Los saldos de la Tarjeta Prepaga
            serán reintegrados al Usuario a partir de la aprobación de la
            cancelación por parte de Visa.
            <br />
            <br />
            4.8 Tarjeta de regalo. Procede arrepentimiento del usuario que la
            emite dentro del término de 10 días. Si la tarjeta de regalo no se
            utilizó, se puede reintegrar el monto de la misma dentro de los 10
            días. Si ella fue utilizada no se puede revocar. Validez de la
            tarjeta de regalo 12 meses. Quien recibe la tarjeta de regalo debe
            ser usuario de la billetera.
          </TermsText>

          <TermsSubText>
            5. Declaración Jurada PEP y de Origen de Fondos
          </TermsSubText>

          <TermsText>
            5.1. El Usuario o Titular declara bajo juramento que no se encuentra
            incluido o alcanzado dentro de la “Nómina de Personas Expuestas
            Políticamente” (PEP) aprobada por la Unidad de Información
            Financiera, que ha leído. En caso afirmativo, el Usuario o Titular
            se compromete a indicar detalladamente el motivo a n1u a través de
            los canales de atención habilitados.
            <br />
            <br />
            Además, el Titular asume el compromiso de informar cualquier
            modificación que se produzca a este respecto dentro de los 30 días
            de ocurrida, mediante la presentación de una nueva declaración
            jurada a través de los canales de atención habilitados.
            <br />
            <br />
            5.2. El Usuario o Titular manifiesta con carácter de declaración
            jurada que los Fondos de las operaciones realizadas con la Tarjeta
            Prepaga fueron obtenidos mediante actividades lícitas y declaradas y
            tendrán un destino igualmente legítimo.
          </TermsText>

          <TermsSubText>
            6. Plazo de Vigencia de la Tarjeta Prepaga
          </TermsSubText>

          <TermsText>
            6.1. La Tarjeta Prepaga será válida desde la fecha de su activación
            hasta la fecha de vencimiento informado en su dorso. Vencido el
            plazo de vigencia, la Tarjeta Prepaga se cancelará automáticamente.
            Sin embargo, n1u podrá cancelar la Tarjeta Prepaga en caso de que no
            fuera utilizada por su Titular durante un plazo de 6 meses o mayor
            sin notificación previa a su Titular.
            <br />
            <br />
            6.2. En caso de vencimiento y/o cancelación de la Tarjeta Prepaga,
            el Usuario podrá solicitar una nueva Tarjeta Prepaga por los canales
            habilitados.
          </TermsText>

          <TermsSubText>
            7. Denuncia de extravío, robo, hurto, cancelación y nueva emisión de
            Tarjeta Prepaga
          </TermsSubText>

          <TermsText>
            7.1. El Usuario deberá notificar inmediatamente a n1u mediante por
            el medio habilitado, el extravío, hurto, robo o uso indebido de la
            Tarjeta Prepaga. La Tarjeta Prepaga informada como extraviada,
            hurtada, robada o usada indebidamente será cancelada y no podrá ser
            reactivada.
            <br />
            <br />
            7.2. El Usuario será el único responsable por el uso de su Tarjeta
            Prepaga hasta que comunique el evento por medio de un medio
            habilitado y obtenga la confirmación de la cancelación de la Tarjeta
            Prepaga por parte de n1u. n1u y Visa no serán responsables en ningún
            caso por el uso de la Tarjeta Prepaga antes de su cancelación
            efectiva.
            <br />
            <br />
            7.3. Una vez cancelada, la emisión de una nueva Tarjeta Prepaga
            estará sujeta a las Tarifas aplicables y se emitirá una nueva solo
            por decisión del usuario que emitió la orignal.
          </TermsText>

          <TermsSubText>
            8. Cancelación o suspensión de la Tarjeta Prepaga
          </TermsSubText>

          <TermsText>
            8.1. El Usuario podrá solicitar la cancelación voluntaria de su
            Tarjeta Prepaga en cualquier momento a través de los canales
            habilitados por n1u.
            <br />
            <br />
            8.2. Además, la Tarjeta Prepaga será cancelada o suspendida, sin
            limitación, en los siguientes casos: i) denuncia de daño, extravío,
            robo o hurto de la Tarjeta Prepaga por parte del Titular, ii)
            requerimiento judicial o de autoridad competente, iii) sospecha de
            fraude, uso ilegal o prohibido a criterio exclusivo de n1u o Visa,
            iv) suspensión o cancelación de la Cuenta del Titular, v) falta de
            provisión a n1u de información o documentación solicitada, y vi)
            incumplimiento de los presentes términos y condiciones, incluyendo
            los Términos y Condiciones de Uso de n1u . En caso de
            desconocimiento de una transacción por parte del Titular, y sin que
            ello implique reconocimiento alguno de dicho desconocimiento, se
            emitirá al momento de la denuncia un bloqueo de la Tarjeta Prepaga y
            la emisión de un nuevo plástico en su reemplazo.
          </TermsText>

          <TermsSubText>9. Canal de Atención al Titular</TermsSubText>

          <TermsText>
            9.1. n1u brinda al Titular distintos canales de atención para que
            éste pueda despejar sus dudas o realizar consultas relacionadas con
            la Tarjeta Prepaga. El Titular podrá obtener ayuda en el sitio web
            ayuda@n1u.app .
          </TermsText>

          <TermsSubText>10. Operaciones en el exterior.</TermsSubText>

          <TermsText>
            El tipo de cambio que se tomará en consideración para la conversión
            de moneda extranjera a pesos para las operaciones realizadas con la
            Tarjeta Prepaga en el exterior, será el tipo de cambio vendedor
            minorista del Banco de la Nación Argentina publicado al cierre del
            día anterior a fecha de la operación (“Tipo de Cambio BNA”), más el
            3% sobre el Tipo de Cambio BNA más todos los impuestos y
            percepciones que correspondan conforme normativa.
          </TermsText>

          <TermsSubText>11. Limitación de responsabilidad de n1u.</TermsSubText>

          <TermsText>
            11.1. n1u no garantiza el acceso ininterrumpido de los canales de
            uso de la Tarjeta Prepaga, los cuales pueden no estar disponibles
            debido a inconvenientes técnicos, fallas de internet,
            indisponibilidad de la red Visa, indisponibilidad de extracción en
            cajeros automáticos adheridos a la red, o cualquier otro motivo,
            incluso ajeno a n1u.
            <br />
            <br />
            11.2. Los Usuarios o Titulares exoneran de todo tipo de
            responsabilidad a n1u por daño directo, lucro cesante o cualquier
            otro daño o perjuicio que haya podido sufrir el Titular con relación
            a las transacciones con la Tarjeta Prepaga realizadas, rechazados o
            restringidas.
          </TermsText>

          <TermsSubText>
            12. n1u como proveedor de servicios de pagos
          </TermsSubText>
          <TermsText>
            n1u ofrece servicios de pago y no se encuentra autorizado a operar
            como entidad financiera por el Banco Central de la República
            Argentina. n1u no presta al usuario ningún servicio bancario o
            cambiario. Los fondos depositados en la cuenta de pago no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos de entidades financieras.
          </TermsText>

          <br />
          <br />
          <TermsTitle>
            Términos y condiciones pago con activos digitales
          </TermsTitle>

          <TermsQuestions>
            Uso de Activos Digitales para el pago con la Tarjeta Prepaga
          </TermsQuestions>

          <TermsText>
            1. El Titular instruirá a n1u para que al momento de realizar una
            Transacción con su Tarjeta Prepaga, n1u realice una venta de la
            cantidad de activos digitales, disponibles en la cuenta del Titular
            en la Plataforma, necesaria para cubrir el monto en moneda fiat de
            la Transacción a realizar (cada una de ellas, una “Instrucción”). La
            venta de activos digitales se realizará conforme a lo elegido en
            “Configuración de Pagos” en la app n1u y a la cotización del momento
            de la Instrucción. Cada operación de pago que se realice implicará
            la conversión del saldo de activos digitales y el débito del saldo
            resultante. En ningún caso se podrán realizar operaciones por un
            importe superior al saldo disponible en la cuenta del Titular en la
            Plataforma al momento de la Transacción.
          </TermsText>

          <TermsQuestions>
            Elección del activo digital a debitar con el uso de la tarjeta
            prepaga.
          </TermsQuestions>

          <TermsText>
            1.Para hacer uso de esta funcionalidad, el usuario deberá pre
            seleccionar una moneda diferente a ARS, desde la sección
            Configuración de Pagos, en su perfil o en la sección “Más” de la
            aplicación. Dentro de la sección, podrá elegir entre las monedas
            disponibles al momento. Teniendo en cuenta que la disponibilidad de
            monedas puede variar. La funcionalidad quedará activada una vez que
            el usuario confirme la moneda a debitar de su saldo, y visualice la
            confirmación en la app de n1u. Se debitará el valor total de la
            compra, con los impuestos correspondientes incluídos. Si no posee el
            saldo en la moneda elegida necesaria para cubrir el monto restante,
            se rechazará la compra.
          </TermsText>

          <TermsQuestions>
            Desconocimientos y/o cancelaciones de transacciones
          </TermsQuestions>

          <TermsText>
            2.1. Una vez solicitado el desconocimiento o cancelación mediante el
            proceso detallado en el punto 4.7 de los Términos y Condiciones
            generales del servicio el saldo de la transacción será reintegrado
            al Usuario en la cuenta del Titular en la app de n1u, por la
            cantidad de pesos argentinos, equivalente al monto de la
            Transacción.
            <br />
            <br />
            2.2. El Titular podrá desconocer una Transacción y/o movimiento de
            su cuenta, dentro de los 30 (treinta) días de visualizado el mismo
            en la Plataforma. Para ello, deberá informar a n1u a través de los
            canales habilitados, la cual deberá dar trámite de dicho
            desconocimiento dentro de los 7 (siete) días de recibido el reclamo.
            En caso de corresponder, n1u podrá requerir al Titular la
            documentación respaldatoria que acredite dicho desconocimiento en
            forma previa a la devolución del monto en un plazo no mayor a los 15
            (quince) días de recepcionado el reclamo.
            <br />
            <br />
            2.3 El uso de la Tarjeta Prepaga estará sujeto a la disponibilidad
            de la red Visa, lo cual constituye una circunstancia ajena y fuera
            del control de n1u. La aprobación de cada Transacción se realizará
            de acuerdo con los procedimientos específicos establecidos o
            acordados, según el tipo de Transacción o canal de pago utilizado.
            Por ejemplo, respecto de algunos canales de pago ofrecidos por
            ciertos establecimientos, el uso de la contraseña o la firma se
            podrán reemplazar mediante la indicación del número de la Tarjeta
            Prepaga, su fecha de vencimiento y su código de seguridad. En
            cualquier caso, el cumplimiento de los requisitos necesarios para
            realizar la Transacción implica la aceptación de la Transacción y su
            consiguiente responsabilidad por parte del Titular.
            <br />
            <br />
            3. Los montos de las Transacciones realizadas con la Tarjeta
            Prepaga, incluyendo cualquier Tarifa, se debitarán del saldo de la
            Tarjeta Prepaga en el momento, en forma total y en un solo débito.
            Si el saldo de la Tarjeta Prepaga no fuera suficiente, la
            Transacción no será autorizada. Las operaciones realizadas con la
            Tarjeta Prepaga se podrán consultar en la Plataforma de n1u pero
            podrán estar sujetas a limitaciones temporales, de forma tal que
            operaciones de cierta antigüedad pre-establecida, no estarán
            disponibles para consulta en la Plataforma a partir de cierta fecha,
            pero podrán igualmente ser solicitadas a n1u para consulta a través
            de los canales habilitados a los Usuarios.
            <br />
            <br />
            4. Los fondos depositados por los Titulares en su cuenta no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos de entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default PrepaidCard;
