import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubTitle,
  TermsSubText,
} from "../styles/texts";

function PrivacyPoliciesDigital() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Politicas de Privacidad activos digitales</TermsTitle>
          <TermsSubTitle>
            POLÍTICAS DE PRIVACIDAD DE KOIBANX
            <br /> <br /> <br /> PLATAFORMA N1U LEVEL
            <br /> <br /> <br /> 29 de marzo de 2023
          </TermsSubTitle>
          <TermsText>Definiciones:</TermsText>

          <TermsSubText>
            1. <b>Usuario:</b> aquella persona que haga uso del Servicio, titular de
            los Datos Personales de acuerdo con lo establecido en la presente
            Política.
            <br />
            2. <b>Servicio:</b> compra, venta y/o Swap de activos de moneda digital
            ofrecidos por Koibanx en la Plataforma de n1u.
            <br />
            3. <b>Plataforma:</b> ámbito tecnológico denominado “n1u level”,
            perteneciente a Blockchain Payments Rails S.A., en el que Koibanx
            presta el servicio correspondiente.
            <br />
            4. <b>Activos de moneda digital:</b> activos escritos en clave susceptibles
            de apreciación pecuniaria, objeto de las operaciones a ser
            realizadas por los Usuarios (ej: Bitcoin, Ethereum, USDC).
            <br />
            5. <b>Forks:</b> bifurcación referida a un ajuste de las reglas de consenso
            de una red blockchain que puede o no producir una división de la
            misma.
            <br />
            6. <b>Blockchain:</b> es una tecnología que consiste en una cadena de
            bloques con una base de datos pública donde se registran de forma
            segura las transacciones que se realizan <a href="https://www.arimetrics.com/glosario-digital/internet"><span>en la red</span></a>. Los datos están
            validados por una comunidad más amplia, en lugar de por una
            autoridad central. Es decir, es una colección de registros que una
            multitud supervisa y mantiene, sin depender así de ninguna entidad,
            ya sea un banco o un gobierno.
            <br />
            7. <b>Protocolos Blockchain:</b> conjunto de reglas, pautas y operaciones
            programadas que hacen de base para las redes blockchain y los
            desarrollos web3.
            <br />
            8. <b>Swap:</b> servicio provisto por Koibanx que consiste en la
            posibilidad de intercambiar un activo de moneda digital disponible
            en el Servicio por otro.
            <br />
            9. <b>Chains o redes Blockchain:</b> distintas redes que funcionan bajo
            tecnología blockchain y a través de las cuales se realizan las
            operaciones ofrecidas por el Servicio.
            <br />
            10. <b>Wallet:</b> billetera digital destinada a la recepción,
            almacenamiento o envío de activos de moneda digital.
            <br />
            11. <b>Airdrop:</b> refiere a un procedimiento de distribución de nuevos
            tokens otorgados a poseedores de activos de moneda digital.
            <br />
            12. <b>Dinero fiduciario:</b> moneda de curso legal de un país determinado
            (ej: pesos argentinos, dólares, etc).
            <br />
            13. <b>On-Ramp:</b> operatoria referida al ingreso de dinero fiduciario a
            los fines de hacer uso del Servicio.
            <br />
            14. <b>Off-Ramp:</b> operatoria referida al retiro de dinero fiduciario por
            el uso del Servicio.
            <br />
            15. <b>Datos Personales:</b> refiere a cualquier información relativa a una
            persona humana identificada o identificable.
            <br />
            16. <b>Tratamiento de datos personales:</b> implica la obtención, uso,
            divulgación o almacenamiento de datos personales en virtud de las
            finalidades descritas en la presente Política.
          </TermsSubText>

          <TermsText>
            Las presentes Políticas de Privacidad (en adelante, la “Política”)
            se encarga de detallar la recolección de datos personales, sus
            clases y finalidad que adoptará Koibanx El Salvador S.A. de C.V. (en
            adelante, “Koibanx”) con el usuario de la plataforma “n1u level”,
            propiedad de Blockchain Payments Rails S.A. (en adelante, “n1u” o
            “la Plataforma”), en razón de la prestación del servicio de compra y
            venta de activos de moneda digital a través de la Plataforma.
            <br /> <br />
            De este modo, se describirán los tipos de datos personales (los
            “Datos Personales”) que Koibanx recolecta y trata a los efectos de
            brindar a los usuarios de n1u el Servicio. La Política de Privacidad
            también detalla cómo tratará Koibanx los Datos Personales de los
            Usuarios, las finalidades de su uso, el período de tiempo que los
            conserva, cómo se comparten y los derechos que asisten al titular de
            los Datos Personales con respecto a los mismos. Esta Política aplica
            a toda la información que usted nos proporcione, y a la que
            obtengamos de usted de conformidad con esta Política, a través de la
            Plataforma, las páginas de redes sociales y cualesquiera otras
            páginas o Plataformas web asociados al uso y a la promoción de
            nuestros productos y servicios y cualesquiera otras fuentes
            mencionadas en la presente.
            <br /> <br />
            SE ACLARA QUE LOS USUARIOS NO TIENEN LA OBLIGACIÓN DE PROPORCIONAR
            INFORMACIÓN. LA MISMA DEBE PROPORCIONARSE POR EL USUARIO DE MANERA
            LIBRE, EXPRESA E INFORMADA. POR ESO ES IMPORTANTE QUE SE INFORMEN DE
            SUS DERECHOS LEYENDO LA PRESENTE POLÍTICA.Koibanx no revelará la
            información provista a ninguna organización externa, a menos que
            previamente posea la autorización del Usuario y/o le sea requerida
            por ley o por resolución de autoridad competente.
            <br /> <br />
            AL ACEPTAR COMPARTIR SUS DATOS, EL USUARIO DA FE DE QUE HA LEÍDO,
            ENTENDIDO, Y ACEPTA LA PRESENTE POLÍTICA. SI EL USUARIO NO ESTÁ DE
            ACUERDO CON LOS TÉRMINOS DE ESTA POLÍTICA, NO DEBE UTILIZAR LOS
            SERVICIOS.
          </TermsText>

          <TermsText>
            <b>1. Marco legal</b>
            <br /> <br />
            Koibanx El Salvador S.A. de C.V. es una sociedad de la República de
            El Salvador inscrita en el Registro de Comercio al número 85 del
            libro 4440, con Número de Identificación Tributaria
            0614-300821-102-4.; y autorizada para operar como Proveedor de
            Servicios Bitcoin como: Custodio de Bitcoins, Casas de Intercambio
            Digital o Exchange, Billetera Digital para Bitcoin y Procesador de
            Pago por el Gobierno de El Salvador bajo el Código de Registro
            6148e2e2fc9c46746cdf0b71.
            <br /> <br />
            Al aceptar esta Política, el Usuario manifiesta haberla leído,
            entendido y brinda su consentimiento expreso, libre e informado al
            respecto. En dicho sentido, se aclara que toda la operatoria
            relacionada al Servicio es perfeccionada entre el Usuario y Koibanx.
            <br /> <br />
            La Política debe entenderse complementaria a los Términos y
            Condiciones de Koibanx en la Plataforma y a las Políticas de
            Privacidad de n1u. En caso de duda por parte del Usuario respecto a
            la presente Política, podrá contactarse a <a href="mailto:dataprivacy@koibanx.com"><span>dataprivacy@koibanx.com.</span></a>
            <br /> <br />
            Se hace saber que la presente Política es únicamente aplicable al
            servicio prestado por Koibanx dentro de la Plataforma denominada
            “n1u level”.
            <br /> <br />
            <b>2. Necesidad de haberse registrado como usuario de la Plataforma</b>
            <br /> <br />
            A los fines de hacer uso del Servicio, el Usuario deberá haberse
            registrado como tal en la Plataforma, y contar con una cuenta a su
            nombre y bajo su titularidad. Esta obligación se considera requisito
            excluyente para poder hacer uso del Servicio y aceptar la presente
            Política. La cuenta del Usuario deberá encontrarse activa al momento
            de hacer uso del Servicio. La Plataforma, que cumple con las medidas
            de seguridad requeridas para el tratamiento de Datos Personales,
            será el canal de comunicación entre los Usuarios y Koibanx para
            gestionar cualquier consulta o requerimiento. Sin perjuicio de lo
            expuesto, Koibnx podrá habilitar otras modalidades diferentes a la
            mencionada precedentemente para la comunicación y gestión del
            Servicio.
            <br /> <br />
           <b> 3. Consentimiento del Usuario</b>
            <br /> <br />
            EL USUARIO, EN SU CARÁCTER DE TITULAR DE LA INFORMACIÓN PERSONAL,
            PRESTA SU CONSENTIMIENTO LIBRE, EXPRESO, ESPECÍFICO, INDIVIDUALIZADO
            E INFORMADO PARA LA RECOLECCIÓN Y TRATAMIENTO DE SU INFORMACIÓN
            PERSONAL CON EL ALCANCE Y EN LOS TÉRMINOS ESTABLECIDOS EN LA
            PRESENTE POLÍTICA, CONSTITUYENDO CON SU CONSENTIMIENTO UN ACUERDO
            LEGAL, VÁLIDO Y VINCULANTE, ENTRE EL USUARIO Y KOIBANX.
            <br /> <br />
            <b>Su acceso al Servicio, su registración en la Plataforma y la
            contratación y uso de los Servicios implica que Ud. presta su
            consentimiento expreso, libre e informado para la inclusión, uso y
            tratamiento de sus datos personales por parte de Koibanx. Asimismo,
            tal consentimiento podrá ser requerido de forma expresa realizando
            “click” en el checkbox que indica “ACEPTO COMPARTIR MIS DATOS”. La
            falta de comunicación formal del desacuerdo con los términos
            establecidos en la presente Política, implicarán el expreso
            consentimiento del Usuario a la extensión, modalidad y pautas
            fijadas en la presente Política.</b>
            <br /> <br />
           <b> 4. Recopilación y Uso</b>
            <br /> <br />
            Al hacer uso del Servicio, Koibanx recopilará diferentes tipos de
            datos, incluyendo los de carácter personal, con diversos fines para
            prestar, mejorar, proteger y mejorar nuestros Servicios. Al aceptar
            la presente Política de Privacidad, el Usuario acepta la
            recopilación y el uso de la información proporcionada en acuerdo con
            esta Política.
            <br /> <br />
            A menos que esta sección indique lo contrario, el significado de los
            términos utilizados resulta equivalente al expuesto en nuestros
            Términos y Condiciones, y los definidos expresamente en la presente
            Política.
            <br /> <br />
           <b> Datos Personales</b>
            <br /> <br />
            Al hacer click en “ACEPTO COMPARTIR MIS DATOS”, el Usuario da su
            consentimiento expreso, libre e informado para que n1u comparta a
            Koibanx los siguientes datos personales, a los fines de darle
            tratamiento para la provisión del Servicio:
          </TermsText>
          <TermsSubText>
            1. Nombre y apellido;
            <br /> 2. Nombre de usuario en la Plataforma;
            <br /> 3. CUIT;
            <br /> 4. DNI;
            <br /> 5. E-mail;
            <br /> 6. Número de celular;
            <br /> 7. Estado Civil;
            <br /> 8. Profesión;
            <br /> 9. Determinación de sujeto PEP;
            <br /> 10. Domicilio;
          </TermsSubText>

          <TermsText>
           <b> 5. Tratamiento de los datos personales y finalidades</b>
            <br /> <br />
            La información detallada en el acápite precedente será recopilada en
            forma directa por Koibanx al momento en que acepte compartir sus
            datos a Koibanx y acepte las presentes Políticas de Privacidad. Esta
            información es necesaria a fin de poder identificarlo como Usuario y
            a fin de utilizar los Servicios para el fin que fueron diseñados.
            <br /> <br />
            Koibanx no asume ninguna responsabilidad derivada del uso incorrecto
            de la información facilitada a través de la Plataforma. Asimismo, y
            con los límites legales, no asume ninguna responsabilidad por la
            falta de exactitud, integridad o actualización de los datos o
            informaciones que sean introducidos en la Plataforma.
            <br /> <br />
            La información brindada por el Usuario será utilizada para su
            identificación, autenticación, administración y gestión comercial.
            Con estos datos, se validará que cumple con los requisitos
            correspondientes para poder hacer uso del Servicio.
            <br /> <br />
            Los Datos Personales recolectados serán recolectados para las
            siguientes finalidades:
          </TermsText>

          <TermsSubText>
            1. uso del Servicio por parte del Usuario;
            <br />
            2. verificar la veracidad de la identidad del Usuario;
            <br />
            3. comunicaciones con el Usuario;
            <br />
            4. para mejorar la provisión del Servicio;
            <br />
            5. para cumplir con requerimientos legales;
            <br />
            6. para confirmar la seguridad en la identificación del Usuario;
            <br />
            7. a los fines de cumplir con la normativa aplicable en materia de
            protección de datos personales;
            <br />
            8. a los fines de cumplir con la normativa aplicable en materia de
            Lavado de Activos y Financiamiento del Terrorismo;
            <br />
            9. para detectar y prevenir fraudes, delitos o abusos a los fines de
            proteger a los Usuarios.
            <br />
            10. a los fines de notificar al Usuario con respecto a cambios en el
            Servicio;
            <br />
            11. para contactarlo con newsletters, materiales de marketing o
            promocionales, y o cualquier otro tipo de prestación relevante para
            su operación. Puede optar por no recibir todas estas comunicaciones
            tanto al momento de haber creado su cuenta como una vez creada,
            haciendo click en el enlace que permite la cancelación de la
            suscripción;
            <br />
            12. para una satisfactoria provisión de la asistencia que pudieran
            requerir, así como con el objeto de contactarlos y/o identificarlos;
            <br />
            13. Para resolver disputas entre Usuarios o resolver problemas
            técnicos;
            <br />
            14. para ofrecer determinados servicios que requieran perfilamiento
            de usuarios.
          </TermsSubText>

          <TermsText>
            En caso de utilizar la información personal del Usuario para un
            propósito distinto a los mencionados, se procederá a actualizar esta
            Política y se explicarán los motivos del requerimiento, para lo cual
            será necesario contar con el consentimiento del Usuario.
            <br /> <br />
           <b> 6. Integraciones con n1u</b>
            <br /> <br />
            Koibanx ofrecerá acceso a los usuarios de la Plataforma al servicio
            de compra y venta de activos de moneda digital. El Servicio es
            administrado por Koibanx. A través del mismo, los Usuarios pueden
            realizar compra y/o ventas de activos de moneda digital, de acuerdo
            a lo indicado en los Términos y Condiciones que el Usuario debe
            aceptar.
            <br /> <br />
            A los fines de poder ofrecer dicho servicio, n1u ha celebrado con
            Koibanx un acuerdo de transferencia internacional de datos
            personales en el que se contempla y regula esta relación. Asimismo,
            a los fines de hacer uso del Servicio y al aceptar esta Política de
            Privacidad, el Usuario brinda su consentimiento para compartir sus
            datos personales brindado a n1u en razón de su registro en la
            Plataforma.
            <br /> <br />
            Asimismo, Koibanx no asume responsabilidad alguna por el
            cumplimiento de n1u en cuanto a la protección de Datos Personales.
            <br /> <br />
           <b> 7. Información compartida con terceros</b>
            <br /> <br />
            Los Datos Personales recabados por Koibanx podrán revelarse a
            terceros únicamente de acuerdo con lo descrito en la presente
            Política. Los datos personales recolectados por Koibanx podrán ser
            comunicados a filiales y entidades asociadas para los fines
            anteriormente descritos, así como las sociedades recaudadoras de
            cada país, por cuenta y orden de Koibanx.
            <br /> <br />
            Asimismo, Koibanx podrá compartir Datos Personales de Usuarios con
            proveedores externos vinculados con Koibanx, que contribuyan al
            cumplimiento de la actividad de Koibanx y la prestación del Servicio
            y/o servicios de marketing. Para tal fin, Koibanx implementar
            cláusulas contractuales que prevean la confidencialidad y debido
            resguardo de los datos personales.
            <br /> <br />
            Los datos personales también pueden ser compartidos con organismos
            públicos, tribunales, reguladores y otras autoridades
            administrativas, cuando Koibanx lo considere necesario para cumplir
            con una obligación legal o regulatoria.
            <br /> <br />
            Por último, se podrá compartir con cualquier tercero que compre, o
            al cual se transfieran, todos o una parte sustancial de los activos
            y negocios de Koibanx, incluyendo la etapa para facilitar tal compra
            o venta total o parcial del negocio de Koibanx, por ejemplo,
            compartiendo datos con una empresa con la que pueda fusionarse o
            adquirir Koibanx; y también con motivo de nuestras funciones de
            auditoría, cumplimiento y gobernanza empresarial.
            <br /> <br />
            En caso de que se produzca dicha venta o transferencia, se
            realizarán todos los esfuerzos razonables para tratar de garantizar
            que la entidad a la que se transfieran sus datos personales los
            utilice de acuerdo con esta Política. En todos los casos Koibanx
            solicitará de terceros el cumplimiento de una obligación de
            confidencialidad respecto de la información personal a la que
            accedan por los motivos arriba mencionados, suscribiéndose los
            acuerdos correspondientes conforme la legislación aplicable.
            <br /> <br />
            En ningún caso, Koibanx compartirá datos con terceros con motivos o
            finalidades diferentes a las planteadas en la presente Política.
            Koibanx no comercializará los Datos Personales de los Usuarios.
            <br /> <br />
           <b> 8. Transferencia internacional de datos personales</b>
            <br /> <br />
            <b>Koibanx hace saber al Usuario que el Servicio que brinda requiere el
            soporte tecnológico de ciertos proveedores, como pueden ser
            servidores y/o servicios en la nube, que serán provistos por
            terceros. Para ello, Koibanx adoptará las mejores prácticas
            tendientes al resguardo y protección de los Datos Personales del
            Usuario, mediante cláusulas contractuales que así lo indiquen.</b>
            <br /> <br />
            Koibanx podrá transferir los datos personales de los Usuarios que se
            recopilen a países distintos a aquellos en los que la información se
            hubiera recopilado originalmente. Estos países podrían no tener las
            mismas leyes de protección de datos que el país en el que el Usuario
            inicialmente brindó la información. Cuando se transfieren tales
            datos personales a otros países, Koibanx toma las medidas diseñadas
            para garantizar que la transferencia se realice de acuerdo con la
            ley aplicable.
            <br /> <br />
            Atento a lo mencionado, el Usuario expresamente entiende y acepta
            que sus Datos Personales pueden ser compartidos o transferidos fuera
            de su país de residencia para su procesamiento y en virtud de las
            finalidades descriptas en la presente Política.
            <br /> <br />
           <b> 9. Seguridad de los datos personales</b>
            <br /> <br />
            La información personal del Usuario se almacenará en las bases de
            datos de Koibanx o en bases de datos que mantengan nuestros
            proveedores de servicios. Koibanx implementa y mantiene todas las
            medidas de control y prevención necesarias para mantener la
            seguridad de la información personal suministrada, implementando las
            medidas técnicas y tecnológicas internas necesarias para garantizar
            la debida seguridad y confidencialidad de los datos suministrados
            por los usuarios y tratando por todos los medios de evitar el acceso
            no autorizado a los mismos. A tal fin, Koibanx podrá tomar la medida
            de cifrar datos personales y demás medidas capaces de garantizar la
            confidencialidad, integridad, disponibilidad y servicios de
            tratamiento.
            <br /> <br />
            Sin embargo, Koibanx no garantiza ni será responsable por
            violaciones o vulneraciones sufridas, que excedan las medidas de
            seguridad y controles implementados, cuando estos sean razonables,
            idóneos y adecuados.
            <br /> <br />
            <b>10. Plazo de conservación</b>
            <br /> <br />
            Koibanx únicamente conservará los Datos Personales durante el plazo
            necesario para cumplir con el propósito para el que se ha
            recopilado, para cumplir con requisitos reglamentarios o legales, o
            durante el periodo de prescripción legal de posibles
            responsabilidades legales o contractuales.
            <br /> <br />
            Una vez concluido el lapso, los datos serán eliminados o
            anonimizados de manera tal que no pueda ser individualizada ninguna
            persona, según lo permita la normativa de cada país.
            <br /> <br />
            No obstante ello, debe tener en cuenta que: 1) puede haber
            inconvenientes de latencia en la eliminación de esta información de
            nuestros servidores; y 2) podremos retener esta información a los
            fines de cumplir con nuestras obligaciones legales, resolver
            controversias o exigir el cumplimiento de nuestros acuerdos.
            <br /> <br />
           <b> 11. Requerimiento por parte de autoridad competente</b>
            <br /> <br />
            En caso de ser solicitado por autoridad judicial u órgano
            competente, nos veremos obligados a proporcionar los datos que
            procesamos a los fines de que se puedan llevar a cabo las
            investigaciones judiciales o criminales para las que se lo solicite.
            <br /> <br />
           <b> 12. Derechos de los Usuarios</b>
            <br /> <br />
            El Usuario tendrá la facultad deejercer sus derechos contactando a
            Koibanx de forma rápida y sencilla enviando un correo electrónico a
            <a href="mailto:dataprivacy@koibanx.com"><span>dataprivacy@koibanx.com.</span></a> En ciertas ocasiones, el Usuario deberá
            dirigirse a lo establecido en las Políticas de Privacidad de n1u
            para ejercer alguno de los derechos que se enuncian seguidamente.
            <br /> <br />
            Los Usuarios tienen los siguientes derechos:
            <br /> <br />
            a) Derecho de acceso: derecho a solicitar y obtener información
            sobre qué datos personales son procesados y almacenados por Koibanx.
            Asimismo, el Usuario podrá, a través de la Plataforma, acceder a los
            datos proporcionados para el registro de su cuenta de n1u.
            <br /> <br />
            b) Derecho de rectificación: derecho a solicitar a Koibanx que
            actualice o rectifique los datos personales cuando sean inexactos o
            estén desactualizados. Para dichos fines, el Usuario deberá llevar a
            cabo los pasos estipulados en las Políticas de Privacidad de n1u.
            <br /> <br />
            c) Derecho de supresión: el Usuario que desee cerrar su cuenta
            también tendrá derecho a que los datos proporcionados sean
            eliminados y hacer ejercicio del “derecho a ser olvidado”. Se hace
            saber que, en ciertos casos, y sin perjuicio de este requerimiento
            del Usuario Koibanx deberá mantener la información en su base de
            datos por exigencias legales.
            <br /> <br />
            d) Derecho de oposición: el Usuario tendrá derecho a oponerse al
            tratamiento de sus datos, o frente a una finalidad específica,
            cuando no haya prestado consentimiento y/o su tratamiento no sea
            legítimo o no esté justificado de acuerdo con la normativa vigente.
            <br /> <br />
            Asimismo, el Usuario tendrá derecho a la revocación de su
            consentimiento. En este sentido, el Usuario puede retirarlo en
            cualquier momento.
            <br /> <br />
            Se hace saber que, para cualquier cambio que el Usuario desee
            realizar respecto a la información provista en su cuenta de la
            Plataforma, deberá directamente comunicarse con los medios de
            contacto provistos en la Plataforma y debidamente descritos en las
            Políticas de Privacidad de n1u level. En dicho sentido, para ejercer
            el derecho de rectificación, el Usuario deberá directamente
            solicitarlo por medio de los canales provistos por n1u.
            <br /> <br />
            <b>13. Modificación de la Política</b>
            <br /> <br />
            Koibanx se reserva el derecho a modificar esta Política en cualquier
            momento y con efecto inmediato. Ante tal modificación, el Usuario
            será notificado. El Usuario acepta que el uso del Servicio con
            posterioridad a la notificación constituye aceptación íntegra de los
            nuevos términos de la Política.
            <br /> <br />
            La fecha de vigencia se indica al inicio de la misma. Por lo tanto,
            es responsabilidad del Usuario revisar periódicamente la misma para
            estar informado sobre su versión más actualizada. Para ser siempre
            coherente con la dinámica de uso de nuestros productos y servicios,
            actualizaremos la política de privacidad con el tiempo necesario
            para ser aceptada. Es responsabilidad del Usuario leer de manera
            fehaciente las Políticas a los fines de decidir su aceptación.
            <br /> <br />
            Si el Usuario no está de acuerdo con los cambios implementados,
            deberá solicitar su baja y abstenerse al uso del Servicio. Si el
            Usuario no efectúa esta cancelación o continúa utilizando la
            Plataforma, se considerará que ha aceptado la nueva Política.
            <br /> <br />
            <b>14. Información de contacto</b>
            <br /> <br />
            En caso de tener alguna inquietud respecto de la privacidad y/o
            seguridad de la información brindada a Koibanx, podrá comunicarse
            directamente a través de la dirección de correo electrónico
            <a href="mailto:dataprivacy@koibanx.com"><span>dataprivacy@koibanx.com</span></a>
            <br /> <br />
           <b> 15. Ley aplicable y jurisdicción</b>
            <br /> <br />
            Los presentes Términos y Condiciones se regirán por las leyes de la
            República de El Salvador, sin perjuicio de aquellas normas de orden
            público que resulten aplicables.
            <br /> <br />
            Koibanx y el Usuario acuerdan de manera irrevocable someter
            cualquier disputa o reclamo derivado del uso del Servicio, o de la
            aplicación, interpretación, validez y/o alcance de la presente
            Política a la jurisdicción no exclusiva de los tribunales de San
            Salvador, República de El Salvador, dejando a salvo cualquier
            derecho del consumidor a iniciar reclamos contra Koibanx en la
            jurisdicción que resulte competente y bajo la ley aplicable de
            acuerdo con su domicilio.
            <br /> <br />
            <b>16. Notificaciones</b>
            <br /> <br />
            Se entenderá que a todos los efectos que pudieran corresponder, el
            Usuario fija domicilio en el correo electrónico informado a los
            fines de su registración en el Servicio y se fija el domicilio de
            Koibanx en 101 Av Norte # 558 Col Escalón, San Salvador, El
            Salvador;  <a href="mailto:dataprivacy@koibanx.com"><span>dataprivacy@koibanx.com</span></a> donde serán válidas las
            notificaciones que se remitan entre las Partes con relación al
            vínculo existente entre Usuario y responsable del Servicio.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default PrivacyPoliciesDigital;
