import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import ray from "../assets/wiki/ray.svg";
import izq from "../assets/wiki/izq.png"
import tutorials from "../jsons/tutorials.json";
import { Link } from "react-router-dom";

const Tutorials = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const carouselRef = useRef(null);

  return (
    <>
      <TutorialsSection>
        <Top>
          <div>
            <h2>tutoriales</h2>
            <Line />
            <img src={ray} alt="rayo" />
          </div>
        </Top>
        <CarouselContainer>
          <Carousel
            itemsToShow={width > 1200 ? 3.5 : width > 730 ? 2.5 :   width > 430  ? 1.5 : 1.2}
            pagination={false}
            ref={carouselRef}
          >
            {tutorials.map((tutorial, index) => (
              <Link to={tutorial.link} target="_blank">
                <Item key={index}>
                  <img
                    src={require(`../assets/tutorials/video${tutorial.video}.png`)}
                    alt="tutorial"
                  />
                </Item>
              </Link>
            ))}
          </Carousel>

          {width > 600 ? (<>
            <ButtonContainer>
          
          <img
            LeftButton
            onClick={() => carouselRef.current.slidePrev()}
            src={izq}
            alt="button left"
          />

          <img
            onClick={() => carouselRef.current.slideNext()}
            src={izq}
            alt="button right"
            className="derecha"
          />
      
        </ButtonContainer>
          </>) : (<></>)}
         
        </CarouselContainer>
      </TutorialsSection>
    </>
  );
};

const TutorialsSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  width: 90%;
  display: none;
  justify-self: flex-start;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: none;
  }

  h2 {
    color: #fff;
    font-family: "LoRes";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    margin-right: 30px;
  }

  img {
    height: 50px;
    width: 50px;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;

    div {
      display: flex;
    }
  }

  @media only screen and (max-width: 800px) {
    h2 {
      font-size: 30px;
    }
    img {
      height: 40px;
      width: 40px;
    }
  }

  @media only screen and (max-width: 415px) {
    height: 40px;
    margin-top: 0px;
    h2 {
      font-size: 30px;
      margin-right: 10px;
    }
    img {
      height: 30px;
      width: 30px;
    }
  }
`;

const Line = styled.div`
  width: 550px;
  background-color: white;
  height: 1px;
  margin-right: 30px;

  @media only screen and (max-width: 1650px) {
    width: 400px;
  }

  @media only screen and (max-width: 800px) {
    width: 100px;
  }

  @media only screen and (max-width: 415px) {
    margin-right: 10px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 0;
  overflow: hidden;

  .rec-arrow {
    display: none;
  }
`;

const Item = styled.div`
  height: 560px;
  width: 340px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px !important;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: 900px) {
    height: 500px;
  width: 270px;
  }

  @media only screen and (max-width: 445px) {
    height: 500px;
  width: 250px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 90%;
  margin: 0 auto;


  img {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .derecha{
    transform: rotate(180deg);
  }
`;

export default Tutorials;
