import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function Referrals() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Términos y Condiciones Campaña Referidos</TermsTitle>
          <TermsSubText>
            1. <b>Elegibilidad y vigencia</b>
          </TermsSubText>
          <TermsText>
            Esta promoción está abierta a todos los usuarios de n1u, que
            participen dentro del periodo de vigencia será desde el 17/10/2023
            al 17/11/2023, aplicable solo para el territorio Argentino.
          </TermsText>

          <TermsSubText>
            2. <b>Mecanismo de participación </b>
          </TermsSubText>
          <TermsText>
            El usuario de n1u deberá ingresar en{" "}
           <a href="https://n1u.app/referidos-n1u/"> <span>https://n1u.app/referidos-n1u</span></a> , debiendo completar el
            formulario con su n1uID y con el correo electrónico de la persona a
            quien quiere referir, al referido le llegará un correo electrónico
            de parte de n1u para que pueda descargar y registrarse en n1u, una
            vez que el referido se haya registrado efectivamente, tanto quien lo
            refirió como el referido podrán ser acreedores del premio.
            <br />* Aclaraciones:
          </TermsText>
          <TermsSubText>
            • Si una persona, independientemente de que se refiera con distinto
            correo electrónico, es referida más de una vez solo se le acreditará
            una (1) sola vez el importe del premio, del primer usuario n1u que
            lo refirió;
            <br />• Un mismo usuario de n1u tiene la posibilidad de referir
            hasta 5 personas;
          </TermsSubText>
          <TermsSubText>
            3. <b>Premio</b>
          </TermsSubText>
          <TermsText>
            El usuario que haya cumplido con todos los requisitos enumerados en
            el punto 2. Mecanismo de participación, recibirá en su billetera n1u
            la suma de $1000.- (Mil pesos Argentinos).
            <br />
            <br />
            Tanto el referido como quien refirió, recibirá la suma de $1000.-
            (Mil pesos Argentinos) en su billetera n1u.
            <br />
            <br />
            Ahora bien, si el usuario que refiere consigue llegar a que 5
            (cinco) personas se registren en n1u, se le acreditarán 2000 n1u
            coins en su billetera n1u, dentro de los 30 días a contar desde la
            fecha que el último referido se registró en n1u.
          </TermsText>

          <TermsSubText>
            4. <b>Acreditación del Premio</b>
          </TermsSubText>
          <TermsText>
            La acreditación del premio se realizará dentro de los 30 días
            siguientes al registro del referido.
          </TermsText>

          <TermsSubText>
            5. <b>Cambios y Terminación</b>
          </TermsSubText>
          <TermsText>
            n1u se reserva el derecho de realizar cambios en estos términos y
            condiciones o de dar por terminada la promoción en Argentina en
            cualquier momento y sin previo aviso.
          </TermsText>

          <TermsSubText>
            6. <b>Leyes aplicables y jurisdicción</b>
          </TermsSubText>
          <TermsText>
            Esta promoción está sujeta a todas las leyes y regulaciones
            aplicables en Argentina.
            <br />
            <br />
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>
            7. <b>Aceptación de Términos</b>
          </TermsSubText>
          <TermsText>
            La participación en esta promoción implica la aceptación de todos
            los términos y condiciones establecidos aquí.
          </TermsText>

          <TermsSubText>
            8. <b>Cumplimiento legal</b>
          </TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Referrals;
