import React from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import AnimatedText from "../components/AnimatedText";

function ComingSoon() {
  return (
    <>
      <ComingSoonSection>
        <NavbarFixed />
        <ComingSoonContainer>
          <AnimatedText>
            <h1>Coming soon</h1>
          </AnimatedText>
        </ComingSoonContainer>
        <Footer />
      </ComingSoonSection>
    </>
  );
}

const ComingSoonSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  background-color: black;
  display: flex;
  flex-direction: column;
`;

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
  justify-content: center;
  height: auto;

  h1 {
    color: white;
    font-family: "LoRes";
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;

    @media only screen and (max-width: 1000px) {
      font-size: 90px;
    }

    @media only screen and (max-width: 830px) {
      font-size: 70px;
    }

    @media only screen and (max-width: 645px) {
      font-size: 50px;
    }

    @media only screen and (max-width: 420px) {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 85%;
  }
  @media only screen and (max-width: 645px) {
    width: 95%;
  }
`;

export default ComingSoon;
