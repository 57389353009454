import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsSubTitle,
  TermsText,
  TermsSubText,
} from "../styles/texts";

function Digital() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>TyC activos digitales</TermsTitle>
          <TermsSubTitle>
            TÉRMINOS Y CONDICIONES DEL SERVICIO DE KOIBANX EN LA PLATAFORMA n1u
            level
          </TermsSubTitle>

          <TermsText>Última actualización 20 de julio de 2023</TermsText>

          <TermsText>Definiciones:</TermsText>

          <TermsSubText>
            1. <b>Usuario:</b> aquella persona que haga uso del Servicio, en virtud de
            lo estipulado en los presentes Términos y Condiciones.
            <br />
            2. <b>Servicio:</b> compra, venta y/o Swap de activo de moneda digital
            ofrecidos por Koibanx en la Plataforma de n1u level.
            <br />
            3. <b>Plataforma:</b> ámbito tecnológico denominado “n1u level level”,
            perteneciente a B. Payments Rails S.A., en el que Koibanx presta el
            servicio correspondiente.
            <br />
            4. <b>Activo de moneda digital:</b> activos escritos en clave susceptibles
            de apreciación pecuniaria, objeto de las operaciones a ser
            realizadas por los Usuarios (ej: Bitcoin, Ethereum, USDC).
            <br />
            5. <b>Forks:</b> bifurcación referida a un ajuste de las reglas de consenso
            de una red que puede o no producir una división de la misma.
            <br />
            6. <b>Seguridad:</b> utilizamos una tecnología que consiste en una cadena
            con una base de datos pública donde se registran de forma segura las
            transacciones que se realizan <a href="https://www.arimetrics.com/glosario-digital/internet"><span> en la red.</span></a> Los datos están validados
            por una comunidad más amplia, en lugar de por una autoridad central.
            Es decir, es una colección de registros que una multitud supervisa y
            mantiene, sin depender así de ninguna entidad, ya sea un banco o un
            gobierno.
            <br />
            7. <b>Protocolos:</b> conjunto de reglas, pautas y operaciones programadas
            que hacen de base para las redes y los desarrollos web3.
            <br />
            8. <b>Swap:</b> servicio provisto por Koibanx que consiste en la
            posibilidad de intercambiar un activo de moneda digital disponible
            en el Servicio por otro.
            <br />
            9. <b>Chains o redes:</b> distintas redes que funcionan bajo esta
            tecnología y a través de las cuales se realizan las operaciones
            ofrecidas por el Servicio.
            <br />
            10. <b>Wallet:</b> billetera digital destinada a la recepción,
            almacenamiento o envío de activos de moneda digitals.
            <br />
            11. <b>Airdrop:</b> refiere a un procedimiento de distribución de nuevos
            tokens otorgados a poseedores de activos de moneda digital.
            <br />
            12. <b>Dinero fiduciario:</b> moneda de curso legal de un país determinado
            (ej: pesos argentinos, dólares, etc).
            <br />
            13. <b>On-Ramp:</b> operatoria referida al ingreso de dinero fiduciario a
            los fines de hacer uso del Servicio.
            <br />
            14. <b>Off-Ramp:</b> operatoria referida al retiro de dinero fiduciario por
            el uso del Servicio.
            <br />
            15. <b>CVU:</b> Clave Virtual Uniforme otorgada por n1u level a sus
            Usuarios.
            <br />
            <br />
           <b> 1. Introducción</b>
          </TermsSubText>
          <TermsText>
            Los presentes Términos y Condiciones (en adelante los “TyC”) rigen
            para las operaciones de compra y venta de activos de moneda digital
            que realicen los usuarios habilitados (en adelante los “Usuarios”) a
            través de la plataforma denominada n1u (en adelante “la Plataforma”,
            “n1u level” o “n1u”, indistintamente), en la que se ha autorizado la
            provisión del servicio de compra y venta de activos de moneda
            digital (en adelante, “el Servicio”) por parte de Koibanx El
            Salvador S.A. de C.V. (en adelante “Koibanx”), sociedad constituida
            y registrada en El Salvador inscrita en el Registro de Comercio con
            el número 85 del libro 4440, con Número de Identificación Tributaria
            0614-300821-102-4. Koibanx cuenta con la debida autorización para
            operar como Proveedor de Servicios Bitcoin en carácter de Custodio
            de Bitcoins, Casas de Intercambio Digital o Exchange, Billetera
            Digital para Bitcoin y Procesador de Pago por el Gobierno de El
            Salvador bajo el Código de Registro 6148e2e2fc9c46746cdf0b71.
            <br />
            <br />
            En razón de lo recientemente expuesto, los presentes TyC establecen
            el marco contractual entre el Usuario y Koibanx, respecto al uso del
            Servicio. Los Términos y Condiciones regirán los derechos y
            obligaciones de las partes sobre el servicio de compra y venta de
            activos de moneda digital ofrecido por Koibanx. El Usuario se
            compromete a leer, comprender y aceptar todas las condiciones
            establecidas.
            <br />
            <br />
            Los presentes Términos y Condiciones se considerarán aceptados desde
            el momento en el que su registro como Usuario sea aprobado por
            Koibanx, de acuerdo a las políticas de alta del usuario.
            <br />
            <br />
            Se aclara que la utilización de los servicios del Servicio, como la
            aceptación de los presentes Términos y Condiciones, no configuran
            aceptación de los Términos y Condiciones de ningún otro producto o
            servicio ofrecido por n1u level dentro de su Plataforma.
            <br />
            <br />
            AL HACER CLIC EN “ACEPTO”, O ACCEDER O UTILIZAR NUESTROS SERVICIOS
            (COMO SE DEFINE EN ESTOS TÉRMINOS Y CONDICIONES), USTED ACEPTA ESTOS
            TÉRMINOS Y CONDICIONES EN SU TOTALIDAD, Y LOS OTROS TÉRMINOS
            INCORPORADOS POR REFERENCIA.
            <br />
            <br />
            El Usuario acepta que deberá ajustarse íntegramente a estos TyC y a
            la totalidad de las condiciones que se establezcan en la Plataforma,
            incluyendo todo lo dispuesto con relación a las condiciones de uso
            de la Plataforma que fueran requeridos para el correcto registro.
            <br />
            <br />
            En caso de no encontrarse de acuerdo con los presentes Términos y
            Condiciones, solicitamos se abstenga de utilizar el Servicio.
            <br />
            <br />
            EL USUARIO ACEPTA Y RECONOCE QUE EL SERVICIO COMPRENDE OPERACIONES O
            ACTOS QUE SE LLEVAN A CABO FUERA DEL ÁMBITO TERRITORIAL DE LA
            REPÚBLICA ARGENTINA, ENCONTRÁNDOSE SUJETOS A LAS LEYES NACIONALES
            APLICABLES EN LA JURISDICCIÓN CORRESPONDIENTEDE.
          </TermsText>
          <TermsSubText><b>2. Marco legal</b></TermsSubText>
          <TermsText>
            Koibanx El Salvador S.A. de C.V. es una sociedad inscrita en El
            Salvador inscrita en el Registro de Comercio al número 85 del libro
            4440, con Número de Identificación Tributaria 0614-300821-102-4.; y
            autorizada para operar como Proveedor de Servicios Bitcoin como:
            Custodio de Bitcoins, Casas de Intercambio Digital o Exchange,
            Billetera Digital para Bitcoin y Procesador de Pago por el Gobierno
            de El Salvador bajo el Código de Registro 6148e2e2fc9c46746cdf0b71.
            <br />
            <br />
            Al aceptar estos TyC, el Usuario contrata a Koibanx para hacer uso
            del Servicio. En dicho sentido, se aclara que toda la operatoria
            relacionada al Servicio es perfeccionada entre el Usuario y Koibanx.
          </TermsText>

          <TermsSubText><b>3. Capacidad</b></TermsSubText>

          <TermsText>
            Para la prestación del Servicio en la Plataforma n1u level, podrán
            ser Usuarios del Servicio aquellas personas con capacidad legal para
            contratar de acuerdo con la legislación aplicable y a lo indicado en
            los Términos y Condiciones generales de la Plataforma n1u level.
            <br />
            <br />
            En dicho sentido, el registro del Usuario se encontrará supeditado a
            lo estipulado en los Términos y Condiciones generales de la
            Plataforma.
            <br />
            <br />
            Los Usuarios están obligados a presentar datos verdaderos,
            fidedignos y actualizados sobre su persona, como así también
            responder a los requerimientos exigidos por el Servicio.
          </TermsText>

          <TermsSubText><b>4. Registro y uso del Servicio</b></TermsSubText>

          <TermsText>
            Para el acceso al uso del Servicio en la Plataforma de n1u level, el
            Usuario deberá pasar por el proceso de “onboarding” o registro
            indicado en la Plataforma. Se hace saber que, de acuerdo a las
            Políticas de Privacidad, la protección de datos personales es
            llevada a cabo de conformidad con la normativa aplicable. En dicho
            sentido, el Usuario deberá crear una cuenta propia en n1u level que
            le permitirá el acceso al uso del Servicio provisto por Koibanx.
            <br />
            <br />
            El registro reviste carácter de declaración jurada para el Usuario,
            por lo que deberá completar el mismo con exactitud, veracidad y de
            manera integral. Además, deberá poner a disposición de Koibanx o n1u
            level, en el caso de ser solicitado, la adecuada adicional que
            pudiera ser requerida para verificar la autenticidad de la
            información provista por el Usuario.
            <br />
            <br />
            Hasta tanto el Usuario no suministre los datos personales requeridos
            por n1u level y/o Koibanx, conforme se hace saber en la Política de
            Privacidad del Servicio y los mismos no hayan sido debidamente
            verificados, Koibanx podrá proceder a restringir o cancelar el
            acceso de aquel Usuario a el Servicio. Todos los datos personales
            estarán protegidos por la Política de Privacidad, que deberá ser
            aceptada por el Usuario junto con los Términos y Condiciones.
            <br />
            <br />
            Se hace saber que cada Usuario puede tener un único y exclusivo
            registro. No podrán utilizar los servicios de Koibanx quienes hayan
            sido suspendidos o inhabilitados. A discreción exclusiva de Koibanx,
            se suspenderán o cancelarán registros duplicados, incompletos, o
            dudosos, lo cual no habilitará al Usuario a reclamar derechos
            indemnizatorios o resarcitorios de índole alguna. El Usuario declara
            y garantiza que no utilizará el Servicio para realizar actividades
            ilegales de ningún tipo, incluyendo, pero no limitando, a lavado de
            activos y financiamiento del terrorismo, fraudes, estafas y otras
            defraudaciones.
            <br />
            <br />
            Completando su nombre de usuario o correo electrónico y la
            contraseña en las casillas correspondientes, el usuariopodrá acceder
            a su cuenta única en Koibanx de la que será responsable de todas las
            operaciones que efectúe. En el caso de que el Usuario no recuerde la
            contraseña, podrá solicitar el recupero de la misma, siguiendo las
            instrucciones provistas en el Servicio.
            <br />
            <br />
            Si el Usuario llegara a descubrir o detectar que un intruso tuvo
            acceso no autorizado a su cuenta o que la seguridad de la misma ha
            sido, de cualquier modo, infringida, deberá comunicarse de inmediato
            a soporte@koibanx.com para informarlo.
            <br />
            <br />
            Koibanx se excluye de toda responsabilidad por los daños y
            perjuicios de toda naturaleza que pudieran deberse al accionar de
            terceros no autorizados que hubieran tenido acceso a su cuenta.
            <br />
            <br />
            Se hace saber que, para aceptar los presentes TyC, el Usuario debe
            haber aceptado los Términos y Condiciones generales de la
            Plataforma.
          </TermsText>

          <TermsSubText><b>5. Seguridad</b></TermsSubText>

          <TermsText>
            El Usuario tendrá la obligación de crear una contraseña que se
            ajuste a los estándares mínimos de seguridad indicados en el
            Servicio. Ante ello, Koibanx se excluye de todo tipo de
            responsabilidad respecto a la vulneración de las contraseñas de los
            Usuarios.
            <br />
            <br />
            Para dichos fines, se hacen las siguientes recomendaciones para la
            formulación de contraseñas:
          </TermsText>

          <TermsSubText>
            • Contener mínimamente 12 carácteres;
            <br />• Inclusión de al menos una letra mayúscula;
            <br />• Inclusión de al menos un número;
            <br />• Inclusión de al menos un carácter especial (ej: ¡%$&);
            <br />• Inclusión de al menos una letra minúscula;
            <br />• Evitar hacer alusión a datos personales;
            <br />• No ser similar al nombre de Usuario;
            <br />• Evitar contraseñas integradas por secuencia de caracteres
            (ej: qwerty1234).
            <br />• Modificar la contraseña cada 3 meses;
            <br />• Evitar hacer uso de contraseñas utilizadas en otras
            Plataformas;
            <br />• Evitar guardar las contraseñas en un archivo de texto plano
            sin encriptar o en un papel susceptible de acceso de terceros.
          </TermsSubText>

          <TermsText>
            Asimismo, el Servicio podrá establecer un doble factor de
            autenticación a los fines de verificar la identidad del Usuario al
            momento de realizar operaciones o para acceder al Plataforma.
            <br />
            <br />
            Se hace saber al Usuario que Koibanx, de ninguna manera, será
            responsable de la vulneración de su contraseña. El Usuario asume la
            responsabilidad de llevar a cabo sus máximos esfuerzos a los fines
            de establecer una contraseña segura para el acceso a su cuenta.
            <br />
            <br />
           <b> Koibanx no será responsable en caso de que el Usuario sufra un daño
            o interrupción del Servicio, cuando la causa sea externa y/o ajena a
            la voluntad de Koibanx. Del mismo modo, Koibanx tampoco deberá
            asumir responsabilidad en los supuestos de daño o interrupción del
            Servicio causados por cualquier virus informático, spyware u otro
            malware que pueda afectar el dispositivo desde el que el Usuario
            desea hacer uso del Servicio. Por último, Koibanx tampoco será
            responsable ante los casos de suplantación de identidad (phishing),
            falsificación (spoofing) u otro tipo de actividad delictiva. Para
            dichos casos, se le sugiere al Usuario que verifique que el dominio
            web utilizado es el correspondiente a la Plataforma o al Servicio.</b>
          </TermsText>

          <TermsSubText>
           <b> 6. Custodia de los activos de moneda digital</b>
          </TermsSubText>

          <TermsText>
            Los activos de moneda digital serán depositados en una cuenta de
            custodia abierta por Koibanx con un proveedor de servicios externo
            denominado BitGo Trust Company Inc. (el “Custodio”), una sociedad
            debidamente constituida y registrada bajo las leyes de Dakota del
            Sur de los Estados Unidos de América, que cuenta con la respectiva
            licencia para actuar como custodio de los activos de moneda digital
            , en atención a la § 51A-6A-1(12A) of the South Dakota Banking Law.
            El Custodio se encargará de llevar a cabo la actividad custodia de
            los activos de moneda digital en forma directa. Koibanx ha
            contratado los servicios del Custodio a los fines de brindar
            protección a la custodia de los activos de moneda digital .
          </TermsText>

          <TermsSubText><b>7. Riesgos en el uso del Servicio</b></TermsSubText>
          <TermsText>
            Se deja en claro que las operaciones con activos de moneda digital
            presentan ciertos riesgos que no dependen del control de Koibanx.
            Asimismo, las pérdidas que puedan sufrir los usuarios a causa de las
            fluctuaciones propias del mercado no serán responsabilidad de
            Koibanx ni de la propietaria de la Plataforma. En dicho sentido, se
            realizan las siguientes aclaraciones:
          </TermsText>

          <TermsSubText>
            1. Koibanx no brinda ningún tipo de asesoramiento de índole
            financiero o de inversión. El Usuario, al hacer uso del Servicio,
            declara conocer existen riesgos asociados a la fluctuación del valor
            de los activos de moneda digital ofrecidos dentro del Servicio. El
            Usuario reconoce y acepta el riesgo implícito de realizar
            transacciones con activos de moneda digital , principalmente en
            razón de la volatilidad de los mismos. Los Usuarios actúan bajo su
            propio riesgo y en ningún caso Koibanx será responsable por las
            pérdidas que el Usuario pudiese haber sufrido debido a las
            fluctuaciones propias del mercado o de errores en sus datos
            bancarios o en la dirección de su billetera para el retiro del
            saldo. Koibanx sugiere a los Usuarios que consulten con personal
            capacitado y/o tome el conocimiento necesario al momento de operar
            con activos de moneda digital . La información suministrada por
            Koibanx en la Plataforma o en cualquier otro medio, incluyendo
            publicidades, avisos y cualquier otra comunicación que Koibnax
            realice, no debe interpretarse como asesoramiento o consejo
            financiero ni como recomendaciones de inversión. El Usuario entiende
            que, al emitir una orden de compra o de venta a Koibanx, es el único
            responsable de determinar si esa orden es adecuada de acuerdo con su
            situación financiera, capacidad patrimonial o su tolerancia al
            riesgo. Los Servicios de Activos Digitales están sujetos a riesgos
            que pueden incluir la pérdida total de capital. El Usuario comprende
            que la utilización del Servicio puede implicar mayores riesgos que
            las operaciones realizadas con activos financieros tradicionales.
            <br />
            2. Los saldos de activos de moneda digital contenidos en la
            Plataforma por el uso del Servicio son de propiedad del Usuario, por
            lo que será el Usuario quien soportará las pérdidas y gozará de las
            ganancias que pudieran generarse. En carácter de propietario del
            saldo en Activos Digitales Admitidos disponibles en su Cuenta, el
            Usuario correrá con todos los riesgos de pérdida aplicables a su
            saldo correspondiente a los activos de moneda digital. Se reitera
            que Koibanx no tendrá responsabilidad alguna por fluctuaciones en la
            oscilación de los precios de los activos de moneda digital. Ninguno
            de los saldos en Activos Digitales Admitidos en la Cuenta del
            Usuario es de propiedad de Koibanx o se otorgará en préstamo a
            Koibanx así como tampoco se encuentran bajo custodia de Koibanx.
            Koibanx no otorga ningún derecho de garantía con respecto a los
            saldos en Activos Digitales Admitidos que el Usuario mantenga en su
            Cuenta. A menos que así lo exigiera una orden judicial válida
            emitida por un tribunal competente o excepto que se estipule de
            forma contraria en algún punto de estos TyC, Koibanx se abstendrá de
            vender, transferir, hipotecar, prendar, liquidar, prestar, dar en
            garantía o de cualquier forma disponer de los saldos en Activos
            Digitales que se encuentran en la Cuenta del Usuario
            <br />
            3. El Usuario comprende que las operaciones en el marco del Servicio
            pueden estar sujetas a confirmación y registro en la cadena de
            bloques o red específica de cada activo de moneda digital . Estas
            cadenas son operadas mediante redes descentralizadas que cuentan con
            el soporte de terceros independientes y no son de propiedad de
            Koibanx, ni son controladas u operadas por Koibanx ni nadie
            vinculado o relacionado con Koibanx. Koibanx no ejerce control sobre
            ninguna de estas redes y no garantiza que se confirmen los detalles
            de operaciones que sean presentados en el marco del Servicio.
            Asimismo, el Usuario entiende que el carácter inmutable de la
            tecnología imposibilita la reversión de cualquier tipo de operación
            realizada.
            <br />
            4. Los protocolos pueden presentar cambios abruptos en su
            funcionamiento o reglas operativas conocidos como bifurcaciones o
            “forks” en su cadena de bloques. Koibanx no será responsable por el
            funcionamiento de los Protocolos ni garantizará su operación ni
            disponibilidad. Asimismo, los Usuarios, a través de la aceptación de
            los presentes TyC, declaran conocer que los Forks pueden afectar
            materialmente el valor, utilidad y disponibilidad de los activos de
            moneda digital ofrecidos en el Servicio. Asimismo, Koibanx se
            reserva la facultad de suspender temporalmente la operatoria
            vinculada un Protocolo afectado por un Fork y podrá decidir operar o
            no operar con los activos de moneda digital subyacentes que el
            Protocolo modificado por un Fork origine. Koibanx no se encontrará
            obligado a listar estos nuevos activos de moneda digital y tampoco
            asumirá responsabilidad alguna por el daño causado por un Fork no
            soportado por Koibanx. El Usuario reconoce los riesgos que presentan
            los Forks y acepta que Koibanx no tiene responsabilidad de brindarle
            asistencia para vender un activo de moneda digital no soportado por
            Koibanx y comprende que puede no tener a disposición los activos de
            moneda digital bifurcados no admitidos. Koibanx no tiene
            responsabilidad alguna por las pérdidas totales o parciales en las
            que incurra el Usuario en forma directa o indirecta, como
            consecuencia de algún Fork u otra disrupción en la red.
            <br />
            <br />
           <b> 8.Depósitos</b>
          </TermsSubText>

          <TermsText>
            A los fines de efectuar depósitos en dinero fiduciario, el Usuario
            deberá realizar transferencias desde su cuenta bancaria personal y/o
            cuenta en un Proveedor de Servicios de Pago, a través del empleo de
            su propio CBU o CVU. En dicho sentido, el Usuario deberá realizar
            dicha transferencia a la cuenta del agente de recaudación consignado
            por Koibanx, que será detallado en la Plataforma. Asimismo, se
            aclara que no se aceptarán transacciones u operaciones realizadas en
            efectivo ni de personas no registradas en la Plataforma.
            <br />
            <br />
            Koibanx se reserva el derecho de limitar las jurisdicciones para las
            que se aceptan cuentas bancarias. Koibanx podrá tomar las medidas
            que crea necesarias para consultar información sobre la cuenta
            bancaria y no tendrá ningún tipo de responsabilidad por cualquier
            incapacidad temporal o permanente del Usuario para acceder al saldo
            en el Servicio.
            <br />
            <br />
            El Usuario autoriza a Koibanx a dar cumplimiento de una eventual
            obligación de informar ciertas operaciones y proveer de toda la
            información requerida por las instituciones gubernamentales
            competentes, en caso de ser requeridas por procedimientos de
            prevención de lavado de activos, financiamiento del terrorismo y/o
            prevención del fraude en todas las jurisdicciones en donde el
            servicio se encuentre disponible. El Usuario se obliga a garantizar
            la lícita procedencia de los fondos empleados para operar y hacer
            uso del Servicio.
            <br />
            <br />
            Se hace saber que el Usuario deberá realizar los depósitos desde una
            cuenta bancaria o de un proveedor de servicio de pago que se
            encuentre registrada a su nombre. El Usuario no podrá realizar
            depósitos de dinero fiduciario desde cuentas que no le pertenezcan.
            <br />
            <br />
            El límite de depósito en dinero fiduciario se encuentra indicado al
            momento de realizar el depósito, al acceder a la solapa “Ingresar y
            Retirar”, específicamente en la opción “Ingresar”.
          </TermsText>

          <TermsSubText>
          <b>  9. Agentes de recaudación para On y Off Ramp</b>
          </TermsSubText>

          <TermsText>
            A los fines de posibilitar a los Usuarios el ingreso y retiro de
            dinero fiduciario para hacer uso del Servicio, Koibanx cuenta con
            representantes y agentes que actúan prestando el servicio de
            recaudación y pagos a cuenta y orden. Los agentes sólo podrán
            recibir y entregar dinero desde y hacia CVUs o cuentas bancarias
            indicadas en la Plataforma y siguiendo las instrucciones
            establecidas en el Servicio. Los agentes no solicitarán dinero en
            efectivo o depósitos anónimos en ningún momento.
          </TermsText>

          <TermsSubText><b>10. Monedas fiduciarias aceptadas</b></TermsSubText>

          <TermsText>
            Se considera como moneda fiduciaria tanto aquellas divisas que se
            caractericen por poseer carácter de curso legal en los países donde
            opere el Usuario, como así también las monedas extranjeras.
            <br />
            <br />
            Koibanx permitirá la operatoria de compra y venta de activos de
            moneda digital a través del empleo de dinero fiduciario. La moneda
            nacional permitida por el Servicio dependerá del país desde el que
            se opere.
            <br />
            <br />
          <b>  Para el caso de la prestación del Servicio en la Plataforma “n1u
            level”, únicamente se admitirán pesos argentinos.</b>
          </TermsText>

          <TermsSubText>
            11. Compra y venta de activos de moneda digital
          </TermsSubText>
          <TermsText>
            El Servicio comprende la operatoria de compra y venta de activos de
            moneda digital, cuyos precios se encuentran reflejados en la
            Plataforma. Los precios de los mismo se verán reflejados en monedas
            fiduciarias, dependiendo del país desde el que se opere. En el caso
            de n1u level, los precios podrán ser exhibidos en pesos argentinos.
            <br />
            <br />
            El Usuario declara conocer y manifiesta entender que los activos de
            moneda digital con los que podrá operar se encontrarán taxativamente
            listados a la hora de hacer uso del Servicio en la Plataforma.
            Asimismo, Koibanx se reserva la facultad de agregar o quitar activos
            de moneda digital en el listado exhibido para el uso del Servicio.
            <br />
            <br />
            El Usuario acepta y reconoce que todas estas operaciones podrán
            estar sujetas a un cobro por servicio, comisión o costos de
            transacción que informe Koibanx.
            <br />
            <br />
            Del mismo modo, el Usuario también reconoce que el precio de los
            activos de moneda digital, detallado en al momento del uso del
            Servicio, es el existente al momento de realizar la compra o venta
            del activo escritos en clave. Sin embargo, el Usuario declara
            conocer que el precio de cualquier activo de moneda digital podrá
            oscilar, decrecer o incrementar de acuerdo a las variaciones del
            mercado por causas totalmente ajenas a la voluntad de Koibanx.
            <br />
            <br />
            En algunos casos incluso podrá mostrarse simplemente el precio de
            compra y de venta de cada activo de moneda digital . Esto no
            significa que Koibanx haga las veces de oferente o demandante, sino
            que simplificará o limitará las opciones de operación de los
            Usuarios.
            <br />
            <br />
            EL USUARIO DECLARA TENER CONOCIMIENTO, Y ACEPTA, DE QUE KOIBANX BAJO
            NINGÚN CONCEPTO SERÁ QUIÉN DETERMINA LOS PRECIOS DE LOS ACTIVOS DE
            MONEDA DIGITAL OFRECIDOS EN EL SERVICIO. LA COTIZACIÓN, SU SUBA O
            BAJA PODRÁ VARIAR DE ACUERDO CON LAS OSCILACIONES PROPIAS DEL
            MERCADO. KOIBANX ÚNICAMENTE ACTÚA COMO FACILITADOR DE LA COMPRA Y
            VENTA DE ESTOS, SIN TENER NINGÚN TIPO DE IMPLICANCIA EN SU EMISIÓN
            Y/O DETERMINACIÓN DEL PRECIO.
            <br />
            <br />
            SE HACE SABER QUE LOS ÚNICOS ACTIVOS DE MONEDA DIGITAL QUE ESTARÁN
            DISPONIBLES SON AQUELLOS LISTADOS EN EL SERVICIO. KOIBANX SE RESERVA
            LA FACULTAD DE AGREGAR Y/O ELIMINAR ACTIVOS DE MONEDA DIGITAL DE SU
            LISTADO Y EL USUARIO DECLARA ACEPTARLO.
          </TermsText>

          <TermsSubText><b>12. Swap</b></TermsSubText>

          <TermsText>
            Koibanx podrá brindar el servicio de intercambio entre los activos
            de moneda digital ofrecidos dentro del Servicio (en adelante,
            “operatoria Swap” o “Swap).
            <br />
            <br />
            El Usuario contará con la posibilidad de instruir a Koibanx para
            realizar un Swap de una cantidad específica de un activo de moneda
            digital por otro. Koibanx pondrá a disposición del Usuario la
            cotización correspondiente para realizar la Instrucción Swap durante
            el tiempo detallado en la Plataforma.
            <br />
            <br />
            Para ejecutar la instrucción de Swap, el Usuario deberá tener: a) la
            cantidad suficiente del monto especificado del activo digital
            seleccionado y; b) el valor referente a las comisiones o costos en
            el activo digital seleccionado, que apliquen a dicha operatoria, de
            corresponder.
            <br />
            <br />
            El Swap solo se ejecutará sobre la cantidad especificada por el
            Usuario. La ejecución de la operatoria Swap se realizará
            inmediatamente luego de la confirmación a la misma.
            <br />
            <br />
            El Usuario no podrá cambiar, revocar o cancelar una operación Swap
            una vez confirmada. Si su Cuenta no tiene suficiente cantidad de
            activos digitales para ejecutar una Instrucción Swap o para abonar
            las comisiones o gastos correspondientes a la Instrucción Swap,
            Koibanx no permitirá o cancelará la Instrucción Swap.
          </TermsText>

          <TermsSubText><b>13. Comisiones</b></TermsSubText>

          <TermsText>
            El Usuario reconoce y acepta que las operatorias detalladas en los
            puntos 11 y 12 de los presentes TyC pueden implicar gastos, costos y
            comisiones. Todos los costos y comisiones establecidos por Koibanx
            se encuentran debidamente informados en la Plataforma. Es
            responsabilidad del Usuario conocer los costos transaccionales que
            implican las diferentes redes aceptadas para la operatoria. La
            emisión, por parte del usuario, de una instrucción de compra, venta
            y/o Swap de activos de moneda digital constituye una declaración de
            conocimiento y aceptación de todos los gastos, costos y comisión que
            pudieran generarse.
            <br />
            <br />
            En caso de existir comisiones, las mismas serán exhibidas en la
            Plataforma. El usuario contará con la información respectiva de
            forma clara y precisa.
          </TermsText>

          <TermsSubText>
            <b>14. Depósitos de activos de moneda digital</b>
          </TermsSubText>

          <TermsText>
            El Usuario podrá depositar los activos de moneda digital listados en
            el Servicio siguiendo las instrucciones de establecidas en la
            Plataforma. Es responsabilidad del Usuario conocer qué “red” resulta
            compatible con cada activo de moneda digital para poder efectuar el
            depósito de manera correcta. A exclusivo criterio de Koibanx, podrán
            agregarse redes adicionales, así como también, dejar de soportar una
            o más de las actualmente ofrecidas.
            <br />
            <br />
            Una vez hecho el depósito, Koibanx se reserva el derecho a no
            permitir que se operen fondos catalogados como riesgosos por los
            sistemas de Monitoreo de Operaciones de nuestro Programa de
            Antilavado de Dinero. Se solicitará al Usuario las justificaciones
            pertinentes al caso y, eventualmente, en caso de justificación
            insatisfactoria, deberá el Usuario someterse al procedimiento
            indicado para el retiro, debiendo soportar el costo del gas y otros
            gastos administrativos.
          </TermsText>

          <TermsSubText><b>15. Retiros</b></TermsSubText>

          <TermsText>
            El Usuario tiene la posibilidad de retirar, en forma total o
            parcial, el saldo existente en su cuenta de Koibanx para
            transferirlo directamente a su cuenta bancaria, CVU o billetera
            virtual de activos de moneda digital. Koibanx no se hace responsable
            de las cargas impositivas o comisiones bancarias de la cuenta
            receptora. Respecto al retiro en wallets personales de los Usuarios,
            se hace saber que el costo del gas que implique cada transacción
            será afrontado por el Usuario.
            <br />
            <br />
            En el caso de que el Usuario no proporcionare una cuenta bancaria
            válida, habilitada, o la misma fuese proporcionada con un número de
            cuenta incorrecto o incompleto, moneda de la cuenta errónea, o
            cualquier otro motivo que imposibilitara el correcto procedimiento
            de la operación, el retiro no podrá ejecutarse hasta tanto se
            cumplimente con los requisitos necesarios.
            <br />
            <br />
            Koibanx no será responsable ante el caso de que el Usuario se
            encuentre momentáneamente imposibilitado de hacer retiro de sus
            fondos porque el banco destinatario solicitara información,
            documentación complementaria, o por cualquier tipo de motivo cursado
            por el banco destinatario. El Usuario es el único responsable en la
            relación contractual que mantiene con la entidad bancaria o cuenta
            de proveedor de servicios de pago a la que envía sus fondos a
            retirar.
            <br />
            <br />
            No se ejecuta retención indebida cuando el Usuario desea ejecutar un
            retiro y su perfil se encuentra inhabilitado por estar incompleto o
            por alguna causal de suspensión. Koibanx no se responsabiliza por el
            destino de los activos de moneda digital. No se realizarán retiros
            manuales de saldo a terceros no registrados en el Servicio.
            <br />
            <br />
            Koibanx se reserva el derecho de inmovilizar los saldos de cuenta e
            impedir la transferencia a terceros por parte de los Usuarios en
            caso de ser cursado cualquier tipo de requerimiento judicial o
            agencia gubernamental que así lo exigiera.
            <br />
            <br />
            El Usuario declara tener conocimiento de que deberá ser titular de
            la cuenta bancaria a la que se envíe el retiro de sus fondos. Es
            decir, el titular de la cuenta bancaria de retiro deberá ser el
            mismo que el titular de la cuenta de depósito. En caso de hacer el
            retiro a una cuenta de titularidad compartida, uno de los titulares
            deberá ser el mismo que el de la cuenta de depósito.
            <br />
            <br />
            Es responsabilidad del Usuario conocer qué red soporta cada activo
            de moneda digital para poder efectuar el retiro de manera correcta.
            <br />
            <br />
            <b>Koibanx no permite realizar retiros de dinero fiduciario a cuentas
            de terceros. Koibanx no será responsable por las demoras de los
            bancos o proveedores de servicios de pago.</b>
          </TermsText>

          <TermsSubText><b>16. Eliminar su cuenta de Koibanx</b></TermsSubText>

          <TermsText>
            Para eliminar su cuenta de Koibanx en la plataforma de n1u level, el
            Usuario podrá hacerlo desde la Plataforma accediendo al apartado de
            “Cuenta”, “Eliminar Cuenta”. Una vez ingresado allí, se le advertirá
            respecto a: 1) la pérdida del acceso a su cuenta de Koibanx, como
            así también la pérdida de manera permanente de sus datos y perfil;
            2) la posibilidad de retiro de sus fondos; 3) la necesidad de
            verificar su identidad a los fines de comprobar que se trata
            efectivamente del Usuario; 4) pérdida de beneficios de la
            Plataforma.
            <br />
            <br />
            Previo a determinar la eliminación de su cuenta, el Usuario tendrá
            la posibilidad de indicar el motivo de la eliminación de su cuenta
            de la Plataforma y/o de Koibanx. Luego, una vez seleccionado el
            botón “Verificar Identidad”, deberá realizar la verificación
            correspondiente. Una vez completados todos los pasos, y en caso de
            verificarse correctamente la identidad del Usuario solicitante, se
            procederá al cierre de la cuenta y eliminación del acceso. El
            Usuario habrá eliminado su cuenta de Koibanx, recibiendo un último
            correo de confirmación. En caso de que el Usuario disponga de dinero
            y/o activos de moneda digital en su cuenta de Koibanx, deberá
            retirarlos, de lo contrario no podrá acceder a los mismos una vez
            eliminada la cuenta.
            <br />
            <br />
            La información de las transacciones, operaciones, movimientos, y
            toda otra información imprescindible del Usuario, permanecerá en
            poder de Koibanx durante el tiempo necesario para cumplir con sus
            obligaciones legales. Para más información sobre el plazo de
            conservación de sus datos personales, el Usuario podrá visitar la
            Política de Privacidad de Koibanx.
          </TermsText>

          <TermsSubText><b>17. Suspensión y baja definitiva</b></TermsSubText>

          <TermsText>
            Koibanx podrá, a su exclusivo criterio, suspender o dar de baja
            usuarios por cualquier razón sin necesidad expresa de causa al
            Usuario.
            <br />
            <br />
            Se suspenderá o se dará de baja al Usuario:
          </TermsText>

          <TermsSubText>
            • que actúe violando estos Términos y Condiciones o actúe de manera
            incompatible con los objetivos del Servicio;
            <br />• cuya identidad no pudiera verificarse, proporcione datos
            falsos o incompletos, posea más de una cuenta en el Servicio de
            Koibanx, intente ingresar a una cuenta que no es de su titularidad
            en el Servicio, autorice el uso de la cuenta por terceras personas,
            y se detecten en su relato incongruencias o inconsistencias;
            <br />• que intentara llevar a cabo actividades ilegales,
            fraudulentas o maliciosas, infrinja la propiedad intelectual o
            funcionalidad del Servicio, genere responsabilidad legal;
            <br />• cuando lo soliciten las autoridades competentes;
            <br />• por aplicación de cualquier política, ley o regulación que
            obligue a Koibanx a realizarlo, incluyendo, pero no limitando, la
            eventual obligación de informar ciertas operaciones o en el marco de
            actividades de Prevención de Lavados de Activos y/o Financiación del
            Terrorismo.
          </TermsSubText>

          <TermsText>
            La enumeración es meramente enunciativa, no taxativa. En cualquiera
            de los casos, no se generará para el Usuario el derecho de
            indemnización o resarcimiento bajo ningún concepto. El Usuario
            acepta que no podrá acceder a su saldo en el Servicio mientras se
            encuentre suspendido. En caso de que Koibanx diera de baja su
            cuenta, la devolución será emitida de forma manual a la cuenta
            bancaria de su titularidad. Los gastos operativos frente al caso de
            suspensión o baja de la cuenta correrán por cuenta del Usuario.
          </TermsText>

          <TermsSubText><b>18. Muerte del usuario</b></TermsSubText>

          <TermsText>
            Luego de un período de inactividad considerable, Koibanx se reserva
            el derecho de deducir un cargo por inactividad, o cargos
            administrativos de los fondos que posea el Usuario en su cuenta. En
            caso de fallecimiento del Usuario, o incapacidad sobreviniente, los
            herederos o representantes legales, deberán presentar la
            documentación que se le solicite para así poder tener acceso a los
            fondos de la cuenta.
            <br />
            <br />
            Hasta tanto no se verifiquen los recaudos exigidos para poder hacer
            devolución de los fondos, la cuenta se mantendrá suspendida. Una vez
            cumplimentados los recaudos requeridos, se procederá a hacer retiro
            de los fondos y cerrar definitivamente la cuenta.
            <br />
            <br />
            Para ello, se solicita que se envíe correo a soporte@koibanx.com a
            los fines de conocer los pasos a seguir.
          </TermsText>

          <TermsSubText><b>19. Responsabilidad del Usuario</b></TermsSubText>

          <TermsText>
            El Usuario declara y acepta que el uso del Servicio y de sus
            funcionalidades se realiza bajo su única y exclusiva
            responsabilidad.
            <br />
            <br />
            Koibanx no se responsabiliza por fallas en el sistema o problemas
            inherentes al uso de internet o cualquier virus que afecte al
            dispositivo utilizado por el Usuario. En caso de que el Servicio no
            se encuentre disponible por eventuales dificultades técnicas o
            fallas de red, demora o interrupciones, se procurará restablecerlo
            con la máxima celeridad posible, pero no se imputará responsabilidad
            alguna a Koibanx por ser un hecho fortuito o por fuerza mayor ajena
            a su control.
            <br />
            <br />
            Se hace saber que cada Usuario será responsable en cuanto al
            cumplimiento de todas las obligaciones y cargas fiscales y/o legales
            que correspondan por sus operaciones efectuadas, sin que pueda
            imputarse a Koibanx ningún tipo de responsabilidad derivada de los
            incumplimientos en cuestión. Koibanx no se responsabiliza por el
            efectivo cumplimiento de las obligaciones fiscales o impositivas
            establecidas por la ley vigente y/o cualquier otra obligación que
            surja por el uso del Servicio por parte de los Usuarios.
            <br />
            <br />
            Koibanx se reserva el derecho a modificar los presentes Términos y
            Condiciones en cualquier momento y con efecto inmediato. El Usuario
            acepta que en caso de que se actualicen los Términos y Condiciones,
            deberá proceder a la aceptación de los mismos para poder operar
            dentro del Servicio. Por lo tanto, es responsabilidad del Usuario
            revisar periódicamente los mismos para estar informado sobre su
            versión más actualizada.
          </TermsText>

          <TermsSubText><b>20. Ausencia de intermediación financiera</b></TermsSubText>
          <TermsText>
            Koibanx informa que no está autorizada por el Banco Central de la
            República Argentina para operar como entidad financiera en los
            términos de la ley 21.526, ni realiza intermediación financiera. Los
            saldos en activos de moneda digital no constituyen depósitos en una
            entidad financiera regulada ni cuentan con ninguna de las garantías
            que tales depósitos puedan gozar de acuerdo con la legislación y
            reglamentación aplicables en materia de depósitos en entidades
            financieras.
            <br />
            <br />
            Koibanx podrá discontinuar el Servicio por cualquier motivo,
            incluyendo, sin limitación, los siguientes casos: a) ante cualquier
            cambio legal o regulatorio que afecte al Servicio; b) cualquier
            situación política, económica o de otro tipo que pudiera afectar al
            Servicio.
          </TermsText>

          <TermsSubText>
           <b> 21. Otros servicios ofrecidos en la Plataforma</b>
          </TermsSubText>

          <TermsText>
            Dentro de la Plataforma podrán ofrecerse determinados servicios que
            no se relaciona con el Servicio ofrecido por Koibanx. En tal caso,
            Koibanx no será responsable del contenido de dichos servicios.
            Además, la existencia de un vínculo entre el Servicio de Koibanx y
            cualquier plataforma de terceros de ninguna manera implica que
            Koibanx apruebe el contenido de dicha plataforma, o, asimismo,
            cualquier uso que dicho contenido pueda tener.
            <br />
            <br />
            Se hace saber al Usuario que la Plataforma denominada n1u level no
            es de titularidad de Koibanx y que Koibanx únicamente se limita a
            ofrecer el Servicio en virtud del permiso otorgado para que sea
            provisto en la Plataforma. De este modo, Koibanx no será responsable
            por los demás servicios ofrecidos o contenido exhibido en la
            Plataforma que no se relacione con el Servicio.
          </TermsText>

          <TermsSubText>
           <b>22. Interacción con personal de Koibanx o n1u level</b> 
          </TermsSubText>

          <TermsText>
            El Usuario reconoce que, cualquier interacción que mantenga con un
            empleado de Koibanx o n1u level será llevada a cabo a los fines de
            brindar el soporte adecuado para la correcta utilización del
            servicio.
            <br />
            <br />
            Resulta totalmente prohibido para el Usuario divulgar cualquier
            comunicación, sea por e-mail, llamada telefónica, whatsapp,
            telegram, o cualquier medio afín, en la que se brinde soporte por
            parte de un empleado de Koibanx o n1u level. El nombre y apellido,
            e-mail, teléfono, dirección o cualquier otro dato personal de un
            empleado de Koibanx reviste carácter confidencial y su divulgación
            por cualquier medio queda terminantemente prohibida, siendo su
            incumplimiento causal de cierre de cuenta, sin perjuicio de ejercer
            las acciones legales que se estimen conveniente y su divulgación por
            cualquier medio queda terminantemente prohibida, siendo su
            incumplimiento causal de cierre de cuenta, sin perjuicio de ejercer
            las acciones legales que se estimen convenientes, por los daños y
            perjuicios que pudieran generarse. Asimismo, el personal de Koibanx
            sólo se contactará a través de los canales oficiales publicados en
            la página web y nunca le pedirá al Usuario que divulgue sus
            contraseñas.
          </TermsText>

          <TermsSubText><b>23. Propiedad intelectual</b></TermsSubText>

          <TermsText>
            Koibanx, la titular de la Plataforma y/o sus sociedades vinculadas
            son titulares de todos los derechos de propiedad intelectual
            relacionados al Servicio, incluyendo, pero no limitándose a su
            contenido, productos, marcas, logos, frases publicitarias, imágenes,
            derechos de autor, dominios, códigos de software, patentes, modelos
            de utilidad, diseños, entre otras (en adelante, “Propiedad
            Intelectual”).
            <br />
            <br />
            El uso del Servicio por parte del Usuario no le concede ningún tipo
            de autorización para el uso de la Propiedad Intelectual de Koibanx.
            Se encuentra prohibido su uso indebido, sin autorización y/o
            contrario a la normativa vigente. Koibanx no permitirá el uso
            denigratorio de su Propiedad Intelectual en cualquier medio,
            incluyendo redes sociales.
          </TermsText>

          <TermsSubText><b>24. Revocación del Servicio</b></TermsSubText>

          <TermsText>
            El Usuario tendrá derecho a revocar la aceptación a los presentes
            TyC dentro del plazo de 10 (diez) días hábiles contados desde el día
            siguiente a la fecha de la aceptación, en la medida que no haya
            utilizado el Servicio, sin necesidad de indicar las razones y sin
            penalidad alguna. Si ejerciere este derecho, el Usuario deberá
            notificar su decisión mediante correo electrónico a
            soporte@koibanx.com
          </TermsText>

          <TermsSubText>
           <b> 25. Ley aplicable y resolución de conflictos</b>
          </TermsSubText>
          <TermsText>
            Los presentes Términos y Condiciones se regirán por las leyes de la
            República de El Salvador, sin perjuicio de aquellas normas de orden
            público que resulten aplicables.
            <br />
            <br />
            Koibanx y el Usuario acuerdan de manera irrevocable someter
            cualquier disputa o reclamo derivado del uso del Servicio, o de la
            aplicación, interpretación, validez y/o alcance de los presentes TyC
            a la jurisdicción no exclusiva de los tribunales de San Salvador,
            República de El Salvador, dejando a salvo cualquier derecho del
            consumidor a iniciar reclamos contra Koibanx en la jurisdicción que
            resulte competente y bajo la ley aplicable de acuerdo con su
            domicilio.
          </TermsText>

          <TermsSubText><b>26. Notificaciones</b></TermsSubText>
          <TermsText>
            Se entenderá que a todos los efectos que pudieran corresponder, el
            Usuario fija domicilio en el correo electrónico informado a los
            fines de su registración en el Servicio y se fija el domicilio de
            Koibanx en 101 Av Norte # 558 Col Escalón, San Salvador, El
            Salvador; soporte@koibanx.com, donde serán válidas las
            notificaciones que se remitan entre las Partes con relación al
            vínculo existente entre Usuario y responsable del Servicio.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Digital;
