import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ios from "../assets/home/homecover/IOS buttom (1).png";
import googleplay from "../assets/home/homecover/Google play buttom (1).png";
import phones from "../assets/benefits/card-phone.png";

function DownloadCardPhone() {
  return (
    <>
      <BottomContainer>
        <div>
          <div className="image">
            <img src={phones} alt="phones" />
          </div>
          <Content>
            <div className="text-container">
              <h2>
                <b>Descargá </b>ahora
                <br /> y <b> creá tu cuenta</b>
              </h2>
            </div>
            <div className="links-container">
              <Link
                to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                target="_blank"
              >
                <img src={ios} alt="ios" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" className="second-link"/>
              </Link>
            </div>
          </Content>
        </div>
      </BottomContainer>
    </>
  );
}

const BottomContainer = styled.div`
  background: linear-gradient(-45deg, #5c0a33, black, #5c0a33, black);
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1000px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  div {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  .image {
    height: auto;
    width: 70%;
    display: flex;
    justify-content: flex-end;
    margin-top: -130px;

    @media only screen and (max-width: 1000px) {
      width: auto;
      align-items: center;
      margin-top: 0px;
    }

    img {
      height: 480px;
      width: 550px;
      @media only screen and (max-width: 1000px) {
        height: 300px;
        width: auto;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  width: 30%;

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    color: #ffffff;
    font-weight: 300;

    b {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }
  }

  .links-container {
    width: 400px;
    display: flex;
    flex-direction: row;
    margin-top: -50px;
  }

  .second-link{
    margin-left: 15px;
  }

  img {
    height: 50px;
    width: 170px;
    margin-top: -30px;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column !important;
    height: 210px !important;
    width: 100% !important;
    h2 {
      font-size: 30px;
    }

    .links-container {
      width: auto;
      justify-content: space-around;
      height: 100px;
    }

    img {
      height: 40px;
      width: 130px;
    }
  }

  @media only screen and (max-width: 340px) {
    h2 {
      font-size: 27px;
    }
  }
`;

export default DownloadCardPhone;
