import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function FinancialUser() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Atención usuarios de servicios financieros</TermsTitle>
          <TermsText>
            <b>Características:</b>
            <br />
            <br />
            ‍ n1u level ofrece el servicio de app digital con procesamiento
            electrónico de pagos (el “Servicio”) que permite al Usuario realizar
            pagos en forma presencial o a distancia con la tarjeta recargable,
            transferencias, recargas, entre otras que Blockchain Payment Rails
            S.A. habilita. Para ello, es necesario el uso de la aplicación móvil
            n1u (la “App”). ‍ <br />
            <br />
            Ponemos a tu disposición los canales donde podrás efectuar
            consultas, reclamos y/o quejas:
            <br />
            <br />
            • <b>Vía “Nota”</b> presentándola en Ciudad de la Paz 3252 (C.A.B.A.-
            C1429AOD) ‍ <br />
            <br />
            • <b>En nuestra web en:</b><a href="/"><span> https://www.n1u.app</span></a>
            <br />
            <br />
            • <b>Vía correo electrónico a:</b><a href="mailto:ayuda@n1u.app"> <span>ayuda@n1u.app</span></a>
            <br />
            <br />
            <b>Ante la falta de respuesta en los plazos estipulados o
            disconformidad en la resolución podrás contactarte con los
            Responsables de Atención a Usuarios de Servicios Financieros
            (Comunicación “A” 7146 del B.C.R.A):</b> Alberto Germán Czernikowski
            (Titular), legales@n1u.app, Ciudad de la Paz 3252 – CABA, horario de
            atención: de lunes a viernes de 9 a 18 hs. Excepto feriados. El
            plazo máximo de resolución de consultas y/o reclamos es de diez (10)
            días hábiles (excepto cuando reglamentariamente se hayan establecido
            plazos mayores o cuando medien causas ajenas a la Entidad
            debidamente justificadas).En caso de incumplimiento o
            disconformidad, podrás canalizar tu reclamo a través del Banco
            Central de la República Argentina – Centro de Atención al Usuario de
            Servicios Financieros ingresando a <a href="https://www.bcra.gob.ar/BCRAyVos/Usuarios_Financieros.asp"><span>www.usuariosfinancieros.gob.ar.</span></a>
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default FinancialUser;
