import React from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import Players from "../n1uverse/Players";
// import Store from "../n1uverse/Store";
import N1uverseCover from "../n1uverse/N1uverseCover";
import Comunity from "../n1uverse/Comunity";
import CoverVideo from "../n1uverse/CoverVideo";

const N1uverse = () => {
  return (
    <>
      <N1uverseSection>
        <NavbarFixed />
        <CoverVideo/>
        <N1uverseCover />
        <Comunity />
        <Players />
        {/* <Store /> */}
        <Footer />
      </N1uverseSection>
    </>
  );
};

const N1uverseSection = styled.div`
  height: auto;
  width: 100%;
  background-color: black;
  margin-bottom: 0px;
  padding-bottom: 0px;
  position: relative;
  overflow: hidden;
`;


export default N1uverse;
