import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const Tabs = ({ view, menu }) => {
  const [fixed, setFixed] = useState(false);
  const [benefit, setBenefit] = useState(false);

  const seccion = useParams();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (seccion.id) {
      setBenefit(true);
    } else {
      setBenefit(false);
    }
  }, [seccion]);

  return (
    <>
      <TabsContainer view={view} menu={menu}>
        <Link to="/features" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            features
          </TabH6>
        </Link>
        <Link to="/n1uverse" className="link">
          {" "}
          <TabH6 benefit={benefit} fixed={fixed}>
            n1uverse
          </TabH6>
        </Link>
        <Link to="/store" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            store
          </TabH6>
        </Link>

        <Link to="/faqs" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            FAQs
          </TabH6>
        </Link>

        <Link to="/wiki" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            wiki
          </TabH6>
        </Link>
        <Link to="/contact" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            contacto
          </TabH6>
        </Link>

        <Link to="https://status.n1u.app/" target="_blank" className="link">
          <TabH6 benefit={benefit} fixed={fixed}>
            {" "}
            status
          </TabH6>
        </Link>
        {/* <Link
          to="https://referido.n1u.app/referidos-n1u/"
          target="_blank"
          className="link"
        >
          <TabH6 className="referidos"> referí y ganá</TabH6>
        </Link> */}
      </TabsContainer>
    </>
  );
};

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-left: 30px;
  padding-top: ${(props) => (props.menu ? "0px" : "0px")};
  padding-top: 0px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }

  .link {
    text-decoration: none;
  }

  @media only screen and (max-width: 900px) {
    justify-content: center;
  }

  .referidos {
    color: #ff009c;

    @media only screen and (max-width: 900px) {
      color: black;
    }
  }
`;

const TabH6 = styled.h6`
  color: ${(props) =>
    props.benefit ? (props.fixed ? "white" : "black") : "white"};

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    color: #ff009c;
    font-weight: 500;

    @media only screen and (max-width: 900px) {
      color: black;
    }
  }

  @media only screen and (max-width: 1050px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 900px) {
    color: #fff;
    font-size: 55px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0;
    margin: 0px 0px;
  }

  @media only screen and (max-width: 340px) {
    font-size: 50px;
  }
`;

export default Tabs;
