import React from "react";
import styled from "styled-components";
import card1 from "../assets/wiki/card1.png";
import card2 from "../assets/wiki/card2.png";
import AnimatedText from "../components/AnimatedText";
import SocialMedia from "../components/SocialMedia";
import { Link } from "react-router-dom";
const Cards = () => {


  return (
    <>
      <CardsSection>
        <CardsContainer style={{ textDecoration: "none" }}>
          <Item backgroundimage={card1}></Item>


            <Item backgroundimage={card2} >
              <Content>
              <Link to="https://linktr.ee/n1u.app" target="_blank" style={{ textDecoration: "none" }}>
                <AnimatedText>
                  <h1 style={{ cursor: "pointer" }}>¿Te quedaste con ganas de más?</h1>
                </AnimatedText>
                </Link>
                <AnimatedText>
                  <p>Seguinos en todas nuestras redes</p>
                </AnimatedText>
                <SocialMedia view="wiki" />
              </Content>
            </Item>

        </CardsContainer>
      </CardsSection>
    </>
  );
};

const CardsSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  background-color: black;
  background-color: #070707;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media only screen and (max-width: 1020px) {
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const CardsContainer = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  height: auto;
  justify-content: space-around;
  max-width: 1600px;

  @media only screen and (max-width: 1020px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Item = styled.div`
  height: 300px;
  width: 500px;
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  color: black;
  margin: 30px 0px;
  border-radius: 15px;
  cursor: auto;
  text-decoration: none !important;

  h1 {
    color: #fbfc57;
    font-family: "Roboto", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-top: -5px;
  }

  p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-top: -10px;
  }

  @media only screen and (max-width: 1140px) {
    height: 250px;
    width: 400px;
    h1 {
      font-size: 45px;
      margin-top: 0px;
    }
  }

  @media only screen and (max-width: 1020px) {
    height: 280px;
    width: 370px;
    margin: 20px 0px;
  }

  @media only screen and (max-width: 500px) {
    height: 280px;
    width: 340px;
    margin: 20px 0px;

    h1 {
      font-size: 40px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 85%;
`;

export default Cards;
