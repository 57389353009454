import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function Discord() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Términos y condiciones activación Discord</TermsTitle>

          <TermsSubText>
            1. <b>Elegibilidad y vigencia</b>
          </TermsSubText>
          <TermsText>
            Esta promoción está abierta a todos los usuarios de n1u que se
            encuentren activos dentro de la plataforma Discord, en el canal de
            n1u, su periodo de vigencia será desde el 15/09/2023 al 15/10/2023.
            <br />
            <br />
            Se elegirá al ganador por semana, que será quien sume por cada
            comentario cierta cantidad de puntos y lo ubique en el puesto número
            uno (1), se le dará una suma de n1u coins que serán acreditadas en
            su cuenta n1u.
          </TermsText>

          <TermsSubText>
            2. <b>Sistema de puntos </b>
          </TermsSubText>

          <TermsText>
            En los canales ayuda y sugerencias se otorga al usuario 5 puntos por
            mensaje, en el resto de los canales cada mensaje solo otorga 1
            punto.
            <br />
            <br />
            El otorgamiento de puntos tiene un tiempo de 10 minutos, es decir
            que si el usuario hace 100 mensajes en 9 minutos solo recibirá
            puntaje una sola vez.
          </TermsText>

          <TermsSubText>
            3. <b>Premio</b>
          </TermsSubText>
          <TermsText>
            El/ Los usuario/s elegible/s que cumpla/n con los requisitos
            recibirán un pago único de 10.000 n1u coins.
            <br />
            <br />
            Aquel usuario que haya resultado ganador, podrá volver a participar
            durante la vigencia de la presente promoción.
          </TermsText>

          <TermsSubText>
            4. <b>Acreditación del Premio</b>
          </TermsSubText>
          <TermsText>
            La acreditación del premio se realizará dentro de los 30 días
            siguientes a la finalización del período de elegibilidad, en n1u
            coins, exclusivamente en Argentina.
            <br />
            <br />
            Se aclara, que la acreditación del premio se realizará, en la cuenta
            n1u que haya abierto el usuario ganador en el momento del proceso de
            onboarding.
          </TermsText>

          <TermsSubText>
            5. <b>Requisitos</b>
          </TermsSubText>
          <TermsText>
            Los usuarios deben completar el proceso de onboarding según las
            pautas proporcionadas mediante la app.
          </TermsText>

          <TermsSubText>
            6. <b>Exclusiones</b>
          </TermsSubText>
          <TermsText>
            Los empleados y familiares directos de n1u no son elegibles para
            participar en esta promoción en Argentina.
          </TermsText>

          <TermsSubText>
            7. <b>Cambios y Terminación</b>
          </TermsSubText>
          <TermsText>
            n1u se reserva el derecho de realizar cambios en estos términos y
            condiciones o de dar por terminada la promoción en Argentina en
            cualquier momento y sin previo aviso.
          </TermsText>

          <TermsSubText>
            8. <b>Leyes aplicables y jurisdicción</b>
          </TermsSubText>
          <TermsText>
            Esta promoción está sujeta a todas las leyes y regulaciones
            aplicables en Argentina.
            <br />
            <br />
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>
            9. <b>Aceptación de Términos</b>
          </TermsSubText>
          <TermsText>
            La participación en esta promoción implica la aceptación de todos
            los términos y condiciones establecidos aquí.
          </TermsText>

          <TermsSubText>
            10. <b>Cumplimiento legal</b>
          </TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Discord;
