import React from "react";
import styled from "styled-components";

const Widget = () => {
  return (
    <>
      <script
        type="text/javascript"
        src="//sgwidget.leaderapps.co/js/sendgrid-widget.js"
      ></script>
      <WidgetContainer>
        <WidgetForm
          id="sg-widget"
          data-token="e2c37163dc95b4351b82ab699ae28b29"
          onSubmit={() => false}
        >
          <div className="sg-response" id="sg-response"></div>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Ingresá tu mail"
            required
            autoComplete="off"
          />
          <input type="submit" id="sg-submit-btn" value="suscribirme" />
        </WidgetForm>
      </WidgetContainer>
    </>
  );
};

const WidgetContainer = styled.div`
  background-color: transparent;
  display: flex;
  height: 110px;
  @media only screen and (max-width: 500px) {
    padding-top: 15px;
  }
`;

const WidgetForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 500px) {
    width: 250px;
  }

  #email {
    width: 100%;
    background-color: black !important;
    border: none;
    border-bottom: 1px solid #ffffff;
    height: 40px;
    color: white;
    outline: none;
    font-size: 15px;

    @media only screen and (max-width: 500px) {
      font-size: 13px;
    }

    &:focus,
    &:active,
    &:not(:placeholder-shown) {
      background-color: black;
    }
  }

  #sg-submit-btn {
    background-color: black;
    border: 1px solid #ffffff;
    border-radius: 20px;
    height: 35px;
    width: 120px;
    color: #ffffff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin-top: 5px;
    cursor: pointer;

    @media only screen and (max-width: 500px) {
      height: 30px;
      width: 105px;
      font-size: 13px;
      margin-top: 0px;
    }
  }
`;

export default Widget;
