import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import lines from "../assets/home/homecover/lines-button.svg";

const Route404 = () => {
  return (
    <>
      <Route404Section>
        <Route404Container>

          <h2>This page doesn’t seem to exist</h2>

          <ButtonContainer>
            <Lines src={lines} alt="lines" />
            <Link to="/">

            <div className="button">
              <p>back to home</p>
            </div>
            </Link>
          </ButtonContainer>
        </Route404Container>
      </Route404Section>
    </>
  );
};

const Route404Section = styled.div`
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
`;

const Route404Container = styled.div`
  height: 100vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    color: #e8e9ee;
    font-family: "LoRes";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
  }

  h1 {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 50px;

  }

  .button {
    background-color: #ff009c;
    color: white;
    border-radius: 30px;
    display: flex;
    height: 50px;
    width: 260px;
    justify-content: center;

    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      margin-top: 12px;
      letter-spacing: 0.5px;
    }

    @media only screen and (max-width: 520px) {
      width: 250px;
      height: 45px;

      p {
        margin-top: 10px;
      }
    }
}
`;


const ButtonContainer = styled.div`
  height: 100px;
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;


  @media only screen and (max-width: 520px) {
    height: 100px;
    width: 200px;
  }
`;

const Lines = styled.img`
  position: absolute;
  z-index: 1;
`;

export default Route404;
