import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../../styles/texts";

function CyberWeek() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>LEGALES CYBER WEEK</TermsTitle>
          <TermsText>
            <b>Reintegro por compras</b>
            <br />
            <br />
            REINTEGRO APLICABLE PARA TODOS LOS CLIENTES N1U QUE NO HAYAN
            UTILIZADO PREVIAMENTE SU TARJETA PREPAGA VISA FÍSICA O VIRTUAL. LA
            PRESENTE PROMOCIÓN TENDRÁ VIGENCIA DESDE EL 06/11/2023 AL
            08/11/2023, DENTRO DEL TERRITORIO ARGENTINO.
            <br />
            <br />
            EL REINTEGRO SERÁ DEL 10% SOBRE LA PRIMERA COMPRA QUE REALICE
            UTILIZANDO SU TARJETA PREPAGA VISA FÍSICA O VIRTUAL, CON TOPE DE
            REINTEGRO DE HASTA $3000.-(ARS). EN CASO DE QUE LA PRIMERA COMPRA LA
            REALICE EL CLIENTE ADICIONAL EL TITULAR NO PODRÁ REALIZAR UNA NUEVA
            COMPRA EN LA QUE SE APLIQUE EL REINTEGRO YA QUE APLICA SOBRE LA CVU
            Y NO SOBRE LA TARJETA PREPAGA.
            <br />
            <br />
            EL REINTEGRO SE REALIZARÁ DENTRO DE LOS 10 DÍAS HÁBILES POSTERIORES
            A LA COMPRA.
            <br />
            <br />
            EN EL CASO DE LOS REINTEGROS POR LAS COMPRAS REALIZADAS EN DÓLARES
            NORTEAMERICANOS, N1U SOLO REALIZARÁ EL REINTEGRO SOBRE EL VALOR DE
            LA COMPRA Y NO SOBRE LOS IMPUESTOS APLICABLES QUE EL CLIENTE DEBA
            SOPORTAR POR DICHA COMPRA. EN EL CASO DE REVERSOS DE CONSUMOS Y/U
            OPERACIONES, CONTRACARGOS Y DEVOLUCIONES NO CORRESPONDERÁ EL
            REINTEGRO.
            <br />
            <br />
            N1U NO SE RESPONSABILIZA POR CUALQUIER PERJUICIO QUE PUDIERA SUFRIR
            EL CLIENTE DERIVADO DE LAS COMPRAS QUE REALICE DONDE UTILICE LA
            TARJETA PREPAGA VISA FÍSICA O VIRTUAL. EL PROVEEDOR ES EL ÚNICO Y
            EXCLUSIVO RESPONSABLE POR LA PRESTACIÓN, FUNCIONAMIENTO O GARANTÍA Y
            ENVÍO DEL PRODUCTO/SERVICIO.
            <br />
            <br />
            LA CUENTA DEL USUARIO DEBE ESTAR HABILITADA PARA OPERAR. N1U NO
            EFECTUARÁ CANJES/AJUSTES/REINTEGROS EN AQUELLOS CASOS EN LOS QUE
            EXISTAN SOSPECHAS DE ILEGALIDAD-FRAUDE.
            <br />
            <br />
            PARA MÁS INFORMACIÓN CONTACTARSE CON AYUDA@N1U.APP
            <br />
            <br />
            BLOCKCHAIN PAYMENTS RAILS S.A ES UNA SOCIEDAD ANÓNIMA BAJO LA LEY
            ARGENTINA LOS SOCIOS LIMITAN SU RESPONSABILIDAD A LA INTEGRACIÓN DE
            LAS ACCIONES SUSCRIPTAS. (LEY 25738).
            <br />
            <br />
            N1U SE ENCUENTRA LIMITADA A OFRECER SERVICIOS DE PAGO Y NO SE
            ENCUENTRA AUTORIZADA A OPERAR COMO ENTIDAD FINANCIERA POR EL BCRA.
            <br />
            <br />
            LOS FONDOS DEPOSITADOS EN LAS CUENTAS DE PAGO OFRECIDAS POR N1U, NO
            CONSTITUYEN DEPÓSITOS EN UNA ENTIDAD FINANCIERA, NI CUENTAN CON
            NINGUNA DE LAS GARANTÍAS QUE TALES DEPÓSITOS PUEDAN GOZAR DE ACUERDO
            CON LA LEGISLACIÓN Y REGLAMENTACIÓN APLICABLES EN MATERIA DE
            DEPÓSITOS EN ENTIDADES FINANCIERAS.
            <br />
            <br />
            <b>Reintegro categoría “Gaming”</b>
            <br />
            <br />
            REINTEGRO APLICABLE PARA TODOS LOS CLIENTES N1U QUE REALICEN COMPRAS
            DENTRO DEL STORE DE N1U. LA PRESENTE PROMOCIÓN TENDRÁ VIGENCIA DESDE
            EL 06/11/2023 AL 08/11/2023, DENTRO DEL TERRITORIO ARGENTINO.
            <br />
            <br />
            EL REINTEGRO SERÁ DEL 20% SOBRE LA PRIMERA COMPRA QUE SE REALICE
            SOBRE CADA PRODUCTO QUE SE ENCUENTRE DENTRO DEL STORE DE N1U DENTRO
            DE LA CATEGORÍA “GAMING” EN EL CASO DE REVERSOS DE CONSUMOS Y/U
            OPERACIONES, CONTRACARGOS Y DEVOLUCIONES NO CORRESPONDERÁ EL
            REINTEGRO.
            <br />
            <br />
            EN EL CASO DE QUE EL CLIENTE REALICE LA COMPRA CON N1U COINS O
            REALICE LA COMPRA CON PESOS, RECIBIRÁ EL 20% DE REINTEGRO SOBRE LA
            MONEDA QUE UTILIZÓ. EL REINTEGRO SE REALIZARÁ DENTRO DE LOS 10 DÍAS
            HÁBILES POSTERIORES A LA COMPRA.
            <br />
            <br />
            LA CUENTA DEL USUARIO DEBE ESTAR HABILITADA PARA OPERAR. N1U NO
            EFECTUARÁ CANJES/AJUSTES/REINTEGROS EN AQUELLOS CASOS EN LOS QUE
            EXISTAN SOSPECHAS DE ILEGALIDAD-FRAUDE. EL PROVEEDOR ES EL ÚNICO Y
            EXCLUSIVO RESPONSABLE POR LA PRESTACIÓN, FUNCIONAMIENTO O GARANTÍA Y
            ENVÍO DEL PRODUCTO/SERVICIO.
            <br />
            <br />
            <br />
            <br />
            PARA MÁS INFORMACIÓN CONTACTARSE CON AYUDA@N1U.APP
            <br />
            <br />
            BLOCKCHAIN PAYMENTS RAILS S.A ES UNA SOCIEDAD ANÓNIMA BAJO LA LEY
            ARGENTINA LOS SOCIOS LIMITAN SU RESPONSABILIDAD A LA INTEGRACIÓN DE
            LAS ACCIONES SUSCRIPTAS. (LEY 25738).
            <br />
            <br />
            N1U SE ENCUENTRA LIMITADA A OFRECER SERVICIOS DE PAGO Y NO SE
            ENCUENTRA AUTORIZADA A OPERAR COMO ENTIDAD FINANCIERA POR EL BCRA.
            <br />
            <br />
            LOS FONDOS DEPOSITADOS EN LAS CUENTAS DE PAGO OFRECIDAS POR N1U, NO
            CONSTITUYEN DEPÓSITOS EN UNA ENTIDAD FINANCIERA, NI CUENTAN CON
            NINGUNA DE LAS GARANTÍAS QUE TALES DEPÓSITOS PUEDAN GOZAR DE ACUERDO
            CON LA LEGISLACIÓN Y REGLAMENTACIÓN APLICABLES EN MATERIA DE
            DEPÓSITOS EN ENTIDADES FINANCIERAS.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default CyberWeek;
