import React from "react";
import styled from "styled-components";
import lines from "../assets/home/homecover/lines-button.svg";
import AnimatedText from "../components/AnimatedText";
import discord from "../assets/wiki/discord-icon.png";
import { Link } from "react-router-dom";

const Cover = () => {
  return (
    <>
      <CoverSection>
        <CoverContainer>
          <Top>
            <h1>
              unite a <br />
              nuestro
              <br /> clan
            </h1>
            <img src={discord} alt="discord" />
            <p>
              fomentamos la unión y la creación de una comunidad autosustentable
              donde todos los n1ubers participan en charlas, encuestas y
              sugerencias para mejorar la app y experiencia de cada uno de los
              usuarios en la app. Tu opinión nos importa. Queremos escucharte,
              unite. ¡Tenemos premios todas las semanas!
            </p>
          </Top>
          <MidleText>
            <p>
              fomentamos la unión y la creación de una comunidad autosustentable
              donde todos los n1ubers participan en charlas, encuestas y
              sugerencias para mejorar la app y experiencia de cada uno de los
              usuarios en la app. Tu opinión nos importa. Queremos escucharte,
              unite. ¡Tenemos premios todas las semanas!
            </p>
          </MidleText>
          <Bottom>
            <AnimatedText>
              <h2>
                ¡Entrá a nuestro server de discord <br />y unite a la comunidad
                de miles!
              </h2>
            </AnimatedText>

            <ButtonContainer>
              <Lines src={lines} alt="lines" />
              <Link to="https://discord.com/invite/ggFTQRNBGb" target="_blank">
              <div className="button">
                <AnimatedText>
                  <p>press start to continue</p>
                </AnimatedText>
              </div>
              </Link>
            </ButtonContainer>

          </Bottom>
        </CoverContainer>
      </CoverSection>
    </>
  );
};

const CoverSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: black;
  @media only screen and (max-width: 1350px) {
    height: auto;
  }
`;

const CoverContainer = styled.div`
  width: 90%;
  height: 700px;
  max-width: 1600px;
  @media only screen and (max-width: 850px) {
    width: 90%;
    margin-left: 0px;
    height: 550px;
    padding-top: 30px;
  }
`;

const Top = styled.div`
  height: 60%;
  width: 100%;
  position: relative;
  max-width: 1600px;
  max-height: 80vh;
  margin-top: 90px;
  max-width: 1100px;
  max-height: 500px;
  @media only screen and (max-width: 1200px) {
    max-height: 350px;
    margin-top: 0px;
  }

  @media only screen and (max-width: 850px) {
    max-height: 250px;
    height: 30%;

  }

  @media only screen and (max-width: 400px) {
    max-height: 180px;

 
  }

  h1 {
    color: #fff;
    font-size: 160px;
    font-family: "LoRes";
    font-style: normal;
    font-weight: 400;
    line-height: 83%;
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    z-index: 200;

    @media only screen and (max-width: 1200px) {
      font-size: 110px;
    }

    @media only screen and (max-width: 920px) {
      font-size: 90px;
    }

    @media only screen and (max-width: 675px) {
      font-size: 70px;
    }

    @media only screen and (max-width: 525px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 55px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 40px;
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 400px;
    margin-top: -40px;

    @media only screen and (max-width: 1200px) {
      height: 300px;
      margin-top: 0px;
    }

    @media only screen and (max-width: 675px) {
      height: 200px;

    }
  }

  p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    width: 650px;
    position: absolute;
    bottom: 0;
    right: 0;
    @media only screen and (max-width: 1350px) {
      display: none;
    }
  }
`;

const Bottom = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;

  h2 {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 85%;
    width: 100%;
  }

  @media only screen and (max-width: 1350px) {
    height: auto;
  }

  @media only screen and (max-width: 1140px) {
    h2 {
      font-size: 30px;
      line-height: 90%;
    }
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    height: 230px;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10px 0px 0px 0px;
  }

  @media only screen and (max-width: 630px) {
    height: 200px;
    h2 {
      font-size: 20px;
      line-height: 90%;
    }
  }
`;

const MidleText = styled.div`
  display: none;
  @media only screen and (max-width: 1350px) {
    display: flex;
  }

  p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    width: 650px;

    @media only screen and (max-width: 850px) {
      font-size: 16px;
  }
}
`;

const ButtonContainer = styled.div`
  height: 70px;
  width: 220px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    background-color: #ff009c;
    color: white;
    border-radius: 30px;
    display: flex;
    height: 50px;
    width: 220px;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      margin-top: 16px;
      letter-spacing: 0.5px;
    }
  }

  @media only screen and (max-width: 850px) {
    align-self: center;
  }

  @media only screen and (max-width: 520px) {
    height: 100px;
    width: 200px;

  }
`;

const Lines = styled.img`
  position: absolute;
  z-index: 1;
  height: 120px;
`;

export default Cover;
