import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function PrivacyPolicies() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Politicas de Privacidad</TermsTitle>

          <TermsText>
            Este documento (en adelante, las “Políticas de Privacidad”) describe
            los tipos de datos de carácter personal que Blockchain Payments
            Rails S.A., titular y desarrollador de n1u level, (en adelante,
            “n1u”) recaba a través del sitio web <a href="/"><span>https://www.n1u.app/</span></a> y su
            aplicación móvil, cómo usamos sus datos, a quién se los comunicamos
            y los derechos y opciones que ponemos a su disposición en lo que
            respecta al uso que hacemos de sus datos personales y al ejercicio
            de los derechos de los titulares de los datos. También describimos
            las medidas implementadas para proteger la seguridad de los datos
            personales recabados y la forma en que los usuarios pueden
            contactarse con nosotros para informarse sobre nuestras prácticas en
            materia de privacidad. La entidad responsable del tratamiento de los
            datos personales es Blockchain Payments Rails S.A., con domicilio
            social en Ciudad de la Paz 3252, de la Ciudad Autónoma de Buenos
            Aires. Ud. consiente en forma expresa, informada, voluntaria e
            inequívoca la inclusión de todos sus datos personales facilitados a
            través de n1u en las bases de datos propiedad y responsabilidad de
            n1u, así como al tratamiento de todos sus datos personales para los
            fines descriptos en los siguientes apartados de este documento y con
            sujeción a los términos y condiciones establecidos en el mismo.
            Asimismo, al utilizar n1u, Ud. acepta y presta su consentimiento
            libre, expreso e informado con los términos de las presente
            Políticas de Privacidad. En consecuencia, Ud. debe leer estas
            Políticas de Privacidad con atención. AL ACCEDER, CONECTARSE O DE
            CUALQUIER OTRA FORMA UTILIZAR N1U, UD. CONFIRMA QUE HA LEÍDO,
            ENTENDIDO, CONCUERDA Y ACEPTA LOS TÉRMINOS Y CONDICIONES DE ESTAS
            POLÍTICAS DE PRIVACIDAD. SI UD. NO ESTÁ DE ACUERDO CON LOS TÉRMINOS
            DE ESTA POLÍTICA, UD. NO DEBE UTILIZAR N1U.
          </TermsText>
          <TermsTitle>DATOS RECOLECTADOS</TermsTitle>

          <TermsText>
            A través de n1u recabamos determinados datos, incluidos datos de
            carácter personal. La información de carácter personal es
            información que puede identificarlo a Ud. Para n1u, su privacidad y
            la seguridad de su información son muy importantes. Por ello, solo
            le solicitaremos los datos estrictamente necesarios para que Ud.
            pueda utilizar los servicios que n1u le ofrece. A tal fin, n1u podrá
            solicitar y tratar los datos que a continuación se enumeran, sin que
            la siguiente sea una lista taxativa: Datos de contacto (tales como
            nombre y apellido, domicilios, teléfono, correo electrónico); DNI,
            CUIT o CUIL; Datos demográficos (tales como nacionalidad; fecha y
            lugar de nacimiento, edad, sexo); una fotografía de tipo “selfie”;
            información de redes sociales por Ud. provista; condición de persona
            expuesta políticamente; declaración sobre el origen y licitud de
            fondos; e información que el usuario nos facilita en relación a sus
            consultas o comentarios. Recopilamos dichos datos al momento de su
            registro, en cada actualización y también recopilamos información
            personal acerca de su persona cuando Ud. nos brinda información
            directamente a nosotros o cuando interactúa con nosotros a través de
            n1u. En caso de usuario menores de edad, además exigimos la
            autorización de un usuario mayor de edad y la aclaración del vínculo
            entre ambos
            <br />
            <br />
            En lo que respecta a sus datos personales contenidos en su Documento
            Nacional de Identidad los que incluyen sus datos biométricos de
            huella dactilar y de reconocimiento facial y los que deriven de la
            fotografía selfie que nos envíe, consiente que sean confrontados con
            lo que informa el web service del REGISTRO NACIONAL DE LAS PERSONAS
            (“RENAPER”) así como sean objeto de tratamiento por otros
            proveedores de servicios de acreditación de identidad. Este
            especifico tratamiento se efectúa con la finalidad exclusiva de
            validar la identidad y vigencia del Documento Nacional de Identidad.
            Adicionalmente, se le hace saber que los datos referidos en este
            párrafo son de carácter obligatorio para asegurar el correcto
            proceso de identificación y que en su calidad de titular de los
            datos podrá ejercer los derechos de acceso, rectificación y
            supresión de sus datos en cualquier momento y a su sola solicitud
            ante el RENAPER.
            <br />
            <br />
            Además, n1u podrá recolectar información sobre los dispositivos en
            los que se encuentra instalada la aplicación, tales como aquellos
            relativos a la operadora móvil, marca, modelo y sistema operativo
            del dispositivo, geolocalización y podrá monitorear el tráfico,
            visualizaciones, interacciones y cualquier otra actividad en la
            aplicación (estos datos podrían ser recolectados a través del uso de
            cookies, web beacons, pixels, canvas fingerprinting y/o similares).
            Usted será el único responsable por la veracidad, exactitud,
            integridad, vigencia, autenticidad y certeza de la información
            provista, debiendo notificar a n1u de cualquier actualización o
            modificación que corresponda en forma inmediata. Sin perjuicio de lo
            anterior, n1u podrá verificar los datos personales y/o información
            suministrada – por si o a través de terceros- recurriendo a
            entidades públicas, compañías especializadas o centrales de riesgo,
            entre otros, lo que usted consiente expresamente.
            <br />
            <br />
            En el supuesto de no proporcionar los datos personales que resulten
            obligatorios para el registro y utilización de los servicios,
            detectarse falsedad y/o inexactitud en los datos personales
            proporcionados, n1u podrá suspender y/o cancelar al usuario y/o
            inhabilitar total o parcialmente su acceso a la aplicación de n1u
            y/o sus servicios. Lo anterior, no obstará a que n1u cumpla en
            reportar tales falsedades y/o inexactitudes a terceros y/o
            autoridades competentes.
          </TermsText>

          <TermsTitle>
            FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES
          </TermsTitle>

          <TermsText>
            Los datos que recolectamos pueden ser utilizados para los siguientes
            fines: prestar los servicios de n1u y de otros terceros que ofrecen
            servicios en la aplicación de n1u; permitirle el acceso a la
            aplicación y sus funcionalidades; comunicarnos con Ud.; enviarle
            materiales promocionales y otras comunicaciones; responder a sus
            consultas; personalizar las preferencias y visitas a n1u de nuestros
            usuarios y ofrecer contenidos adaptados a sus intereses y a la forma
            en que navegan e interactúan con n1u; operar, evaluar y mejorar
            nuestros productos y servicios; recabar la documentación
            respaldatoria y demás datos que justifiquen cualquier tipo de
            operación o transacción; y cumplir, prevenir actividades ilícitas
            como lavado de dinero, evasión de impuestos y fraudes y hacer
            cumplir los requisitos legales aplicables, las normas
            correspondientes del sector, las obligaciones contractuales y
            nuestras políticas. Para el caso de que vayamos a usar sus datos
            personales de forma distinta a la descrita anteriormente, se lo
            comunicaremos de forma específica en el momento de recolectarlos.
            Asimismo, a través de n1u, podemos además usar servicios analíticos
            de terceros. Los proveedores de servicios de análisis de webs que
            administran dichos servicios utilizan tecnologías tales como
            cookies, registros de servidores de red y balizas web que nos ayudan
            a analizar el uso que los usuarios hacen de n1u. La información
            recogida por estos medios (incluida la dirección IP) puede ponerse
            en conocimiento de los proveedores de tales servicios analíticos,
            así como de otros terceros que vayan a hacer un uso de la
            información recabada para, entre otros fines, evaluar el uso de n1u.
            <br />
            <br />
            Por último, n1u puede contratar soluciones de análisis masivos de
            datos – conocidas como “analytic solutions”- provistas por terceros,
            que podría procesar datos recolectados a través de la aplicación o
            sitio de n1u tales como respecto de las pantallas visitadas,
            patrones de interacción (como acciones de pantalla, gestos: clicks,
            scrolling, etc.) y detalles del dispositivo que se utiliza para
            acceder a la aplicación o sitio de n1u (tipo, versión, modelo,
            sistema operativo). Esta información es usada para mejorar nuestra
            aplicación. Este tipo de soluciones no procesa ni recolecta datos de
            carácter personal que permitan identificar los movimientos
            individuales de cada usuario y no hace seguimiento de sus hábitos de
            navegación en las aplicaciones por lo que su procesamiento no estará
            alcanzado por la Ley Protección de Datos Personales No 25.326.
          </TermsText>

          <TermsTitle>INFORMACIÓN QUE COMPARTIMOS</TermsTitle>

          <TermsText>
            Los datos personales que recabamos a través de n1u, podrán revelarse
            a terceros únicamente de acuerdo con lo descrito en las presentes
            Políticas de Privacidad. Los datos personales recolectados a través
            de n1u podrán ser comunicados para el cumplimiento de los fines
            anteriormente descritos a nuestras filiales y entidades asociadas,
            sucursales, agencias, representaciones, controlantes, subsidiarias,
            controladas, vinculadas y cualquier persona humana o jurídica que
            pudiera estar relacionada con n1u y/o con la que n1u tenga un
            vínculo comercial para prestarle servicios como es el caso de
            nuestros socios estratégicos en relación a ciertas productos que le
            ofrecemos. También comunicaremos dichos datos personales a
            proveedores de servicios que presten servicios en nuestro nombre de
            conformidad con nuestras instrucciones y con las finalidades
            detalladas en estas Políticas de Privacidad, como es el caso de
            nuestros proveedores de servicio de hosting, almacenamiento en la
            nube, call-center y otros. En ningún caso autorizamos a dichos
            proveedores de servicios a revelar o divulgar sus datos personales
            salvo para aquellos usos que sean estrictamente necesarios para la
            prestación de los servicios en nuestro nombre, o para dar
            cumplimiento a obligaciones legales. N1u comprende la importancia de
            mantener la confidencialidad de todos los datos que pudieran ser
            recolectados y respeta la Ley de Protección de Datos Personales N°
            25.326 aplicables a éstos. Así, se hace saber que n1u y quienes
            intervengan en cualquier fase del tratamiento de datos personales
            están obligados al secreto profesional respecto de los mismos y que
            n1u suscribe convenios de confidencialidad e implementa políticas y
            procesos para garantizar el secreto de los datos personales que
            trata.
            <br />
            <br />
            Asimismo, podremos revelar sus datos personales (i) si estamos
            obligados a hacerlo por la normativa aplicable (incluyendo pero no
            limitándose al Banco Central de la República Argentina (“BCRA”), la
            Unidad de la Información Financiera (“UIF”), la Agencia Federal de
            Ingresos Públicos (AFIP) la Administración Nacional de la Seguridad
            Social (ANSES), entre otros); (ii) en el marco de un proceso
            judicial o en cumplimiento de un requerimiento de autoridad
            competente, (iii) a las fuerzas y cuerpos de seguridad o a otros
            funcionarios públicos cuando lo soliciten legítimamente, (iv) cuando
            consideremos que dicha revelación es necesaria o conveniente para
            evitar daños físicos o pérdidas económicas, o (v) en el marco de una
            investigación de actividades fraudulentas o ilegales, o que se
            presuman fraudulentas o ilegales.
            <br />
            <br />
            Por último, Ud. nos autoriza a transferir sus datos personales en
            caso de venta o transmisión de la totalidad o de una parte de
            nuestra empresa o de nuestros activos (incluyendo los casos de
            reestructuración, disolución o liquidación). Respecto de la
            información que pudiera ser provista a los fines de la Central de
            Deudores que lleva el BCRA, se hace saber que dentro de los 10 días
            corridos del pedido, se le deberá comunicar la última clasificación
            que se le ha asignado, junto con los fundamentos que la justifican
            según la evaluación realizada por la entidad, el importe total de
            deudas con el sistema financiero y las clasificaciones asignadas que
            surjan de la última información disponible en la “Central de
            deudores del sistema financiero”. Podrá informarse sobre sus
            derechos de acceso y conocimiento de la información crediticia
            registrada sobre su persona en la Central de Deudores del BCRA
            disponible en:
           <a href="https://www.bcra.gob.ar/bcrayvos/Situacion_Crediticia.asp"> <span>https://www.bcra.gob.ar/bcrayvos/<br/>Situacion_Crediticia.asp</span></a>
          </TermsText>

          <TermsTitle>TRANSFERENCIAS DE DATOS</TermsTitle>

          <TermsText>
            Usted entiende y consiente que sus datos personales recabados a
            través de n1u pueden ser transferidos para los fines anteriormente
            detallados y a los sujetos antes listados, pudiendo implicar
            transferencias internacionales de datos a jurisdicciones que pueden
            no tener la misma normativa de protección de datos que la que existe
            en Argentina y/o que no proporcionen niveles de protección adecuados
            bajo la normativa local. Cuando transfiramos sus datos personales a
            otros países, los protegeremos en la forma descrita en este
            documento y en conformidad con la legislación aplicable. En
            consecuencia, le hacemos saber que siempre que lo requiere la
            regulación aplicable hemos implementado salvaguardas que garantizan
            un nivel adecuado de protección de los datos en los términos de la
            ley y de las disposiciones de la Agencia de Acceso a la Información
            Pública.
            <br />
            <br />
            KOIBANX EL SALVADOR S.A. de C.V. (en adelante, “Koibanx”) ofrecerá
            acceso a los usuarios de n1u al servicio de compra y venta de Activo
            de moneda digital a través de la Plataforma. Dicho servicio será
            administrado por Koibanx. Para ello, el Usuario deberá aceptar tanto
            los Términos y Condiciones como las Políticas de Privacidad
            correspondientes.
            <br />
            <br />
            Se hace saber que, para la prestación de este servicio, n1u
            compartirá datos personales recolectados de Usuarios a Koibanx. Esto
            será realizado en virtud de la relación comercial existente entre
            ambas empresas, mediante la cual n1u permite a Koibanx ofrecer el
            Servicio en su Plataforma. Asimismo, se hace saber al Usuario que
            ambas empresas han suscrito un acuerdo de transferencia
            internacional de datos que concierne la mencionada operatoria, de
            acuerdo con lo indicado por la legislación vigente.
            <br />
            <br />A LOS FINES DE HACER USO DEL SERVICIO PROVISTO POR KOIBANX EN
            LA PLATAFORMA N1U, EL USUARIO DEBERÁ DAR SU CONSENTIMIENTO LIBRE,
            EXPRESO E INFORMADO A TRAVÉS DEL CORRESPONDIENTE CLICK EN EL
            CHECKBOX QUE INDICA “ACEPTO COMPARTIR MIS DATOS”.
          </TermsText>

          <TermsTitle>SEGURIDAD DE SUS DATOS PERSONALES</TermsTitle>

          <TermsText>
            Los datos recabados se mantendrán en estricta confidencialidad y
            reserva. N1u solo utilizará dichos datos en la medida que su
            relación con n1u se encuentre vigente y su utilización sea
            estrictamente necesaria para el cumplimiento de los fines detallados
            anteriormente. N1u cumple con toda la normativa de protección de
            datos personales y en particular, con la Ley N° 25.326 y sus normas
            complementarias (en adelante, la “Normativa de Protección de Datos
            Personales”). En tal sentido, todos sus datos serán almacenados en
            una base de datos de titularidad de n1u que se encuentra inscripta
            en el Registro Nacional de Protección de Datos Personales. Para
            garantizar la seguridad de los datos personales que Ud. proporcione
            a N1U, se aplicarán los mismos criterios y el mismo grado de
            diligencia que n1u aplica para resguardar su propia información. En
            particular, mantenemos medidas de seguridad administrativas,
            técnicas y físicas diseñadas para proteger los datos personales que
            Ud. nos proporciona frente a su destrucción, pérdida, alteración,
            acceso, comunicación o uso accidental, ilegal o no autorizado. Así,
            para prevenir el acceso no autorizado, mantener la precisión de los
            datos y asegurar el uso correcto de los datos de carácter personal
            que Ud. nos proporciona, n1u ha puesto en uso ciertos medios
            físicos, electrónicos, administrativos y procedimientos de seguridad
            para resguardar y asegurar sus datos de carácter personal que
            recopilamos en línea. Nosotros resguardamos sus datos de carácter
            personal de acuerdo a estándares y procedimientos de seguridad
            establecidos y continuamente evaluamos nueva tecnología para
            proteger esa información. N1u garantiza que los procesos internos
            propios de las bases de datos cumplen con las obligaciones legales
            de seguridad y confidencialidad impuestas por la Normativa de
            Protección de Datos Personales. Sin embargo, Ud. reconoce que los
            medios técnicos existentes que brindan seguridad no son
            inexpugnables, y que aun cuando se adopten todos los recaudos
            razonables de seguridad es posible sufrir manipulaciones,
            destrucción y/o pérdida de información. Finalmente, si Ud. cree que
            su interacción con nosotros ya no es segura (por ejemplo, si cree
            que la seguridad de una cuenta que Ud. tiene con nosotros se ha
            puesto en riesgo), notifíquenos inmediatamente del problema usando
            cualquiera de nuestras vías de comunicación.
          </TermsText>

          <TermsTitle>LOS DERECHOS Y ELECCIONES DEL USUARIO</TermsTitle>

          <TermsText>
            N1u presume la veracidad de toda la información que sea suministrada
            por Ud. En consecuencia, n1u no verifica, ni asume la obligación de
            verificar la veracidad, vigencia, suficiencia y autenticidad de los
            datos que Ud. proporcione. Ud. asume la responsabilidad de mantener
            dicha información actualizada en todo momento. Ud. podrá acceder,
            modificar, rectificar, eliminar y actualizar sus datos personales en
            el momento que desee, contactándose con nosotros. Ud. podrá acceder
            en forma gratuita a la información que suministró a n1u, a
            intervalos no menores a seis meses, salvo que acredite un interés
            legítimo al efecto, conforme lo previsto en la Normativa de
            Protección de Datos Personales. Si los datos son incorrectos, desea
            actualizarlos y/o rectificarlos y/o suprimirlos, nosotros
            actualizaremos, corregiremos y/o suprimiremos esa información a su
            requerimiento sin costo alguno. Para ejercer los derechos de acceso,
            rectificación, actualización o supresión, conforme lo previsto en la
            Normativa de Protección de Datos Personales, deberá enviar un correo
            electrónico a: <a href="mailto:ayuda@n1u.app"><span>ayuda@n1u.app</span></a> indicando qué derecho quiere ejercer y
            cumpliendo el proceso de acreditación de identidad que n1u le
            indicará a tal efecto. La información suministrada por Ud. será
            almacenada mientras la relación con n1u esté vigente, y por el plazo
            de 10 (diez) años posteriores y/o por el plazo adicional que
            requiera la legislación aplicable para fines contables y/o legales.
            La Agencia de Acceso a la Información Pública, órgano de control de
            la Ley N° 25.326, tiene la atribución de atender las denuncias y
            reclamos que se interpongan con relación al incumplimiento de las
            normas sobre protección de datos personales.
          </TermsText>

          <TermsTitle>ENLACES A OTROS SITIOS WEB</TermsTitle>

          <TermsText>
            N1u puede contener enlaces a otros sitios web para su conveniencia e
            información. Esos sitios web pueden estar operados por compañías que
            no estén vinculadas a nosotros. Habitualmente, los sitios web
            enlazados tienen sus propias políticas o avisos de privacidad, los
            cuales recomendamos que Ud. lea si visita cualquiera de tales sitios
            web enlazados. No asumimos ninguna responsabilidad sobre el
            contenido de los sitios web distintos al nuestro, así como tampoco
            respecto del uso que se lleve a cabo de tales sitios web, ni de las
            prácticas de privacidad de los mismos.
          </TermsText>

          <TermsTitle>
            ACTUALIZACIONES DE ESTAS POLÍTICAS DE PRIVACIDAD
          </TermsTitle>

          <TermsText>
            N1u se reserva el derecho de efectuar en cualquier momento
            modificaciones o actualizaciones a estas Políticas de Privacidad sin
            previo aviso, para reflejar los cambios introducidos en nuestras
            prácticas en materia de datos personales, conforme lo exija la
            Normativa de Protección de Datos Personales. Cuando se trate de
            cambios sustanciales, n1u podrá modificar en cualquier momento las
            Políticas de Privacidad notificando previamente los cambios al
            Usuario vía mail y/o notificación push en la App y publicando una
            versión actualizada de dichas Políticas de Privacidad en el sitio
            web www.n1u.app con expresión de la fecha de la última modificación.
            Toda modificación de estas cláusulas de Políticas de Privacidad
            entrará en vigor en la fecha indicada en la notificación. Dentro de
            los 10 (diez) días corridos siguientes a la notificación de las
            modificaciones introducidas, el Usuario podrá comunicar por e-mail
            si no acepta las mismas; en ese caso quedará disuelto el vínculo
            contractual. Vencido dicho plazo, se considerará que el Usuario
            acepta los nuevos Términos de Políticas de Privacidad y el contrato
            continuará vinculando a ambas partes.
            <br />
            <br />
            Desde el momento de su publicación a través de n1u su interacción
            con nosotros después de la introducción de estas modificaciones
            significara que Ud. acepta dichas modificaciones.
          </TermsText>

          <TermsTitle>INFORMACIÓN DE CONTACTO</TermsTitle>

          <TermsText>
            En caso de que Ud. quisiera ponerse en contacto con nosotros a fin
            de actualizar sus datos o ejercer los derechos que le corresponden,
            o tuviera alguna consulta con relación al alcance de esta política
            y/o alguna inquietud respecto de la privacidad y/o seguridad de la
            información brindada a n1u, podrá comunicarla directamente a través
            de la dirección de correo electrónico <a href="mailto:ayuda@n1u.app"><span>ayuda@n1u.app</span></a>
            <br />
            <br />
            Estas Políticas de Privacidad fueron actualizadas por última vez el
            09.03.2023
            <br />
            <br />
            Domicilio legal de Blockchain Payments Rails S.A, Ciudad de La Paz
            3252, Ciudad de Buenos Aires, Argentina.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default PrivacyPolicies;
