import React, { useState } from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import { Heading1 } from "../styles/texts";
import AccordionItem from "../components/AccordionItem";
import questions1 from "../jsons/questions1.json";
import questions2 from "../jsons/questions2.json";
import FormatedText from "../components/FormatedText";

function Faqs() {
  const [active, setActive] = useState("");

  const handleToggle = (id) => {
    setActive((prevActive) => (prevActive === id ? null : id));
  };
  return (
    <>
      <QuestionsSection>
        <NavbarFixed />

        <Cover>
     
          <div>
            <Heading1 style={{ marginBottom: "0px" }}>FAQs</Heading1>
            <h2>
              ¿Tenés dudas? <br />{" "}
              <b className="bold">Consultá nuestras preguntas frecuentes</b>{" "}
            </h2>
            <p>
              Hablá con soporte en{" "}
              <a href="mailto:ayuda@n1u.app">ayuda@n1u.app</a>
            </p>
          </div>
        </Cover>
        <QuestionsContainer>
          <Box>
            <h2>Sobre n1u</h2>
            <p>
              n1u es una super app que llegó para solucionar todas tus finanzas.
              <br />
              <br />
              Accedé a una tarjeta VISA internacional prepaga para comprar en
              cualquier comercio y sitio web del mundo. Además, podés enviar y
              recibir, dinero a/de otros usuarios de n1u, cuentas bancarias
              (CBU) y cuentas virtuales (CVU) gratis e instantáneamente. Podés
              extraer dinero de los cajeros de las redes habilitadas (Red Link,
              Banelco o si estás fuera de Argentina VISA Plus).
              <br />
              <br />
              También comprar y vender activos digitales, comprar juegos,
              recargar y pagar servicios, celular, transporte y SUBE.
              <br />
              <br />
              La app te ayuda a proteger tus gastos, ya que recibís una
              notificación en el momento que realices un consumo y seguir todos
              tus movimientos cuando y desde donde quieras.
            </p>

            {questions1.map((question, index) => (
              <AccordionItem
                key={index}
                active={active}
                handleToggle={() => handleToggle(question.id)}
                id={question.id}
                header={question.title}
                content={question.content}
              />
            ))}

            <h2>Usuario y Cuenta</h2>

            {questions2.map((question, index) => (
              <AccordionItem
                key={index}
                active={active}
                handleToggle={() => handleToggle(question.id)}
                id={question.id}
                header={question.title}
                // content={question.content}

                content={<FormatedText data={question.content} />}
              />
            ))}
          </Box>
        </QuestionsContainer>

        <Footer />
      </QuestionsSection>
    </>
  );
}

const QuestionsSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  background-color: white;
`;

const Cover = styled.div`
  height: 100vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/backgrounds/background-cover-faqs.png");
  background-size: cover;
  background-position: center;

  @media only screen and (min-width: 2000px) {
    height: auto;
    padding: 180px 0px;
  }


  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 0px;
    line-height: 100%;

    @media only screen and (max-width: 880px) {
      max-width: 500px;
    }
    @media only screen and (max-width: 570px) {
      font-size: 25px;
      max-width: 320px;
    }
  }

  b {
    font-weight: 700;
  }

  p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media only screen and (max-width: 570px) {
      font-size: 15px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;


const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background-color: white;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 1600px;
  z-index: 100 !important;

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
`;

const Box = styled.div`
  background-color: white;
  height: auto;
  margin: 80px 0px;
  width: 100%;

  h2 {
    padding-bottom: 20px;
    font-family: "LoRes";
    color: #ff009c;
    font-size: 28.125px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px;

    @media only screen and (max-width: 800px) {
      font-size: 28.125px;
      font-style: normal;
      font-weight: 400;
      line-height: 37.5px;
    }
  }

  p {
    padding-bottom: 30px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.25px;
    @media only screen and (max-width: 800px) {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;

export default Faqs;
