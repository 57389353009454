import React from "react";
import styled from "styled-components";
import SocialMedia from "../components/SocialMedia";
import orange from "../assets/footer/orange-box-min.png";
import { Link } from "react-router-dom";
import Widget from "../components/Widget";

const Footer = ({ view }) => {
  return (
    <FooterWrapper view={view}>
      <FooterContainer>
        <MiddleColumn />

        <Column>
          <div className="social-desktop">
            <SocialMedia view="footer" />
          </div>
          <div className="widget-desktop">
            <Widget />
          </div>
        </Column>

        <Column>
          <div className="button-column">
            <div className="widget-mobile">
              <Widget />
            </div>
            <div className="social-mobile">
              <SocialMedia view="footer" />
            </div>

            <img className="orange-button" src={orange} alt="button" />
          </div>
        </Column>
      </FooterContainer>
    </FooterWrapper>
  );
};

const MiddleColumn = () => {
  return (
    <>
      <MiddleColumnContainer>
        <Column>
          <Link
            to="https://help.n1u.app/hc/es-419/sections/23080687792013"
            className="link"
          >
            <p>TÉRMINOS & CONDICIONES</p>
          </Link>

          <Link
            to="https://help.n1u.app/hc/es-419/sections/24155347265037"
            className="link"
          >
            <p>POLÍTICAS DE PRIVACIDAD</p>
          </Link>

          <Link
            to="https://help.n1u.app/hc/es-419/sections/21758271322253-Seguridad-Cuidados-y-Privacidad"
            className="link"
          >
            <p>SEGURIDAD, CUIDADO Y PRIVACIDAD</p>
          </Link>
        </Column>
        <Column>
          <Link
            to="https://help.n1u.app/hc/es-419/articles/24182227021453-Defensa-del-Consumidor"
            className="link"
          >
            <p>DEFENSA AL CONSUMIDOR</p>
          </Link>

          <Link
            to="https://help.n1u.app/hc/es-419/articles/24141118850317-Costos-y-comisiones"
            className="link"
          >
            <p>COSTOS & COMISIONES</p>
          </Link>

          <Link
            to="https://help.n1u.app/hc/es-419/categories/24249119665933-FAQs"
            className="link"
          >
            <p>FAQs</p>
          </Link>
        </Column>
      </MiddleColumnContainer>
    </>
  );
};

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.view === "home" ? "100vh" : "445px")};
  /* z-index: 1000 ; */
  overflow: hidden;



  @media only screen and (max-width: 1000px) {
    height: auto;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
  }

  .link {
    text-decoration: none;
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 60px;
  height: auto;
  justify-content: space-between;
  max-width: 1600px;

  @media only screen and (max-width: 1000px) {
    padding: 10px;
    width: 90%;
  }

  .orange-button {
    padding-top: 0px;
    height: 80px;
    width: 200px;
    margin-left: 80px;
    @media only screen and (max-width: 1000px) {
      margin-left: 0px;
      padding-top: 10px;
    }

  }



  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  p {
    text-align: left;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.625px;

    @media only screen and (max-width: 1000px) {
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
    }
  }

  button {
    background-color: orange;
    height: 60px;
    border: none;
    margin-top: 20px;
    border-radius: 5px;
    width: 90%;
  }


`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;

  .social-desktop {
    width: 200px;

    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .social-mobile {
    width: 200px;
    display: none;
    @media only screen and (max-width: 1000px) {
      display: block;
    }
  }

  .widget-desktop {
    display: block;

    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .widget-mobile {
    display: none;

    @media only screen and (max-width: 1000px) {
      display: block !important;
      padding-top: 10px;
    }
  }

  p {
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 1000px) {
    width: 300px;
    padding: 0px;
    text-align: left;
    padding-top: 0px;
    p {
      padding: 5px 0px;
    }
  }



`;

const MiddleColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  > ${Column} {
    margin-right: 10px;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      order: initial;
    }
    > ${Column} {
      margin-right: 0px;
    }
  }
`;

export default Footer;
