import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../../styles/texts";

function Booking() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Términos y Condiciones Booking</TermsTitle>
          <TermsText>
            Reintegro de hasta 4% en tu reserva en Booking. Válido en Argentina,
            del 1/10/2023 al 31/12/2023 para reservas realizadas a través de la
            página web de Booking.com
          <a href="https://sp.booking.com/index.en.html?aid=2372325&auth_success=1">  <span>
                https://sp.booking.com/gating/authkey?aid=2372325&key=3arwJz0RfO</span></a> y
            abonada con tarjeta prepaga Visa n1u. Se bonifica un 4% sobre el
            precio total abonado. Para que el descuento sea aplicable la reserva
            deberá ser realizada por medio de la plataforma de Booking, tildando
            previamente el recuadro “Quiero recibir mi reintegro de
            regalo&quot;. El reintegro se verá reflejado en la billetera de
            Booking del usuario dentro de los 70 días posteriores al check out
            de la propiedad reservada. n1u no se responsabiliza por las reservas
            realizadas fuera del plazo de vigencia. n1u es sólo un medio de pago
            y no se responsabiliza por las reservas realizadas a través de
            Booking.com como tampoco por el servicio ofrecido por Booking.com o
            terceros. Condiciones aplicables a los beneficios. No es acumulable
            con otra promoción. La cuenta del usuario debe estar habilitada para
            operar. n1u no efectuará canjes/ajustes en aquellos casos en los que
            existan sospechas de ilegalidad-fraude. El proveedor es el único y
            exclusivo responsable por la prestación, funcionamiento o garantía y
            envío del producto/servicio.
            Establecimientos/productos/servicios/tecnologías no son
            promocionados/garantizados por Blockchain Payments Rails. El canje
            no admite cambio/devolución de n1u coins. No válido para transacción
            a través de terminales como Mercado Pago, APP YPF, viüMi u otras y
            para pago electrónico inmediato. Más información en forma previa a
            realizar la operación en ayuda@n1u.app . Aplicación n1u level de
            Blockchain Payments Rails S.A. cuit 30717506673 Ciudad de la Paz
            3252 P 3 Dpto C-CABA, se limita a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el Banco
            Central de la República Argentina. Los fondos depositados en cuentas
            de pago no constituyen depósitos en una entidad financiera, ni
            cuentan con ninguna de las garantías que tales depósitos puedan
            gozar de acuerdo con la legislación y reglamentación aplicables en
            materia de depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Booking;
