import styled from "styled-components";

export const Heading1 = styled.h1`
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: center;
  font-size: 218.75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 40px;

  @media only screen and (max-width: 880px) {
    font-size: 190px;
    line-height: 220px;
  }

  @media only screen and (max-width: 570px) {
    font-size: 120px;
    line-height: 130px;
  }

  @media only screen and (max-width: 440px) {
    font-size: 75px;
  }
`;

export const Heading2 = styled.h2`
  margin-top: -170px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 47.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  b {
    font-weight: 900;
  }

  @media only screen and (max-width: 845px) {
    font-size: 35px;
    line-height: 75px;
  }

  @media only screen and (max-width: 570px) {
    max-width: 170px;
    line-height: 20px;
    font-size: 20px;
    margin-top: 0px;
  }
`;

export const Heading3 = styled(Heading1)`
  font-size: 20px;
  line-height: 32px;
`;

export const LargeText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
`;
export const StandardText = styled(LargeText)`
  font-size: 16px;
  line-height: 22px;
`;

export const SmallText = styled(LargeText)`
  font-size: 14px;
  line-height: 20px;
`;

export const Button = styled.button`
  background-color: #ff009c;
  width: auto;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  width: 260px;
  height: 50.625px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px;
  }
`;

export const CardTitle = styled.h2`
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.75px;
  word-break: break-word;

  @media only screen and (max-width: 400px) {
    font-size: 18px;
  }
`;

export const CardText = styled.h2`
  text-decoration: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.337px;

  margin-top: -10px;

  @media only screen and (max-width: 400px) {
    font-size: 12px;
  }
`;
export const CardLink = styled.p`
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  right: 10%;
  bottom: 0%;

  @media only screen and (max-width: 400px) {
    font-size: 10px;
    bottom: 5%;
  }

  /* 
  @media only screen and (max-width: 850px) {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.02em;
    padding: 6px 23px;
  } */
`;

export const Container = styled.div`
  margin: auto;
  max-width: 1140px;
  margin: auto;

  @media only screen and (min-width: 701px) and (max-width: 1200px) {
    max-width: 90%;
  }
  @media only screen and (min-width: 1900px) {
    margin: auto;
  }
  @media only screen and (max-width: 700px) {
    margin: 0 24px;
    padding: 24px 0;
  }
`;

export const TermsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: black;

  .link {
    text-decoration: none;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 55%;
  height: auto;
  max-width: 1600px;
  padding-top: 150px;
  padding-bottom: 150px;
  overflow: hidden;
  word-wrap: break-word !important;
  overflow-wrap: break-word;


  @media only screen and (max-width: 1000px) {
    width: 75%;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #333;
    color: white;
    color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-size:20px;
  @media only screen and (max-width: 600px) {
font-size:12px;
  }
  }

  /* Estilo para las celdas de encabezado */
  th {
    /* background-color: #333; */
    color: white;
    text-align: left;
    padding: 10px;
  }

  /* Estilo para las celdas de datos */
  td {
    border: 1px solid #333;
    padding: 10px;
  }

  /* Estilo para las filas impares */
  tr:nth-child(odd) {
    /* background-color: #f2f2f2; */
  }
`;

export const TermsTitle = styled.h1`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsSubTitle = styled.h6`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsSubSubTitle = styled.h6`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 40px;
  margin-top: 20px;

  span {
    font-size: 15px;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsText = styled.p`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  letter-spacing: 0.1px;
  word-wrap: break-word;

  a {
    text-decoration: none;
  }

  span:hover {
    color: #fff680 !important;
  }

  b {
    font-weight: 500px;
    word-wrap: break-word;
  }
  span {
    color: #ff009c;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsSubText = styled.p`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-left: 50px;

  a {
    text-decoration: none;
  }

  span:hover {
    color: #fff680 !important;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 10px;
  }
  b {
    font-weight: 500px;
  }
  span {
    color: #ff009c;
    word-wrap: break-word;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;
export const TermsSubSubText = styled.p`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-left: 80px;
  @media only screen and (max-width: 800px) {
    margin-left: 30px;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsSubSubSubText = styled.p`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-left: 100px;
  @media only screen and (max-width: 800px) {
    margin-left: 40px;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsSubSubSubSubText = styled.p`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-left: 120px;
  @media only screen and (max-width: 800px) {
    margin-left: 50px;
  }

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

export const TermsQuestions = styled.h1`
  color: #e8e9ee;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding: 0;
  margin: 0;
  margin-top: 40px;
  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;
