import React from "react";
import styled from "styled-components";
import piggybank from "../assets/home/homepixel/nopixel/piggy-bank-min.png";
import card from "../assets/home/homepixel/nopixel/card-pixel-min.png";
import coin from "../assets/home/homepixel/nopixel/coin-pixel-min.png";
import coins from "../assets/home/homepixel/nopixel/coins-pixel-min.png";
import { animated } from "react-spring";

const Pixel = ({ style }) => {
  return (
    <>
      <PixelSection>
        <>
          <PixelContainer>
            <Item>
              <img src={card} alt="card" />

              <animated.p style={style}>
                Usá tu n1u card física o virtual Visa Internacional sin costo.
              </animated.p>
            </Item>
            <Item>
              <img src={piggybank} alt="piggybank" />

              <animated.p style={style}>
                Abrí tu cuenta gratis y sin intermediarios.
              </animated.p>
            </Item>

            <Item>
              <img src={coin} alt="coin" />

              <animated.p style={style}>
                Manejá tu platita cómo y dónde quieras.
              </animated.p>
            </Item>
            <Item>
              <img src={coins} alt="coins" />

              <animated.p style={style}>
                Pagá con n1u y obtené recompensas.
              </animated.p>
            </Item>
          </PixelContainer>
        </>
      </PixelSection>
    </>
  );
};

const PixelSection = styled.div`
  height: 20vh;
  width: 100%;
  background: #070707;
  position: absolute;
  bottom: 0%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PixelContainer = styled.div`
  height: 20vh;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1600px;


`;

const Item = styled.div`
  height: auto;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 100px;
    width: 100px;
  }

  p {
    font-size: 13px;
    margin: 10px 0;
    font-family: "LoRes";
    letter-spacing: 0.02em;
    font-weight: 400;
    max-width: 150px;
    color: white;
    padding-left: 15px;
    padding-top: 5px;
  }

  @media only screen and (max-width: 1145px) {
    width: 220px;
    padding: 10px 0px;
    justify-content: center;

    img {
      height: 80px;
      width: 80px;
    }

    p {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 1000px) {
    p {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 915px) {
    width: 170px;
    padding: 5px 0px;

    img {
      height: 80px;
      width: 80px;
    }

    p {
      font-size: 10px;
    }
  }
`;

export default Pixel;
