import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../../styles/texts";

function Samsung() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Legales promoción Samsung 10% de descuento</TermsTitle>
          <TermsText>
            Promoción válida dentro del territorio Argentino, desde el 15/9/2023
            al 30/06/2024 abonando con tarjeta prepaga Visa n1u física o
            virtual. Descuento de un 10% para la compra de celulares de Serie A
            y auriculares inalámbricos en Tienda Samsung <a href="https://shop.samsung.com/ar/"><span>shop.samsung.com/ar</span></a>.
            <br />
            <br />
            Promoción que podrá acumularse con otras promociones vigentes de la
            Tienda Samsung.
            <br />
            <br />
            n1u no se responsabiliza por cualquier perjuicio que pudiera sufrir
            el cliente derivado de las compras que realice en Tienda Samsung
            donde utilice la tarjeta prepaga Visa física o virtual.
            <br />
            <br />
            La cuenta del usuario debe estar habilitada para operar. n1u no
            efectuará canjes/ajustes en aquellos casos en los que existan
            sospechas de ilegalidad-fraude. El proveedor es el único y exclusivo
            responsable por la prestación, funcionamiento o garantía y envío del
            producto/servicio.
            <br />
            <br />
            Productos/servicios/tecnologías no son promocionados/garantizados
            por Blockchain Payments Rails. El canje no admite cambio/devolución
            de n1ucoins. Más información en forma previa a realizar la operación
            en ayuda@n1u.app .
            <br />
            <br />
            Aplicación n1u level de Blockchain Payments Rails S.A. CUIT
            30717506673 Ciudad de la Paz 3252 P 3 Dpto C-CABA, se limita a
            ofrecer servicios de pago y no se encuentra autorizada a operar como
            entidad financiera por el Banco Central de la República Argentina.
            Los fondos depositados en cuentas de pago no constituyen depósitos
            en una entidad financiera, ni cuentan con ninguna de las garantías
            que tales depósitos puedan gozar de acuerdo con la legislación y
            reglamentación aplicables en materia de depósitos en entidades
            financieras.
            <br />
            <br />
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Samsung;
