import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import styled from "styled-components";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function CuentaRemunerada() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            TÉRMINOS Y CONDICIONES APERTURA CUENTA COMITENTE
          </TermsTitle>
          <TermsText>
            Estos términos y condiciones (en adelante, los “Términos y
            Condiciones”) constituyen un contrato entre usted (en adelante, el
            “Usuario” ) y Blockchain Payments Rails S.A (CUIT Nro.
            30-71750667-3) sociedad inscripta en la Inspección General de
            Justicia de la Capital Federal con fecha 06 de abril de 2022 bajo el
            número 1975078 del Libro 1 de Sociedades Anónimas con domicilio en
            Ciudad de la Paz 3252, Piso 3, Dpto C, de la Ciudad Autónoma de
            Buenos Aires, República Argentina (en adelante, “BPR” y, junto con
            el Usuario, las “Partes” o en forma individual como la “Parte”), con
            el objeto de que el Usuario pueda instruir para invertir sus saldos
            disponibles en su cuenta de pago de n1u (la “Cuenta n1u”) en
            cuotapartes del Fondo Común de Inversión Delta Pesos (las
            “Cuotapartes” y el “Fondo”, respectivamente), que es administrado
            por Delta Asset Management S.A. CUIT Nro 33-70917291-9 con domicilio
            en Maipú 1210 Piso 6°, Ciudad Autónoma de Buenos Aires (en adelante,
            “Delta”) en su carácter de sociedad gerente (en adelante, “Sociedad
            Gerente”) inscripta ante la Comisión Nacional de Valores (“CNV”) y
            cuya custodia de activos está a cargo de Banco de Valores S.A. en su
            carácter de sociedad depositaria (en adelante, “Sociedad
            Depositaria” y/o “Banco de Valores”, indistintamente) inscripta ante
            la CNV (el “Servicio”).
            <br />
            <br />
            Al aceptar estos Términos y Condiciones, el Usuario acepta
            expresamente las condiciones previstas en el reglamento de gestión
            del Fondo (en adelante, “Reglamento de Gestión de Fondo”) el que se
            encuentra disponible en{" "}
            <a href="https://deltaam.com.ar/fondos_de_inversion/delta-pesos">
              {" "}
              <span>
                https://deltaam.com.ar/fondos_de_inversion/delta-pesos
              </span>
            </a>
            . El Usuario acepta expresamente – y sin reservas - las condiciones
            previstas en el Reglamento de Gestión del Fondo. El Usuario acepta
            expresamente – y sin reservas - que la documentación e información
            relacionada con los Términos y Condiciones será publicada
            exclusivamente mediante soporte electrónico. Los presentes Términos
            y Condiciones se componen además por los Términos y condiciones de
            uso de la APP n1u publicados en el sitio WEB{" "}
            <a href="https://n1u.app/">
              <span>https://n1u.app/</span>
            </a>{" "}
            y cuyo contenido el Usuario manifiesta haber leído y aceptado
            expresamente. Los Términos y Condiciones entrarán en plena vigencia
            para cada Usuario a partir de su aceptación por el Usuario, y se
            mantendrán vigentes, incluyendo sus modificaciones, hasta que el
            Usuario no tenga el carácter de tal.
            <br />
            <br />
            1. El Servicio.
            <br />
            <br />
            1.1. Sólo podrán acceder al Servicio aquellas personas que cumplan
            los requisitos establecidos en estos Términos y Condiciones. No
            podrán registrarse ni acceder al Servicio los menores de edad, las
            personas que no tengan capacidad para contratar y/o aquellos
            Usuarios que hayan sido suspendidos y/o inhabilitados por n1u,
            temporal o definitivamente.
            <br />
            <br />
            1.2. Se deja constancia que n1u “SE LIMITA A OFRECER SERVICIOS DE
            PAGO Y NO SE ENCUENTRA AUTORIZADA A OPERAR COMO ENTIDAD FINANCIERA
            POR EL BANCO CENTRAL DE LA REPÚBLICA ARGENTINA. LOS FONDOS
            DEPOSITADOS EN CUENTAS DE PAGO NO CONSTITUYEN DEPÓSITOS EN UNA
            ENTIDAD FINANCIERA, NI CUENTAN CON NINGUNA DE LAS GARANTÍAS QUE
            TALES DEPÓSITOS PUEDAN GOZAR DE ACUERDO CON LA LEGISLACIÓN Y
            REGLAMENTACIÓN APLICABLE EN MATERIA DE DEPÓSITOS DE ENTIDADES
            FINANCIERAS” (Comunicación “A” 6885 y sig. del BCRA).
            <br />
            <br />
            1.3. El Usuario entiende y acepta que la información relevante
            vinculada con el Servicio a través del Fondo estará disponible en
            todo momento en{" "}
            <a href="https://deltaam.com.ar/nuestros-fondos/">
              <span>https://deltaam.com.ar/nuestros-fondos/</span>
            </a>
            . Allí se publicarán los honorarios y gastos actualizados imputables
            al Fondo. Salvo que allí se indique lo contrario, Delta cobrará un
            honorario de administración del 3,5% anual y Banco de Valores
            cobrará un honorario de 0,10 % anual, ambos honorarios calculados
            sobre el patrimonio del Fondo y, en ambos casos más IVA de ser
            aplicable, que se devengarán diariamente y se percibirán de manera
            mensual. Asimismo, existen gastos ordinarios de gestión que serán
            deducibles del Fondo. No existen comisiones de suscripción ni de
            rescate. Delta no percibirá honorarios del Usuario por su actuación
            como colocador del Fondo.
            <br />
            <br />
            1.4. El Servicio se presta a través de una modalidad de colocación
            de Cuotapartes del Fondo (APP n1u) que cumple con lo dispuesto por
            las NORMAS (N.T. 2013 y mod,) de la CNV (las “Normas de la CNV”), y
            las actividades de Delta y Banco de Valores se encuentran
            comprendidas dentro de las autorizaciones otorgadas por la CNV.
            <br />
            <br />
            1.5. Las inversiones en Cuotapartes del Fondo no constituyen
            depósitos en Banco de Valores, a los fines de la Ley de Entidades
            Financieras ni cuentan con ninguna de las garantías que tales
            depósitos a la vista o a plazo puedan gozar de acuerdo con la
            legislación y reglamentación aplicables en materia de depósitos en
            entidades financieras. Asimismo, Banco de Valores se encuentra
            impedida por normas del Banco Central de la República Argentina
            (“BCRA”) de asumir, tácita o expresamente, compromiso alguno en
            cuanto al mantenimiento, en cualquier momento, del valor del capital
            invertido, al rendimiento, al valor de rescate de las Cuotapartes o
            al otorgamiento de liquidez a tal fin. Rendimientos pasados no
            garantizan rendimientos futuros. Las inversiones están sujetas a
            riesgos que pueden incluir la pérdida de capital.
            <br />
            <br />
            1.6. n1u presta sus servicios de procesamiento de pagos y billetera
            digital acorde a las instrucciones que sean impartidas por el
            Usuario y por Delta conforme a los Términos y Condiciones, a los
            fines de permitir la suscripción y rescate de Cuotapartes.
            <br />
            <br />
            2. Declaraciones
            <br />
            <br />
            2.1. Es condición esencial para el uso del Servicio el compromiso
            del Usuario de utilizarlo en forma lícita y de acuerdo con estos
            Términos y Condiciones, y la aceptación de la obligación de proveer
            información que le sea solicitada en cumplimiento de la normativa
            aplicable.
            <br />
            <br />
            2.2. Ni n1u, ni Delta ni Banco de Valores serán responsables de los
            daños o perjuicios que el Usuario pudiera sufrir derivados del
            acceso, uso y/o mala utilización de los contenidos del Servicio.
            <br />
            <br />
            2.3. Ni n1u, ni Delta ni Banco de Valores serán responsables si por
            cualquier causa fuera imposible acceder al Servicio, se desconectara
            el mismo, o por los accesos no exitosos a cualquiera de los vínculos
            incluidos en el Servicio.
            <br />
            <br />
            2.4. Queda prohibida la copia, duplicación, redistribución,
            comercialización o cualquier otra actividad que se pueda realizar
            con los contenidos del Servicio.
            <br />
            <br />
            2.5. Queda expresamente determinado que el Usuario deberá ajustarse
            íntegramente a la totalidad de los términos y condiciones que se
            establezcan en la APP n1u, incluyendo todo lo dispuesto en relación
            a las condiciones de uso disponibles en{" "}
            <a href="https://n1u.app/">
              <span>https://n1u.app/</span>
            </a>{" "}
            en relación al procesamiento de pagos que n1u realice a fin de que
            el Usuario y/o representante legal suscriba y/o rescate Cuotapartes
            del Fondo.
            <br />
            <br />
            2.6. Los Usuarios entienden y aceptan que, en caso de revestir el
            carácter de personas humanas responsables inscriptas o personas
            humanas monotributistas y decidan invertir sus saldos disponibles de
            la Cuenta APP n1u en Cuotapartes del Fondo, deberán con carácter
            previo a la apertura de la cuenta comitente cumplir con el Censo
            Nacional Económico (CNE 2020-2021), de conformidad con lo dispuesto
            por el Resolución 180/2020 del Instituto Nacional de estadística y
            Censos (INDEC) y la Comunicación “B” 121100/2020 del BCRA. El
            presente requisito comenzará a regir de conformidad con el siguiente
            cronograma: (i) desde el 1° de abril de 2021 para las personas
            jurídicas; (ii) 1° de mayo de 2021 para las personas humanas
            responsables inscriptas; y (iii) 1° de julio de 2021 para las
            personas humanas monotributistas. Los Usuarios reconocen y aceptan
            que, ante requerimiento de Banco de Valores, deberán poner a
            disposición el certificado de cumplimiento censal correspondiente.
            <br />
            <br />
            2.7. Delta y Banco de Valores (i) podrán solicitar a n1u en
            cualquier momento, los legajos de información y/o documentación del
            Usuario a fin de dar cumplimiento a obligaciones regulatorias de
            conocimiento del Usuario de conformidad con la normativa aplicable,
            para lo cual el Usuario presta su consentimiento a fin de que n1u
            provea dicha información y/o documentación; (ii) informarán a n1u de
            manera inmediata en caso de recibir cualquier notificación o
            requerimiento que pudiera afectar las inversiones del Usuario,
            incluyendo embargos, reclamos y otras medidas adoptadas por una
            autoridad competente.
            <br />
            <br />
            2.8. El Usuario podrá (i) realizar operaciones de suscripción y
            rescate de Cuotapartes, (ii) consultar su valuación, sus movimientos
            de cuenta en su Cuenta APP n1u, y el detalle de operaciones diarias
            vigentes a la fecha de la consulta en el Servicio, y en el periodo
            que se estipule oportunamente, así como otros datos conforme se
            implementen a futuro.
            <br />
            <br />
            2.9. El Usuario utilizará para el ingreso a su Cuenta APP n1u el
            correo electrónico que haya utilizado para registrarse en la APP n1u
            de acuerdo a todos los requisitos de acceso establecidos por n1u.
            n1u, Delta y Banco de Valores no son responsables por errores,
            omisiones, atrasos o inconvenientes en el procesamiento de las
            operaciones de suscripción y rescate de Cuotapartes generados por la
            transmisión incorrecta de datos o informaciones, o por una avería o
            falla en los servicios y sistemas de transmisión de datos, como
            tampoco serán responsables por falta de servicios de transmisión de
            datos y/o servicios de conexión a Internet. En consecuencia, n1u,
            Delta y Banco de Valores, sus directores, funcionarios, personal, y
            agentes no son responsables por las pérdidas acaecidas cuando sean
            causadas directa o indirectamente por estas causales.
            <br />
            <br />
            2.10. La responsabilidad por la administración y gestión del Fondo y
            la responsabilidad por la custodia de los activos que lo componen
            recaerá exclusivamente sobre Delta en su carácter de Sociedad
            Gerente inscripta ante la CNV y sobre Banco de Valores en su
            carácter de Sociedad Depositaria inscripta ante la CNV,
            respectivamente. n1u no tiene responsabilidad alguna por dicha
            administración, gestión y custodia de los fondos y activos de los
            Usuarios una vez tomada la decisión de suscribir Cuotapartes del
            Fondo de acuerdo con los Términos y Condiciones.
            <br />
            <br />
            3. Modificaciones a los Términos y Condiciones
            <br />
            <br />
            3.1. n1u, Delta y/o Banco de Valores se reservan el derecho de
            realizar modificaciones a los Términos y Condiciones, como así
            también a cualquier información incorporada a los mismos a fin de
            adaptarlos a nuevas disposiciones operativas, normas y
            reglamentaciones aplicables sin limitación alguna, por una
            obligación legal o contractual que así lo determine. Cuando los
            cambios mencionados se pongan en vigencia, se enviará un mensaje
            anunciando tal modificación al Usuario.
            <br />
            <br />
            3.2. El Usuario entiende y acepta que el uso del Servicio después de
            haberse realizado cambios a los Términos y Condiciones constituye la
            aceptación de tales modificaciones, salvo comunicación en forma
            expresa del Usuario a n1u, Delta y/o Banco de Valores, lo cual
            implicará que el Usuario podrá ser dado de baja del Servicio. n1u,
            Delta y/o Banco de Valores se encuentran facultados, sin necesidad
            de aprobación del Usuario, a modificar, enmendar, suspender,
            transitoria o definitivamente, o dar de baja el Servicio.
            <br />
            <br />
            4. Mandatos irrevocables a n1u, Delta y Banco de Valores-
            Autorización.
            <br />
            <br />
            4.1. El Usuario instruye a Delta y a Banco de Valores, a que éstos
            generen por su cuenta y orden las órdenes de suscripción y de
            rescate de Cuotapartes del Fondo. En consecuencia, el Usuario
            autoriza e instruye irrevocablemente en este acto a Delta y a Banco
            de Valores en forma indistinta para que, por su cuenta y orden, y
            conforme a las decisiones del Usuario, suscriba la documentación
            necesaria y realice los actos jurídicos y/o materiales necesarios o
            convenientes para la suscripción y/o rescate de Cuotapartes del
            Fondo. El Usuario entiende y acepta que a tal fin se utilizará la
            modalidad de Agente de Colocación y Distribución Integral (ACDI),
            rol que asumirá Delta en todo conforme con las normas aplicables. El
            Usuario entiende y acepta que esta modalidad incluye la apertura de
            cuentas comitentes de su titularidad en Caja de Valores S.A. (en su
            carácter de Agente Depositario Central de valores Negociables
            autorizado por la CNV) para la custodia de las Cuotapartes que
            suscriba, y el registro de las Cuotapartes en el sistema de Banco de
            Valores; y el Usuario instruye a Delta para que reciba de manera
            directa las comunicaciones correspondientes de Banco de Valores. El
            Usuario entiende y acepta que Delta, Banco de Valores, y Caja de
            Valores S.A., en el marco de lo que autoricen las Normas, podrán
            utilizar otros sistemas en lugar del mencionado. El Usuario autoriza
            e instruye irrevocablemente en este acto a n1u para que, por su
            cuenta y orden, y conforme a las decisiones del Usuario, realice el
            procesamiento de los pagos y cobros vinculados con las instrucciones
            de suscripción y rescates de Cuotapartes del Fondo. Sin limitación
            de lo anterior, ello implica debitar, acreditar, retener y/o
            realizar cualquier acto necesario con los fondos disponibles en la
            Cuenta APP n1u del Usuario.
            <br />
            <br />
            4.2. El Usuario entiende que, adoptada su decisión de invertir en el
            Fondo, podrá invertir los saldos disponibles de su Cuenta APP n1u,
            de conformidad con la decisión del Usuario, y sin perjuicio de la
            facultad de modificar dicha decisión en el futuro.
            <br />
            <br />
            4.3. Cada vez que el Usuario decida rescatar las Cuotapartes con el
            fin de (i) cancelar pagos por la compra de bienes, productos o
            servicios; (ii) realizar transferencias a cuentas bancarias y/o
            cuentas de pago , (iii) retirar fondos en efectivo a través de redes
            de cobranzas extrabancarias habilitadas o comercios habilitados que
            ofrezcan la funcionalidad de retiro de efectivo de la Cuenta APP
            n1u, y/o (iv) retirar fondos en efectivo por cajeros automáticos
            utilizando la tarjeta prepaga de n1u y/o a través de otros canales
            alternativos de retiros de fondos; y/o rescatar las Cuotapartes con
            el fin de mantener el dinero disponible en su Cuenta APP n1u, ello
            implicará una instrucción de rescate emitida por el Usuario, y por
            tanto Delta y Banco de Valores procederán a rescatar las Cuotapartes
            por cuenta y orden del Usuario, aceptando el Usuario que a los
            efectos de permitir la disponibilidad inmediata de fondos, los
            rescates de Cuotapartes pueden implementarse a través de mecanismos
            de compensación.
            <br />
            <br />
            4.4. Los eventos detallados a continuación, implicarán asimismo una
            instrucción de rescate de Cuotapartes del Fondo emitida por el
            Usuario y, por lo tanto, Delta y/o Banco de Valores estarán
            facultados a rescatar las Cuotapartes del Usuario, otorgando éste un
            mandato expreso a tal fin y autorizando la actuación por su cuenta y
            orden: (i) Orden judicial que indicara o requiriera para su
            cumplimiento el rescate de las Cuotapartes; (ii) Si el Usuario
            tuviera saldos deudores a cubrir en su Cuenta APP n1u o servicios
            ofrecidos por Delta y/o Banco de Valores; (iii) Si el Usuario
            tuviera responsabilidad frente a Blockchain Payments Rails S.A por
            contracargos generados en su Cuenta APP n1u de acuerdo a los
            términos y condiciones de uso de la APP n1u; (iv) Si los datos de la
            Cuenta APP n1u del Usuario no coincidieran con los datos registrados
            en Delta y Banco de Valores. (v) Otras causales que Delta y Banco de
            Valores tengan a consideración por las cuales la cuenta comitente de
            un Usuario deba ser inhabilitada y/o cerrada.
            <br />
            <br />
            4.5. Los mandatos indicados son otorgados por el plazo de UN (1)
            año, renovables automáticamente salvo decisión en contrario del
            Usuario, comunicada por escrito.
            <br />
            <br />
            4.6. Delta y Banco de Valores se encuentran facultados de manera
            irrestricta para trabar embargos con el consecuente bloqueo de
            Cuotapartes y/o aplicar cualquier otra medida que les fuera ordenada
            por autoridad competente, incluyendo sin limitación, la
            inhabilitación de la cuenta comitente en caso de quiebra; quedando,
            durante el plazo de vigencia de la cautelar, inhabilitada la opción
            de rescate de los valores prendados. Asimismo, Delta y Banco de
            Valores, ante la traba de un embargo (o cualquier otra medida
            cautelar), podrán imposibilitar al Usuario la realización de futuras
            inversiones. El Usuario reconoce y acepta que tanto el Banco de
            Valores como entidad financiera y Blockchain Payments Rails S.A en
            su carácter de proveedor de servicios de pago, deben cumplir con las
            comunicaciones difundidas por el BCRA relativos a la traba de
            embargos y otras medidas cautelares
            <br />
            <br />
            5. Política de Privacidad
            <br />
            <br />
            5.1. Las Políticas de Privacidad de Blockchain Payments Rails S.A,
            Delta y Banco de Valores. Respectivamente cumplen las exigencias de
            la Ley 25.326, su decreto reglamentario y normas complementarias
            (“Ley de Protección de Datos Personales”).
            <br />
            <br />
            5.2. El Usuario entiende y acepta que la totalidad de la información
            que suministre a Blockchain Payments Rails S.A, Delta y/o Banco de
            Valores es necesaria para la ejecución de los Términos y
            Condiciones, y presta declaración jurada de su veracidad, exactitud,
            integridad, vigencia y autenticidad, y autoriza a que la misma sea
            compartida entre Blockchain Payments Rails S.A, Delta y/o Banco de
            Valores, especialmente en el marco de lo previsto por la Ley 25.246
            y su normativa reglamentaria (conjuntamente, la “Ley de PLDyFT”).
            Blockchain Payments Rails S.A, Delta y/o Banco de Valores se
            reservan el derecho de solicitar al Usuario información adicional
            relacionada con la obtención y tenencia de activos del Usuario,
            incluyendo la justificación de origen lícito de sus fondos,
            consintiendo expresamente el Usuario tal facultad.
            <br />
            <br />
            5.3. Los Usuarios son los titulares de los datos personales
            informados a Blockchain Payments Rails S.A, Delta y Banco de
            Valores, y tienen la facultad de ejercer el derecho de acceso a los
            mismos en forma gratuita, en intervalos no inferiores a seis meses
            y, en caso de corresponder, solicitar la rectificación,
            actualización o supresión de sus datos personales, en los términos
            dispuestos por la Ley 25.326 de Protección de Datos Personales.
            Blockchain Payments Rails S.A, Delta y Banco de Valores podrán
            informar a los Usuarios cualquier cuestión relativa al Fondo por
            medio de un aviso mediante una publicación en la APP n1u, o por
            correo electrónico a su dirección de correo electrónico registrada
            en ayuda@n1u.app
            <br />
            <br />
            5.4. La APP no contendrá ni permitirá el acceso a información de
            terceros que ni n1u, ni Delta, ni Banco de Valores hayan autorizado.
            Es responsabilidad del Usuario las consecuencias derivadas de su
            acceso, incluyendo, pero sin limitarse a, infección del equipo del
            Usuario por virus informáticos, o que los mismos tengan contenido
            contrario a la moral o buenas costumbres; como también la exactitud,
            veracidad, utilidad o adecuación para propósitos determinados de los
            contenidos de la APP n1u.
            <br />
            <br />
            5.5. El Usuario entiende y acepta sin reservas que su información
            está sujeta a análisis y deberes de reporte o información, de
            conformidad con la regulación de la Comisión Nacional de Valores,
            Banco Central de la República Argentina, Unidad de Información
            Financiera u otros entes (incluyendo, enunciativamente, la
            regulación FATCA –Foreign Account Tax Compliance Act–, y las de
            implementación del régimen de intercambio de información OCDE
            –Organización para la Cooperación y el Desarrollo Económico–).
            <br />
            <br />
            6. Impuestos
            <br />
            <br />
            6.1. Blockchain Payments Rails S.A, Delta y Banco de Valores no
            asumen responsabilidad alguna por el cumplimiento de las
            obligaciones fiscales de los Usuarios que pudieran resultar de la
            inversión en Cuotapartes del Fondo, según lo previsto en los
            presentes Términos y Condiciones. En este sentido, todos los
            impuestos, tasas, contribuciones y demás obligaciones tributarias
            (nacionales, provinciales y municipales) que se generen como
            consecuencia de la inversión en Cuotapartes del Fondo, estarán a
            cargo única y exclusivamente del Usuario.
            <br />
            <br />
            7. Disposiciones Adicionales. Autorización
            <br />
            <br />
            7.1. La información suministrada, los resultados de las fases
            interactivas (“Test de inversor”, etc.) y/o cualquier elemento del
            Fondo no constituye en sí una recomendación de contratación de
            servicios o inversiones específicas, no se aseguran rendimientos
            futuros de ningún tipo, ni suplanta la toma de decisiones de
            inversión, con el debido y previo asesoramiento legal, fiscal y
            contable a cargo del Usuario. No se garantiza el carácter genuino o
            la veracidad de la información pública, ni se asume obligación
            alguna de actualizar cualquiera de los datos incorporados en el
            documento. Se encuentra prohibida la distribución o reproducción
            –total o parcial– de dicha información.
            <br />
            <br />
            7.2. El Usuario autoriza e instruye expresamente a Blockchain
            Payments Rails S.A a recibir de manera directa todas las
            comunicaciones emitidas por CAJA DE VALORES S.A. en el marco del
            Servicio solicitado. No obstante, el Usuario podrá revocar la
            autorización e instrucción aquí otorgada, comunicando dicha
            decisión, por escrito, a Blockchain Payments Rails S.A, a la
            siguiente casilla de correo electrónico: ops@n1u.app
            <br />
            <br />
            7.3. Blockchain Payments Rails S.A, no se responsabiliza por los
            posibles daños y perjuicios, de cualquier tipo que fueren, que
            pudieren sufrir los Usuarios, en ocasión y con motivo de la
            autorización e instrucción otorgada a Blockchain Payments Rails S.A
            en el punto 7.2. de los presentes Términos y Condiciones.
            <br />
            <br />
            8. Domicilio. Ley aplicable. Competencia
            <br />
            <br />
            8.1. Estos Términos y Condiciones se rigen por las leyes de la
            República Argentina. Toda acción derivada de estos Términos y
            Condiciones deberá someterse exclusivamente a la jurisdicción de la
            Justicia Nacional de Primera Instancia en lo Comercial con asiento
            en la Ciudad Autónoma de Buenos Aires, República Argentina,
            renunciando las Partes a cualquier otro fuero o jurisdicción que
            pudiera corresponder.
            <br />
            <br />
            8.2. En caso de declararse la nulidad de alguna de las cláusulas de
            estos Términos y Condiciones, tal nulidad no afectará a la validez
            de las restantes, las cuales mantendrán su plena vigencia y efecto.
            <br />
            <br />
            8.3. A todos los efectos emergentes de estos Términos y Condiciones,
            Blockchain Payments Rails S.A constituye domicilio en Ciudad de la
            Paz 3252, Piso 3, Dpto C.
            <br />
            <br />
            8.5. Las controversias vinculadas al Fondo se resolverán conforme lo
            previsto en el Reglamento de Gestión del Fondo aprobado por la CNV.
            <br />
            <br />
            8.6. Para todo lo aquí no previsto será de aplicación lo dispuesto
            en los Términos y Condiciones de Uso de la APP n1u.
          </TermsText>

          <a
            href="../pdf/TyC Usuarios DELTA_n1u..pdf"
            download
            style={{ textDecoration: "none" }}
          >
            <ButtonDownload>
              <p> Descargar PDF</p>
            </ButtonDownload>
          </a>
        </TermsContainer>

        <Footer />
      </TermsSection>
    </>
  );
}

const ButtonDownload = styled.button`
  height: 45px;
  width: 180px;
  background-color: #ff009c;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  p {
    color: white;
    font-size: 16px;
  }
`;

export default CuentaRemunerada;
