import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import Faqs from "./sections/Faqs";
import Features from "./sections/Features";
import N1uverse from "./sections/N1uverse";
import Benefits from "./sections/Benefits";
import Benefit from "./sections/Benefit";
import { useEffect } from "react";
import { StyleSheetManager } from "styled-components";
import NewScrollHome from "./sections/NewScrollHome";
import General from "./terms/General";
import Digital from "./terms/Digital";
import N1uPass from "./terms/N1uPass";
import PrivacyPolicies from "./terms/PrivacyPolicies";
import PrivacyPoliciesDigital from "./terms/PrivacyPoliciesDigital";
import PrepaidCard from "./terms/PrepaidCard";
import ForbiddenActivities from "./terms/ForbiddenActivities";
import FaqsTerms from "./terms/FaqsTerms";
import ConsumerDefense from "./terms/ConsumerDefense";
import FinancialUser from "./terms/FinancialUser";
import FaqsN1uPass from "./terms/FaqsN1uPass";
import PromotionalBenefits from "./terms/PromotionalBenefits";
import N1uRegister from "./terms/promotional/N1uRegister";
import Play from "./terms/promotional/Play";
import Discord from "./terms/promotional/Discord";
import Ags from "./terms/promotional/Ags";
import Ysy from "./terms/promotional/Ysy";
import YoungMiko from "./terms/promotional/YoungMiko";
import Referrals from "./terms/promotional/Referrals";
import Booking from "./terms/promotional/Booking";
import Samsung from "./terms/promotional/Samsung";
import FreeFire from "./terms/promotional/FreeFire";
import CyberWeek from "./terms/promotional/CyberWeek";
import Route404 from "./structure/Route404";
import Costs from "./terms/Costs";
import ComingSoon from "./sections/ComingSoon";
import Bhavi from "./terms/promotional/Bhavi";
import FaqsCuentaRemunerada from "./terms/FaqsCuentaRemunerada";
import RgCuentaRemunerada from "./terms/RgCuentaRemunerada";
import Wiki from "./sections/Wiki";
import CuentaRemunerada from "./terms/CuentaRemunerada";
import Contact from "./sections/Contact";
import whatsapp from "./assets/mediaicons/whatsapp.svg";
import { Link } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <>
      <ScrollToTop />
      <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith("$")}>
        <Link to="https://wa.me/1125252222" target="_blank">
          <WhatsappButton>
            <img src={whatsapp} alt="whatsapp" />
          </WhatsappButton>
        </Link>
        <Routes>
          <Route path="/" element={<NewScrollHome />} />
          <Route path="/n1uverse" element={<N1uverse />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/store" element={<Benefits />} />
          <Route path="/store/:id" element={<Benefit />} />
          <Route path="/features" element={<Features />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/wiki" element={<Wiki />} />
          <Route path="/terminos-y-condiciones" element={<General />} />
          <Route
            path="/terminos-y-condiciones-activos-digitales"
            element={<Digital />}
          />
          <Route
            path="/terminos-y-condiciones-n1u-pass"
            element={<N1uPass />}
          />
          <Route
            path="/politicas-de-privacidad"
            element={<PrivacyPolicies />}
          />
          <Route
            path="/politicas-de-privacidad-activos-digitales"
            element={<PrivacyPoliciesDigital />}
          />

          <Route path="/tarjetaprepaga" element={<PrepaidCard />} />

          <Route
            path="/actividadesprohibidas"
            element={<ForbiddenActivities />}
          />
          <Route path="/preguntas-frecuentes" element={<FaqsTerms />} />
          <Route
            path="/preguntas-frecuentes-n1u-pass"
            element={<FaqsN1uPass />}
          />
          <Route path="/defensa-del-consumidor" element={<ConsumerDefense />} />

          <Route
            path="/atencion-usuarios-de-servicios-financieros"
            element={<FinancialUser />}
          />

          <Route path="/costos-y-comisiones" element={<Costs />} />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones"
            element={<PromotionalBenefits />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/promocion-registro-n1u"
            element={<N1uRegister />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-carrera-por-la-play/"
            element={<Play />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-activacion-discord/"
            element={<Discord />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/sorteo-entradas-ags/"
            element={<Ags />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/preventa-ysy-a/"
            element={<Ysy />}
          />
          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/sorteo-entradas-young-miko/"
            element={<YoungMiko />}
          />
          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/campana-referidos/"
            element={<Referrals />}
          />
          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/booking/"
            element={<Booking />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/promo-samsung/"
            element={<Samsung />}
          />

          <Route
            path="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-torneos-free-fire/"
            element={<FreeFire />}
          />
          <Route path="/cyberweekpromo/" element={<CyberWeek />} />

          <Route path="/preventa-bhavi/" element={<Bhavi />} />

          <Route
            path="/preguntas-frecuentes-n1u-cuenta-remunerada/"
            element={<FaqsCuentaRemunerada />}
          />

          <Route
            path="/terminos-y-condiciones-cuenta-remunerada/"
            element={<CuentaRemunerada />}
          />
          <Route
            path="/rg-fci-cuenta-remunerada/"
            element={<RgCuentaRemunerada />}
          />


          <Route path="/contact" element={<Contact />} />

          <Route path="*" element={<Route404 />} />
        </Routes>
      </StyleSheetManager>
    </>
  );
}

const WhatsappButton = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #ff009c;
  height: 60px;
  width: 60px;
  border-radius: 40px;
  border: 1px solid white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 40px;
    width: 40px;
    bottom: 30px;
    right: 30px;
  }

  img {
    height: 30px;
    width: 30px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      width: 20px;
    }
  }
`;

export default App;
