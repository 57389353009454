import React from "react";
import styled from "styled-components";
import Tabs from "./Tabs";
import logowhite from "../assets/logon1u/logo white.svg";
import { Link } from "react-router-dom";

const Menu = ({ open, view, setOpen }) => {

  
  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <StyledMenu open={open}>
      <Link
        to="/"
        className="link"
        onClick={() => view === "home" && closeMenu()}
      >
        <div className="logo-menu">
          <img alt="logo" src={logowhite} />
        </div>
      </Link>
      <Tabs menu={true} view={view} setOpen={setOpen}/>
    </StyledMenu>
  );
};

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ff009c;
  height: 100vh;
  text-align: left;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

  .logo-menu {
    padding-left: 35px;
    padding-top: 20px;
    height: 50px;
    width: 100px;
  }
`;

export default Menu;
