import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import title from "../assets/n1uverse/players/select-player.png";
import buttonright from "../assets/n1uverse/players/button-right.svg";
import buttonleft from "../assets/n1uverse/players/button-left.svg";
import furia from "../assets/n1uverse/players/furia-still.png";
import n1uton from "../assets/n1uverse/players/n1uton-still.png";
import rebel from "../assets/n1uverse/players/rebel-still.png";
import furiadesc from "../assets/n1uverse/players/furiadesc.png";
import n1utondesc from "../assets/n1uverse/players/n1utondesc.png";
import rebeldesc from "../assets/n1uverse/players/rebeldesc.png";
import box from "../assets/n1uverse/players/box.png";
import Carousel from "react-elastic-carousel";
import AnimatedText from "../components/AnimatedText";

function Players() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const medium = 1080;

  const carouselRef = useRef(null);

  return (
    <>
      <PlayersSection>
        <PlayerContainer>
          <img src={title} alt="title" className="title" />

          {width > medium ? (
            <>
              <AnimatedText>
                <PlayerContent>
                  <PlayerItem
                    image={rebel}
                    name="rebel"
                    description={rebeldesc}
                    video="rebel.webm"
                    width={width}
                  />
                  <PlayerItem
                    image={n1uton}
                    name="n1uton"
                    description={n1utondesc}
                    video="n1uton.webm"
                    width={width}
                  />
                  <PlayerItem
                    image={furia}
                    name="alien"
                    description={furiadesc}
                    video="furia.webm"
                    width={width}
                  />
                </PlayerContent>
              </AnimatedText>
            </>
          ) : (
            <>
              <CarouselContainer>
                <Carousel
                  itemsToShow={width > 900 ? 2.5 : width > 545 ? 1.5 : 1.1}
                  pagination={false}
                  ref={carouselRef}
                >
                  <PlayerItem
                    image={rebel}
                    name="rebel"
                    description={rebeldesc}
                    video="rebel.webm"
                    width={width}
                  />
                  <PlayerItem
                    image={n1uton}
                    name="n1uton"
                    description={n1utondesc}
                    video="n1uton.webm"
                    width={width}
                  />
                  <PlayerItem
                    image={furia}
                    name="alien"
                    description={furiadesc}
                    video="furia.webm"
                    width={width}
                  />
                </Carousel>

                <ButtonContainer>
                  <img
                    LeftButton
                    onClick={() => carouselRef.current.slidePrev()}
                    src={buttonleft}
                    alt="button left"
                  />

                  <img
                    onClick={() => carouselRef.current.slideNext()}
                    src={buttonright}
                    alt="button right"
                  />
                </ButtonContainer>
              </CarouselContainer>
            </>
          )}
        </PlayerContainer>
      </PlayersSection>
    </>
  );
}

const PlayerItem = ({ image, name, description, video, width }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <>
        <ItemContainer
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <PlayerBox>
            <img className="box" src={box} alt="box" />
            <div className="media-container">
              {isHovered  && width > 500 ? (
                <>
                  <video
                    className="video"
                    autoPlay
                    muted
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <>
                  <img className="player" src={image} alt="player" />
                </>
              )}
            </div>
            <button>
              <p>{name}</p>
            </button>
          </PlayerBox>

          <Description>
            <img
              className="description"
              src={description}
              alt="player-description"
            />
          </Description>
        </ItemContainer>
      </>
    </>
  );
};

const PlayersSection = styled.div`
  width: 100%;
  height: auto;
  z-index: 10;
  background-image: url("/backgrounds/degrade-players.png");
  background-position: center ;
  background-size: cover;

`;
const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  margin: 0 auto;
  max-width: 1600px;
  z-index: 10;
  position: relative;


  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  .title {
    height: 100%;
    width: 50%;
    padding-bottom: 0px;
    align-self: center;
    z-index: 1000;

    @media only screen and (max-width: 950px) {
      width: 80%;
    }

    @media only screen and (max-width: 800px) {
      width: 90%;
      padding-bottom: 0px;
    }
  }
`;

const PlayerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 10;

`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  padding-top: 20px;


  @media only screen and (max-width: 1080px) {
    padding-top: 0px;
  }

  .box {
    position: absolute;
    z-index: -1;
    width: 90%;
    height: 100%;
    bottom: 0;

    @media only screen and (max-width: 420px) {
      width: 80%;
    }
  }
`;

const PlayerBox = styled.div`
  height: 350px;
  width: 370px;
  display: flex;
  justify-content: center;
  position: relative;

  button {
    position: absolute;
    bottom: 2%;
    border: none;
    margin-bottom: 10px;
    background-color: #ff009c;
    color: white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    height: 37px;
    width: auto;
    /* @media only screen and (max-width: 480px) {
      margin-bottom: 50px;
    } */

    p {
      color: white;
      font-family: "LoRes";
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.5px;
      padding: 0 20px;
      justify-content: center;
      align-self: center;
      @media only screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
  }

  .box {
    height: 340px;
    width: 83%;
    margin-top: -70px;

    @media only screen and (max-width: 420px) {
      height: 340px;
      width: 80%;
    }
  }

  .player {
    /* height: 420px;
    width: 95%;
    margin-top: -74px; */
    height: 360px;
    width: 300px;
    margin-top: -13.8px;
  }

  .video {
    /* height: 460px;
    width: 94%;
    margin-top: -93.5px; */
    height: 390px;
    width: 300px;
    margin-top: -29px;
  }
`;

const Description = styled.div`
  height: auto;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  margin-bottom: 20px;

  .description {
    height: 300px;
    width: 250px;

    @media only screen and (max-width: 500px) {
      width: 200px;
      height: 250px;
    }
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 50px 0;
  .rec-arrow {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 50px;

  img {
    height: 30px;
    width: 60px;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
`;

export default Players;
