import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Pixel from "../home/Pixel";
import ios from "../assets/home/homecover/IOS buttom (1).png";
import googleplay from "../assets/home/homecover/Google play buttom (1).png";
import { Link } from "react-router-dom";
import stain from "../assets/home/homecover/stain full.png";
import phone from "../assets/home/homecover/phone-home-mobile-1.png";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const Cover = () => {
  const [width, setWidth] = useState(null);
  const getWidth = () => divRef?.current?.offsetWidth;

  const divRef = useRef(null);
  const { ref, inView } = useInView({ threshold: 0.3 });

  const [animatedStyle, setAnimatedStyle] = useSpring(() => ({
    opacity: 0,
    config: { duration: 1800 },
  }));

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setAnimatedStyle({
          opacity: 1,
          config: { duration: 1800 },
        });
      }, 8);
    }
  }, [inView]);

  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setTotalWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <CoverSection ref={ref}>
        {totalWidth > 800 ? (
          <>
            <GifContainer>
              <Video loop autoPlay muted playsinline>
                {/* <source src="GIF-web.mp4" type="video/mp4" /> */}
                <source src="WEB-FINAL.webm" type="video/webm" />
              </Video>
            </GifContainer>
          </>
        ) : (
          <>
            <Hero>
              <Title>
                <animated.h1 style={animatedStyle}>
                  tu billetera <br />
                  gamer.
                </animated.h1>
              </Title>

              <StainContainer>
                <img className="stain" src={stain} alt="stain" />
                <img className="phone" src={phone} alt="phone" />
              </StainContainer>

              <Buttons>
                <Link
                  to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                  target="_blank"
                >
                  <img src={ios} alt="ios" />
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                  target="_blank"
                >
                  {" "}
                  <img src={googleplay} alt="googleplay" />{" "}
                </Link>
              </Buttons>
              <BottomCoins />
            </Hero>
          </>
        )}

        <CoverContainer>
          <Column1>
            <div className="title">
              <animated.h1 style={animatedStyle}>
                tu billetera
                <br /> gamer.
              </animated.h1>
            </div>
          </Column1>

          <Column3>
            <Content>
              <Link
                to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                target="_blank"
              >
                <img src={ios} alt="ios" className="first" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" />
              </Link>
            </Content>
          </Column3>
        </CoverContainer>

        {totalWidth < 800 ? (
          <></>
        ) : (
          <>
            <Pixel style={animatedStyle} />
          </>
        )}
      </CoverSection>
    </>
  );
};

const CoverSection = styled.div`
  width: 100%;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  /* margin-top: 182px; */
  @media only screen and (max-width: 800px) {
    height: auto;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 80vh;
  object-fit: contain;
  position: absolute;
  top: 10px;
  left: 0;
  @media only screen and (max-width: 990px) {
    object-fit: cover;
  }
`;

const GifContainer = styled.div`
  width: 100%;
  height: 80vh;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    object-fit: cover;
  }
`;

const Hero = styled.div`
  width: 100%;
  /* height: auto; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
`;

const StainContainer = styled.div`
  display: flex;
  justify-content: center;

  .stain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .phone {
    width: 65% !important;
    height: auto;
    padding-top: 0px;
    z-index: 20;

    @media only screen and (max-width: 700px) {
      width: 70% !important;
    }

    @media only screen and (max-width: 630px) {
      width: 75% !important;
    }

    @media only screen and (max-width: 500px) {
      width: 100% !important;
    }

    @media only screen and (max-width: 400px) {
      width: 100% !important;
    }
  }
`;

const Title = styled.div`
  z-index: 500;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px 0px;


  h1 {
    color: white;
    font-family: "LoRes";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    max-width: 500px;
    margin: 0px;

    @media only screen and (max-width: 800px) {
      text-align: center;
    }

    @media only screen and (max-width: 500px) {
      font-size: 35px;
      line-height: 35px;
    }

    @media only screen and (max-width: 400px) {
      font-size: 32px;
      line-height: 32px;
    }
  }
`;

const Buttons = styled.div`
  z-index: 800;

  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 500;
  margin-bottom: 50px;

  img {
    height: 45px !important;
    width: 150px !important;
    padding-left: 4px !important;
    cursor: pointer;
    margin-top: 3px;
  }
`;

const BottomCoins = styled.div`
  background-image: url("/backgrounds/coins-ipad-min.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 150px;
  width: 100%;
  bottom: 20%;
  z-index: 400;
  @media only screen and (max-width: 800px) {
    bottom: -2%;
  }
`;

const CoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  height: auto;
  justify-content: space-between;
  max-width: 1600px;
  z-index: 500;
  margin-top: -190px;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;

  .title {
    h1 {
      color: white;
      font-family: "LoRes";
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: 60px;
      max-width: 450px;

      @media only screen and (max-width: 1145px) {
        font-size: 50px;
        max-width: 400px;
        line-height: 50px;
      }

      @media only screen and (max-width: 1145px) {
        font-size: 45px;
        max-width: 400px;
        line-height: 45px;
      }

      @media only screen and (max-width: 955px) {
        font-size: 35px;
      }
    }

    @media only screen and (max-width: 905px) {
      font-size: 40px;
      max-width: 400px;
      line-height: 40px;
    }
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin-top: -35px;

  .first {
    padding-right: 20px;
    @media only screen and (max-width: 1200px) {
      padding-right: 0px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 120px;
  max-width: 500px;

  img {
    height: 55px;
    width: 190px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column !important;
    height: 120px;
    justify-content: space-between;
    align-items: flex-end;
    width: 400px;
    img {
      height: 55px !important;
      width: 190px !important;
    }
  }
`;

export default Cover;
