import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import styled from "styled-components";

import { TermsSection, TermsContainer, TermsTitle } from "../styles/texts";

function RgCuentaRemunerada() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Reglamento de Gestión Cuenta Remunerada</TermsTitle>
          <a
            href="../pdf/RG FCI Delta Pesos.pdf"
            download
            style={{ textDecoration: "none" }}
          >
            <ButtonDownload>
              <p> Descargar PDF</p>
            </ButtonDownload>
          </a>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

const ButtonDownload = styled.button`
  height: 45px;
  width: 180px;
  background-color: #ff009c;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  p {
    color: white;
    font-size: 16px;
  }
`;

export default RgCuentaRemunerada;
