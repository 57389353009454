import React from "react";
import styled from "styled-components";
import lines from "../assets/wiki/linesblack.png";
import AnimatedText from "../components/AnimatedText";
import alien from "../assets/wiki/alien.png";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <>
      <WorkSection>
        <WorkContainer>
          <Rectangle>
            <img className="alien" src={alien} alt="alien" />

            <Content>
              <AnimatedText>
                <h1>Trabajá con nosotros</h1>
              </AnimatedText>
              <Link
                to="https://n1uapp.notion.site/Open-Positions-c06f1829e4ac47acbcdadef025f4f41a"
                target="_blank"
              >
                <ButtonContainer>
                  <Lines src={lines} alt="lines" />

                  <div className="button">
                    <AnimatedText>
                      <p>press start to continue</p>
                    </AnimatedText>
                  </div>
                </ButtonContainer>
              </Link>
            </Content>
          </Rectangle>
        </WorkContainer>
      </WorkSection>
    </>
  );
};

const WorkSection = styled.div`
  height: auto;
  width: 100%;
  background-color: #cebdf2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WorkContainer = styled.div`
  height: auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
`;

const Rectangle = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin: 90px 0px;

  @media only screen and (max-width: 1100px) {
    width: 570px;
  }

  @media only screen and (max-width: 630px) {
    height: 450px;
    width: 300px;
    align-items: flex-start;
  }

  .alien {
    height: 280px;
    position: absolute;
    bottom: 0;
    left: 30px;

    @media only screen and (max-width: 630px) {
      height: 250px;
      left: 25%;
    }
  }
`;

const Content = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;

  h1 {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-size: 40px;
    text-align: center;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;

    h1 {
      font-size: 30px;
      width: 200px;
    }
  }
`;

const ButtonContainer = styled.div`
  height: 100px;
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    background-color: #ff009c;
    color: white;
    border-radius: 30px;
    display: flex;
    height: 50px;
    width: 260px;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      margin-top: 14px;
      letter-spacing: 0.5px;
    }

    @media only screen and (max-width: 1100px) {
      width: 200px;
      p {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 630px) {
    height: 100px;
    width: 170px;

    .button {
      height: 45px;
      width: 180px;
    }
  }
`;

const Lines = styled.img`
  position: absolute;
  z-index: 1;
  height: 100px;
  width: 200px;

  @media only screen and (max-width: 630px) {
    height: 90px;
    width: 190px;
  }
`;
export default Work;
