import React from "react";
import styled from "styled-components";
import cubogif from "../assets/home/homecube/cube.gif";
import cubeimage from "../assets/home/homecube/cube-image-1.png";
const CuboGif = () => {
  return (
    <>


      {cubogif ? (
        <>
          <GifContainer>
            <img src={cubogif} alt="Mi GIF" />
          </GifContainer>
        </>
      ) : (
        <>
          <ImageContainer>
            <img src={cubeimage} alt="cube" />
          </ImageContainer>
        </>
    )} 
    </>
  );
};



const GifContainer = styled.div`
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;

  img {
    height: 600px !important;
    width: 720px !important;
  }

  @media only screen and (max-width: 950px) {
    img {
      height: 500px !important;
      width: 600px !important;
    }
  }

  @media only screen and (max-width: 750px) {
    img {
      height: 430px !important;
      width: 500px !important;
    }
  }

  @media only screen and (max-width: 500px) {
    img {
      height: 300px !important;
      width: 360px !important;
    }
  }
`;

const ImageContainer = styled.div`
  height: 550px;
  width: auto;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    img {
      height: 400px;
      width: 400px;
    }
  }

  @media only screen and (max-width: 600px) {
    img {
      height: 340px;
      width: 340px;
    }
  }
`;

export default CuboGif;
