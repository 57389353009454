import React from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import Download from "../home/Download";
import Cards from "../wiki/Cards";
import Cover from "../wiki/Cover";
import Learn from "../wiki/Learn";
import Work from "../wiki/Work"

function Wiki() {
  return (
    <>
      <WikiSection>
        <NavbarFixed />
        <Learn />
        <Cover />
        <Cards />
        <Work />
        <Download />
        <Footer />
      </WikiSection>
    </>
  );
}

const WikiSection = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  margin: auto;
  background-color: white;
`;

export default Wiki;
