import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Footer from "../structure/Footer";
import cards from "../jsons/esentialcards.json";
import background from "../assets/features/background-features.jpg"
import backmobile from "../assets/features/features-background-mobile-min.png";
import NavbarFixed from "../structure/NavbarFixed";
import ios from "../assets/home/homecover/IOS buttom (1).png";
import googleplay from "../assets/home/homecover/Google play buttom (1).png";
import { Link } from "react-router-dom";

function Features() {
  const [width, setWidth] = useState(null);
  const getWidth = () => divRef?.current?.offsetWidth;
  const medium = 800;
  const divRef = useRef(null);

  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setTotalWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <EsentialsSection>
        <NavbarFixed />
        <Image  src={totalWidth > medium ? background : backmobile} alt="background" />
        <Cover>
          <TitleContainer>
            <FeatureHeading>Features</FeatureHeading>
            <SubHeading>
              n1u es tu billetera, <b>pero a otro level.</b>
            </SubHeading>
            <div className="buttons">
              <Link
                to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                target="_blank"
              >
                <img src={ios} alt="ios" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" />
              </Link>
            </div>
          </TitleContainer>
        </Cover>
        <EsentialsContainer>
          <Box>
            <Item className="first">
              <div>
                <CardTitle>n1u card</CardTitle>
                <CardText>
                  ¡Tu tarjeta Visa recargable Internacional física & virtual sin
                  costo! Usala en todo el mundo.
                </CardText>
              </div>
            </Item>
            {cards.map((card, index) => (
              <Item key={index}>
                <div>
                  <CardTitle>{card.title} </CardTitle>
                  <CardText>{card.content} </CardText>
                </div>
              </Item>
            ))}
          </Box>
        </EsentialsContainer>
      </EsentialsSection>{" "}
      <Footer />
    </>
  );
}

const EsentialsSection = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  @media only screen and (min-width: 2000px) {
    height: 2000px;
  }
`;

const Cover = styled.div`
  height: 100vh;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 2000px) {
    height: auto;
    padding: 180px 0px;
  }

  .buttons {
    width: 420px;
    display: flex;
    justify-content: space-between;

    img {
      height: 60px;
      width: 190px;
    }

    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @media only screen and (min-width: 2000px) {
    height: 2500px;
  }
`;

const EsentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: auto;
  margin: 0 auto;
  max-width: 1600px;
`;

const Box = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* height: auto;
  flex-wrap: wrap;
  gap: 40px 90px; 
  justify-content: flex-start; 
background-color: red; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 90px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 770px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Item = styled.div`
  height: auto;
  min-height: 230px;
  width: 280px !important;
  color: white;
  letter-spacing: 0.02em;
  margin-bottom: 80px;
  border-radius: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  position: relative;

  @media only screen and (max-width: 11450px) {
    margin-bottom: 50px;
  }

  &:hover {
    background-color: #c7a3ff;
    p {
      color: black;
    }
    h2 {
      color: black;
    }
  }
`;

export const CardTitle = styled.h2`
  font-family: "LoRes";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: white;
  position: absolute;
  top: 3%;
  left: 10%;
  max-width: 270px;

  @media only screen and (max-width: 400px) {
    font-size: 24px;
  }
`;

export const CardText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 1px;
  line-height: normal;
  /* position:absolute;
  top:40%;
  left: 10%;
  max-width: 250px; */

  margin-top: 120px;

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    max-width: 230px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  width: auto;
  height: auto;
`;

const FeatureHeading = styled.h1`
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: center;
  font-size: 218.75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 40px;
  line-height: 100%;
  margin-bottom: 0px;

  @media only screen and (max-width: 1000px) {
    font-size: 190px;
  }

  @media only screen and (max-width: 845px) {
    font-size: 170px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 120px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 80px;
  }
`;

const SubHeading = styled.h2`
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 47.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-height: 100%;
  margin-top: 0px;

  b {
    font-weight: 900;
  }

  @media only screen and (max-width: 845px) {
    font-size: 35px;
    line-height: 75px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 35px;
    line-height: 100%;
    max-width: 300px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 25px;
    max-width: 200px;
  }
`;
export default Features;
