import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
} from "../../styles/texts";

function Ags() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            TÉRMINOS Y CONDICIONES EXCLUSIVOS N1U “SORTEO DE ENTRADAS PARA
            ARGENTINA GAME SHOW”
          </TermsTitle>
          <TermsSubText>1. ORGANIZACIÓN</TermsSubText>
          <TermsText>
            Los presentes términos y condiciones rigen para la participación del
            acceso a la “Argentina Game Show” sorteo realizado por BLOCKCHAIN
            PAYMENTS RAILS S.A. (en adelante “n1u”)
          </TermsText>

          <TermsSubText>2. ACEPTACIÓN DE LAS BASES:</TermsSubText>
          <TermsText>
            Quienes participan del sorteo, por su sola participación, implica el
            conocimiento y aceptación de todas sus términos y condiciones, como
            así también, de las decisiones que adopte n1u sobre cualquier
            cuestión no prevista en las mismas, las cuales tendrán carácter de
            definitivas e inapelables.
          </TermsText>

          <TermsSubText>3. ALCANCE Y VIGENCIA:</TermsSubText>
          <TermsText>
            El presente sorteo tendrá vigencia en todo el territorio de la
            República Argentina (en adelante, el “territorio”). Su vigencia
            inicia el 10 de octubre de 2023 y culmina el 12 de octubre de 2023 a
            las 18:00 pm.
          </TermsText>

          <TermsSubText>4. PARTICIPACIÓN:</TermsSubText>

          <TermsText>
            Podrán participar del sorteo todas las personas humanas mayores de
            CATORCE (14) años que residan en el territorio.
            <br />
            <br />
            Se aclara que en el caso de los menores de edad podrán participar,
            solamente con autorización expresa de sus progenitores y/o tutores.
            <br />
            <br />
            Quedan excluidos expresamente de la participación del presente
            sorteo los directores de n1u, el personal dependiente de ella y sus
            familiares directos, los directores de sus agencias de publicidad y
            el personal dependiente de ellas, y los directores de sus
            proveedores y personal dependiente de ellos.
          </TermsText>

          <TermsSubText>5. MECÁNICA DE PARTICIPACIÓN:</TermsSubText>
          <TermsText>Para participar del presente sorteo se deberá:</TermsText>
          <TermsSubText>
            1. Seguir en la plataforma Instagram de Meta a la cuenta “n1u.app” y
            darle like a la publicación que se titula “n1u te invita a la AGS”.
            <br />
            2. Comentar con tu “n1uID” y etiquetar a dos amigos.
          </TermsSubText>
          <TermsText>
            Cualquier interesado, a través de los canales de comunicación, podrá
            solicitar información acerca de los alcances y modalidad del sorteo.
          </TermsText>

          <TermsSubText>6. OBTENCIÓN DEL N1UID</TermsSubText>
          <TermsText>
            El n1uid solo podrá ser obtenido mediante la descarga de la app de
            n1u (tu billetera a otro Level) mediante el store de IOS o Android,
            luego de que haya realizado el correcto onboarding (registración) en
            la App.
          </TermsText>

          <TermsSubText>7. SELECCIÓN DE LOS GANADORES:</TermsSubText>
          <TermsText>
            Entre todas las personas que hayan cumplimentado los requisitos para
            participar conforme se establece en el punto 5 de estos términos y
            condiciones, mediante un sistema informático de selección aleatoria,
            se seleccionará a TRES (3) ganadores.
            <br />
            <br />
            Los ganadores del sorteo serán anunciados mediante el stories en la
            cuenta de instagram “n1u.app”. Serán seleccionados 3 (TRES)
            ganadores y 2 (DOS) suplentes.
            <br />
            <br />
            Los suplentes solamente formarán parte de la competencia si alguno
            de los elegidos como participantes no se presentará a la
            competencia.
            <br />
            <br />
            La adjudicación del premio identificado en el punto 8 quedará
            condicionada a que el ganador haya completado los requisitos del
            punto 5. Cumplidas las condiciones mencionadas, el ganador será
            considerado ganador del premio.
            <br />
            <br />
            n1u, no se hará responsable de los gastos que pudiera ocasionarle a
            los ganadores, el traslado hacía el “ARGENTINA GAME SHOW” a
            realizarse en “Costa Salguero”, CABA, ni tampoco se hará responsable
            en el caso de suspensión del mismo.
          </TermsText>
          <TermsSubText>8. DEL LUGAR Y FECHA DEL SORTEO:</TermsSubText>

          <TermsText>
            El sorteo correspondiente se realizarán mediante un sistema
            informático de selección aleatoria, siendo{" "}
         <a href="https://app-sorteos.com/es/apps/sorteo-instagram-multicuenta">   <span>
              https://app-sorteos.com/es/apps/sorteo-instagram-multicuenta
            </span></a>
            , el día 12/10/2023 a las 18hs, anunciandose a los ganadores
            mediante stories en la cuenta “n1u.app” de Instagram.
          </TermsText>

          <TermsSubText>9. PREMIO:</TermsSubText>
          <TermsText>
            El programa de premios consistirá en la entrega de 6 (SEIS) accesos
            al “ARGENTINA GAME SHOW”, siendo 2 (DOS) accesos para cada ganador.
            <br />
            <br />
            Los premios serán entregados de acuerdo al mecanismo que n1u
            estipule, el cual el ganador deberá cumplir con todos los pasos sin
            excepción a fin de obtener el premio.
            <br />
            <br />
            Sin perjuicio de los antes dicho, n1u no responde por las demoras
            que puedan existir en la entrega del premio por causas no imputables
            al mismo.
          </TermsText>
          <TermsSubText>10. AUTORIZACIÓN:</TermsSubText>
          <TermsText>
            El ganador de la presente promoción, por su sola participación,
            autoriza expresamente a n1u a difundir sus datos personales,
            imágenes, fotografías, a los fines publicitarios, en los medios de
            comunicación y formas que n1u disponga durante toda la vigencia de
            la presente promoción y hasta transcurrido CIENTO OCHENTA (180) días
            de su finalización, sin tener por ello derecho a recibir
            compensación alguna.
            <br />
            <br />
            Asimismo, los participantes del presente sorteo, por el simple hecho
            de participar, autorizan a n1u a utilizar sus datos para
            incorporarlos en una base de datos, junto a su exclusivo uso y
            acceso. Al aceptar las bases y condiciones, el participante confiere
            el consentimiento previsto en los artículos 5 y 11 de la Ley de
            Protección de Datos Personales N° 25.326
          </TermsText>

          <TermsSubText>11. SPONSOR- FACULTADES:</TermsSubText>
          <TermsText>
            n1u sin que ello le genere carga, obligación y/o responsabilidad
            alguna podrá: a) Modificar las presentes BASES bastando la
            publicación del nuevo instrumento en la página web{" "}
           <a href="/"> <span>https://n1u.app/</span></a> para considerar notificados a los
            potenciales participantes, los participantes, los potenciales
            ganadores y los ganadores; b) dejar sin efecto o suspender esta
            promoción antes de la notificación a los ganadores; c) prorrogar
            cada uno de los plazos de vigencia y fechas fijadas antes de la
            finalización del periodo de inscripción previsto en estas bases;
            modificar la fecha de entrega de los premios o ejecución de los
            mismos; d) solicitar a los participantes y/o potenciales ganadores,
            en cualquier momento antes de la finalización de la promoción, que
            verifique sus datos y/o los corroboren y/o rectifiquen los mismos.
          </TermsText>

          <TermsSubText>12. DESLINDE DE RESPONSABILIDAD</TermsSubText>
          <TermsText>
            n1u, NO toma cargo, ni se responsabiliza por ningún tipo de
            indemnización reclamo, costo, daño y/o perjuicio reclamado,
            incluyendo y no limitado a, daños por accidentes, daños materiales,
            físicos o psíquicos o morales, lucro cesante, causados a el/los
            participantes y/o sus derecho habientes, con motivo y en ocasión de
            la competencia.
            <br />
            <br />
            Como así también el n1u se libera en caso que en ocasión del evento
            surja algún tipo de accidente y/o caso fortuito, cualquiera fuera la
            causa que lo origine, con anterioridad, durante el transcurso o con
            posterioridad a la finalización de la competencia.
          </TermsText>

          <TermsSubText>13. JURISDICCIÓN:</TermsSubText>

          <TermsText>
            Para cualquier controversia que pueda presentarse con motivo de la
            presente promoción y, a todos los efectos de la misma, cada una de
            las partes implicadas se encontrará sometida a la competencia y
            jurisdicción de los tribunales ordinarios de la Ciudad de Bs.As
            (Provincia de Bs.As), a los que los participantes aceptan someterse
            voluntariamente, renunciando a cualquier otro fuero o jurisdicción
            que pudiera corresponderles.
          </TermsText>

          <TermsSubText>14. CUMPLIMIENTO LEGAL</TermsSubText>
          <TermsText>
            Blockchain Payments Rails S.A es una sociedad anónima bajo la ley
            argentina los socios limitan su responsabilidad a la integración de
            las acciones suscriptas. (ley 25738).
            <br />
            <br />
            n1u se encuentra limitada a ofrecer servicios de pago y no se
            encuentra autorizada a operar como entidad financiera por el BCRA.
            <br />
            <br />
            Los fondos depositados en las cuentas de pago ofrecidas por n1u, no
            constituyen depósitos en una entidad financiera, ni cuentan con
            ninguna de las garantías que tales depósitos puedan gozar de acuerdo
            con la legislación y reglamentación aplicables en materia de
            depósitos en entidades financieras.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Ags;
