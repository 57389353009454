import React from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import { Link } from "react-router-dom";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
} from "../styles/texts";

function PromotionalBenefits() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>
            Términos y condiciones de beneficios y promociones
          </TermsTitle>
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/promocion-registro-n1u/" className="link">
            <TermsLinks>
              Términos y Condiciones Promoción registro n1u
            </TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-carrera-por-la-play/" className="link">
            <TermsLinks>
              Términos y Condiciones “Carrera por la Play”
            </TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-activacion-discord/" className="link">
            <TermsLinks>Términos y Condiciones Activación Discord</TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/sorteo-entradas-ags/" className="link">
            <TermsLinks>
              Términos y Condiciones "Sorteo entradas AGS"
            </TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/preventa-ysy-a/" className="link">
            <TermsLinks>Términos y Condiciones "Pre Venta YSY A"</TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/sorteo-entradas-young-miko/" className="link">
            <TermsLinks>
              Términos y Condiciones Sorteo entradas show Young Miko
            </TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/campana-referidos/" className="link">
            <TermsLinks>Términos y Condiciones “Campaña Referidos”</TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/booking/" className="link">
            <TermsLinks>Términos y Condiciones Booking</TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/promo-samsung/" className="link">
            <TermsLinks>Términos y Condiciones Promo Samsung</TermsLinks>
          </Link>
          <Line />
          <Link to="/terminos-y-condiciones-de-beneficios-y-promociones/terminos-y-condiciones-torneos-free-fire/" className="link">
            <TermsLinks>Términos y Condiciones Torneos Free Fire</TermsLinks>
          </Link>
          <Line />

          <Link to="/cyberweekpromo/" className="link">
            <TermsLinks>Términos y Condiciones CyberWeek</TermsLinks>
          </Link>
          <Line />
          <Link to="/preventa-bhavi/" className="link">
          <TermsLinks>Términos y Condiciones de Preventa - Bhavi</TermsLinks>
          </Link>
          <Line />

       
        </TermsContainer>

        <Footer />
      </TermsSection>
    </>
  );
}

const TermsLinks = styled.h2`
  color: #ff009c;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 300px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-bottom: 20px;

  &::selection {
    background: #ff009c;
    color: #e8e9ee;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  color: grey;
  z-index: 10;
  background-color: grey;
  margin-top: -10px;
`;

export default PromotionalBenefits;
