import React from "react";
import styled from "styled-components";
import instagram from "../assets/mediaicons/instagram.svg";
import discord from "../assets/mediaicons/discord.svg";
import tiktok from "../assets/mediaicons/tik-tok.svg";
import twitter from "../assets/mediaicons/twitter.svg";
import linkedin from "../assets/mediaicons/linkedin.svg";
import youtube from "../assets/mediaicons/youtube.svg";
import { Link } from "react-router-dom";

function SocialMedia({ view }) {
  return (
    <>



      <SocialMediaContainer view={view}>
        <Link to="https://www.instagram.com/n1u.app/"  target="_blank">
          <img alt="instagram" src={instagram} />
        </Link>
        <Link to="https://twitter.com/n1uapp"  target="_blank">
          <img alt="twitter" src={twitter} />
        </Link>
        <Link to="https://www.TikTok.com/@n1u.app"  target="_blank">
        
          <img alt="tiktok" src={tiktok} />
        </Link>
        <Link to="https://www.youtube.com/@n1uapp"  target="_blank">
          <img alt="youtube" src={youtube} />
        </Link>
     
        <Link to="https://www.linkedin.com/company/n1uapp/"  target="_blank">
          <img alt="linkedin" src={linkedin} />
        </Link>
           <Link to="https://discord.com/invite/ggFTQRNBGb"  target="_blank">
          <img alt="discord" src={discord} />
        </Link>
      </SocialMediaContainer>
    </>
  );
}

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.view === "wiki" ? "180px" : "160px")};
  padding-top: ${(props) => (props.view === "wiki" ? "0px" : "10px")};
  img {
    width: 21px;
    height: 21px;
    cursor: pointer;
    padding: 0px 10px;
  }

  @media only screen and (max-width: 900px) {
    width: 200px;
    padding: 10px;
    margin-left: ${(props) => (props.view === "footer" ? "-40px" : "0px")};
    img {
      width: ${(props) => (props.view === "footer" ? "22px" : "21px")};
      height: ${(props) => (props.view === "footer" ? "22px" : "21px")};
    }
  }

  @media only screen and (max-width: 1000px) {
    width: ${(props) => (props.view === "footer" ? "300px" : "200px")};
    padding-left: 0px;
    padding-top: ${(props) => (props.view === "footer" ? "50px" : "0px")};
    margin-left: ${(props) => (props.view === "footer" ? "0px" : "0px")};
    img {
      width: ${(props) => (props.view === "footer" ? "24px" : "22px")};
      height: ${(props) => (props.view === "footer" ? "24px" : "22px")};
      padding: 0px 0px;
   
    }
  }

  @media only screen and (max-width: 500px) {
    width: ${(props) => (props.view === "footer" ? "250px" : "200px")};
  }
`;

export default SocialMedia;
