import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsSubSubTitle,
  TermsSubText,
  TermsSubTitle,
} from "../styles/texts";

function FaqsN1uPass() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Preguntas Frecuentes n1u store</TermsTitle>

          <TermsSubTitle>1. Programa y misiones</TermsSubTitle>

          <TermsSubSubTitle>
            <span>a.</span> ¿Qué es n1u store?
          </TermsSubSubTitle>
          <TermsSubText >
            n1u store es el programa de beneficios de n1u, donde podés canjear
            tus coins por premios y/o beneficios.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>b.</span> ¿Cómo ingreso a n1u store?
          </TermsSubSubTitle>

          <TermsSubText>
            Para ingresar a n1u store, debes ingresar a la aplicación móvil de
            n1u y seleccionar la sección de n1u store.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>c.</span> ¿Quiénes pueden participar de n1u store?
          </TermsSubSubTitle>

          <TermsSubText>
            Todos los usuarios de n1u pueden participar de n1u store.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>d.</span> Si soy menor de edad, ¿puedo participar de n1u store?
          </TermsSubSubTitle>

          <TermsSubText>
            Si, siempre y cuando el adulto acepte los términos y condiciones del
            programa.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>e.</span> ¿n1u store tiene costo?
          </TermsSubSubTitle>

          <TermsSubText>
            No, n1u store es un programa gratuito para los usuarios de n1u.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>f.</span> ¿Qué son las misiones?
          </TermsSubSubTitle>

          <TermsSubText>
            Las misiones son objetivos semanales para acumular coins.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>g.</span> ¿Cada cuánto se renuevan las misiones?
          </TermsSubSubTitle>

          <TermsSubText>
            Las misiones se renuevan todas las semanas.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>h.</span> ¿Qué pasa si no cumplo todas las misiones?
          </TermsSubSubTitle>

          <TermsSubText>
            No pasa nada. La semana siguiente se renuevan y tenés nuevas chances
            de acumular coins.
          </TermsSubText>

          <TermsSubTitle>2. Coins</TermsSubTitle>

          <TermsSubSubTitle>
            <span>a.</span> ¿Cómo acumulo coins?
          </TermsSubSubTitle>

          <TermsSubText>
            Podés acumular coins con los consumos de tu tarjeta recargable Visa
            y con el cumplimiento de las misiones semanales.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>b.</span> ¿Cuántos coins se acumulan por consumo en mi Tarjeta
            Recargable?
          </TermsSubSubTitle>

          <TermsSubText>Cada $150 consumidos acumulás 1 coin.</TermsSubText>

          <TermsSubSubTitle>
            <span>c.</span> ¿Cuándo se acreditan mis coins?
          </TermsSubSubTitle>

          <TermsSubText>
            Los coins acumulados por consumo o transacciones se acreditan hasta
            después de una hora de la transacción.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>d.</span> ¿Dónde puedo ver los coins que tengo?
          </TermsSubSubTitle>

          <TermsSubText>
            Podés ver las coins que tenés en la Home, en tu perfil y en la
            sección de n1u store de la aplicación móvil de n1u.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>e.</span> ¿Los coins tienen vencimiento?
          </TermsSubSubTitle>

          <TermsSubText>
            Las coins no vencen a menos que no acumules ni canjees coins por 90
            días.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>f.</span> ¿Puedo transferir las coins a otra cuenta n1u?
          </TermsSubSubTitle>

          <TermsSubText>
            No. Por el momento las coins son intransferibles.
          </TermsSubText>

          <TermsSubTitle>3. Canjes:</TermsSubTitle>

          <TermsSubSubTitle>
            <span>a.</span> ¿Qué productos, servicios o beneficios ofrece n1u
            pass?
          </TermsSubSubTitle>

          <TermsSubText>
            n1u store ofrece una amplia variedad de beneficios para canjear con
            tus coins acumulados. Podés encontrar recargas, gift cards, entre
            otros.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>b.</span> ¿Cuáles son las modalidades para adquirir productos,
            servicios o beneficios de n1u store?
          </TermsSubSubTitle>

          <TermsSubText>
            Dentro del n1u store existe la posibilidad de adquirir productos,
            servicios o beneficios bajo tres modalidades. Solo con coins, solo
            con pesos, o un combinable de coins + pesos. Cada producto, servicio
            o beneficio es independiente del otro y no se rigen bajo las mismas
            modalidades.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>c.</span> ¿Puedo adquirir beneficios si no tengo coins?
          </TermsSubSubTitle>

          <TermsSubText>
            Únicamente para aquellos beneficios que tengan disponible la
            modalidad de solo pesos o combinable coins + pesos.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>d.</span> ¿Cómo realizo un canje de Recargas?
          </TermsSubSubTitle>

          <TermsSubText>
            Para realizar un canje de recargas en n1u store debes ingresar al n1u
            store, elegir el monto de la recarga que deseas y deslizar en la
            opción “Desliza para canjear”. Luego, deberás seguir los pasos
            indicados para completar el canje y recibirás el código de recarga
            correspondiente.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>e.</span> ¿Cómo realizo un canje de coins por pesos?
          </TermsSubSubTitle>

          <TermsSubText>
            Para realizar un canje de coins por pesos en n1u store debes ingresar
            al n1u store, elegir la cantidad de coins que deseas canjear y
            deslizar en la opción “Desliza para canjear”. Luego, recibirás el
            monto correspondiente en pesos en tu cuenta bancaria.
          </TermsSubText>



          <TermsSubSubTitle>
            <span>f.</span> ¿Cómo realizo un canje de coins por crypto?
          </TermsSubSubTitle>

          <TermsSubText>
          Para realizar un canje de coins por activos digitales en n1u store debes ingresar al n1u store, elegir la cantidad de coins que deseas canjear y deslizar en la opción “Desliza para canjear”. Luego, recibirás la cantidad correspondiente de los activos digitales elegidos en tu billetera virtual.
          </TermsSubText>



          <TermsSubSubTitle>
            <span>g.</span> ¿Cómo convertir coins en Carga SUBE?
          </TermsSubSubTitle>

          <TermsSubText>
          Para convertir tus coins en carga SUBE en n1u store debes ingresar al n1u store,  elegir la cantidad de carga que deseas y deslizar en la opción “Desliza para canjear”. Luego, deberás seguir los pasos indicados para completar el canje y recibirás el código de carga SUBE correspondiente.
          </TermsSubText>



          <TermsSubTitle>4. Gift card</TermsSubTitle>


          <TermsSubSubTitle>
            <span>a.</span> ¿Cómo realizo un canje de Gift Cards?
          </TermsSubSubTitle>

          <TermsSubText>
          Para canjear una gift card en n1u store debes ingresar al n1u store y seleccionar la gift card que deseas. Confirma tu solicitud de canje y recibirás la Gift card en la app y/o en tu correo electrónico registrado.
          </TermsSubText>


          <TermsSubSubTitle>
            <span>b.</span> ¿Cuánto tiempo tengo para utilizar una Gift Card que canjee?
          </TermsSubSubTitle>

          <TermsSubText>
          El tiempo de uso de las gift cards varía según la marca. Debes verificar los términos y condiciones de cada tarjeta de regalo antes de canjearla.
          </TermsSubText>



          <TermsSubTitle>5. Ayuda</TermsSubTitle>

          <TermsSubSubTitle>
            <span>a.</span> ¿Cómo debo proceder en caso de tener algún inconveniente con las solicitudes de premios virtuales?
          </TermsSubSubTitle>

          <TermsSubText>
          Si tienes algún problema con las solicitudes de premios virtuales, debes contactar al  servicio al cliente de n1u store a través de los canales de soporte disponibles en la plataforma. En primer lugar podés escribirle al BOT para que responda todas tus dudas, y en caso que tu consulta no quede resuelta podes contactarnos via mail a ayuda@n1u.app, que el equipo de soporte te ayudará a resolver cualquier problema que puedas tener.
          </TermsSubText>

          <TermsSubSubTitle>
            <span>b.</span> ¿Puedo devolver un premio que canjee?
          </TermsSubSubTitle>

          <TermsSubText>
          No se permiten devoluciones de premios canjeados en n1u store.
          </TermsSubText>



        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default FaqsN1uPass;
