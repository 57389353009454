import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsQuestions,
} from "../styles/texts";

function FaqsCuentaRemunerada() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>PREGUNTAS FRECUENTES CUENTA REMUNERADA</TermsTitle>
          <TermsQuestions>
            ¿Cómo puedo invertir mi dinero en la app y obtener rendimientos?
          </TermsQuestions>
          <TermsText>
            Sólo necesitas activar la función “Invertí” y así todo el dinero que
            ingreses en la app comienza a generar rendimientos diarios.
          </TermsText>

          <TermsQuestions>
            ¿Cómo funciona y dónde se invierte mi dinero?
          </TermsQuestions>
          <TermsText>
            Al realizar estos simples pasos, tu dinero se invierte en un Fondo
            Común de Inversión (también conocido como FCI) que nos permite
            ingresar el dinero invertido en un vehículo donde muchas otras
            personas también invierten. La participación que tengas, se lo
            conoce como cuotaparte. Cada cuotaparte tiene un rendimiento que
            varía, reflejándose en su valuación: cuanto más invertís, más
            participás del rendimiento. Podés usar tu dinero en cualquier
            momento, sin necesidad de solicitar un rescate y sin restricciones.
            Por el horario de cierre del mercado, algunos movimientos de rescate
            o suscripción de cuotapartes pueden quedar pendientes. En ese caso,
            verás reflejado el rendimiento al día siguiente, a excepción de los
            días feriados y fines de semana, en los que el mercado no opera, y
            se verán reflejados al siguiente día hábil.
          </TermsText>

          <TermsQuestions>
            ¿Qué es un Fondo Común de Inversión (FCI)?
          </TermsQuestions>
          <TermsText>
            Un Fondo Común de Inversión (por sus siglas, "FCI") es un
            instrumento de inversión colectiva. Es decir, cuando se invierte en
            un FCI, un grupo de personas aporta su dinero para que lo administre
            un profesional, en nuestro caso Delta Asset Management S.A.,
            sociedad gerente de FCI autorizada por la Comisión Nacional de
            Valores, invirtiéndolo en determinados activos autorizados, tales
            como bonos, plazos fijos y otros instrumentos financieros
            disponibles en el mercado que conforman el patrimonio del Fondo
            Común de Inversión y según lo establecido en el{" "}
            <a href="/rg-fci-cuenta-remunerada/">
              <span>Reglamento de Gestión.</span>
            </a>
          </TermsText>

          <TermsQuestions>
            ¿Que diferencia tiene con un plazo fijo?
          </TermsQuestions>
          <TermsText>
            El fondo común de inversión no es un plazo fijo. La principal
            diferencia entre ambos, es que en un plazo fijo no podés retirar los
            fondos durante el tiempo en que estén colocados (30 días o más). Por
            otro lado, el FCI no está alcanzado por las garantías de los
            depósitos previstas en las normas del Banco Central de la República
            Argentina (BCRA) para los plazos fijos. Si decidís invertir en el
            FCI, siempre que haya fondos invertidos, vas a poder retirar la
            totalidad o parte de los fondos que estén invertidos cualquier día,
            sin tiempo mínimo.
            <br />
            <br />
            Las inversiones en cuotapartes del FCI no constituyen depósitos en
            Banco de Valores S.A. a los fines de la Ley de Entidades Financieras
            ni cuentan con ninguna de las garantías que tales depósitos a la
            vista o a plazo puedan gozar de acuerdo a la legislación y
            reglamentación aplicables en materia de depósitos en entidades
            financieras. Asimismo, Banco de Valores S.A. se encuentra impedido
            por normas del Banco Central de la República Argentina de asumir,
            tácita o expresamente, compromiso alguno en cuanto al mantenimiento,
            en cualquier momento, del valor del capital invertido, al
            rendimiento, al valor de rescate de las cuotapartes o al
            otorgamiento de liquidez a tal fin.
          </TermsText>

          <TermsQuestions>
            ¿Los fines de semana mi dinero sigue generando ganancias?
          </TermsQuestions>

          <TermsText>
            Sí, tu dinero está invertido todos los días. Como los fines de
            semana y feriados el FCI no está operativo, los rendimientos
            correspondientes a esos días, los verás acreditados el próximo día
            hábil.
          </TermsText>

          <TermsQuestions>
            ¿Cuáles son los requisitos mínimos para invertir?
          </TermsQuestions>
          <TermsText>
            • Ser mayor de 18 años.
            <br />
            • Tener una cuenta activa y validada en n1u.
            <br />• Aceptar los TyC
          </TermsText>

          <TermsQuestions>¿Cómo lo activo desde la app?</TermsQuestions>
          <TermsText>
            - Accedé a la sección de la Home que dice “Invertí”
            <br />
            - Aceptá los Términos y Condiciones.
            <br />
            - Aceptá el Reglamento de Gestión del FCI.
            <br />
            - Aceptá la Declaración Jurada.
            <br />
            <br />
            Una vez que te confirmemos si estás habilitado, ¡ya podés empezar a
            invertir!
          </TermsText>

          <TermsQuestions>
            ¿Cuál es el monto con el que puedo empezar a invertir?
          </TermsQuestions>
          <TermsText>
            Podés empezar a invertir desde $1, sin límite máximo de inversión.
            Podés usar tu dinero en cualquier momento sin restricciones.
          </TermsText>

          <TermsQuestions>¿Cuáles son los plazos?</TermsQuestions>
          <TermsText>
            El mínimo para obtener rendimiento es 1 día hábil y no hay un plazo
            máximo: podés tener tu dinero generando rendimientos todo el tiempo
            que decidas.
          </TermsText>

          <TermsQuestions>
            ¿Si ingreso más dinero en la billetera tengo que volver a
            invertirla?
          </TermsQuestions>
          <TermsText>
            No. Todo el dinero que cargues se toma como suscripción al FCI
            automáticamente mientras tengas activa la opción “Invertí”. La
            inversión es por el total del saldo que tengas en tu cuenta de pago.
          </TermsText>

          <TermsQuestions>
            ¿Puedo invertir sólo una parte de mi saldo?
          </TermsQuestions>

          <TermsText>
            No, una vez activada la función “Invertí”, todo tu saldo empieza a
            generar rendimientos. No te preocupes, ¡podes usarlo en cualquier
            momento!
          </TermsText>

          <TermsQuestions>¿Dónde puedo ver mis rendimientos?</TermsQuestions>
          <TermsText>
            Haciendo Click en el % de rendimiento diario. Podrás ver todos tus
            rendimientos diarios y acumulado.
          </TermsText>

          <TermsQuestions>¿El servicio tiene costo?</TermsQuestions>

          <TermsText>
            n1u no cobra por el servicio. Solo tenés que cumplir los requisitos
            de uso. Los costos correspondientes al FCI, se encuentran descriptos
            en{" "}
            <a href="https://deltaam.com.ar/fondos_de_inversion/delta-pesos/">
              <span>
                https://deltaam.com.ar/fondos_de_inversion/delta-pesos/
              </span>
            </a>
            , y ya incluyen el descuento de honorarios mencionados alli dentro
            del % de rendimiento.
          </TermsText>
          <TermsQuestions>
            ¿Cuál es el rendimiento anual esperado? ¿Dónde lo puedo ver?
          </TermsQuestions>
          <TermsText>
            El rendimiento anual estimado surge de una tasa que se hace en base
            a la rentabilidad obtenida del FCI Delta Pesos durante los últimos
            12 meses. El rendimiento se actualiza diariamente y lo podés ver
            detallado en la App. Accede desde la home en donde está el % de
            rendimiento diario a la sección de inversiones.
          </TermsText>

          <TermsQuestions>¿Cómo dejo de invertir mi dinero?</TermsQuestions>
          <TermsText>
            Desde la home haciendo click en el % de rendimiento diario, luego en
            el margen inferior vas a encontrar un botón que dice “No invertir
            más” y luego confirmarlo nuevamente haciendo click en “Desactivar
            Inversión”.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default FaqsCuentaRemunerada;
