import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsSubText,
  TermsSubSubText,
  TermsSubSubSubText,
  TermsSubSubSubSubText,
  TermsQuestions,
} from "../styles/texts";

function FaqsTerms() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Preguntas Frecuentes</TermsTitle>
          <TermsTitle>Sobre n1u</TermsTitle>
          <TermsText>
            n1u es una super app que llegó para solucionar todas tus finanzas.
            Podés contar con una tarjeta Visa internacional prepaga, para
            comprar en cualquier comercio y sitio web del mundo que acepte Visa.
            Además, podés enviar y recibir, plata a/de otros usuarios de n1u,
            cuentas bancarias (CBU) y cuentas virtuales (CVU) gratis e
            instantáneamente. Además, podes extraer dinero de los cajeros de las
            redes habilitadas (Red Link, Banelco o si estás fuera de Argentina
            VISA Plus), También comprar y vender activos digitales, comprar
            juegos, recargar y pagar servicios, celular, transporte y SUBE.
            Además la app te ayuda a proteger tus gastos, ya que recibís una
            notificación en el momento que realices un consumo y seguir todos
            tus movimientos cuando y desde donde quieras.
          </TermsText>

          <TermsQuestions>
            ¿Qué tipo de estructura jurídica tiene n1u?
          </TermsQuestions>
          <TermsText>
            BPR, es la empresa dueña de n1u y está constituida de acuerdo a la
            legislación de la República Argentina como una Sociedad Anónima
            (S.A.). Está inscripta en el Registro Público de Comercio de la
            Ciudad Autónoma de Buenos Aires y cumple con todas las obligaciones
            impuestas por Ley para funcionar como tal.
          </TermsText>

          <TermsQuestions>
            ¿Cómo protege n1u los datos de sus usuarios?
          </TermsQuestions>
          <TermsText>
            n1u está inscripta en el Registro de Bases de Datos Personales y da
            cumplimiento a la Ley 25.326 de protección de datos personales,
            garantizando el resguardo de los datos de sus usuarios.
          </TermsText>

          <TermsQuestions>
            ¿n1u está regulada como entidad financiera por el Banco Central de
            la República Argentina (BCRA)?
          </TermsQuestions>
          <TermsText>
            No, n1u es un Proveedor de Servicios de Pago (“PSP”) que se
            encuentra inscripto en el “Registro de Proveedores de Servicios de
            Pago que ofrecen cuentas de pago” bajo el número 34.516.
          </TermsText>

          <TermsQuestions>
            ¿Qué servicios brinda n1u? ¿Es un banco?
          </TermsQuestions>
          <TermsText>
            n1u es una empresa que se encuentra dentro de la categoría fintech.
            n1u no se encuadra en los requerimientos que exige la Ley 21.526 de
            entidades financieras para ser considerada como tal. n1u no es un
            banco porque no realiza intermediación financiera, actividad que sí
            realizan los bancos.
          </TermsText>

          <TermsQuestions>
            ¿n1u está regulada por algún organismo?
          </TermsQuestions>
          <TermsText>
            Sí, n1u está regulada por la Unidad de Información Financiera (UIF).
            n1u es Sujeto Obligado ante la UIF y, por tal motivo, n1u cumple con
            las exigencias de la Resolución UIF 76/2019
          </TermsText>

          <TermsTitle>Usuario y Cuenta</TermsTitle>
          <TermsQuestions>
            ¿Puedo descargarme la app de n1u en una computadora o un celular con
            sistema operativo Windows?
          </TermsQuestions>
          <TermsText>
            No, la app de n1u solo se puede descargar y usar desde celulares con
            sistema operativo Android o iOS.
          </TermsText>

          <TermsQuestions>
            ¿Qué necesito para poder descargar la app n1u?
          </TermsQuestions>
          <TermsText>
            Tenés que tener un teléfono celular con sistema operativo Android
            (versión 5.0 o superior) o iOS (versión 11 o superior) y una
            conexión a internet WIFI o red móvil. La podés descargar gratis
            desde Play Store o App Store.
          </TermsText>

          <TermsQuestions>
            ¿Qué costo tiene descargar la app de n1u?
          </TermsQuestions>
          <TermsText>
            ¡Ninguno! Además, la tarjeta n1u Visa internacional que vas a
            obtener es sin cargo de emisión, mantenimiento o cierre.
          </TermsText>

          <TermsQuestions>
            Quise descargarme la app de n1u en mi celular y el store me dice que
            no está disponible en mi país pero estoy en Argentina, ¿qué tengo
            que hacer?
          </TermsQuestions>
          <TermsText>
            La app por ahora está sólo disponible para Argentina. En este caso,
            tu store debe estar configurado en otro país. Lo qué tenés que hacer
            para poder descargar n1u en tu celular es lo siguiente:
            <br />
            <br />
            Si tu celular es Android:
          </TermsText>
          <TermsSubText>
            • Ingresá a “Ajustes” o “Configuración” en tu celular.
            <br />• Entrá a tus cuentas y seleccioná “Añadir una cuenta de
            Google”.
            <br />• Creá una nueva cuenta de Google siguiendo los pasos que te
            indique el sistema.
            <br />• Después, vas al Play Store, seleccioná “Menú” y elegí el
            nuevo usuario que creaste.
            <br />• Con esta cuenta que creaste en Argentina no deberías tener
            problemas para bajarte la app.
          </TermsSubText>

          <TermsText>Si tu celular es iOS:</TermsText>

          <TermsSubText>
            • Ingresá a tu App Store.
            <br />• Entrá a tu cuenta y seleccioná tu cuenta de Apple ID.
            <br />• Elegí la opción “País/Región” y luego “Cambiar el país o la
            región”.
            <br />• Elegí Argentina y aceptá los Términos y Condiciones.
            <br />• Cambiá los datos de facturación de tu cuenta de Apple.
            <br />• Como “Método de pago” seleccioná “Ninguno” y completá los
            datos de facturación con tu domicilio de Argentina.
            <br />• Para más información, ingresá a este link:
            <a href="https://support.apple.com/es-es/HT201389">
              <span>https://support.apple.com/es-es/HT201389</span>
            </a>
          </TermsSubText>

          <TermsQuestions>¿Cómo me registro en n1u?</TermsQuestions>
          <TermsSubText>
            • Descargate la app desde el PlayStore o App Store.
            <br />
            <a href="https://play.google.com/store/apps/details?id=n1u.app&pli=1">
              {" "}
              <span>
                {" "}
                https://play.google.com/store/apps/details?id=n1u.app&pli=1
              </span>
            </a>
            <br />
            <a href="https://apps.apple.com/ar/app/n1u/id6446706311?l=en">
              {" "}
              <span> https://apps.apple.com/ar/app/n1u/id6446706311?l=en</span>
            </a>
            <br />• Ingresá a la app y creá tu nombre de usuario y contraseña.
            <br />• Sacá fotos del frente y dorso de tu DNI y por último, una
            selfie tuya.
            <br />• Te vamos a pedir tu e-mail y número de celular para mandarte
            un código de verificación y validar que sean tuyos.
            <br />• Una vez que te enviemos el código de verificación deberás
            validar tus datos personales y crear tu n1uID.
            <br />• Una vez que termines el registro, nos toca trabajar a
            nosotros para validar tus datos.
            <br />• En general aprobaremos tu cuenta INMEDIATAMENTE.
          </TermsSubText>
          <TermsText>
            Si tenés entre 13 y 17 años, el proceso nos lleva un poco más de
            tiempo porque necesitamos la autorización de un adulto. Si aprobamos
            tu cuenta, te avisaremos por mail o notificación push en tu celular.
          </TermsText>

          <TermsQuestions>
            ¿Dónde actualizo mis datos personales o mi domicilio?
          </TermsQuestions>
          <TermsText>
            En la misma app vas a poder corregir tus datos personales. Desde tu
            perfil haciendo click en tu avatar, ingresando a “Información
            Personal”
          </TermsText>

          <TermsQuestions>
            ¿Para qué me piden fotos del DNI y una selfie mía?
          </TermsQuestions>
          <TermsText>
            En n1u priorizamos tu seguridad. Por eso te vamos a pedir foto de tu
            DNI y una selfie para asegurarnos que sos vos y así poder aprobar tu
            cuenta y darte la tarjeta. Si las fotos se ven mal o si tu DNI está
            vencido, no vamos a poder avanzar con tu validación.
          </TermsText>

          <TermsQuestions>
            ¿Quiénes pueden crear una cuenta en n1u?
          </TermsQuestions>
          <TermsText>
            Es posible abrir tu cuenta en n1u siempre y cuando cumplas con
            alguno de los siguientes requisitos:
          </TermsText>
          <TermsSubText>
            • Tener más de 18 años y DNI emitido en Argentina
            <br />• Tener entre 13 y 17 años y un DNI emitido en Argentina, con
            expresa autorización de padre/madre o tutor/a.
          </TermsSubText>

          <TermsQuestions>
            ¿Si soy extranjero nacionalizado Argentino, puedo tener n1u?
          </TermsQuestions>
          <TermsText>
            Si, mientras que tengas un DNI emitido en Argentina y que la
            residencia sea permanente.
          </TermsText>

          <TermsQuestions>¿Qué es el n1uID?</TermsQuestions>
          <TermsText>
            Sos vos, es tu nombre para la billetera, tu ID que te hace único y
            especial. Con el n1uID podés transferir a tus amigos de n1u.
          </TermsText>

          <TermsQuestions>¿Por qué debo introducir un password?</TermsQuestions>
          <TermsText>
            Al registrarte en n1u tendrás que introducir por primera vez un
            password. Este código será tu clave de acceso cada vez que ingreses
            a la app.
            <br />
            <br />
            Es obligatorio, porque priorizamos la seguridad y queremos
            garantizar que seas efectivamente tú quien visualiza y opera desde
            tu cuenta. Si te resulta más cómodo y tu celular lo permite, podrás
            utilizar la identificación biométrica para acceder a la app. Elige
            esta opción en la pantalla de introducción de tu password o desde la
            sección de seguridad en tu perfil.
            <br />
            <br />
            Te recordamos: NUNCA nadie del equipo de n1u te pedirá tu contraseña
            u otra clave.
          </TermsText>

          <TermsQuestions>Restricción geográfica</TermsQuestions>
          <TermsText>
            Por ahora, n1u solo funciona en Argentina. Pero pronto estará
            disponible en otros países.
          </TermsText>

          <TermsQuestions>¿Qué pasa si olvido mi contraseña?</TermsQuestions>

          <TermsText>
            Sigue estos pasos para restablecer tu contraseña y seguir
            disfrutando de n1u
          </TermsText>
          <TermsSubText>
            • Presioná en Olvidé mi contraseña
            <br />• Escaneá nuevamente tu rostro
            <br />• Generá una nueva contraseña
            <br />• ¡Listo!
          </TermsSubText>

          <TermsQuestions>¿Cómo cambio mi id en n1u?</TermsQuestions>
          <TermsText>
            Seguí estos pasos para cambiar tu Id, recordá que tiene que ser
            único, como vos.
          </TermsText>
          <TermsSubText>
            • Vas a Mis datos personales
            <br />• Luego Nombre elegido
            <br />• ¡Listo!
          </TermsSubText>

          <TermsText>También hay una opción B</TermsText>

          <TermsSubText>
            • En el Home, arriba a la derecha está la opción Tu CVU
            <br />• Te va a mostrar una pantalla debajo donde podés editar tu
            n1uID
            <br />• ¡Listo!
          </TermsSubText>

          <TermsQuestions>¿Cómo doy de baja mi cuenta?</TermsQuestions>
          <TermsSubText>
            • Vas a Mi Perfil - Ayuda
            <br />• Eliminar mi cuenta n1u
            <br />• Respondé las preguntas que te hacemos
            <br />• ¡Listo!
          </TermsSubText>
          <TermsText>
            Para que tenga efecto, deberás primero retirar todo tu saldo, sea de
            pesos o de activos digitales, a otra cuenta o billetera. La baja de
            tu cuenta es definitiva, por lo que una vez eliminada, deberás crear
            otra nuevamente si querés volver a n1u.
          </TermsText>

          <TermsTitle>La tarjeta</TermsTitle>
          <TermsQuestions>
            ¿Qué puedo comprar con la tarjeta virtual prepaga de n1u?
          </TermsQuestions>
          <TermsText>
            La tarjeta virtual de n1u sirve para hacer pagos online,
            suscripciones, débitos automáticos.
          </TermsText>

          <TermsQuestions>
            ¿Cuál es el saldo disponible que tengo para comprar con mi tarjeta
            prepaga n1u Visa?
          </TermsQuestions>
          <TermsText>
            El saldo disponible para comprar es el que tenés cargado en tu
            cuenta n1u. Podés verlo desde el “inicio” de la app de n1u
            ingresando con tu usuario y contraseña. Controlá la plata que tenés
            disponible para comprar.
          </TermsText>

          <TermsQuestions>
            ¿Cuál es la diferencia entre la tarjeta virtual y la física?
          </TermsQuestions>
          <TermsText>
            La tarjeta virtual de n1u sirve únicamente para compras ecommerce,
            suscripciones, débitos automáticos y está habilitada inmediatamente
            desde que la solicitas. Con tarjetas físicas, vas a poder hacer las
            mismas transacciones que con la virtual, realizar compras en tiendas
            físicas y además, realizar extracciones en atm.
          </TermsText>

          <TermsQuestions>¿Cómo solicito la n1u card Visa?</TermsQuestions>
          <TermsText>
            Solo tenés que descargarte n1u, abrir tu cuenta sin costo. Vas a
            poder sacar tu tarjeta virtual y luego la física que te va a llegar
            a tu casa sin costo de envío, mantenimiento y cierre.
          </TermsText>

          <TermsQuestions>¿Puedo bloquear mi tarjeta de n1u?</TermsQuestions>
          <TermsText>
            Sí, para congelar tu tarjeta solamente tenés que entrar a la sección
            “Tarjeta” -> Seleccioná la tarjeta que querés bloquear -> “Pausar
            Tarjeta”. Esto bloquea las transacciones de tu tarjeta virtual o
            física hasta que vos lo decidas. Si querés pausar las dos, tenes que
            hacer el mismo procedimiento en ambas
          </TermsText>

          <TermsQuestions>
            Tengo un consumo en mi tarjeta n1u Visa que no conozco, ¿qué puedo
            hacer?
          </TermsQuestions>
          <TermsText>
            La tarjeta no habilita la posibilidad de desconocer consumos ya que
            es equivalente a dinero en efectivo, siendo el titular, el
            responsable de la totalidad de los movimientos efectuados con la
            tarjeta. Solo es posible desconocer consumos que se hayan realizado
            de forma online luego de haber denunciado la pérdida o robo de la
            tarjeta. Si querés ver más detalles de la compra, andá al inicio de
            la app, seleccioná la compra y vas a ver en los detalles el rubro,
            la hora y el día del consumo. En el caso de las compras de servicios
            digitales del exterior, también vas a encontrar el IVA e impuestos
            aplicados a esa transacción.
          </TermsText>

          <TermsQuestions>
            ¿Puedo pagar impuestos de AFIP con mi n1u card Visa?
          </TermsQuestions>
          <TermsText>
            Podés realizar pagos de impuestos de AFIP siempre y cuando se
            acepten pagos con tarjeta Visa. El pago puede ser a través de
            Internet, por teléfono o incluso adhiriendo tu tarjeta n1u a débito
            automático. Tené en cuenta que al pagar, la AFIP se puede demorar
            hasta 20 días en acreditar el pago. Este plazo no es definido por
            n1u sino de cómo se procesan los pagos con tarjetas en general.
          </TermsText>

          <TermsQuestions>
            ¿A dónde envían mi n1u card Visa física?
          </TermsQuestions>
          <TermsText>
            La tarjeta n1u Visa se envía al domicilio de entrega que vos nos
            indiques al registrarte en la app de n1u o si querés que se envíe a
            otro lugar, lo podes modificar cuando solicites el producto. Tené en
            cuenta que tiene que haber un mayor de 18 años para recibir la
            tarjeta cuando el correo haga la visita.
          </TermsText>

          <TermsQuestions>
            ¿Cómo realizo una compra con mi tarjeta n1u Visa?
          </TermsQuestions>

          <TermsText>
            Para poder realizar una compra, primero tenés que tener saldo en tu
            app n1u. Podés cargar saldo por transferencia virtual y/o bancaria.
            Recordá que los cajeros automáticos todavía no permiten transferir a
            un CVU. Una vez que se acredite el saldo en tu cuenta de n1u, podés
            usar tu tarjeta en cualquier comercio y/o sitio web del mundo que
            acepte Visa. Si vas a comprar a un comercio y presentas tu tarjeta,
            van a pasarla por el POS y cuando se aprueba la transacción, te van
            a dar un comprobante de compra que puede ser que necesiten que
            firmes. Después, te van a entregar una copia de ese comprobante. Si
            la compra la vas a hacer por internet, deberás seleccionar lo que
            vas a comprar y vas siguiendo los pasos que te indique el sitio.
            Cargá los datos de la tarjeta en el sitio: tu nombre tal cual
            aparece en ella, el n° de tarjeta, el vencimiento y el CVV o código
            de seguridad de 3 dígitos que está en la parte de atrás de la
            tarjeta. Algunas páginas también piden los datos de facturación. Ahí
            tenés que completar tu domicilio que ingresaste en el momento de
            registro en la app. Cuando se aprueba la transacción, la página te
            dará un comprobante ahí mismo o te lo manda al e-mail que hayas
            registrado cuando estabas haciendo la compra.
          </TermsText>

          <TermsQuestions>
            ¿Por qué me cobran más en las compras digitales de origen digital
            extranjero?
          </TermsQuestions>

          <TermsText>
            Según el decreto 354/2018, se aplica el 21% de IVA (Impuesto al
            Valor Agregado) en las compras digitales de origen extranjero, más
            el 8% del Impuesto PAIS a través del Decreto 99/2019 y 35% en
            concepto de Percepción RG 4815. Tené en cuenta que si vas a
            suscribirte, por ejemplo, a Netflix o Spotify Premium necesitás
            tener saldo por el importe de la suscripción más el 21% y el 8% de
            ese valor. Para calcular el valor total, deberás sumar, al valor de
            tu compra el 21% y 8% y cargar ese saldo en tu cuenta n1u, sino la
            página va a rechazar la compra por saldo insuficiente. Para calcular
            el IVA, deberás multiplicar el total por 1,21. El resultado es lo
            que vas a tener que pagar
          </TermsText>

          <TermsQuestions>
            ¿Dónde encuentro la tarjeta de n1u en la app?
          </TermsQuestions>
          <TermsText>
            Tener tu tarjeta de n1u es fácil. Podés encontrarla en la app de
            n1u, debajo en la opción “Tarjetas” y listo!.
          </TermsText>

          <TermsQuestions>
            Perdí o me robaron mi celular, ¿tienen acceso a mi n1u card?
          </TermsQuestions>
          <TermsText>
            Los datos de tu tarjeta virtual y física están protegidos por tu
            clave de seguridad para que únicamente vos puedas acceder. De todas
            formas, en caso de pérdida o robo de tu celular te pedimos que nos
            escribas a ayuda@n1u.app con tu caso y DNI para que podamos cerrar
            la sesión en tu dispositivo.
          </TermsText>

          <TermsQuestions>
            Si soy menor de 18 años, ¿puedo recibir yo mi tarjeta? ¿Qué pasa si
            queda en custodia de una sucursal de Andreani?
          </TermsQuestions>
          <TermsText>
            Si tenés entre 13 y 17 años, solo puede recibir tu tarjeta cualquier
            persona mayor de 18 años en el domicilio de entrega. Si te visitaron
            dos veces y no encontraron a nadie, tenés que ir vos a retirar la
            tarjeta por la sucursal donde esté retenida. No olvides ir con tu
            DNI.
          </TermsText>

          <TermsQuestions>
            Perdí o me robaron mi n1u card Visa, ¿qué hago?
          </TermsQuestions>
          <TermsText>
            Pausa la desde la app la tarjeta para que nadie más pueda usarla.
            Andá a la pantalla “Tarjeta” y presioná la opción “reportar”. Va a
            aparecer una opción que dice “Bloqueó permanentemente tu n1ucard
            física” y ahí podes pedir una nueva n1ucard física.
          </TermsText>

          <TermsQuestions>
            No recibí mi n1u card Visa y pasaron más de 15 días hábiles, ¿qué
            tengo que hacer?
          </TermsQuestions>
          <TermsText>
            Primero, ingresá en Andreani para hacer el seguimiento del envío en
            <a href="https://www.andreani.com/#!/personas">
              <span>https://seguimiento.andreani.com.</span>{" "}
            </a>
            Si al final del seguimiento, el estado dice “En custodia” significa
            que Andreani te visitó 2 veces y no pudo concretar la entrega. La
            tarjeta quedará retenida 15 días en la sucursal más cercana al
            domicilio de entrega. Obtené los datos de la sucursal donde está tu
            tarjeta con los horarios y días en los que podés pasar a buscarla.
            Recordá que tenés que ir vos como titular de la tarjeta n1u y llevar
            tu DNI para poder retirarla. Si sos menor de 18 años también podés
            ir vos con tu DNI. Si pasaron más de 15 días hábiles tu tarjeta se
            envía a destrucción. ¡No te olvides de retirarla!
            <br />
            <br />
            También podés escribirnos a ayuda@n1u.app y solicitarnos un reenvio
            al mismo domicilio u otro domicilio mientras Andreani tenga tu
            tarjeta.
          </TermsText>

          <TermsQuestions>
            Puse mi tarjeta como medio de pago en un sitio web y me descontaron
            saldo en la app. ¿Por qué?
          </TermsQuestions>
          <TermsText>
            Los sitios y plataformas web pueden retener un monto de plata para
            validar tu n1u card Visa. Este monto te lo reintegran
            automáticamente. Si no te hacen el reintegro, contactate con el
            comercio para realizar el reclamo.
          </TermsText>

          <TermsQuestions>
            ¿Puedo adherir un servicio de débito automático en mi tarjeta n1u
            Visa?
          </TermsQuestions>
          <TermsText>
            Sí, podés usar tu tarjeta n1u Visa para adherir a un débito
            automático. Comunicate con el comercio que querés asociar y
            asegurate de siempre tener saldo cargado en tu cuenta n1u, de lo
            contrario el pago saldrá rechazado.
          </TermsText>

          <TermsQuestions>
            ¿La n1u card Visa que tipo de tarjeta es?
          </TermsQuestions>
          <TermsText>
            La n1u card Visa es una tarjeta prepaga. Para poder usar tu tarjeta
            n1u, primero tenés que tener saldo en tu app. Una vez que tengas
            saldo, podés comprar en cualquier comercio y/o sitio web del mundo
            que acepten Visa. Recordá, si aceptan Visa, aceptan n1u.
          </TermsText>
          <TermsQuestions>
            ¿Puedo usar mi tarjeta n1u para comprar en cuotas?
          </TermsQuestions>
          <TermsText>Por ahora, solo podés comprar en un pago.</TermsText>

          <TermsQuestions>
            ¿Para qué puedo usar la n1u card Visa?
          </TermsQuestions>
          <TermsText>
            Podés usar tu n1u para hacer compras en todo comercio o sitio web en
            Argentina o el mundo que acepte Visa. Además, podés retirar con tu
            n1u card física efectivo a través de la red de cajeros Banelco o
            Link. Desde el inicio de la app, vas a poder ver tu saldo disponible
            y el detalle de todos los movimientos que hiciste con n1u.
          </TermsText>

          <TermsQuestions>
            ¿Dónde puedo ver el detalle de las compras realizadas con mi n1u
            card Visa?
          </TermsQuestions>
          <TermsText>
            Desde la pantalla “Tarjetas” podés ver todas tus compras. También en
            el home podés ver las transferencias, recargas, débitos automáticos
            y extracciones que hiciste.
          </TermsText>

          <TermsQuestions>
            ¿Puedo usar mi n1u card Visa internacional como medio de pago para
            la venta de productos o servicios que yo comercializo?
          </TermsQuestions>
          <TermsText>
            No, el uso de la tarjeta n1u Visa internacional es exclusivamente
            personal y no comercial.
          </TermsText>

          <TermsQuestions>
            Perdí mi tarjeta física, ¿puedo usar mi tarjeta virtual?
          </TermsQuestions>
          <TermsText>
            Si, podes seguir usando la virtual, igual recordá hacer la denuncia
            por pérdida o robo para que la podamos reimprimir y también por
            seguridad.
          </TermsText>

          <TermsQuestions>
            Intenté realizar una compra con mi n1u card Visa y me sale
            rechazada, ¿qué tengo que hacer?
          </TermsQuestions>
          <TermsText>
            Recordá que necesitás tener saldo para poder realizar cualquier
            compra. Así que, asegurate de tener el monto suficiente para hacer
            la compra. Si la compra que querés hacer es online, el sitio te
            puede pedir que selecciones el tipo de tarjeta.
          </TermsText>
          <TermsSubText>
            1. Probá seleccionando el tipo de tarjeta como crédito o débito y si
            la página te indica que ingreses un banco, elegí “otros”.
            <br />
            2. Asegurate que estés ingresando los datos de la tarjeta tal cual
            aparecen en ella: tu nombre, el n° de tarjeta, el vencimiento, el
            CVV (código de seguridad de 3 dígitos que aparece en la parte de
            atrás de tu tarjeta n1u).
            <br />
            3. Si te piden un domicilio de facturación, ingresá el domicilio
            donde solicitaste tu tarjeta. Recordá que solo podés comprar en un
            pago y no en cuotas. Tené en cuenta que las compras de servicios
            digitales de origen extranjero alcanzadas por el decreto 354/2018
            cobran un 21% más sobre el valor de la compra por el IVA + 8% en
            concepto de Impuesto País a través del Decreto 99/2019 y 35% en
            concepto del Decreto 4815/2020.
          </TermsSubText>

          <TermsText>
            Si la compra es presencial, asegurate que el comercio ingrese tu
            tarjeta n1u en su POS como tarjeta de crédito, en un pago y con los
            datos correctos.
            <br />
            <br />
            Si chequeaste estos pasos y seguís sin poder finalizar tu compra,
            mandanos un mail a ayuda@n1u.app.
          </TermsText>

          <TermsQuestions>
            ¿Qué requisitos tengo que cumplir para poder solicitar la n1u card
            Visa internacional?
          </TermsQuestions>
          <TermsText>
            Tenés que tener DNI argentino vigente y ser mayor de 13 años. Si
            vivís en Argentina pero sos extranjero ¡también podés pedir tu n1u!
            Tené en cuenta que para hacer la solicitud necesitás tener DNI
            argentino vigente.
          </TermsText>

          <TermsTitle>Cargar y retirar plata</TermsTitle>
          <TermsQuestions>
            ¿Cuántas veces puedo extraer en el día y cuáles son los límites de
            extracción?
          </TermsQuestions>
          <TermsText>
            Podés hacer tres extracciones al día por un máximo de $8.500 total.
            Por mes son 60 extracciones como máximo.
          </TermsText>

          <TermsQuestions>
            ¿Cuándo tengo disponible la carga que realicé?
          </TermsQuestions>
          <TermsText>La acreditación es inmediata.</TermsText>

          <TermsQuestions>
            Si hubo un error y me descontaron el saldo pero no pudieron
            entregarme el efectivo, ¿tengo que contactarme con n1u o es
            automático?
          </TermsQuestions>
          <TermsText>
            En este caso deberás esperar al día siguiente. También podés
            escribirnos a través de nuestro chat de la app de n1u o por mail a
            ayuda@n1u.app.
          </TermsText>

          <TermsQuestions>¿Cómo obtengo mi CVU?</TermsQuestions>
          <TermsText>
            Para poder hacer una transferencia a través de CVU, tenés que
            ingresar a n1u y tocar en “Tu CVU” que está en el home arriba a la
            derecha. Ahí te aparecerá la información de tu n1u ID, CVU y Alias.
            También podrás compartirlo a otros para que te transfieran a vos.
          </TermsText>

          <TermsQuestions>
            ¿Las extracciones en efectivo tienen algún costo?
          </TermsQuestions>
          <TermsText>
            En cajeros automáticos Link y Banelco, cada extracción tiene un
            costo de $300 + IVA. El límite diario de extracción es de $8.500
          </TermsText>

          <TermsQuestions>
            ¿Las extracciones en efectivo en el exterior tienen algún costo?
          </TermsQuestions>
          <TermsText>
            Por regulación los impuestos que se aplican en las extracciones de
            USD en ATM del exterior son Ganancias por un 45% e Impuesto País del
            30%, entonces, cuando hagas una extracción fuera del país, te vamos
            a cobrar estos impuestos.
          </TermsText>

          <TermsQuestions>
            ¿Hay un monto mínimo para cargar saldo a mi tarjeta n1u Visa?
          </TermsQuestions>
          <TermsText>
            Por transferencia no hay carga mínima. Si sos menor de edad (13 a 17
            años), la carga no puede superar dos SMVM (salario mínimo vital y
            móvil)
          </TermsText>

          <TermsQuestions>
            ¿Tiene algún costo cargar saldo a mi tarjeta n1u Visa?
          </TermsQuestions>
          <TermsText>
            No tenes cargos adicionales por cargar saldo en la app
          </TermsText>

          <TermsQuestions>
            ¿Hay límite de horario para extraer plata?
          </TermsQuestions>

          <TermsText>No tenés límite de horario de extracción</TermsText>

          <TermsTitle>Pagos y Recargas</TermsTitle>
          <TermsQuestions>
            ¿Cuánto tarda en acreditarse el pago en el servicio?
          </TermsQuestions>
          <TermsText>
            En el momento se va a debitar el pago desde tu cuenta n1u. La
            acreditación en el servicio se realiza dentro de las 24/48 horas
            hábiles. Si no lo ves acreditado, escribinos a ayuda@n1u.app así te
            asistimos. Recordá mantenerte en línea así podemos responderte.
          </TermsText>
          <TermsQuestions>
            ¿Cómo puedo recargar saldo en mis servicios prepagos desde la app?
          </TermsQuestions>
          <TermsText>
            Para cargar saldo a servicios prepagos desde la app de n1u:
          </TermsText>
          <TermsSubText>
            1. Ingresá a la app de n1u y chequeá que tengas saldo.
            <br />
            2. Luego, ingresá a la sección de “Recargas”.
            <br />
            3. Elegí el servicio que querés recargar, completá los datos
            solicitados e ingresá el importe a acreditar.
            <br />
            4. Vamos a pedirte que confirmes la recarga con tu clave de
            seguridad o tu huella digital.
            <br />
            5. Listo!
          </TermsSubText>
          <TermsQuestions>
            ¿Tiene algún costo recargar saldo a un servicio prepago desde la app
            de n1u?
          </TermsQuestions>

          <TermsText>No, la recarga es totalmente GRATIS.</TermsText>
          <TermsQuestions>
            ¿Tiene algún costo pagar un servicio desde la app de n1u?
          </TermsQuestions>
          <TermsText>No, el pago de servicios es totalmente GRATIS.</TermsText>

          <TermsQuestions>
            ¿Qué pasa si no se me acredita el saldo en la SUBE?
          </TermsQuestions>
          <TermsText>
            Luego de seguir los pasos de la app, asegurate de acercarte a una
            Terminal Automática para acreditar el saldo en tu SUBE. Recordá que
            también podés hacerlo desde Dispositivo de Conexión Móvil apto para
            carga SUBE. Cualquier inconveniente adicional, comunicarse con SUBE
            al 0800-777-7823.
          </TermsText>

          <TermsQuestions>
            ¿Puedo cargar otras tarjetas de transporte? ¿Cómo?
          </TermsQuestions>
          <TermsText>
            Sí, podés. Para cargar tu tarjeta de transporte, tenés que ingresar
            en la app de n1u e ir a la sección “Recargas”, seleccionar
            “Recargas” y elegir la tarjeta que querés cargar.
            <br />
            <br />
            Seguí los pasos que te indica la app ingresando el número de la
            tarjeta de transporte que querés cargar, indicá el monto y luego
            confirmá la operación con tu clave de seguridad n1u.
          </TermsText>

          <TermsQuestions>
            Al pagar un servicio a través de la app de n1u, ¿qué comprobante
            conservo?
          </TermsQuestions>
          <TermsText>
            Luego de realizar el pago es importante que guardes el N° de
            transacción. Podés descargar el comprobante de pago desde la sección
            “Pagos” desde la app de n1u ingresando a “últimos pagos” o
            “historial”
          </TermsText>

          <TermsQuestions>
            ¿Me llega alguna notificación del pago que realicé?
          </TermsQuestions>
          <TermsText>
            En el momento que realices tu pago, te va a llegar la confirmación a
            través de una notificación en la pantalla de tu celular.
          </TermsText>

          <TermsQuestions>
            ¿Cómo acredito la carga que le hice a mi SUBE?
          </TermsQuestions>
          <TermsText>
            Luego de cargar tu SUBE con n1u, es necesario acreditar la carga.
            Podés hacerlo en una Terminal Automática, con el dispositivo de
            Conexión Móvil y/o desde la app Carga SUBE Beta
          </TermsText>

          <TermsQuestions>
            Mi factura ya venció, ¿Puedo realizar el pago a través de la app de
            n1u?
          </TermsQuestions>
          <TermsText>
            Si tu factura está vencida, no vas a poder pagarla. Igual, te
            recomendamos que llames a la empresa emisora del servicio para saber
            que otros métodos de pago tenés.
          </TermsText>

          <TermsQuestions>
            ¿Cómo hago para pagar una factura desde la app de n1u?
          </TermsQuestions>
          <TermsSubText>
            1. Ingresá a la app de n1u y chequeá que tengas saldo.
            <br />
            2. Ingresá a la sección “Pagos”.
            <br />
            3. Seleccioná “Pagar Servicios” y elegí cargar por id o escanear el
            código de barra de la factura que querés pagar. Te va a aparecer el
            importe de la factura y el servicio.
            <br />
            4. eleccioná “Deslizar para pagar” y confirmá el pago con tu clave
            de seguridad n1u o tu huella digital y ¡listo!.
          </TermsSubText>

          <TermsText>
            Para descargar el comprobante ingresá a la sección pagos y
            ingresando a “últimos pagos” o “historial”.
            <br />
            <br />
            En el momento que realices tu pago, te va a llegar la confirmación a
            través de una notificación en tu celular.
          </TermsText>

          <TermsQuestions>
            ¿Necesito la factura actual para abonar el servicio?
          </TermsQuestions>
          <TermsText>Sí, la factura debe ser actual.</TermsText>

          <TermsQuestions>
            Cuando hago una recarga en un servicio prepago, ¿cuándo se acredita?
          </TermsQuestions>
          <TermsText>
            La acreditación es instantánea. Si no la ves, aguarda un rato,
            porque puede ser una demora del proveedor en acreditar el pago. Si
            sigue sin aparecer, escribinos a ayuda@n1u.app
          </TermsText>

          <TermsQuestions>
            ¿Cómo pago un servicio desde la app de n1u si no tengo la factura en
            papel?
          </TermsQuestions>
          <TermsText>
            Podés escanear el código de barra desde una foto de la factura del
            servicio que querés pagar.
          </TermsText>
          <TermsQuestions>
            ¿Puedo recargar un servicio prepago que esté a nombre de otra
            persona?
          </TermsQuestions>
          <TermsText>
            Sí, podés recargarle saldo al celular o televisión prepaga de un
            amig@ o familiar.
          </TermsText>
          <TermsSubText>
            1. Ingresá a la app de n1u y chequeá que tengas saldo.
            <br />
            2. Luego, ingresá a la sección de “Pagos”, elegí la empresa de
            telefonía.
            <br />
            3. Seleccioná el n° de teléfono de tu familiar o amigo y, ¡listo!
          </TermsSubText>
          <TermsText>
            En caso de ser un servicio prepago de televisión, ingresá su n° de
            cuenta y, ¡listo!
          </TermsText>

          <TermsQuestions>
            ¿Cuál es el monto mínimo de carga en mi SUBE?
          </TermsQuestions>
          <TermsText>El monto mínimo es de $150.</TermsText>

          <TermsQuestions>
            ¿Cuántas facturas puedo pagar en un día a través de la app de n1u?
            ¿Hay algún límite? ¿Y de importe?
          </TermsQuestions>
          <TermsText>
            No hay límite para la cantidad de facturas que quieras pagar.
          </TermsText>

          <TermsQuestions>¿Qué servicios puedo pagar en n1u?</TermsQuestions>
          <TermsText>
            Hay más de 2.000 servicios que actualmente podés pagar desde tu
            cuenta de n1u a través de la función en la pantalla principal
            “Pagos” que te permitirá buscar y conocer los distintos servicios
            disponibles para pagar. El saldo de tu cuenta en n1u, podrás
            utilizarlo para pagar servicios de energía, agua, telefonía,
            internet y educación y más.
          </TermsText>

          <TermsQuestions>
            ¿Puedo dejar un servicio cargado automáticamente desde la app de
            n1u?
          </TermsQuestions>
          <TermsText>
            Si podes adherir servicios, que no tengas que volver a agendarlo
            cuando vas a utilizarlo.
          </TermsText>

          <TermsQuestions>
            ¿Puedo cargar cualquier tipo de línea de Movistar?
          </TermsQuestions>
          <TermsText>
            No, solamente las prepagas y las control admiten recarga. Por las
            dudas te explicamos la diferencia entre cada plan:
            <br />
            <br />
            Prepagas:no tienen facturas asociadas a la línea. Hay que cargarla
            para usarla
            <br />
            <br />
            Control: tiene una factura que equivale a cierta cantidad de minutos
            y luego permite realizarle cargas
            <br />
            <br />
            Pospagas: son con plan libre y no admiten recargas. Dentro de este
            grupo están incluidas las corporativas.
          </TermsText>

          <TermsQuestions>¿Que es una recarga recurrente?</TermsQuestions>

          <TermsText>
            Vas a poder recargar el celular, el transporte o los servicios que
            encuentres en n1u para recargar de manera automática, nos avisas y
            nosotros vamos a hacerlo por vos, si es con n1u, es más fácil!. Solo
            tenés que tener saldo en tu cuenta, no te olvides de fijarte.
          </TermsText>

          <TermsQuestions>
            ¿Puedo dejar un recarga para que se pague solo desde la app de n1u?
          </TermsQuestions>
          <TermsText>
            Si podes hacerlo, vas a Pagos, Recargas y ahi tenes la opción de
            recargas automáticas, segui los pasos y listo! Vamos a empezar a
            recargar automáticamente el servicio que nos agendaste utilizando tu
            saldo disponible de la app.
          </TermsText>

          <TermsQuestions>
            ¿Cuántos servicios puedo agendar para recargas recurrentes?
          </TermsQuestions>
          <TermsText>
            Los que necesites! No hay límite, el único requisito es que haya
            saldo en tu billetera
          </TermsText>
          <TermsQuestions>
            ¿Puedo modificar una recarga recurrente?
          </TermsQuestions>
          <TermsText>
            Claro, vas a Pagos -> Recargas -> Elegí el servicio que tengas
            agendado como recurrente y vas a poder modificarlo
          </TermsText>
          <TermsQuestions>
            ¿Puedo eliminar una recarga recurrente?
          </TermsQuestions>
          <TermsText>
            Claro, vas a Pagos -> Recargas -> Elegí el servicio que tengas
            agendado y presiona en “Cancelar recarga automática”
          </TermsText>

          <TermsQuestions>
            ¿Puedo ver el historial de una recarga recurrente?
          </TermsQuestions>
          <TermsText>
            Claro, vas a Pagos -> Recargas -> Elegí el servicio que tengas
            agendado y si haces scroll vas a ver el histórico de recargas.
          </TermsText>

          <TermsTitle>Transferencias</TermsTitle>
          <TermsQuestions>
            ¿Se pueden hacer transferencias a través de CVU en otra moneda?
          </TermsQuestions>

          <TermsText>No, solo podés transferir en pesos argentinos.</TermsText>

          <TermsQuestions>
            ¿Cómo agrego amigos o contactos nuevos a “transferencias” dentro de
            mi app?
          </TermsQuestions>
          <TermsText>
            Cuando transferís, podes guardar el contacto haciendo click en
            “Agendar a agenda de destinatarios”, se agrupan así:
          </TermsText>
          <TermsSubText>
            • Guardados: todos aquellos usuarios que guardé al momento de una
            transferencia, sea n1u o no.
            <br />• Contactos del celu: Todos aquellos contactos de mi agenda
            del celular que tengan n1u
            <br />• Mis cuentas: Cuentas propias
          </TermsSubText>

          <TermsQuestions>
            ¿Puedo transferir de mi n1u a otra cuenta bancaria o virtual?
          </TermsQuestions>
          <TermsText>
            ¡Sí! Solo tenés que pedirles su CVU o CBU. Si es n1ubers solo
            necesitas su n1uid
          </TermsText>

          <TermsQuestions>
            ¿Cómo obtengo un comprobante de mi transferencia por CVU?
          </TermsQuestions>
          <TermsText>
            Esto lo podés ver en movimientos. Podés ver fecha, hora, datos de
            origen y destino.
          </TermsText>

          <TermsQuestions>¿Qué es CVU y para qué sirve?</TermsQuestions>
          <TermsText>
            El CVU es la Clave Virtual Uniforme que te sirve para realizar
            transferencias de plata entre cuentas bancarias y virtuales.
          </TermsText>
          <TermsQuestions>
            ¿Cuántas transferencias puedo hacer por día desde la app de n1u?
          </TermsQuestions>
          <TermsText>¡Las que quieras! No hay límite.</TermsText>

          <TermsQuestions>¿Cómo cambio mi alias CVU?</TermsQuestions>
          <TermsText>Seguí estos pasos:</TermsText>
          <TermsSubText>
            • En el Home, arriba a la derecha está la opción Tu CVU
            <br />• Te va a mostrar una pantalla debajo donde podés editar tu
            alias
            <br />• Listo!
          </TermsSubText>

          <TermsQuestions>
            ¿Tiene algún costo la transferencia por CVU?
          </TermsQuestions>
          <TermsText>No, las transferencias también son gratis.</TermsText>

          <TermsQuestions>
            ¿Puedo pasar plata de n1u a una cuenta bancaria?
          </TermsQuestions>
          <TermsText>
            Sí, solo tenés que pedirle a la persona que quieras transferirle
            plata su CBU o Alias. Luego ingresá a la sección transferencias de
            la app, hacé click en nueva transferencia y colocá el CBU/Alias de
            la persona a la que querés transferirle, luego ingresá el monto,
            agregá comentarios (si querés) y por último confirmá con tu clave de
            seguridad o huella digital.
          </TermsText>

          <TermsQuestions>
            ¿Cómo hago una transferencia con mi CVU?
          </TermsQuestions>

          <TermsText>
            Para poder hacer una transferencia a través de CVU, tenés que
            ingresar a la sección transferencias de la app:
          </TermsText>
          <TermsSubText>
            1. Hacer click en nueva transferencia y en el margen superior
            derecho seleccionar “No tiene n1u”.
            <br />
            2. Allí colocá el CVU/CBU/Alias de la persona a la que querés
            transferirle, luego ingresá el monto, agregá comentarios (si
            querés).
            <br />
            3. Por último confirmá con tu clave de seguridad o huella digital.
          </TermsSubText>
          <TermsText>La transferencia es en el acto.</TermsText>

          <TermsQuestions>
            Me pueden transferir desde una cuenta bancaria y/o virtual a mi n1u?
          </TermsQuestions>

          <TermsText>
            ¡Sí! Solo tenés que compartirles tu número de CVU n1u. Debés
            ingresar en “Tu CVU” en el home y luego “Compartir datos”
          </TermsText>

          <TermsQuestions>
            ¿Cuánto demora la transferencia por CVU?
          </TermsQuestions>
          <TermsText>La acreditación es instantánea.</TermsText>

          <TermsQuestions>
            ¿Hay un monto mínimo de transferencia a través de CVU?
          </TermsQuestions>
          <TermsText>
            ¡No! Podés transferir lo que quieras mientras que tengas el saldo
            disponible.
          </TermsText>

          <TermsQuestions>
            ¿Qué hago si transferí dinero por error?
          </TermsQuestions>
          <TermsText>A cuentas bancarias o billeteras digitales</TermsText>
          <TermsSubText>
            • El dinero se manda al instante a la cuenta que hayas elegido, por
            lo que la transacción no se puede cancelar.
            <br /> • Si conocés a la persona destinataria, te recomendamos que
            te contactes con ella para que te devuelva el dinero.
          </TermsSubText>
          <TermsText>
            Desde n1u no podemos anular ni devolver una transferencia una vez
            realizada. Recordá que solo puede devolverte el dinero la persona a
            la que le enviaste la transferencia.
          </TermsText>

          <TermsQuestions>
            Mi transferencia bancaria no se acreditó en n1u
          </TermsQuestions>
          <TermsText>
            A veces, la alta demanda en el sistema bancario puede generar
            demoras de hasta un día hábil.
            <br /> <br />
            Si ya pasó más tiempo, comunicate con esa entidad para pedir más
            información.
          </TermsText>

          <TermsQuestions>¿Necesito tener una cuenta bancaria?</TermsQuestions>
          <TermsText>
            No necesariamente. Podrás enviar y recibir pesos hacia y desde
            cualquier cuenta bancaria (CBU) o cuenta de pago (CVU) radicada en
            Argentina
          </TermsText>

          <TermsQuestions>
            ¿Puedo ingresar dólares desde mi banco a n1u?
          </TermsQuestions>
          <TermsText>
            Por el momento no es posible fondear tu cuenta a partir de una
            transferencia en dólares. Si te registraste con DNI argentino,
            podrás realizar envíos de pesos argentinos desde una cuenta bancaria
            o cuenta de pago que sea de tu titularidad.
          </TermsText>

          <TermsTitle>Política de privacidad</TermsTitle>
          <TermsQuestions>
            ¿Cuál es el procedimiento para ejercer mis derechos con relación a
            mis datos personales?
          </TermsQuestions>
          <TermsText>
            Podrás ejercer tus derechos de acceso, rectificación, actualización
            o supresión a través del mecanismo para modificar tus datos previsto
            en la app y/o conforme lo previsto en la Ley de Protección de Datos
            Personales Nº 25.326, enviando un correo electrónico a:
            ayuda@n1u.app e indicando qué derecho queres ejercer.
          </TermsText>
          <TermsQuestions>
            ¿Para qué usa n1u mis datos personales?
          </TermsQuestions>
          <TermsText>
            Recolectamos y tratamos tus datos personales para prestarte nuestros
            servicios, ponernos en contacto con vos, contarte novedades sobre
            n1u, cumplir con la normativa a la que estamos sujetos, personalizar
            y perfeccionar nuestros servicios, entre otras finalidades que
            detallamos en nuestra Política de Privacidad y que aceptas al usar
            los servicios de n1u.
          </TermsText>
          <TermsQuestions>
            ¿Qué medidas de seguridad y confidencialidad protegen mis datos
            personales?
          </TermsQuestions>
          <TermsText>
            Los datos recabados por n1u se mantendrán en estricta
            confidencialidad y reserva. Para garantizar la seguridad de la
            información se implementan medidas administrativas, técnicas y
            físicas diseñadas para proteger tus datos personales frente a su
            destrucción, pérdida, alteración, acceso, comunicación o uso
            accidental, ilegal o no autorizado. Estas medidas cumplen con los
            estándares que exige la normativa aplicable en materia de protección
            de datos personales.
          </TermsText>
          <TermsQuestions>
            ¿Qué son las cookies y cómo las usa n1u?
          </TermsQuestions>
          <TermsText>
            Las cookies son archivos de datos creados y almacenados en el
            navegador o dispositivo utilizado por los usuarios que permiten
            recolectar información. Nos referimos a datos tales como aquellos
            relativos a la operadora móvil, marca, modelo y sistema operativo
            del dispositivo. También podremos obtener datos de geolocalización,
            monitorear el tráfico, las visualizaciones, interacciones y
            cualquier otra actividad en la app. En consecuencia, usamos las
            cookies para mejorar tu experiencia en la app y con nuestros
            servicios
          </TermsText>
          <TermsQuestions>
            ¿Cómo me entero si n1u modifica la Política de Privacidad?
          </TermsQuestions>
          <TermsText>
            Las Políticas de Privacidad podrán ser modificadas sin previo aviso
            para reflejar los cambios introducidos en nuestras prácticas en
            materia de datos personales, conforme lo exija la regulación que nos
            es aplicable. Cuando se trata de cambios sustanciales, lo vamos a
            comunicar vía mail y/o notificación push en la app y lo publicaremos
            en nuestra página web, en la que encontrarás la política actualizada
            y deberás volver a aceptar.
          </TermsText>
          <TermsQuestions>
            ¿Qué pasa si no proporciono los datos personales que me pide n1u o
            si estos no son exactos?
          </TermsQuestions>
          <TermsText>
            Los datos personales son necesarios para el registro y la prestación
            de los servicios por lo que si no nos proporcionas estos datos o
            estos fueran falsos o inexactos, el servicio se verá afectado e
            incluso podremos cancelar o inhabilitarte como usuari@ y/o
            restringirte el acceso a la app.
          </TermsText>
          <TermsQuestions>¿Dónde trata n1u mis datos?</TermsQuestions>
          <TermsText>
            El tratamiento de los datos personales por parte de n1u y/o de los
            terceros, puede implicar transferencias de datos a otras
            jurisdicciones en virtud de que algunos de los servidores de n1u se
            encuentran alojados en otros países. Cuando así fuera, n1u protegerá
            tus datos personales en conformidad con la Política de Privacidad y
            de la legislación aplicable. Así y siempre que lo requiere la
            regulación aplicable para tales casos, implementamos salvaguardas
            que garantizan un nivel adecuado de protección de los datos en los
            términos de la ley y de las disposiciones de la Agencia de Acceso a
            la Información Pública. También, cuando utilices el servicio de
            activos digitales te vamos a pedir autorización para transferir tus
            datos a El Salvador
          </TermsText>
          <TermsQuestions>
            ¿n1u cumple con la normativa vigente en materia de protección de
            datos personales?
          </TermsQuestions>
          <TermsText>
            Sí. n1u trata tus datos conforme a la Ley de Protección de Datos
            Personales Nº 25.326, su Decreto Reglamentario 1558/01 y toda la
            normativa que emite la Agencia de Acceso a la Información Pública,
            órgano de control de la ley, que tiene la atribución de atender las
            denuncias y reclamos que se interpongan con relación al
            incumplimiento de las normas sobre protección de datos personales.
          </TermsText>
          <TermsQuestions>
            ¿n1u tiene la base de datos registrada?
          </TermsQuestions>
          <TermsText>
            Sí. Las bases de datos personales se encuentran debidamente
            registradas ante la Agencia de Acceso a la Información Pública.
          </TermsText>
          <TermsQuestions>
            ¿Qué derechos tenés como titular de datos personales?
          </TermsQuestions>
          <TermsText>
            Bajo la Ley de Protección de Datos Personales Nº 25.326 tenés
            derecho a acceder, modificar, rectificar, eliminar y actualizar tus
            datos personales. Conforme esta normativa, el derecho de acceso lo
            podrás ejercer de forma gratuita en intervalos no menores a seis
            meses, salvo que acredites un interés legítimo al efecto. Sin
            perjuicio de eso, en tu perfil de cuenta podrás acceder en todo
            momento a esa información y actualizarla.
          </TermsText>
          <TermsQuestions>
            ¿Qué datos personales e información trata n1u sobre mí?
          </TermsQuestions>
          <TermsText>
            Al registrarte en n1u, solo te solicitaremos los datos estrictamente
            necesarios para que puedas utilizar los servicios que ofrecemos como
            por ejemplo, tu nombre, domicilio, teléfono, mail, tu DNI, CUIT o
            CUIL, una selfie para poder identificarte y otros datos que
            detallamos en la Política de Privacidad y que necesitamos para
            prestarte los servicios. Además, te solicitaremos cierta información
            personal para cumplir con la normativa que nos es aplicable como es
            el caso de tu condición de persona expuesta políticamente, la
            declaración sobre el origen y licitud de fondos, entre otras.
            <br />
            <br />
            Por último, podremos recolectar información sobre los dispositivos
            en los que se encuentra instalada la app, tales como aquellos
            relativos a la operadora móvil, marca, modelo y sistema operativo
            del dispositivo, geolocalización y podremos monitorear el tráfico,
            visualizaciones, interacciones y cualquier otra actividad en la app
            (estos datos podrían ser recolectados a través del uso de cookies,
            web beacons, pixels, canvas fingerprinting y/o similares).
          </TermsText>

          <TermsQuestions>
            ¿n1u comparte mis datos personales con terceros?
          </TermsQuestions>
          <TermsText>
            Podremos comunicar los datos personales que recolectamos sólo para
            el cumplimiento de los fines que indicamos en nuestra Políticas de
            Privacidad a nuestras filiales y entidades asociadas, empresas
            vinculadas y cualquier persona humana o jurídica que pudiera estar
            relacionada con n1u y/o con la que n1u tenga un vínculo comercial
            para prestarte los servicios que te ofrecemos. Con relación a ell@s,
            tené en cuenta que lo haremos siempre en cumplimiento de la
            normativa aplicable y que no autorizamos a dichos proveedores de
            servicios a revelar o divulgar ni a usar con finalidades diferentes
            tus datos personales, y que sólo podrá tratarlos para aquellos usos
            que sean estrictamente necesarios para la prestación de los
            servicios en nuestro nombre o para dar cumplimiento a obligaciones
            legales y que hayas dado tu autorización.
            <br />
            <br />
            Por último, podremos revelar tus datos personales (i) si estamos
            obligados a hacerlo por la normativa aplicable; (ii) en el marco de
            un proceso judicial o en cumplimiento de un requerimiento de
            autoridad competente, (iii) a las fuerzas y cuerpos de seguridad o a
            otros funcionarios públicos cuando lo soliciten legítimamente, o
            (iv) en el marco de una investigación de actividades fraudulentas o
            ilegales, o que se presuman fraudulentas o ilegales.
          </TermsText>

          <TermsTitle>Claves y Seguridad</TermsTitle>
          <TermsQuestions>
            Perdí mi celular, ¿qué tengo que hacer?
          </TermsQuestions>
          <TermsText>
            Si en tu celular quedó la app de n1u la sesión abierta, escribinos
            un mail a ayuda@n1u.app contándonos esto mismo. Vamos a cerrar la
            sesión de la app en tu celular extraviado para proteger tus datos y
            para que puedas bajarte la app en un nuevo celular e iniciar sesión
            con tu mismo usuario y contraseña sin problemas. Tené en cuenta que
            no pueden haber dos sesiones abiertas de una misma cuenta en
            dispositivos diferentes, por eso, cuando ingreses en un nuevo
            celular te vamos a avisar que la sesión en el anterior se va a
            cerrar y tendrás que realizar otra vez la verificación de seguridad
            para confirmar que eres vos el que está haciendo el cambio.
          </TermsText>
          <TermsQuestions>
            Se contactaron conmigo por redes sociales pidiéndome mi usuario y
            contraseña de n1u, ¿qué hago?
          </TermsQuestions>
          <TermsText>
            Nunca compartas tus claves (incluso si el contacto parece venir de
            n1u) y tampoco dejes que te vean cuando las ingresás en la app de
            n1u.
          </TermsText>
          <TermsQuestions>
            Se contactaron conmigo por redes sociales pidiéndome los datos de mi
            tarjeta n1u Visa, ¿qué hago?
          </TermsQuestions>
          <TermsText>
            Nunca compartas los datos de tu tarjeta, ni muestres los números que
            están adelante y atrás de ella. Tampoco respondas un mail o un
            llamado telefónico pidiéndote los datos de tu tarjeta, incluso si
            parece venir de n1u.
          </TermsText>
          <TermsQuestions>
            Perdí o me robaron mi celular y mi tarjeta n1u, ¿qué tengo que
            hacer?
          </TermsQuestions>
          <TermsText>
            Primero tenés que denunciar la pérdida o robo de la tarjeta en la
            app.
            <br />
            <br />
            Si en tu celular quedó la app de n1u abierta, escribinos un mail a
            ayuda@n1u.app para avisarnos lo que te pasó. Vamos a cerrar la
            sesión de la app en tu celular extraviado para proteger tus datos y
            para que puedas bajarte la app en un nuevo celular e iniciar sesión
            con tu mismo usuario y contraseña sin problemas.
            <br />
            <br />
            Tené en cuenta que no pueden haber dos sesiones abiertas en la app
            de la misma cuenta por ello es necesario que cerremos la sesión en
            el celular extraviado para que puedas utilizar la app en otro
            celular.
          </TermsText>
          <TermsQuestions>¿Para qué sirve mi huella digital?</TermsQuestions>
          <TermsText>
            En los celulares con reconocimiento de huella digital, podés usar tu
            dedo para reemplazar la clave de seguridad. Sirve para confirmar
            todos los movimientos de plata desde la app. Te la vamos a pedir
            cuando quieras confirmar una transferencia o cuando quieras cargar
            saldo a tu celular o televisión prepaga.
            <br />
            <br />
            Para registrarla andá al inicio de la app:
          </TermsText>
          <TermsSubText>
            1. Seleccioná en el menú inferior de la derecha “Mas”. Mi Perfil >
            Seguridad > Datos biométricos.
            <br />
            2. Te vamos a pedir que ingreses tu clave de seguridad para
            confirmar la activación de tu huella digital y, ¡listo!
          </TermsSubText>

          <TermsQuestions>
            Le hice una compra a una persona, le pagué a través de n1u y no me
            entregó el producto.
          </TermsQuestions>
          <TermsText>
            Cuando hagas una compra a alguien que no conocés, asegurate que se
            trate de una persona de confianza ya que n1u es un medio de pago y
            no es responsable por las transacciones entre las partes ni por la
            entrega del producto o servicio.
            <br />
            <br />
            Si el pago fue a otro n1uber, podemos inhabilitar su cuenta por
            fraudulenta. ¡Es importante que nos avises!
          </TermsText>

          <TermsQuestions>
            Voy a hacer una compra por internet, ¿es seguro?
          </TermsQuestions>
          <TermsText>
            Antes de realizar una compra por internet, asegurate que se trate de
            un sitio seguro. Para esto, fijate que el sitio donde estás
            realizando la compra tenga un candado en la parte superior
            izquierda.
          </TermsText>

          <TermsTitle>Glosario</TermsTitle>

          <TermsQuestions>¿Qué significa ser Sujeto Obligado?</TermsQuestions>
          <TermsText>
            Ser Sujeto Obligado, de acuerdo al Artículo 20 de la Ley 25.246,
            significa que por la actividad que n1u realiza, n1u está obligada a
            obtener datos de sus clientes con el fin de reportar ciertos
            movimientos a la UIF con el fin prevenir el lavado de dinero y la
            financiación del terrorismo.
          </TermsText>
          <TermsQuestions>¿Qué es la intermediación financiera?</TermsQuestions>
          <TermsText>
            Es la actividad que realizan los bancos y demás entidades
            financieras que consiste en utilizar los depósitos de los ahorristas
            para prestarlo a otros agentes del sistema financiero y luego
            ponerlo a disposición de su titular cuando lo necesite. Es decir, un
            banco usa una parte de la plata de un cliente “A” para prestársela a
            otro cliente “B” y eso es intermediación financiera. n1u no presta
            la plata de sus usuarios. Por tal motivo, cada usuario de n1u cuenta
            con su dinero disponible en su cuenta cuando lo requiera. Cuando n1u
            otorga préstamos a sus usuarios, no lo hace con la plata de sus
            usuarios sino que lo hace con plata de la empresa.
          </TermsText>

          <TermsQuestions>¿Qué es una fintech?</TermsQuestions>
          <TermsText>
            “Fintech” proviene de la combinación de palabras en inglés “Finance”
            (finanzas) y “Technology” (tecnología). Este término se utiliza para
            hacer referencia a las empresas que, como n1u, proveen servicios
            financieros a través de medios digitales tales como apps.
            <br />
            <br />
            Las fintechs ofrecen herramientas tecnológicas que ayudan a la
            realización de operaciones financieras de forma eficiente, sencilla
            y más accesible.
          </TermsText>

          <TermsQuestions>
            ¿Qué es la Unidad de Información Financiera?
          </TermsQuestions>
          <TermsText>
            La Unidad de Información Financiera (UIF) es el organismo público
            argentino encargado del análisis, tratamiento y transmisión de
            información financiera de personas humanas y jurídicas con el fin de
            prevenir el lavado de dinero y la financiación del terrorismo. A
            través de ciertas personas (los Sujetos Obligados), la UIF obtiene
            información necesaria para detectar actividades que podrían dar
            lugar a la comisión de estos delitos y sancionarlos para garantizar
            la integridad del sistema financiero.
          </TermsText>

          <TermsQuestions>¿Qué es OCDE?</TermsQuestions>
          <TermsText>
            Es la Organización para la Cooperación y el Desarrollo Económico.
            Dado a que OCDE firmó un acuerdo con Argentina es necesario que las
            personas declaren si por su nacionalidad o actividades deban pagar
            impuestos en otros países.
          </TermsText>

          <TermsQuestions>¿Qué es FATCA?</TermsQuestions>

          <TermsText>
            Es la Ley de EE. UU. que exige consultar si por tu nacionalidad,
            residencia o algún motivo tenés que pagar impuestos en EE. UU. En
            caso de tener que hacerlo, se te va a pedir que completes un
            formulario específico que pide el organismo recaudador de impuestos
            de EE. UU.
          </TermsText>
          <TermsTitle>Activos digitales</TermsTitle>
          <TermsQuestions>
            ¿Qué operaciones con activos digitales puedo realizar a través de la
            App de n1u?
          </TermsQuestions>
          <TermsText>
            Podés comprar, vender y hacer swap muy fácil, con n1u y Koibanx
            Salvador, en cualquier momento y súper rápido.
          </TermsText>

          <TermsQuestions>¿Qué son los activos digitales?</TermsQuestions>
          <TermsText>
            Un activo digital es una moneda digital, protegida por tecnología
            escrita en clave y diseñada para funcionar como medio de
            intercambio. Se asegura y verifica transacciones, así como para
            controlar la creación de nuevas unidades de una moneda particular.
            <br />
            <br />
            Esencialmente, los activos digitales son entradas limitadas en una
            base de datos que nadie puede cambiar a menos que se cumplan
            condiciones específicas.
            <br />
            <br />
            ¿Cómo compro activos digitales?
            <br />
            <br />
            Para comprar a través de la app de n1u, tenés que seguir estos
            pasos:
          </TermsText>
          <TermsSubText>
            1. En el home, elegís la sección de activos digitales
            <br />
            2. Seleccioná la opción de ‘Compra Venta’
            <br />
            3. Luego, ‘Comprar’ elegí la moneda y el monto que quieras operar.
            <br />
            4. Revisá la cotización, confirmá y listo.
            <br />
            5. Una vez realizado esto, vas a poder ver tus criptos en tu cuenta.
          </TermsSubText>

          <TermsQuestions>¿Qué es la blockchain?</TermsQuestions>
          <TermsText>
            Blockchain (o cadena de bloques) es un conjunto de tecnologías que
            permiten llevar un registro descentralizado, sincronizado y
            distribuido de las operaciones digitales, sin necesidad de la
            intermediación de terceros. Es como un gran libro contable donde se
            registran y aprueban todas las operaciones.
          </TermsText>

          <TermsQuestions>¿Cómo vendo activos digitales?</TermsQuestions>
          <TermsText>
            Para vender a través de la app de n1u, tenés que seguir estos pasos:
          </TermsText>
          <TermsSubText>
            1. En el home, elegís la sección de activos digitales.
            <br />
            2. Seleccioná la opción de ‘Compra Venta’
            <br />
            3. Luego ‘Vender’, elegí la moneda y el monto que quieras operar.
            <br />
            4. Revisá la cotización, confirmá y listo.
            <br />
            5. Una vez realizado esto, vas a poder ver tu saldo en ‘Tus pesos’.
          </TermsSubText>

          <TermsQuestions>
            ¿Dónde puedo ver las operaciones que realicé con activos digitales?
          </TermsQuestions>
          <TermsText>Podés verlas siguiendo estos pasos:</TermsText>
          <TermsSubText>
            1. Ingresá a la sección de tus activos digitales.
            <br />
            2. Mirá el detalle de las compras y ventas que realizaste.
          </TermsSubText>

          <TermsQuestions>
            ¿Puedo transferir o recibir activos digitales desde o hacia una
            billetera o wallet?
          </TermsQuestions>
          <TermsText>
            Podés enviar o recibir activos a través de n1u hacia cualquier
            billetera.
          </TermsText>
          <TermsQuestions>
            ¿Puedo hacer pagos con activos digitales?
          </TermsQuestions>
          <TermsText>
            No se encuentra habilitado el pago con activos digitales. Por el
            momento, sólo podés comprar, vender y hacer swap de monedas,
            enviar/recibir desde/hacia otra billetera a través de n1u.
          </TermsText>
          <TermsQuestions>
            ¿Qué requisitos tengo que cumplir para poder operar con activos
            digitales a través de la app de n1u?
          </TermsQuestions>
          <TermsText>
            Tenés que ser usuari@ de n1u, aceptar los términos y condiciones, la
            política de privacidad de Koibanx Salvador y compartir tus datos.
          </TermsText>
          <TermsQuestions>¿Koibanx Salvador es un exchange?</TermsQuestions>
          <TermsText>
            Si, lo es y ofrece el servicio de compra, venta y custodia de
            activos digitales con licencia de la Comisión de Servicios
            Financieros de El Salvador.
          </TermsText>

          <TermsQuestions>
            ¿Cuáles son algunos de los riesgos asociados a la operatoria con
            activos digitales?
          </TermsQuestions>
          <TermsText>
            Algunos de los riesgos asociados a la operatoria están relacionados
            con su cotización. Las monedas son volátiles, es decir, fluctúan por
            los movimientos del mercado (oferta y demanda) pudiendo generar
            ganancias en algunos casos y pérdidas en otros. Para más información
            consultá los TyC.
            <br />
            Por la incorporación de activos digitales al ecosistema n1u, ¿me van
            a empezar a cobrar algo adicional?
            <br />
            No, no tiene costos adicionales.
          </TermsText>

          <TermsQuestions>
            ¿Cuál es el mínimo de compra de activos digitales?
          </TermsQuestions>
          <TermsText>Podés comprar desde $500.</TermsText>

          <TermsQuestions>
            ¿Cómo empezar a invertir en activos digitales?
          </TermsQuestions>
          <TermsText>
            ¡Es súper fácil! En menos de 1 minuto, abrís una cuenta entrando a
            la sección de actuvos digitales dentro de la app, seguís los pasos,
            y ¡listo! Ya podés realizar tu primera compra utilizando tu saldo
            disponible en la app.
          </TermsText>

          <TermsQuestions>
            ¿Cuánta plata se necesita para invertir?
          </TermsQuestions>
          <TermsText>
            Te informamos los límites actuales para que empieces a comprar
            activos digitales a través de la app de n1u.
            <br />
            <br />
            Límites para compra/venta de activos digitales contra pesos
            <br />
            <br />• Mínimos:
          </TermsText>
          <TermsSubText>
            • Mínimo de compra de activos digitales usando pesos: $500
            <br />• Mínimo de venta de activos digitales contra pesos: $500
          </TermsSubText>
          <TermsText>• Máximos:</TermsText>
          <TermsSubText>
            Máximos de compra de activos digitales usando pesos:
          </TermsSubText>
          <TermsSubSubText>
            • Diario: $ 300,000
            <br />• Mensual: $ 600,000
            <br />• Anual: $ 5,400,000
          </TermsSubSubText>
          <TermsSubText>
            Máximos de venta de activos digitales contra pesos:
          </TermsSubText>
          <TermsSubSubText>
            • Diario: $300,000
            <br />• Mensual: $ 600,000
            <br />• Anual: $ 5,400,000
          </TermsSubSubText>

          <TermsText>
            Límites para compra/venta y retiros de activos digitales:
          </TermsText>

          <TermsSubText>• Mínimos:</TermsSubText>
          <TermsSubSubText>
            • Mínimo de compra de activos digitales contra activos digitales:
            Equivalente a 1 USD
          </TermsSubSubText>
          <TermsSubText>• Máximos:</TermsSubText>
          <TermsSubSubText>
            • Intercambio de activos digitales contra activos digitales:
          </TermsSubSubText>
          <TermsSubSubSubText>
            • Diario: Sin límite
            <br />• Mensual: Sin límite
            <br />• Anual: Sin límite
          </TermsSubSubSubText>
          <TermsSubSubText>• Retiros:</TermsSubSubText>
          <TermsSubSubSubText>• Activos digitales:</TermsSubSubSubText>
          <TermsSubSubSubSubText>
            • Diario: Equivalente a 1,000 USD
            <br />• Mensual: Equivalente a 10,000 USD
            <br />• Anual: Equivalente a 36,000 USD
          </TermsSubSubSubSubText>

          <TermsQuestions>
            ¿Cómo deposito activos digitales en n1u?
          </TermsQuestions>
          <TermsText>
            Así como puedes depositar pesos, también puedes transferir activos
            digitales a n1u desde una wallet. Muy simple. Desde tu billetera,
            pulsando sobre la sección de activos digitales y luego en “Recibir”,
            podrás elegir la{" "}
            <a href="https://help.belo.app/es/articles/5423390-que-son-las-criptomonedas">
              <span>moneda</span>
            </a>{" "}
            digital que quieras depositar. Allí accedés a tu dirección y su
            código QR, para copiar o escanear desde tu
            <a href="https://help.belo.app/es/articles/5423391-que-es-una-billetera-o-wallet">
              <span> billetera</span>{" "}
            </a>
            y enviar los fondos.
            <br /> <br />
            <b> No reconozco un movimiento, ¿qué hago?</b>
            <br /> <br />
            Si no reconoces el movimiento, escribinos a ayuda@n1u.app
            contándonos la situación para así poder analizarla en profundidad.
            Es fundamental tomar medidas preventivas para este tipo de
            situaciones. Algunas de nuestras recomendaciones son:
          </TermsText>
          <TermsSubText>
            • En caso de perder o sufrir el robo de tu celular, avisanos lo
            antes posible.
            <br />• No compartas tu clave de acceso o cuenta con nadie, ni
            siquiera con alguien que se hace pasar por algún área de atención al
            cliente de n1u
            <br />• Creá una password única, y que no contenga información
            personal como tu fecha de nacimiento.
            <br />• Si vas a hacer una compra, revisá la veracidad del medio que
            publica el producto y/o servicio
          </TermsSubText>

          <TermsQuestions>¿Tengo ganancias aseguradas?</TermsQuestions>
          <TermsText>
            Nunca nadie puede asegurarte que vas a obtener ganancia porque los
            precios de los activos digitales fluctúan por los movimientos del
            propio mercado (oferta y demanda). Esto implica que su volatilidad
            puede ser beneficiosa en algunas ocasiones y ocasionar pérdidas en
            otras.
            <br /> <br />
            Por eso siempre te sugerimos que antes de tomar la decisión de
            invertir, te informes a través de especialistas en el tema respecto
            a la inversión que estás interesado en realizar.
            <br /> <br />
            <b>
              {" "}
              ¿Comparte n1u información al BCRA o AFIP sobre las compras de
              activos digitales?
            </b>
            <br /> <br />
            Por el momento, n1u no está obligado a reportar sobre los
            movimientos al BCRA. No obstante, estamos a disposición de lo que
            las normativas vigentes establezcan.
          </TermsText>
          <TermsTitle>GifCards</TermsTitle>
          <TermsQuestions>¿Qué es una giftcard?</TermsQuestions>
          <TermsText>
            Es una n1u card Visa para que regales con un monto determinado para
            que puedas gastar en el local que quieras
          </TermsText>
          <TermsQuestions>
            ¿Se lo puedo regalar a cualquier persona o tiene que ser n1uber?
          </TermsQuestions>
          <TermsText>
            Se lo podes regalar a quien vos quieras, sin restricción, sea o no
            sea n1uber. Para usarla, va a necesitar descargarse n1u y
            convertirse en un n1uber porque la tarjeta es virtual. ¿Ya pensaste
            en alguien?
          </TermsText>
          <TermsQuestions>¿Yo defino el monto?</TermsQuestions>
          <TermsText>
            Si, vos elegís el monto que querés regalar. Importante: fíjate que
            ese monto lo tengas disponible, si no vas a tener que hacer una
            transferencia antes.
          </TermsText>
          <TermsQuestions>¿Tiene un solo diseño?</TermsQuestions>
          <TermsText>¡Podes elegir entre 8 geniales motivos!</TermsText>
          <TermsQuestions>¿Tiene vencimiento?</TermsQuestions>
          <TermsText>
            La n1u giftcard Visa, tiene una validez de 1 año desde que lo activa
            la persona que se lo regalaste.
          </TermsText>
          <TermsQuestions>¿Puedo reutilizarla?</TermsQuestions>
          <TermsText>
            No, cuando se acaba el saldo, la n1u giftcard Visa queda
            inahibilitada
          </TermsText>
          <TermsQuestions>
            Paso más de 1 año y quedó saldo, ¿Qué pasa?
          </TermsQuestions>
          <TermsText>
            Ese saldo remanente, se devuelve al n1uber que regaló la giftcard
          </TermsText>
          <TermsQuestions>
            ¿Puedo saber cuantas n1u giftcards Visa regalé?
          </TermsQuestions>
          <TermsText>
            Claro! Dentro de giftcards vas a poder ver las que enviaste y
            también las que recibiste.
          </TermsText>

          <TermsTitle>3 - Comisiones</TermsTitle>
          <table>
            <thead>
              <tr>
                <th>Costos y Comisiones</th>
                <th>Costos hasta 29/12/2023</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Costo de envío</td>
                <td>$0,00</td>
              </tr>
              <tr>
                <td>Costo de mantenimiento</td>
                <td>$0,00</td>
              </tr>

              <tr>
                <td>Costo de renovación de tarjeta</td>
                <td>$0,00</td>
              </tr>
              <tr>
                <td>Costo solicitud Adicional</td>
                <td>$0,00</td>
              </tr>

              <tr>
                <td>Costo de extracciones en pesos</td>
                <td>$450,00</td>
              </tr>
              <tr>
                <td>Costo por reimpresión</td>
                <td>$700,00</td>
              </tr>

              <tr>
                <td>Costo por reposición</td>
                <td>$700,00</td>
              </tr>
              <tr>
                <td>Tipo de cambio</td>
                <td>BNA +6,5%</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <TermsTitle>Pago con Activos digitales</TermsTitle>

          <TermsQuestions>
            1. ¿Cómo configuro una moneda para pagar con mi tarjeta?
          </TermsQuestions>
          <TermsText>
            Los pagos con tu tarjeta pueden estar hechos con pesos argentinos o
            con activos digitales. Conocé acá cómo configurar la moneda de pago.
            Una vez que actives tu n1u card, vas a tener la moneda de pesos
            argentinos configurada para realizar todos tus pagos. Sin embargo,
            podés elegir con qué moneda pagar! Seguí estos simples pasos para
            configurar tu moneda de pago:
          </TermsText>

          <TermsSubText>
            • Ingresá a la app.
            <br />
            • Dirígete a la sección de Perfil.
            <br />
            • Hacé click en Configuración de Pagos.
            <br />
            • Elegí la moneda principal que querés usar ¡Y listo!
            <br />
          </TermsSubText>

          <TermsText>
            Asegurate de tener el saldo suficiente en la moneda elegida para
            hacer el pago.
          </TermsText>

          <TermsQuestions>2. ¿Puedo elegir cualquier moneda?</TermsQuestions>
          <TermsText>
            Por el momento, podés elegir entre pesos argentinos (ARS), o
            cualquiera de las monedas disponibles para pagos en la plataforma.
            Más adelante vamos a sumar muchas más. Si tenés alguna duda,
            escribinos a ayuda@n1u.app.
          </TermsText>

          <TermsQuestions>3. ¿Puedo cambiar la moneda elegida?</TermsQuestions>
          <TermsText>
            Si, vas a poder cambiar la moneda principal cuando vos quieras antes
            de hacer una compra o dejarla establecida para todas tus compras con
            tarjeta.
          </TermsText>

          <TermsQuestions>
            4. ¿Por qué no me aparece la opción de Configuración de Pagos?
          </TermsQuestions>
          <TermsText>
            Es posible que tu cuenta esté configurada para un menor, y el tutor
            no haya autorizado la compra/venta de activos digitales. Si no es
            así, probá actualizando la app.
          </TermsText>

          <TermsQuestions>
            5. ¿Qué pasa cuando pago en mi moneda elegida con mi n1u card?
          </TermsQuestions>

          <TermsText>
            Al hacer un pago con tu tarjeta en la moneda elegida, tus monedas se
            cambian de forma automática por ARS para pagarle al comercio. Por
            este cambio, tendrás un 0,5% de gasto de comisión. Tené en cuenta
            que las devoluciones parciales o totales, sean por desconocimientos,
            devoluciones o ajustes, siempre se realizan en pesos,
            independientemente de la moneda utilizada, tomando el monto en ARS
            cobrado por el comercio.
          </TermsText>

          <TermsQuestions>
            6. ¿Estas compras con tarjeta suman n1u coins?
          </TermsQuestions>

          <TermsText>
            Si, las compras que realices pagando con tu n1u card de al menos
            $150 ARS, independientemente de la moneda elegida, sumarán n1u coins
            para luego disfrutar los beneficios en el store.
          </TermsText>

          <TermsQuestions>
            7. ¿Qué cotización van a tomar para vender mis activos digitales?
          </TermsQuestions>

          <TermsText>
            Se toma el precio de venta de la moneda elegida, al momento de hacer
            la compra en el local.
          </TermsText>

          <TermsQuestions>
            8. ¿Si hago una compra en USD, me van a cobrar los mismos impuestos
            aplicables?
          </TermsQuestions>

          <TermsText>
            Si, todas las compras en moneda extranjera van a tener aplicados los
            impuestos correspondientes a compras en el exterior. El saldo total
            del monto convertido a ARS + Impuestos, va a ser lo que debitemos de
            tu saldo en la moneda elegida.
          </TermsText>

          <TermsQuestions>9. ¿Incluye extracciones ATM?</TermsQuestions>

          <TermsText>
            No, las extracciones que hagas en el cajero automático se debitan de
            tus pesos.
          </TermsText>

          <TermsQuestions>
            10.¿Si cancelo una compra que realicé con mi tarjeta, me devolverán
            el monto en la moneda elegida?
          </TermsQuestions>

          <TermsText>
            No, si cancelas una compra que hayas realizado con la tarjeta
            recibirás la devolución del monto en pesos argentinos. Además, las
            n1u coins que hayas recibido por la compra se descontarán.
          </TermsText>

          <TermsQuestions>11. ¿Hay un monto mínimo para operar?</TermsQuestions>
          <TermsText>
            El pago mínimo con la moneda elegida es de $ 100 pesos, en caso de
            que realices una compra con tarjeta menor a ese monto la operación
            será rechazada.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default FaqsTerms;
