import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function Costs() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer style={{ color: "white" }}>
          <TermsTitle>Costos y comisiones</TermsTitle>
          <table>
            <thead>
              <tr>
                <th>Costos y Comisiones</th>
                <th>Importe vigente hasta 17/03/2024</th>
                <th>Importe  previsto desde 18/03/2024</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Costo de envío</td>
                <td>$0,00</td>
                <td>$0,00</td>
              </tr>
              <tr>
                <td>Costo de mantenimiento</td>
                <td>$0,00</td>
                <td>$0,00</td>
              </tr>

              <tr>
                <td>Costo de renovación de tarjeta</td>
                <td>$0,00</td>
                <td>$0,00</td>
              </tr>
              <tr>
                <td>Costo solicitud Adicional</td>
                <td>$0,00</td>
                <td>$0,00</td>
              </tr>

              <tr>
                <td>Costo de extracciones en pesos</td>
                <td>$825,00</td>
                <td>$2.700,00</td>
              </tr>
              <tr>
                <td>Costo por reimpresión</td>
                <td>$1.575,00</td>
                <td>$5.000,00</td>
              </tr>

              <tr>
                <td>Costo por reposición</td>
                <td>$1.575,00</td>
                <td>$5.000,00</td>
              </tr>
              <tr>
                <td>Tipo de cambio</td>
                <td>BNA +6,5%</td>
                <td>BNA +7,0%</td>
              </tr>
            </tbody>
          </table>
          <TermsText>
            Todos los costos y las comisiones están expresados sin IVA incluído.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Costs;
