import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import AnimatedText from "../components/AnimatedText";
import { Link } from "react-router-dom";
import note1desktop from "../assets/wiki/notes/1-desktop.png"
import note2desktop from "../assets/wiki/notes/2 desktop.png"
import note1mobile from "../assets/wiki/notes/1-mobile.png"
import note2mobile from "../assets/wiki/notes/2 mobile.png"
import note3mobile from "../assets/wiki/notes/3 mobile.png"
import note3desktop from "../assets/wiki/notes/3 desktop.png"
import izq from "../assets/wiki/izq.png"

const Notes = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const carouselRef = useRef(null);

  return (
    <>
      <NotesSection>
        <TitleContainer>
          <h1>notas</h1>
        </TitleContainer>
        <CarouselContainer>
          <Carousel
            itemsToShow={
              width > 1200 ? 2.5 : width > 800 ? 2.5 : width > 500 ? 1.5 : 1.2
            }
            pagination={false}
            ref={carouselRef}
          >
           
            <Link to="https://n1uapp.notion.site/n1uapp/Gamificar-las-finanzas-para-educar-e-incluir-acc32b9955b347f196882d6beefa986f" target="_blank">
              <Item>
                <Content>
                  <ImageContainer src={width > 1025 ? note1desktop : note1mobile} alt="note 1"/>

                  <div className="text">
                    <AnimatedText>
                      <h1>Gamificar las finanzas para educar e incluir</h1>
                    </AnimatedText>
                    <button>
                      <p>accedé aca</p>
                    </button>
                  </div>
                </Content>
              </Item>
            </Link>

            <Link to="https://n1uapp.notion.site/El-camino-del-emprendedor-aciertos-ca-das-y-aprendizajes-1-cb1aecdd31c04b108f15eca95542af90?pvs=4" target="_blank">

           

            <Item>
              <Content>

                <ImageContainer src={width > 1025 ? note2desktop : note2mobile}  alt="note 2"/>

                <div className="text">
                  <AnimatedText>
                    <h1>
                      El camino del emprendedor: aciertos, caídas y aprendizajes{" "}
                    </h1>
                  </AnimatedText>
                  <button>
                    <p>accedé aca</p>
                  </button>
                </div>
              </Content>
            </Item>

            </Link>
            <Link to="https://n1uapp.notion.site/Desaf-o-fintech-conectar-a-los-j-venes-con-sus-finanzas-ab8dbfdedc9e46dea48882b38e06a279?pvs=4" target="_blank">

            <Item>
              <Content>

                <ImageContainer src={width > 1025 ? note3desktop : note3mobile}  alt="note 3"/>

                <div className="text">
                  <AnimatedText>
                    <h1>
                    Desafío fintech: conectar a los jóvenes con sus finanzas
                    </h1>
                  </AnimatedText>
                  <button>
                    <p>accedé aca</p>
                  </button>
                </div>
              </Content>
            </Item>
            </Link>
          </Carousel>
        </CarouselContainer>

        {width > 600 ? (<>
          <ButtonContainer>
          
          <img
            LeftButton
            onClick={() => carouselRef.current.slidePrev()}
            src={izq}
            alt="button left"
          />

          <img
            onClick={() => carouselRef.current.slideNext()}
            src={izq}
            alt="button right"
            className="derecha"
          />
      
        </ButtonContainer>
        </>) : (<></>)}
       
      </NotesSection>
    </>
  );
};

const NotesSection = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1600px;
  padding: 0px 0px 50px 0px;
`;

const TitleContainer = styled.div`
  height: auto;
  width: 90%;
  display: flex;
  max-width: 1600px;

  h1 {
    color: #fff;
    font-family: "LoRes";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    margin-left: 30px;
  }


  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 30px;
    }
 
  }

  
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 1600px;
  margin-left: 0px;

  .rec-arrow {
    display: none;
  }
`;

const Item = styled.div`
  height: 300px;
  width: 520px;
  background-color: #9d87d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
  color: black;
  margin: 30px 0px;
  border-radius: 15px;
  cursor: auto;

  h1 {
    font-size: 22px;
    margin: 10px 0;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.02em;
    font-weight: 600;
    margin-top: 5px;
    max-width: 380px;
  }

  p {
    font-size: 12px;
    margin: 10px 0;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.02em;
    font-weight: 400;
    max-width: 280px;
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 1300px) {
    height: 340px;
    width: 380px;
    margin: 20px 0px;


    h1 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 1025px) {
    height: 300px;
    width: 300px;
    margin: 10px 0px;
    h1 {
      font-size: 16px;
      width: 180px;
    }
  }

  @media only screen and (max-width: 395px) {
    width: 280px;}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 90%;
  position: relative;
  @media only screen and (max-width: 1300px) {
    height: 95%;
  }

  .text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  button {
    border-radius: 20px;
    background-color: transparent;
    height: 30px;
    width: 90px;
    border: 1px solid black;
    margin-left: 85px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media only screen and (max-width: 1300px) {
      margin-left: 25px;
      p {
        font-size: 10px;
      }
    }

    @media only screen and (max-width: 1025px) {
      margin-left: 3px;
      height: 25px;

      p {
        font-size: 10px;
      }
    }
  }
`;

const ImageContainer = styled.img`
  display: flex;
  height: 170px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;

  @media only screen and (max-width: 1300px) {
    height: 200px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 90%;
  margin: 0 auto;


  img {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .derecha{
    transform: rotate(180deg);
  }
`;

export default Notes;
