import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import { useParams } from "react-router-dom";
import benefits from "../jsons/benefitscards.json";
import largebenefits from "../jsons/largebenefitscards.json";
import FormatedText from "../components/FormatedText";
import DownloadCardPhone from "../components/DownloadCardPhone";

function Benefit() {
  const params = useParams();

  const [width, setWidth] = useState(null);
  const getWidth = () => divRef?.current?.offsetWidth;
  const divRef = useRef(null);

  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setTotalWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [data, setData] = useState({});
  useEffect(() => {
    const benefitId = parseInt(params.id);
    if (benefitId === 100 || benefitId === 200) {
      const filteredData = largebenefits.filter(
        (item) => item.id === benefitId
      );
      setData(filteredData[0]);
    } else {
      const filteredData = benefits.filter((item) => item.id === benefitId);
      setData(filteredData[0]);
    }
  }, [params]);

  return (
    <>
      <BenefitSection>
        <NavbarFixed view="benefit" />
        {data ? (
          <>
            <BenefitContainer>
              <ImageContainer>
                <CardImage
                  src={totalWidth > 680 ? data.image : data.imagemobile}
                  alt="card image"
                />
                <Logo src={data.logo} alt="logo" />
              </ImageContainer>

              <h3>{data.title}</h3>
              <h4>
                <FormatedText data={data.description} />
              </h4>
              <h6>
                {data.category} - {data.prize} - Modalidad de pago{" "}
                {data.payment} - {data.minors}
              </h6>
              <h5>Validez</h5>
              <h6>{data.validity}</h6>

              <h5>¿Cómo lo uso?</h5>
              <p>
                <FormatedText data={data.use} />{" "}
              </p>

              <h5>bases y condiciones</h5>
              <p className="bases">{data.termsconditions}</p>
            </BenefitContainer>
          </>
        ) : (
          <></>
        )}

        <DownloadCardPhone />

        <Footer />
      </BenefitSection>
    </>
  );
}

const BenefitSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  min-height: 100vh;
  margin: 0 auto;
  justify-content: center;
  height: auto;
  padding-bottom: 150px;
  padding-top: 150px;
  @media only screen and (max-width: 900px) {
    width: 85%;
    padding-top: 100px;
  }

  h3 {
    max-width: 800px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;

    @media only screen and (max-width: 900px) {
      font-size: 35px;
      line-height: 35px;
    }
  }

  h4 {
    line-height: 20px;
    /* max-width: 700px; */
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.008px;
    letter-spacing: 1px;
    overflow-wrap: break-word;

    @media only screen and (max-width: 900px) {
      font-size: 25px;
      line-height: 28px;
    }
  }

  h5 {
    color: #000;

    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.984px;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  h6 {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    margin-top: 10px;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .bases {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  p {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 18.605px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.008px;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 210px;
  border-radius: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px;
  position: relative;

  @media only screen and (max-width: 680px) {
    height: 300px;
    width: 100%;
    margin-bottom: 50px;
  }
`;

const Logo = styled.img`
  position: absolute;
  bottom: -15%;
  left: 5%;
  width: 15%;

  @media only screen and (max-width: 680px) {
    height: 100px;
    width: 100px;
    bottom: -30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: grey;
  border-radius: 30px;
  object-fit: cover;
`;

export default Benefit;
