import React from "react";
import NavbarFixed from "../../structure/NavbarFixed";
import Footer from "../../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../../styles/texts";

function Ysy() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>LEGALES PREVENTA YSA A</TermsTitle>
          <TermsText>
            PREVENTA EXCLUSIVA DE ENTRADAS PARA CLIENTES N1U DESDE EL 11/10/2023
            A LAS 18 HS AL 13/10/2023 HASTA LAS 18 HS O HASTA AGOTAR STOCK, LO
            QUE SUCEDA PRIMERO, PARA COMPRAS CON TARJETA PREPAGA N1U VISA FÍSICA
            O VIRTUAL. PODRÁ ADQUIRIR SUS ENTRADAS A TRAVÉS DE
          <a href="https://www.tuentrada.com/">  <span> https://www.tuentrada.com/</span></a> , STOCK DISPONIBLE DE ENTRADAS: 11.000.-
            <br />
            <br />
            <br />
            LA PRESENTE PROMOCIÓN ES VÁLIDA SÓLO PARA CONSUMOS DE TIPO DE
            FAMILIAR. TOPE DE VENTA POR PERSONA: 2 (DOS) TICKETS.
            <br />
            <br />
            PARA MÁS INFORMACIÓN CONTACTARSE CON AYUDA@N1U.APP
            <br />
            <br />
            BLOCKCHAIN PAYMENTS RAILS S.A ES UNA SOCIEDAD ANÓNIMA BAJO LA LEY
            ARGENTINA LOS SOCIOS LIMITAN SU RESPONSABILIDAD A LA INTEGRACIÓN DE
            LAS ACCIONES SUSCRIPTAS. (LEY 25738).
            <br />
            <br />
            <br />
            N1U SE ENCUENTRA LIMITADA A OFRECER SERVICIOS DE PAGO Y NO SE
            ENCUENTRA AUTORIZADA A OPERAR COMO ENTIDAD FINANCIERA POR EL BCRA.
            <br />
            <br />
            LOS FONDOS DEPOSITADOS EN LAS CUENTAS DE PAGO OFRECIDAS POR N1U, NO
            CONSTITUYEN DEPÓSITOS EN UNA ENTIDAD FINANCIERA, NI CUENTAN CON
            NINGUNA DE LAS GARANTÍAS QUE TALES DEPÓSITOS PUEDAN GOZAR DE ACUERDO
            CON LA LEGISLACIÓN Y REGLAMENTACIÓN APLICABLES EN MATERIA DE
            DEPÓSITOS EN ENTIDADES FINANCIERAS.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default Ysy;
