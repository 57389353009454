import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import BenefitCard from "../components/BenefitCard";
import { CardTitle, CardText, CardLink } from "../styles/texts";
import { Link, useNavigate } from "react-router-dom";
import cards from "../jsons/benefitscards.json";
import largecards from "../jsons/largebenefitscards.json";
import drop from "../assets/benefits/drop white.svg";
import ios from "../assets/home/homecover/IOS buttom (1).png";
import googleplay from "../assets/home/homecover/Google play buttom (1).png";
import "../App.css";

function Benefits() {
  useEffect(() => {
    const savedCategory = localStorage.getItem("selectedCategory");
    if (savedCategory) {
      setSelectedCategory(savedCategory);
      setShowFilteredCards(true);
    }
  }, []);

  const navigate = useNavigate();

  const categorias = [
    "Todas las categorías",
    "Gaming",
    "Cines",
    "Experiencias",
    "Morfi",
    "n1u learn",
    "Transporte",
    "Viajes",
    "Delivery"
  ];

  const [selectedCategory, setSelectedCategory] = useState("");
  const [showFilteredCards, setShowFilteredCards] = useState(false);

  const handleNavigation = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setShowFilteredCards(true);
  };

  const filteredCards = showFilteredCards
    ? cards.filter(
        (card) =>
          selectedCategory === "Todas las categorías" ||
          card.category === selectedCategory
      )
    : cards;

  return (
    <>
      <BenefitsSection>
        <NavbarFixed />
        <Cover>
          <ContentWraper>
            <StoreHeading>store</StoreHeading>
            <SubHeading>
              Cumplí misiones, sumá n1u coins y<br />
              <b>canjealas en tus plataformas favoritas.</b>
            </SubHeading>

            <div className="buttons">
              <Link
                to="https://apps.apple.com/ar/app/n1u/id6446706311?l=en"
                target="_blank"
              >
                <img src={ios} alt="ios" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=n1u.app&pli=1"
                target="_blank"
              >
                <img src={googleplay} alt="googleplay" />
              </Link>
            </div>
          </ContentWraper>
        </Cover>
        <BenefitsContainer>
          <CategorySearchSection
            categorias={categorias}
            selectedCategory={selectedCategory}
            handleCategorySelect={handleCategorySelect}
          />

          <PrincipalBox>
            {largecards.map((card, index) => (
              <LargeCard card={card} index={index} />
            ))}
          </PrincipalBox>

          <Box>
            {filteredCards.map((card, index) => (
              <Link
                to={"/store/" + card.id}
                onClick={() => handleNavigation("/store")}
                style={{ textDecoration: "none" }}
              >
                <BenefitCard card={card} index={index} />
              </Link>
            ))}
          </Box>
        </BenefitsContainer>
      </BenefitsSection>
      <Footer />
    </>
  );
}

const LargeCard = ({ card, index }) => {
  const navigate = useNavigate();

  const handleNavigation = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Link
        to={"/store/" + card.id}
        onClick={() => handleNavigation("/store")}
        style={{ textDecoration: "none" }}
      >
        <Item key={index}>
          <CardImage src={card.image} alt="card logo" />
          <div className="logo">
            <img alt="logo" src={card.logo} />
          </div>
          <div>
            <CardTitle>{card.title}</CardTitle>
            <CardText>{card.content}</CardText>
            <CardLink>ver más</CardLink>
          </div>
        </Item>
      </Link>
    </>
  );
};

const CategorySearchSection = ({
  categorias,
  selectedCategory,
  handleCategorySelect,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionClick = (category) => {
    handleCategorySelect(category);
    setShowOptions(false);
    localStorage.setItem("selectedCategory", category);
  };

  return (
    <CategorySearchContainer>

      <CategoryInputContainer onClick={() => setShowOptions(!showOptions)}>
        <InputContainer>
          <div>
            <CategoryInput
              // onClick={() => setShowOptions(!showOptions)}
              placeholder="Buscar categoría"
              value={selectedCategory}
              readOnly
            />
          </div>
          <div className="image">
            <CategoryImage src={drop} alt="drop" />
          </div>
        </InputContainer>

      </CategoryInputContainer>

      {showOptions && (
        <CategoryOptions>
          {categorias.map((category, index) => (
            <CategoryOption
              key={index}
              onClick={() => handleOptionClick(category)}
            >
              {category}
            </CategoryOption>
          ))}
        </CategoryOptions>
      )}
    </CategorySearchContainer>
  );
};

const BenefitsSection = styled.div`
  height: auto;
  width: 100%;
  margin: auto;
  background: linear-gradient(-45deg, #5c0a33, black, #5c0a33, black);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  @media only screen and (max-width: 845px) {
    padding-top: 50px;
  }

  h5 {
    padding: 30px 0;
    text-align: center;
    color: #fff;
    font-family: "LoRes";
    display: flex;
    justify-content: center;
    /* font-family: LoRes 9 OT; */
    font-size: 28.125px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px; /* 133.333% */
  }
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

const Cover = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url("/backgrounds/background-cover-store.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;


  @media only screen and (min-width: 2000px) {
    height: auto;
    padding: 180px 0px;
  }
`;

const ContentWraper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;

  .buttons {
    width: 420px;
    display: flex;
    justify-content: space-between;

    img {
      height: 60px;
      width: 190px;
    }

    @media only screen and (max-width: 845px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 200px;
      height: 140px;
      margin-top: 30px;
    }

    @media only screen and (max-width: 700px) {
      img {
        height: 55px;
        width: 174px;
      }
    }
  }
`;

const PrincipalBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 100px 0px;
  /* 
  display: grid;
  grid-template-columns: repeat(3, 1fr); */
  /* gap: 60px; */

  @media only screen and (min-width: 600px) and (max-width: 1350px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    height: 600px;
  }

  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const Box = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 0px 150px 0px; */
  padding-top: 30px;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 80px;

  @media only screen and (max-width: 1350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 820px) {
    grid-template-columns: repeat(1, 1fr);
  }
    
    
  @media only screen and (max-width: 750px) {
    padding: 150px 0px 150px 0px;
  }
`;

const Item = styled.div`
  color: white;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 255px;
  position: relative;
  width: 520px;

  div {
    padding-left: 20px;
    margin-top: 20px;
  }

  .logo {
    height: 80px;
    width: 60px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 20px;
    top: 50px;
    background-image: url(${(props) => props.backgroundimage});

    img {
      height: 80px;
      width: 80px;
      padding: 0px !important;
      margin-left: -20px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    margin: 10px 0px;
  }
`;

const CardImage = styled.img`
  width: 520px;
  height: 150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -10px;
  object-fit: cover; /* Ajusta la imagen para cubrir el contenedor sin deformarla */
  object-position: center; /* Centra la imagen dentro del contenedor */
`;

const CategorySearchContainer = styled.div`
  position: relative;
  border-radius: 10px;
  display: flex;
  width: 95%;
  margin-top: 50px;

  @media only screen and (max-width: 1350px) {
    justify-content: center;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 50px;

  div {
    margin: 10px;
  }

  .image {
    margin-left: -40px;
  }

  @media only screen and (max-width: 560px) {
    width: 300px;
  }
`;

const CategoryInputContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CategoryImage = styled.img`
  height: 10px;
  width: 15px;
  align-self: center;
`;
const CategoryInput = styled.input`
  width: 460px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
  font-family: "LoRes";
  height: 40px;
  border: none;
  color: #fff;
  /* font-family: LoRes 9 OT; */
  font-size: 16.25px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.5px;
  letter-spacing: -0.487px;


  @media only screen and (max-width: 560px) {
    width: 100%;
  }

  ::placeholder {
    font-family: "LoRes";
  }
  &:focus {
    outline: none;
    border-color: transparent;
  }
`;

const CategoryOptions = styled.div`
  position: absolute;
  top: 100%;

  width: 100%;
  background-color: #2e0619;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 100;
  width: 500px;


  @media only screen and (max-width: 560px) {
    width: 300px;
  }
  
`;

const CategoryOption = styled.div`
  padding: 10px;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

const StoreHeading = styled.h1`
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: center;
  font-size: 218.75px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 40px;
  line-height: 100%;
  margin-bottom: 0px;

  @media only screen and (max-width: 845px) {
    font-size: 170px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 120px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 110px;
  }
`;

const SubHeading = styled.h2`
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 47.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-height: 100%;
  margin-top: 0px;
  text-align: center;

  b {
    font-weight: 900;
  }

  @media only screen and (max-width: 845px) {
    font-size: 35px;
    line-height: 100%;
  }

  @media only screen and (max-width: 700px) {
    font-size: 25px;
    line-height: 100%;
  }

  @media only screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 120%;
  }
`;

export default Benefits;
