import React from "react";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";

import {
  TermsSection,
  TermsContainer,
  TermsTitle,
  TermsText,
} from "../styles/texts";

function ForbiddenActivities() {
  return (
    <>
      <TermsSection>
        <NavbarFixed />
        <TermsContainer>
          <TermsTitle>Actividades Prohibidas</TermsTitle>

          <TermsText>
           <b> 8- Actividades Prohibidas</b>
            <br />
            <br />
            Los presentes Términos y Condiciones aplicables al uso de la Cuenta
            (“Condiciones de Uso”) establecen las reglas para las actividades
            consideradas prohibidas para los Usuarios de los servicios de n1u.
            Las presentes condiciones de Uso deben considerarse como parte
            integrante de los Términos y Condiciones Generales del Servicio.
            <br />
            <br />
            Las palabras en mayúscula no definidas en estos términos y
            condiciones tendrán el significado provisto en los Términos y
            Condiciones de Uso.
            <br />
            <br />
            Mediante estas Condiciones de Uso, además de las restantes
            obligaciones asumidas en Términos y Condiciones de Uso, el Usuario
            acepta y asume la obligación de NO realizar las actividades,
            prácticas o conductas que se detallan a continuación, en el marco
            del uso del Servicio:
            <br />
            <br />
            ‍a) Utilizar los contenidos de forma, con fines o efectos contrarios
            a la ley, a la moral y a las buenas costumbres generalmente
            aceptadas o al orden público.
            <br />
            <br />
            ‍b) Reproducir, copiar, representar, utilizar, distribuir,
            transformar o modificar los contenidos de la aplicación, por
            cualquier procedimiento o sobre cualquier soporte, total o parcial,
            o permitir el acceso del público a través de cualquier modalidad de
            comunicación pública.
            <br />
            <br />
            ‍c) Utilizar los contenidos de cualquier manera que importe un
            riesgo de daño o inutilización de la aplicación o de los contenidos
            o de terceros.
            <br />
            <br />
            ‍d) Suprimir, eludir o manipular el derecho de autor y demás datos
            identificativos de los derechos de autor incorporados a los
            contenidos, así como los dispositivos técnicos de protección, o
            cualesquiera mecanismos de información que pudieren tener los
            contenidos.
            <br />
            <br />
            ‍e) Emplear los contenidos y, en particular, la información de
            cualquier clase obtenida a través de la aplicación para distribuir,
            transmitir, remitir, modificar, re usar o reportar la publicidad o
            los contenidos de esta con fines de venta directa o con cualquier
            otra clase de finalidad comercial, mensajes no solicitados dirigidos
            a una pluralidad de personas con independencia de su finalidad, así
            como comercializar o divulgar de cualquier modo dicha información.
            <br />
            <br />
            ‍f) Permitir que terceros ajenos al Usuario usen la aplicación con
            su clave.
            <br />
            <br />
            ‍g) Utilizar la aplicación y los contenidos con fines ilícitos,
            contrarios a lo establecido en estos Términos y Condiciones, o al
            uso mismo de la aplicación, que sean lesivos de los derechos e
            intereses de terceros, o que de cualquier forma puedan dañar,
            inutilizar, sobrecargar o deteriorar la aplicación y los contenidos
            o impedir la normal utilización o disfrute de esta y de los
            contenidos por parte de los restantes usuarios.
            <br />
            <br />
            ‍h) Enviar spam, publicidad no solicitada o no autorizada, cartas
            encadenadas, o cualquier otro tipo de comunicaciones ilícitas.
            <br />
            <br />
            ‍i) Vulnerar derechos marcarios o cualquier otra propiedad
            intelectual de n1u o de terceros.
            <br />
            <br />
            ‍j) Cargar, transmitir, o facilitar material que contenga virus
            informáticos o cualquier otro código, archivo o programa informático
            diseñado para interrumpir, destruir o limitar la funcionalidad de
            cualquier software o hardware o equipo de telecomunicaciones de n1u.
            <br />
            <br />
            ‍k) Utilizar los Servicios de la aplicación para: i) reventa de
            entradas para espectáculos; ii) comercialización de sustancias
            medicinales y/o químicas no permitidas por la regulación vigente, o
            que exijan autorizaciones o disposiciones especiales para su
            comercialización; iii) comercialización de listas de correo o base
            de datos personales; iv) comercialización de contenidos relacionados
            con la pornografía; v) comercialización de productos de hacking y
            cracking (herramientas para introducir virus o hack); vi)
            comercialización de armas de fuego y artículos relacionados; vii)
            comercialización de servicios y/o productos para alteración de
            telefonía celular; viii) realización de préstamos de dinero, auto
            préstamos, y remesas; ix) casinos, venta de fichas y/o cualquier
            otro mecanismo; x) personas que operen desde apartados postales
            (P.O. box numbers, o similares); xi) prostitución; xii) negocios
            ilegales o que aparenten estar comprometidos con actividades
            ilegales; xiii) gambling
            <br />
            <br />
            ‍l) Utilizar el Servicio fuera del territorio autorizado y/o
            procesar transacciones en otra moneda que no sea de las autorizadas.
            <br />
            <br />
            ‍m) Discriminar entre las Tarjetas en cualquiera de sus formas y por
            cualquier motivo, sin perjuicio de los descuentos, cuotas u otras
            acciones promocionales que pudieran existir con respecto a la
            utilización de cualquiera de las Tarjetas a través de n1u .
            <br />
            <br />
            ‍n) Aceptar pagos por transacciones no originadas de una operación
            directa entre el Usuario y el comprador.
            <br />
            <br />
            ‍o) Aceptar pagos que el Usuario sepa o deba saber que son
            fraudulentos o no autorizados por el titular de la Tarjeta, de
            acuerdo con las normas legales y los presentes Términos y
            Condiciones del Servicio.
            <br />
            <br />
            ‍p) Usar el Servicio para procesar pagos para terceros o transferir
            fondos para terceros.
            <br />
            <br />
            ‍q) Usar el Servicio con la sola finalidad de desembolsar efectivo,
            ya sea en beneficio del comprador, del Usuario o de terceros. El
            Usuario no deberá efectuar operaciones que puedan considerarse o
            verse como un adelanto de efectivo o préstamo. Se considerará
            adelanto de efectivo a cualquier operación en la que un Usuario
            agregue fondos a su Cuenta de Usuario mediante operaciones de cobro
            con Tarjetas propias y/o de personas humanas o jurídicas
            relacionadas al Usuario para retirar dichos fondos, y en general a
            cualquier operación de cobro de Tarjetas en la medida de que no se
            correspondan con una operación auténtica de venta o prestación de
            servicios. n1u se reserva el derecho de suspender temporalmente o
            bloquear definitivamente al Usuario que incumpla con las
            obligaciones de uso y con los presentes Términos y Condiciones, sin
            previo aviso ni necesidad de fundar la decisión, con el único
            requisito de informar al Usuario la infracción cometida que ha
            motivado la baja o suspensión como Usuario.
          </TermsText>
        </TermsContainer>
        <Footer />
      </TermsSection>
    </>
  );
}

export default ForbiddenActivities;
