import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import styled from "styled-components";
import NavbarFixed from "../structure/NavbarFixed";
import Footer from "../structure/Footer";
import background from "../assets/codes/background-contact.svg";
import backgroundmobile from "../assets/codes/background-contact-mobile.svg";
import lines from "../assets/home/homecover/lines-button.svg";
import DownloadCardPhone from "../components/DownloadCardPhone";
import { ColorRing } from "react-loader-spinner";

function Contact() {
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [sending, setSending] = useState(false);

  const frmContact = {
    user_firtname: ``,
    user_lastname: ``,
    user_email: ``,
    user_phone: ``,
    message: ``,
  };
  const [contact, setContact] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    emailjs
      .send(`service_dd575ae`, `template_9efywsm`, contact, `gzwkT-tCxp6DovTXX`)
      .then(
        (response) => {
          console.log(`SUCCESS!`, response.status, response.text);
          setContact(frmContact);
          setSending(false);
          setMessageSent(true);
        },
        (err) => {
          setSending(false);
          setMessageError(true);
          console.log(`FAILED...`, err);
        }
      );
  };

  const [width, setWidth] = useState(null);
  const getWidth = () => divRef?.current?.offsetWidth;
  const medium = 500;
  const divRef = useRef(null);

  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setTotalWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ContactSection>
        <NavbarFixed />
        <Image
          src={totalWidth > 700 ? background : backgroundmobile}
          alt="background"
        />

        {messageSent ? (
          <MessageSentContainer>
            <div>
              <h1>¡Recibimos tu mensaje!</h1>

              <h2>
                En las próximas horas se pondrán en contacto vía mail para poder
                resolver tu situación.
              </h2>
            </div>
          </MessageSentContainer>
        ) : (
          <>
            {messageError ? (
              <>
                <MessageSentContainer>
                  <div>
                    <h1>¡Ups!</h1>

                    <h2>
                      Algo no salió como esperábamos y no pudimos procesar tu
                      consulta. Por favor volvé a intentar en los próximos
                      segundos.
                    </h2>
                  </div>
                </MessageSentContainer>
              </>
            ) : (
              <>
                {sending ? (
                  <>
                    <RingContainer>
                      <div>
                        <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
               
                    colors={[
                      "#000000",
                 
                    ]}
                  />
                      </div>
                    </RingContainer>
                  </>
                ) : (
                  <>
                    <ContactContainer>
                      <h1>¡Contactanos!</h1>
                      <h2>Estamos para ayudarte.</h2>

                      <FormContainer>
                        <form onSubmit={handleSubmit}>
                          {totalWidth > medium ? (
                            <>
                              <InputContainer>
                                <div className="label-container">
                                  <p>nombre y apellido</p>
                                </div>

                                <div className="input-container">
                                  <input
                                    type="text"
                                    required
                                    value={contact.user_firstname}
                                    name="user_firstname"
                                    onChange={handleChange}
                                  />
                                  <input
                                    className="apellido"
                                    type="text"
                                    required
                                    value={contact.user_lastname}
                                    name="user_lastname"
                                    onChange={handleChange}
                                  />
                                </div>
                              </InputContainer>
                            </>
                          ) : (
                            <>
                              {" "}
                              <InputContainerMobileName>
                                <div className="mobile-div">
                                  <p>nombre</p>
                                  <input
                                    type="text"
                                    required
                                    value={contact.user_firstname}
                                    name="user_firstname"
                                    onChange={handleChange}
                                  />
                                </div>

                                <div className="mobile-div">
                                  <p>apellido</p>
                                  <input
                                    type="text"
                                    required
                                    value={contact.user_lastname}
                                    name="user_lastname"
                                    onChange={handleChange}
                                  />
                                </div>
                              </InputContainerMobileName>
                            </>
                          )}

                          <InputContainer>
                            <div className="label-container">
                              <p>email</p>
                            </div>
                            <div className="input-container">
                              <input
                                className="short"
                                value={contact.user_email}
                                onChange={handleChange}
                                name="user_email"
                                type="text"
                                required
                              />
                            </div>
                          </InputContainer>

                          <InputContainer>
                            <div className="label-container">
                              <p>phone</p>
                            </div>
                            <div className="input-container ">
                              <input
                                className="short"
                                value={contact.user_phone}
                                onChange={handleChange}
                                name="user_phone"
                                type="text"
                                required
                              />
                            </div>
                          </InputContainer>

                          <InputContainer>
                            <div className="label-container">
                              <p>mensaje</p>
                            </div>
                            <div className="input-container">
                              <textarea
                                className="mensaje"
                                name="message"
                                onChange={handleChange}
                                type="text"
                                required
                                value={contact.message}
                              />
                            </div>
                          </InputContainer>

                          <ButtonLine>
                            <ButtonContainer>
                              <Lines src={lines} alt="lines" />

                              <button type="submit" className="button">
                                {" "}
                                <p>enviar</p>
                              </button>
                            </ButtonContainer>
                          </ButtonLine>
                        </form>
                      </FormContainer>
                    </ContactContainer>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContactSection>
      <DownloadCardPhone />
      <Footer />
    </>
  );
}

const ContactSection = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  margin-bottom: 50px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  margin: 0 auto;
  max-width: 1600px;
  padding-top: 110px;
  padding-bottom: 150px;

  h1 {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }

  h2 {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    margin: 0;
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 100px;
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 805px) {
    width: 80%;

    h1 {
      font-size: 23px;
    }
    h2 {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 85%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 100%;
  border: 1px solid white;
  margin-top: 40px;
  padding: 40px 0px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.09);
  align-items: center;

  form {
    width: 85%;
  }
  @media only screen and (max-width: 805px) {
    height: auto;
    width: auto;
  }
`;

const InputContainer = styled.div`
  width: 90%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;

  .label-container {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
  }

  .input-container {
    width: 70%;
    display: flex;
  }

  .short {
    width: 70%;
  }

  input {
    width: 100%;

    background-color: transparent !important;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    padding-left: 10px;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    height: 35px;
  }

  textarea {
    width: 100%;
    height: 80px;

    background-color: transparent !important;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    padding-left: 10px;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    padding-top: 10px;
  }

  p {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-top: 5px;
    width: 200px;
    line-height: auto;
  }

  .apellido {
    margin-left: 15px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 60px;

    .label-container {
      width: 100%;
    }

    .input-container {
      width: 100%;
    }

    .short {
      width: 100%;
    }
  }
`;

const ButtonLine = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 80px;
  @media only screen and (max-width: 550px) {
    margin-top: 110px;
  }
`;

const ButtonContainer = styled.div`
  height: 100px;
  width: 280px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end !important;

  .button {
    background-color: #ff009c;
    color: white;
    border-radius: 30px;
    display: flex;
    height: 50px;
    width: 80%;
    justify-content: center !important;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;

    p {
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.5px;
      display: flex;
      align-self: center;
      justify-content: center;
      margin-top: 12.5px;
    }
  }
`;

const Lines = styled.img`
  position: absolute;
  z-index: 1;
  height: 120px;
`;

const RingContainer = styled.div`
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 auto;

  div {
    display: flex;
  }
`;

const MessageSentContainer = styled.div`
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  div {
    width: 300px;
    background-color: black;
    padding: 35px;
    border-radius: 20px;

    @media only screen and (max-width: 550px) {
      width: 90%;
      padding: 20px;
    }
  }

  h1 {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  h2 {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

const InputContainerMobileName = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 20px;

  .mobile-div {
    width: 45%;
  }

  input {
    width: 90%;
    background-color: transparent !important;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    padding-left: 10px;
    outline: none;
    font-size: 15px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    height: 35px;
  }
  p {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-top: 5px;
    line-height: auto;
  }

  .apellido {
    margin-left: 15px;
  }
`;

export default Contact;
