import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import rayo1 from "../assets/n1uverse/n1uverseicons/rayo 1 desk.png";
import rayo2 from "../assets/n1uverse/n1uverseicons/rayo 1 desk.png";
import rayo3 from "../assets/n1uverse/n1uverseicons/rayo 1 desk.png";
import rayo4 from "../assets/n1uverse/n1uverseicons/rayo 1 desk.png";
import comunitydesktop from "../assets/n1uverse/n1uverseicons/comunity-desktop-new.png";
import comunitymobile from "../assets/n1uverse/n1uverseicons/comunity-mobile-new.png";
import textmobile from "../assets/n1uverse/n1uverseicons/text-mobile 1 (3).png";
// import { Parallax } from 'react-scroll-parallax'

function Comunity() {
  const [width, setWidth] = useState(null);
  const getWidth = () => divRef?.current?.offsetWidth;
  const medium = 950;
  const divRef = useRef(null);

  const [totalWidth, setTotalWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setTotalWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ComunitySection>
        {totalWidth > medium ? (
          <>
            <SectionContainer>
              <BackgroundImage src={comunitydesktop} alt="comunity" />{" "}
              <Rayo1 src={rayo1} alt="rayo1" />
              <Rayo2 src={rayo2} alt="rayo2" />
              <Rayo3 src={rayo3} alt="rayo3" />
              <Rayo4 src={rayo4} alt="rayo4" />
            </SectionContainer>
          </>
        ) : (
          <>
            <SectionContainerMobile>
              <Rayo1 src={rayo1} alt="rayo1" />
              <Rayo2 src={rayo2} alt="rayo2" />
              <Rayo3 src={rayo3} alt="rayo3" />
              <Rayo4 src={rayo4} alt="rayo4" />
              <TextContainer>
                <img src={textmobile} alt="text" />
              </TextContainer>
              <ImageMobile src={comunitymobile} alt="comunity" />
            </SectionContainerMobile>
          </>
        )}
      </ComunitySection>
    </>
  );
}

const ComunitySection = styled.div`
  width: 100%;
  height: auto;
  z-index: 100;
  padding-bottom: 100px;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 30px;

  @media only screen and (max-width: 950px) {
    padding-bottom: 50px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    height: 50px;
    max-width: 1600px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    height: 50px;
    max-width: 1600px;
  }
`;

const SectionContainer = styled.div`
  /* position: relative; */
  width: 90%;
  height: 700px;
  overflow: hidden;
  margin: 0 auto;
  padding-top: -50px;
`;

const SectionContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 98%;
  min-width: 800px;
  z-index: 1000;

  @media only screen and (max-width: 1000px) {
    min-width: 900px;
  }

  @media only screen and (max-width: 900px) {
    max-width: 90%;
    max-height: 90%;
    min-width: 500px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  margin-top: 30px;
  max-width: 100%;
  z-index: 1000;
  img {
    width: 70%;
    height: 70%;
  }

  @media only screen and (max-width: 720px) {
    img {
      width: 80%;
      height: 80%;
    }
  }

  @media only screen and (max-width: 615px) {
    img {
      width: 90%;
      height: 90%;
    }
  }

  @media only screen and (max-width: 450px) {
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const ImageMobile = styled.img`
  height: 650px;
  z-index: 1000;
  margin-top: -270px;

  @media only screen and (max-width: 615px) {
    height: 500px;
    margin-top: -200px;
  }
  @media only screen and (max-width: 450px) {
    height: 400px;
    margin-top: -150px;
  }
`;

const Rayo1 = styled.img`
  height: 200px;
  position: absolute;
  top: 0%;


  @media only screen and (max-width: 950px) {
    height: 200px;
    left: 5%;
  }

  @media only screen and (max-width: 600px) {
    height: 150px;
    left: -8%;
  }
`;

const Rayo2 = styled.img`
  height: 440px;
  position: absolute;
  right: -10%;
  top: 0;

  margin-top: -100px;
  @media only screen and (max-width: 900px) {
    height: 300px;
    right: 0%;
  }

  @media only screen and (max-width: 600px) {
    height: 250px;
    right: -10%;
  }
`;

const Rayo3 = styled.img`
  height: 500px;
  position: absolute;
  bottom: 0;
  margin-left: -170px;
  margin-top: -180px;

  @media only screen and (max-width: 950px) {
    height: 250px;
    left: 0;
    margin-left: 0px;
  }

  @media only screen and (max-width: 600px) {
    height: 200px;
    left: -8%;
    bottom: -8%;
  }
`;

const Rayo4 = styled.img`
  height: 400px;
  position: absolute;
  right: 0;
  bottom: 0;

  margin-bottom: -50px;
  margin-right: -80px;

  @media only screen and (max-width: 950px) {
    height: 250px;
    right: 0;
    bottom: -10%;
    margin-bottom: 0px;
    margin-right: 0px;
  }

  @media only screen and (max-width: 600px) {
    height: 200px;
    right: -10%;
  }
`;

export default Comunity;
